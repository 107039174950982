import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";
import { routes } from "routes";

import { deleteUser } from "services/users";
import { isAnyAdmin } from "utils/isAnyAdmin";
import { getInternationalDateFormat } from "utils/getInternationalDateFormat";
import {
  clearState,
  setCurrentEditedRow,
  setCurrentPage,
  setCurrentRoleFilters,
  setEditedUserDetails,
  setIsRowDeleting,
  setShowDeleteConfirmation,
  updateUserListSorting,
} from "store/users/usersListSlice";
import { fetchUserList } from "store/users/thunks";
import { setSearchTerm } from "store/search/searchbarSlice";

import { ListPageHeaderPlaceholder } from "common-layouts/ListPagePlaceholder/ListPageHeaderPlaceholder";
import { EmptyState } from "components/EmptyState";
import { ErrorScreen } from "components/ErrorScreen";
import { UserListPageHeader } from "./_layouts/UserListPageHeader";
import { UserDetailsPopup } from "./_layouts/UserDetailsPopup";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";
import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { TextTruncator } from "components/TextTruncator";
import { UserAvatar } from "components/UserAvatar.tsx";
import { Text } from "components/Text";

import { USER_LIST_HEADERS } from "./constants";
import { Color } from "ts/enums/color";
import { FlexTableType } from "ts/enums/flexTable";
import { AvatarSize } from "ts/enums/avatarSize";
import { PageErrorType } from "ts/enums/pageErrorType";
import { User } from "ts/user";
import { RoleType } from "@explorance/mly-types";
import { EmptyStateType } from "ts/enums/emptyStateType";

const getValidRoleQueryParamValues = (queryParams: URLSearchParams) => {
  const queryParamVals = queryParams.get("role")?.split(" ");
  if (!queryParamVals) return [];

  const allRoleTypes: string[] = Object.values(RoleType);
  return queryParamVals.filter((roleValue) => allRoleTypes.includes(roleValue));
};

export const UserListPage = () => {
  // redux
  const state = useAppSelector((state) => state.userList);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  // hooks
  const history = useHistory();
  const location = history.location;
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const sortColumn = USER_LIST_HEADERS[updatedSorting.columnIndex].sortingParameter;
    dispatch(updateUserListSorting({ sortColumn, sortOrder: updatedSorting.order }));
    dispatch(fetchUserList());
  };

  const clearSearchCriteria = () => {
    dispatch(setSearchTerm(""));
    dispatch(setCurrentRoleFilters([]));

    queryParams.delete("role");
    history.push(location.pathname + location.hash + "?" + queryParams.toString());
  };

  //Dots Menu Functions

  const getDotsMenuContent = (user: User) => {
    return [
      {
        label: <Text resource="tooltip.editUser" />,
        href: routes.updateProfilePage(user.id),
        show: true,
      },
      {
        label: <Text resource="userList.table.dropdown.viewUserDetails" />,
        onClick: () => dispatch(setEditedUserDetails(user.demographics)),
        show: user.demographics && Object.keys(user.demographics).length > 0,
      },
      {
        label: <Text resource="modal.deleteUser.title" />,
        onClick: () => {
          dispatch(setShowDeleteConfirmation(true));
        },
        show: currentUser.id !== user.id,
      },
    ];
  };

  const confirmDeleteUser = async () => {
    dispatch(setIsRowDeleting(true));
    dispatch(setShowDeleteConfirmation(false));
    try {
      await deleteUser(state.currentEditedRow.id);
      dispatch(fetchUserList());
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setIsRowDeleting(false));
      dispatch(setCurrentEditedRow(null));
    }
  };

  // useEffects
  useEffect(() => {
    dispatch(fetchUserList());
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    setCurrentRoleFilters(getValidRoleQueryParamValues(queryParams) as RoleType[]);
  }, [queryParams]);

  useEffect(() => {
    if (state.currentRoleFilters.length !== 0)
      queryParams.set("role", state.currentRoleFilters.join(" "));
    else queryParams.delete("role");
    history.push(location.pathname + location.hash + "?" + queryParams.toString());
    setCurrentPage(1);
  }, [state.currentRoleFilters, history, queryParams]); // eslint-disable-line

  if (!isAnyAdmin(currentUser.roleType)) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <StyledUserListContent
      className="fade-in"
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => e.preventDefault()}
    >
      {state.isLoading ? (
        <ListPageHeaderPlaceholder showCreateButton showFilterButton applyMorePadding />
      ) : (
        <UserListPageHeader />
      )}
      <div className="fade-in">
        {state.currentUserCount || state.isLoading ? (
          <>
            <ActionConfirmationModal
              isOpen={state.showDeleteConfirmation}
              title={<Text resource="modal.deleteUser.title" />}
              caption={<Text resource="modal.deleteUser.caption" />}
              actionButtonLabel={<Text resource="button.delete" />}
              onCancel={() => dispatch(setShowDeleteConfirmation(false))}
              onClickActionButton={confirmDeleteUser}
            />
            <FlexTable
              type={FlexTableType.Table}
              data={{
                headers: USER_LIST_HEADERS.map((header, index) =>
                  header.columnNameKey === "avatar" || header.columnNameKey === "menu" ? (
                    ""
                  ) : (
                    <Text key={index} resource={`userList.table.${header.columnNameKey}`} />
                  )
                ),
                rows: state.userList?.map((user) => ({
                  contentId: user.id,
                  data: [
                    <UserAvatar
                      key={`avatar-${user.id}`}
                      firstName={user.firstname}
                      lastName={user.lastname}
                      userId={user.id}
                      avatarSize={AvatarSize.sm}
                      customStyles={{ margin: "0px 0px 0px 15px" }}
                    />,
                    <TextTruncator
                      value={user.email}
                      id={user.id}
                      key={`username-${user.id}`}
                      customWidth="85%"
                    />,
                    <TextTruncator
                      value={user.firstname}
                      id={user.id}
                      key={`firstname-${user.id}`}
                      customWidth="85%"
                    />,
                    <TextTruncator
                      value={user.lastname}
                      id={user.id}
                      key={`lastname-${user.id}`}
                      customWidth="85%"
                    />,
                    <TextTruncator
                      value={<Text resource={`roleType.${user.roleType}`} />}
                      id={user.id}
                      key={`roleType-${user.id}`}
                      customWidth="85%"
                    />,
                    user.lastLogin ? (
                      <TextTruncator
                        value={getInternationalDateFormat(new Date(user.lastLogin))}
                        id={user.id}
                        key={`lastLogin-${user.id}`}
                        customWidth="85%"
                      />
                    ) : (
                      <StyledNeverLoggedIn key={`neverLoggedIn-${user.id}`}>
                        <Text resource="userList.table.neverLoggedIn" />
                      </StyledNeverLoggedIn>
                    ),
                  ],
                  dotsMenuParams: {
                    rowId: user.id,
                    isDeleting: state.isRowDeleting,
                    currentEditedRowId: state.currentEditedRow?.id,
                    menuContents: getDotsMenuContent(user).filter(({ show }) => show),
                    altDropdownContent: state.editedUserDetails && (
                      <UserDetailsPopup userDetails={state.editedUserDetails} />
                    ),
                    onDotMenuClick: () => dispatch(setCurrentEditedRow(user)),
                    handleHideUserDetails: () => dispatch(setEditedUserDetails(null)),
                  },
                })),
                columnWidths: ["15%", "18%", "18%", "16%", "15%", "15%", "3%"],
              }}
              handleDoubleClickRow={(rowId) => history.push(routes.updateProfilePage(rowId))}
              customStyles={{
                rows: {
                  padding: "10px",
                  backgroundColor: Color.white,
                },
              }}
              initialSorting={{
                columnIndex: USER_LIST_HEADERS.findIndex(
                  (h) => h.sortingParameter === state.sortColumn
                ),
                order: state.sortOrder,
              }}
              onSortingChange={updateSorting}
              isLoading={state.isLoading}
            />
          </>
        ) : (
          <EmptyState
            type={EmptyStateType.noSearchResults}
            handleClickCaptionLink={clearSearchCriteria}
          />
        )}
      </div>
    </StyledUserListContent>
  );
};

const StyledUserListContent = styled.div`
  width: 100%;
  font-family: Lato, sans-serif;
  font-size: 16px;
  padding-bottom: 40px;
  .content {
    margin: -10px 0px 0px 0px;
    padding: 0px;
  }
`;

export const StyledPillList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledNeverLoggedIn = styled.div`
  padding: 3px 8px;
  border-radius: 3px;
  background-color: ${Color.blue20};
  color: ${Color.gray50};
  width: max-content;
  font-size: 12px;
`;
