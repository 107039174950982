import React from "react";
import styled from "styled-components";
import Modal from "react-modal";

import { TextType } from "ts/TextType";
import { Color } from "ts/enums/color";
import { ButtonSize, ButtonVariant } from "ts/enums/button";

import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";

type props = {
  captionTitle: TextType;
  onClose(): void;
  onDiscard(): void;
  onSave(): void;
};

const modalStyle = {
  content: {
    height: "156px",
    width: "530px",
    padding: "30px",
    margin: "auto",
    overflow: "none",
    border: `1px solid ${Color.sky20}`,
    borderRadius: "5px",
    background: `${Color.white}`,
    boxShadow: "0px 3px 6px #00000029",
  },
};

export const UnsavedChangesModal = ({ captionTitle, onClose, onDiscard, onSave }: props) => {
  return (
    <Modal isOpen={true} style={modalStyle} shouldCloseOnEsc={true} onRequestClose={onClose}>
      <div>
        <StyledUnsavedModal>
          <StyledTitleContainer>
            <StyledTitle>{captionTitle}</StyledTitle>
            <div>?</div>
          </StyledTitleContainer>

          <StyledUnsavedButtons>
            <Button variant={ButtonVariant.light} size={ButtonSize.md} onClick={onClose}>
              <Text resource="button.cancel" />
            </Button>
            <div>
              <Button variant={ButtonVariant.light} size={ButtonSize.md} onClick={onDiscard}>
                <Text resource="button.discardChanges" />
              </Button>
              <Button size={ButtonSize.md} onClick={onSave} style={{ marginLeft: 12 }}>
                <Text resource="button.saveChanges" />
              </Button>
            </div>
          </StyledUnsavedButtons>
        </StyledUnsavedModal>
      </div>
    </Modal>
  );
};

const StyledTitleContainer = styled.div`
  display: flex;
  width: 440px;
  font-size: 1.3125rem;
  color: ${Color.black};
  font-weight: bold;
`;

const StyledTitle = styled.div`
  max-width: 97%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledUnsavedModal = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 0;
`;

const StyledUnsavedButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;
