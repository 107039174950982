import React from "react";
import styled from "styled-components";
import { useAppSelector } from "store";
import CreatableSelect from "react-select/creatable";

import { useUserProfile } from "context/UserProfileContext";

import { StyledUserEditorBlock } from "./StyledUserEditorBlock";
import { CollapsibleSection } from "components/CollapsibleSection";

import { isAnyAdmin } from "utils/isAnyAdmin";

import { Color } from "ts/enums/color";

const getOptions = (demograhicValues: string[]) =>
  demograhicValues.map((value) => ({
    label: value || "-",
    value: value || null,
  }));

export const MainDemographicsBlock = () => {
  const { fields, setField, mainDemographics } = useUserProfile();
  const { currentUser } = useAppSelector((state) => state.auth);

  const dropdownStyles = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? ` 1px solid ${Color.blue50}` : `1px solid ${Color.sky50}`,
      boxShadow: "none",
      cursor: "text",
      borderRadius: "2px",
      "&:hover": {
        borderColor: "none",
      },
      backgroundColor: state.isDisabled ? Color.sky15 : Color.white,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      border: `1px solid ${Color.sky50}`,
    }),
    option: (base, { isFocused }) => ({
      ...base,
      color: isFocused ? Color.blue50 : Color.gray50,
      backgroundColor: isFocused ? Color.sky15 : "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: Color.gray30,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: Color.gray50,
    }),
  };

  return (
    <StyledUserEditorBlock>
      <CollapsibleSection
        titleLeftResource={"userProfile.mainDemographics.title"}
        hideBoxShadow
        hideHeaderBorder
      >
        <StyledExpandedSection>
          {mainDemographics.map((demographic) => (
            <StyledMainDemographicField key={demographic.name}>
              <h4>{demographic.name}</h4>
              <CreatableSelect
                menuPlacement="auto"
                key={demographic.name}
                options={getOptions(demographic.values)}
                onChange={(option) =>
                  setField("mainDemographics", {
                    ...fields.mainDemographics,
                    [demographic.name]: option?.value || null,
                  })
                }
                isSearchable
                styles={dropdownStyles}
                isDisabled={!isAnyAdmin(currentUser.roleType)}
                isClearable
                value={
                  fields.mainDemographics[demographic.name] && {
                    value: fields.mainDemographics[demographic.name],
                    label: fields.mainDemographics[demographic.name],
                  }
                }
              />
            </StyledMainDemographicField>
          ))}
        </StyledExpandedSection>
      </CollapsibleSection>
    </StyledUserEditorBlock>
  );
};

const StyledExpandedSection = styled.div`
  padding: 8px 8px 0 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`;

const StyledMainDemographicField = styled.div`
  min-width: 50%;
  h4 {
    margin-bottom: 6px;
    margin-top: 0;
    font-size: 15px;
    font-weight: normal;
  }
`;
