import React from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { TopicsListView } from "@explorance/mly-types";
import { Text } from "components/Text";

type Props = {
  currentView: TopicsListView;
  isLoading: boolean;
  toggleView: (view: TopicsListView) => void;
};

export const TopicViewToggle = ({ currentView, toggleView, isLoading }: Props) => {
  const changeView = (view: TopicsListView) => {
    if (view === currentView) return;
    toggleView(view);
  };

  return (
    <StyledTopicsToggle>
      <StyledSentimentsButton
        isActive={currentView === TopicsListView.Sentiments}
        onClick={!isLoading ? () => changeView(TopicsListView.Sentiments) : undefined}
        isLoading={isLoading}
      >
        <Text resource="analysisList.table.sentiments" />
      </StyledSentimentsButton>
      <StyledRecommendationsButton
        isActive={currentView === TopicsListView.Recommendations}
        onClick={!isLoading ? () => changeView(TopicsListView.Recommendations) : undefined}
        isLoading={isLoading}
      >
        <Text resource="analysisList.table.recommendations" />
      </StyledRecommendationsButton>
    </StyledTopicsToggle>
  );
};

const StyledTopicsToggle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const StyledButton = styled.button<{ isActive: boolean; isLoading: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  background-color: ${({ isActive }) => (isActive ? Color.sky30 : Color.white)};
  color: ${({ isActive }) => (isActive ? Color.blue40 : Color.gray40)};
  border: 1px solid ${({ isActive }) => (isActive ? Color.blue40 : Color.sky50)};
  transition: background-color 150ms ease-in, color 150ms ease-in, border 150ms ease-in;
  font-size: 0.875em;
  font-weight: bold;
  cursor: ${({ isLoading }) => (isLoading ? "default" : "pointer")};
`;

const StyledSentimentsButton = styled(StyledButton)<{ isActive: boolean }>`
  border-radius: 5px 0px 0px 5px;
`;

const StyledRecommendationsButton = styled(StyledButton)<{ isActive: boolean }>`
  border-radius: 0px 5px 5px 0px;
`;
