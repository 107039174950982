import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";

import { useAppDispatch, useAppSelector } from "store";
import {
  setUserInput,
  setShowCharacterCount,
  resetInput,
  setInitialInputHeight,
  setExpandedInputHeight,
  clearState,
} from "store/notes/discussionThreadSlice";
import { fetchDiscussionThreadNotes, submitDiscussionThreadNote } from "store/notes/thunks";
import { useResource } from "hooks/useResource";

import { DiscussionThreadNote } from "./DiscussionThreadNote";
import { DiscussionThreadPlaceholder } from "./DiscussionThreadPlaceholder";
import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";
import { UserAvatar } from "components/UserAvatar.tsx";
import { EmptyState } from "components/EmptyState";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { AvatarSize } from "ts/enums/avatarSize";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { Color } from "ts/enums/color";
import { NOTE_CHAR_LIMIT } from "assets/constants/noteCharacterLimit";
import { SocketEvent } from "@explorance/mly-types";

type Props = {
  closeHandler: () => void;
  updateCommentNotesCount: (id: number, notesCount: number) => void;
};

export const DiscussionThreadDrawer = ({ closeHandler, updateCommentNotesCount }: Props) => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const state = useAppSelector((state) => state.discussionThread);
  const socket = useAppSelector((state) => state.wsStream.socket);

  const dispatch = useAppDispatch();

  const discussionBodyRef = useRef(null);

  const { getResource } = useResource();

  const scrollToAddedNote = () => {
    discussionBodyRef?.current.scrollTo(0, discussionBodyRef.current.scrollHeight);
  };

  const handleAddNote = async () => {
    if (state.userInput.trim().length === 0) {
      dispatch(resetInput());
      return;
    }
    dispatch(submitDiscussionThreadNote());
    dispatch(resetInput());
    scrollToAddedNote();
    updateCommentNotesCount(state.comment.id, state.discussionThreadNotes.length + 1);
  };

  const handleBlur = () => {
    if (state.userInput.length > 0) return;
    dispatch(setShowCharacterCount(false));
    dispatch(setInitialInputHeight());
  };

  const handleFocus = () => {
    dispatch(setShowCharacterCount(true));
    dispatch(setExpandedInputHeight());
  };

  useEffect(() => {
    dispatch(fetchDiscussionThreadNotes(state.comment.id));
    return () => {
      dispatch(clearState());
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!socket) return;

    const handleUpdateDiscussionThread = () => {
      dispatch(fetchDiscussionThreadNotes(state.comment.id));
    };

    socket.on(SocketEvent.ChangedNote, handleUpdateDiscussionThread);

    return () => {
      socket.off(SocketEvent.ChangedNote, handleUpdateDiscussionThread);
    };
  }, [socket]); // eslint-disable-line

  return (
    <StyledDrawerContainer>
      <StyledCommentContainer>
        <StyledHeader>
          <Text resource="comments.discussionThread.sideDrawer.header" />
        </StyledHeader>
        <StyledComment>{state.comment.value}</StyledComment>
      </StyledCommentContainer>

      <StyledDiscussionBody ref={discussionBodyRef}>
        {state.isLoading ? (
          <DiscussionThreadPlaceholder />
        ) : state.discussionThreadNotes.length === 0 ? (
          <StyledEmptyStateContainer>
            <EmptyState
              type={EmptyStateType.noDiscussionThreadNotes}
              customStyles={{ marginTop: "0", imageWidth: "100px", imageHeight: "100px" }}
            />
          </StyledEmptyStateContainer>
        ) : (
          state.discussionThreadNotes.map((threadNote) => (
            <DiscussionThreadNote
              key={threadNote.id}
              noteContent={threadNote}
              isEditing={state.currentEditedNoteId === threadNote.id}
              updateCommentNotesCount={updateCommentNotesCount}
            />
          ))
        )}
      </StyledDiscussionBody>
      <div>
        <StyledInputSection>
          <UserAvatar
            firstName={currentUser.firstname}
            lastName={currentUser.lastname}
            userId={currentUser.id}
            avatarSize={AvatarSize.sm}
            customStyles={{ margin: "2px 0 0" }}
          />
          <StyledInputContainer showCharacterCount={state.showCharacterCount}>
            <StyledInput
              maxLength={NOTE_CHAR_LIMIT}
              placeholder={getResource("comments.discussionThread.input.placeholder")}
              onChange={(e) => dispatch(setUserInput(e.target.value))}
              value={state.userInput}
              height={state.inputHeight}
              onBlur={handleBlur}
              onFocus={handleFocus}
              showCharacterCount={state.showCharacterCount}
            />
            {state.showCharacterCount && (
              <StyledCharacterCount>
                <span>{state.userInput.length}</span>
                <Text
                  resource={{
                    key: "input.validation.characterCount",
                    args: [`${NOTE_CHAR_LIMIT}`],
                  }}
                />
              </StyledCharacterCount>
            )}
          </StyledInputContainer>
        </StyledInputSection>

        <StyledActionSection>
          <Button variant={ButtonVariant.light} onClick={closeHandler}>
            <Text resource="button.close" />
          </Button>
          <Button variant={ButtonVariant.primary} size={ButtonSize.ml} onClick={handleAddNote}>
            <Text resource="comments.discussionThread.sideDrawer.button.addComment" />
          </Button>
        </StyledActionSection>
      </div>
    </StyledDrawerContainer>
  );
};

const StyledDrawerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
`;

const StyledHeader = styled.div`
  font-size: 12px;
  color: ${Color.gray30};
  font-weight: bold;
  margin-bottom: 5px;
`;

const StyledCommentContainer = styled.div`
  padding: 12px;
  background-color: ${Color.neutral10};
`;

const StyledComment = styled.div`
  color: ${Color.gray50};
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 50px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const StyledDiscussionBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 12px 16px;
`;

const StyledEmptyStateContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledActionSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 18px 10px;
`;

const StyledInputSection = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${Color.sky20};
  padding: 12px 12px 0;
  gap: 8px;
`;

const StyledInputContainer = styled.div<{ showCharacterCount: boolean }>`
  width: 418px;
  border: 1px solid ${Color.sky50};
  display: flex;
  flex-direction: column;
  ${({ showCharacterCount }) =>
    showCharacterCount &&
    css`
      padding: 8px 10px;
    `};

  :focus-within {
    border: 1px solid ${Color.blue50};
  }
`;

const StyledInput = styled.textarea<{ height: number; showCharacterCount: boolean }>`
  width: 100%;
  height: ${({ height }) => height}px;
  transition: height 0.3s ease-out;
  border-radius: 2px;
  display: flex;
  align-items: center;
  color: ${Color.gray50};
  border-radius: 2px;
  font-size: 15px;
  font-family: Lato;
  resize: none;
  border: none;
  cursor: auto;

  ${({ showCharacterCount }) =>
    !showCharacterCount &&
    css`
      overflow: hidden;
      padding: 8px 10px;
    `};

  ::placeholder {
    color: ${Color.gray20};
  }

  :focus {
    outline: none;
  }
`;

const StyledCharacterCount = styled.div`
  color: ${Color.gray40};
  font-size: 14px;
  span {
    font-weight: bold;
  }
`;
