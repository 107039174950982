import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import useClickOutside from "hooks/useClickOutside";

import { TopicsListView } from "@explorance/mly-types";
import { Color } from "ts/enums/color";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { DropdownMenuItem, DropdownSize } from "ts/dropdown";

import { DropdownMenu } from "components/DropdownMenu";
import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";
import { EmptyState } from "components/EmptyState";
import { Text } from "components/Text";

const TOP_COMMENTS_LIMIT = [5, 10, 20];

type Props = {
  relevantComments: string[];
  view: TopicsListView;
  commentLimit: number;
  updateCommentLimit: Dispatch<SetStateAction<number>>;
};

export const TopComments = ({
  relevantComments,
  view,
  commentLimit,
  updateCommentLimit,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownRef = useClickOutside(() => setIsOpen(false));

  const dropdownItems: DropdownMenuItem[] = TOP_COMMENTS_LIMIT.map((cc) => ({
    label: cc.toString(),
    value: cc,
    isActive: commentLimit === cc,
  }));

  const handleClick = (count: number) => updateCommentLimit(count);

  return (
    <>
      <StyledCommentHeader ref={dropdownRef}>
        <Text resource="modal.relevantTopics.commentsHeader.partOne" />
        <Button
          onClick={() => setIsOpen((open) => !open)}
          size={ButtonSize.sm}
          variant={ButtonVariant.light}
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "6px",
            margin: "0 4px",
            width: "42px",
            position: "relative",
          }}
        >
          <span>{commentLimit}</span>
          <span>
            <Icon type={IconType.triangleFilledDown} color={Color.black} size={8} />
          </span>
          <DropdownMenu
            items={dropdownItems}
            show={isOpen}
            position={{ top: 29, left: 0 }}
            size={DropdownSize.sm}
            width="42px"
            onChange={handleClick}
          />
        </Button>
        <span>
          <Text resource="modal.relevantTopics.commentsHeader.partTwo" />
        </span>
      </StyledCommentHeader>
      {relevantComments.length > 0 ? (
        <StyledCommentsSection commentLimit={commentLimit} relevantComments={relevantComments}>
          {relevantComments.map(
            (comment, index) =>
              index < commentLimit && (
                <StyledCommentContainer key={`${comment}-${index}`}>
                  <StyledComment>{comment}</StyledComment>
                </StyledCommentContainer>
              )
          )}
        </StyledCommentsSection>
      ) : (
        <StyledEmptyStateContainer>
          <EmptyState
            type={EmptyStateType.noRelevantComments}
            titleKey={
              view === TopicsListView.Sentiments
                ? "modal.relevantTopics.commentsList.sentimentEmptyState"
                : "modal.relevantTopics.commentsList.recommendationEmptyState"
            }
            customStyles={{ marginTop: "0" }}
          />
        </StyledEmptyStateContainer>
      )}
    </>
  );
};

const StyledCommentHeader = styled.div`
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledCommentsSection = styled.div<{ commentLimit: number; relevantComments: string[] }>`
  height: ${({ commentLimit, relevantComments }) =>
    (commentLimit === 10 || commentLimit === 20) && relevantComments.length > 5
      ? "330px"
      : "248px"};
  overflow-y: auto;
`;

const StyledCommentContainer = styled.div`
  height: fit-content;
  padding: 12px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Color.sky20};
`;

const StyledComment = styled.div`
  animation: fadeIn ease 1s;
  font-size: 14px;
`;

const StyledEmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 271px;
`;
