import React from "react";
import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "store";
import {
  FilteredCommentType,
  incrementExcludedCommentLimit,
  setCommentCountDropdownOpen,
  setCommentExclusionCount,
  setExcludedCommentList,
  setLoadMoreButtonClicked,
} from "store/analysisSettings/settingsSlice";
import { fetchPreFilteringWordSuggestions } from "store/analysisSettings/thunks";

import { useResource } from "hooks/useResource";
import useClickOutside from "hooks/useClickOutside";

import { Select } from "components/_inputs/Select";
import { ToggeablePill } from "components/_pills/ToggeablePill";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";
import { EmptyState } from "components/EmptyState";
import { PreFilteringPlaceholder } from "./Placeholder";
import { LoadingDots } from "components/LoadingDots";

import { Color } from "ts/enums/color";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { EXCLUDED_COMMENT_SUGGESTION_LIMIT } from "assets/constants/excludedCommentSuggestionLimit";
import { ExcludedCommentWordCount } from "@explorance/mly-types";
import { PillType } from "ts/enums/pillType";

const DROPDOWN_VALUES = ["1", "2", "3", "4", "5"];

export const RecommendedComments = () => {
  const state = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const { getResource } = useResource();
  const dropdownRef = useClickOutside(() => dispatch(setCommentCountDropdownOpen(false)));

  const selectAllPillSelected =
    state.excludedCommentList.length > 0 &&
    state.excludedCommentList.some(
      (comment) => comment.selectAllExclusionCount >= state.commentExclusionCount
    );

  const isLoadingMore =
    state.isPrefilteringSuggestionsLoading &&
    state.excludedCommentLimit > EXCLUDED_COMMENT_SUGGESTION_LIMIT;

  const dropdownItems = DROPDOWN_VALUES.map((item) => ({
    label: item,
    value: item,
  }));

  const loadMoreAvailable = () => {
    const count = state.preFilteringWordSuggestions?.suggestedWordsToRemove.reduce(
      (acc, occ) => acc + occ.occurrence,
      0
    );
    return count < state.preFilteringWordSuggestions?.totalCount;
  };

  const handleCommentCountChange = (value: string) => {
    dispatch(setCommentExclusionCount(Number(value)));
    dispatch(fetchPreFilteringWordSuggestions());
  };

  const handleSelectAll = () => {
    const enumKey = Object.keys(ExcludedCommentWordCount)[state.commentExclusionCount - 1];

    dispatch(
      setExcludedCommentList({
        comment: getResource(
          state.commentExclusionCount === 1
            ? "analysisSettings.prefiltering.selectAll.pill.singular"
            : "analysisSettings.prefiltering.selectAll.pill.plural",
          state.commentExclusionCount
        ),
        selectAllEnum: ExcludedCommentWordCount[enumKey],
        selectAllExclusionCount: state.commentExclusionCount,
      })
    );
  };

  const isPillSelected = (cm: string) => {
    return (
      state.excludedCommentList.some((comments) => comments.comment === cm) ||
      selectAllPillSelected ||
      state.excludedCommentList[0]?.selectAllExclusionCount >= cm.split(" ").length
    );
  };

  const handleLoadMore = () => {
    dispatch(setLoadMoreButtonClicked(true));
    dispatch(incrementExcludedCommentLimit());
    dispatch(fetchPreFilteringWordSuggestions());
  };

  const handleAddExcludedComment = (suggestion: FilteredCommentType) => {
    dispatch(setExcludedCommentList(suggestion));
  };

  return (
    <StyledParentContainer>
      <StyledHeader ref={dropdownRef}>
        <Text resource="analysisSettings.prefiltering.recommendedComments.header.part1" />
        <Select
          selectedOption={dropdownItems.find(
            (item) => item.value === state.commentExclusionCount.toString()
          )}
          options={dropdownItems}
          dropdownWidth="60px"
          dropdownPosition={{ top: 35, left: 10 }}
          buttonStyle={{
            width: "60px",
            padding: "8px 10px",
            margin: "0 10px",
            borderRadius: "2px",
          }}
          handleChange={handleCommentCountChange}
        />

        <Text
          resource={
            state.commentExclusionCount === 1
              ? "analysisSettings.prefiltering.recommendedComments.header.part2.singular"
              : "analysisSettings.prefiltering.recommendedComments.header.part2.plural"
          }
        />
      </StyledHeader>

      <StyledRecommendedCommentsSection
        loadMoreButtonClicked={state.isPrefilteringSuggestionsLoading}
      >
        {!isLoadingMore && state.isPrefilteringSuggestionsLoading ? (
          <PreFilteringPlaceholder />
        ) : state.selectedColumns.length === 0 ? (
          <StyledEmptyStateContainer>
            <EmptyState
              type={EmptyStateType.noCommentColumnsSelected}
              customStyles={{ marginTop: "0px" }}
            />
          </StyledEmptyStateContainer>
        ) : state.preFilteringWordSuggestions?.totalCount === 0 ? (
          <StyledEmptyStateContainer>
            <EmptyState
              type={EmptyStateType.noPreFilteringResultsFound}
              customStyles={{ marginTop: "0px" }}
            />
          </StyledEmptyStateContainer>
        ) : (
          <>
            <ToggeablePill
              pillType={PillType.SelectAll}
              customStyles={{ maxHeight: "42px", marginTop: 0, gap: "8px" }}
              isDisabled={selectAllPillSelected}
              handleClick={handleSelectAll}
            >
              <StyledSelectButton disabled={selectAllPillSelected} isSelectAll>
                <Icon
                  type={IconType.plusCircle}
                  size={14}
                  color={selectAllPillSelected ? Color.gray20 : Color.white}
                />
              </StyledSelectButton>
              <Text
                resource={{
                  key:
                    state.commentExclusionCount === 1
                      ? "analysisSettings.prefiltering.selectAll.pill.singular"
                      : "analysisSettings.prefiltering.selectAll.pill.plural",
                  args: [state.commentExclusionCount.toString()],
                }}
              />

              <StyledCommentCount isDisabled={selectAllPillSelected} isTotal>
                {state.preFilteringWordSuggestions?.totalCount}
              </StyledCommentCount>
            </ToggeablePill>

            {state.preFilteringWordSuggestions?.suggestedWordsToRemove.map((suggestion, i) => (
              <ToggeablePill
                key={`pill-${i}`}
                pillType={PillType.SingleSelect}
                isDisabled={isPillSelected(suggestion.comment)}
                customStyles={{ maxHeight: "42px", marginTop: 0, gap: "8px" }}
                handleClick={() => handleAddExcludedComment(suggestion)}
              >
                <StyledSelectButton disabled={isPillSelected(suggestion.comment)}>
                  <Icon
                    type={IconType.plusCircle}
                    size={14}
                    color={isPillSelected(suggestion.comment) ? Color.gray20 : Color.blue50}
                  />
                </StyledSelectButton>
                {suggestion.comment}
                <StyledCommentCount isDisabled={isPillSelected(suggestion.comment)}>
                  {suggestion.occurrence}
                </StyledCommentCount>
              </ToggeablePill>
            ))}

            {loadMoreAvailable() && (
              <StyledLoadMoreButton onClick={handleLoadMore}>
                <Text resource="button.loadMore" />
              </StyledLoadMoreButton>
            )}
          </>
        )}
      </StyledRecommendedCommentsSection>
      {isLoadingMore && (
        <StyledLoadingContainer>
          <LoadingDots />
        </StyledLoadingContainer>
      )}
    </StyledParentContainer>
  );
};

const StyledParentContainer = styled.div`
  width: 50%;
  position: relative;
`;

const StyledHeader = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const StyledRecommendedCommentsSection = styled.div<{ loadMoreButtonClicked?: boolean }>`
  height: 320px;
  border: 2px solid ${Color.blue20};
  border-radius: 5px;
  padding: 11px;
  overflow-y: auto;
  opacity: ${({ loadMoreButtonClicked }) => (loadMoreButtonClicked ? "50%" : "100%")};
`;

const StyledSelectButton = styled.button<{ disabled: boolean; isSelectAll?: boolean }>`
  border: none;
  background-color: ${({ disabled, isSelectAll }) =>
    disabled ? Color.neutral10 : isSelectAll ? Color.blue50 : Color.white};
  padding: 0;
  display: flex;
  align-items: center;
`;

const StyledCommentCount = styled.span<{ isDisabled: boolean; isTotal?: boolean }>`
  width: fit-content;
  height: 16px;
  color: ${({ isDisabled }) => (isDisabled ? Color.white : Color.blue50)};
  background-color: ${({ isDisabled, isTotal }) =>
    isDisabled ? Color.neutral50 : isTotal ? Color.sky30 : Color.sky40};
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 10px;
  font-weight: bold;
`;

const StyledLoadMoreButton = styled.button`
  height: 29px;
  width: 100px;
  padding: 6px 12px;
  border: none;
  background-color: ${Color.sky15};
  color: ${Color.blue50};
  font-weight: bold;
`;

const StyledEmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledLoadingContainer = styled.div`
  width: 64px;
  height: 40px;
  background-color: ${Color.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px ${Color.shadow20};
  border-radius: 10px;
`;
