import React from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { Icon, IconType } from "components/_icons/Icon";
import { TopicModalData } from "ts/topic";
import { useResource } from "hooks/useResource";
import { TopicsListView } from "@explorance/mly-types";
import { BannerSkeleton } from "./Placeholder";
import { TextTruncator } from "components/TextTruncator";
import { TopicPathLabel } from "components/Widget/WidgetStat/L1/TopicPathLabel";
import { getTranslatedFullPath } from "components/Widget/WidgetStat/L1/helpers";

type Props = {
  topic: TopicModalData;
  view: TopicsListView;
  isLoading: boolean;
  topicFullPath: string;
  isVirtual: boolean;
  recommendationCount?: number;
};
export const Banner = ({
  topic,
  view,
  isLoading,
  topicFullPath,
  isVirtual,
  recommendationCount,
}: Props) => {
  const { getResource } = useResource();

  const translatedFullPath = getTranslatedFullPath(topicFullPath, isVirtual, getResource);

  return (
    <StyledBanner>
      <StyledLeftSideBanner>
        <Icon type={IconType.categories} size={12} />
        {isLoading ? (
          <BannerSkeleton />
        ) : (
          <StyledTitleContainer>
            <TextTruncator value={<TopicPathLabel topics={translatedFullPath} />} />
          </StyledTitleContainer>
        )}
      </StyledLeftSideBanner>

      {!isLoading && (
        <StyledRightSideBanner>
          {view === TopicsListView.Sentiments
            ? getResource(
                topic.insights === 1
                  ? "modal.relevantTopics.banner.sentiments.singular"
                  : "modal.relevantTopics.banner.sentiments",
                topic.insights ?? 0
              )
            : getResource(
                topic.recommendations === 1 || recommendationCount === 1
                  ? "modal.relevantTopics.banner.recommendations.singular"
                  : "modal.relevantTopics.banner.recommendations",
                (recommendationCount || topic.recommendations) ?? 0
              )}
        </StyledRightSideBanner>
      )}
    </StyledBanner>
  );
};

const StyledBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: ${Color.neutral10};
  margin: 0 -25px;
  padding: 0 25px;
`;

const StyledLeftSideBanner = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
`;

const StyledRightSideBanner = styled.div`
  animation: fadeIn ease 1s;
  font-size: 15px;
  font-weight: bold;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  margin-left: 10px;
  width: 100%;
`;
