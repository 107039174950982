import { AnalysisListView, SortingOrder } from "@explorance/mly-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteSelectedAnalysis, getAnalyses, getMyAnalysesCount } from "store/analysis/thunks";
import { Analysis, mapRESTAPIDataToAnalysisModel } from "ts/analysis";
import { DropdownMenuItem } from "ts/dropdown";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { AnalysisSortingParameter } from "ts/enums/sorting";

type AnalysisListSortPayload = {
  sortColumn: AnalysisSortingParameter;
  sortOrder: SortingOrder;
};

type AnalysisListState = {
  analysisList: Analysis[];
  currentEditedRow: Analysis | null;
  myAnalysesCount: number;
  currentAnalysisCount: number;
  totalAnalysisCount: number;
  currentPage: number;
  retryUploadAnalysisId: number | null;
  emptyStateType: EmptyStateType;
  sortOrder: SortingOrder;
  sortColumn: AnalysisSortingParameter;
  listView: AnalysisListView;
  dropdownMenuContent: DropdownMenuItem[];
  showDeleteConfirmation: boolean;
  isRowInDeleteMode?: boolean;
  changedListView?: boolean;
  isLoading: boolean;
};

const initialState: AnalysisListState = {
  analysisList: [],
  currentEditedRow: null,
  myAnalysesCount: 0,
  currentAnalysisCount: 0,
  totalAnalysisCount: 0,
  currentPage: 1,
  retryUploadAnalysisId: null,
  emptyStateType: null,
  listView: AnalysisListView.MyAnalysis,
  sortOrder: SortingOrder.DESC,
  sortColumn: AnalysisSortingParameter.LastUpdated,
  dropdownMenuContent: [
    {
      label: "",
      value: AnalysisListView.SharedWithMe,
      isActive: true,
    },
  ],
  showDeleteConfirmation: false,
  isRowInDeleteMode: false,
  changedListView: true,
  isLoading: true,
};

const analysisListSlice = createSlice({
  name: "analysisList",
  initialState,
  reducers: {
    setAnalysisList: (state, action: PayloadAction<Analysis[]>) => {
      state.analysisList = action.payload;
    },
    setDropdownMenuContent: (state, action: PayloadAction<DropdownMenuItem[]>) => {
      state.dropdownMenuContent = action.payload;
    },
    setCurrentEditedRow: (state, action: PayloadAction<Analysis | null>) => {
      state.currentEditedRow = action.payload;
    },
    setShowDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
      state.showDeleteConfirmation = action.payload;
    },
    changeSelectedDropdownItem: (state, action: PayloadAction<AnalysisListView>) => {
      state.dropdownMenuContent = state.dropdownMenuContent.map((item) => ({
        ...item,
        isActive: item.value === action.payload,
      }));
      state.changedListView = true;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setListView: (state, action: PayloadAction<AnalysisListView>) => {
      state.listView = action.payload;
    },
    setRetryUploadAnalysisId: (state, action: PayloadAction<number>) => {
      state.retryUploadAnalysisId = action.payload;
    },
    setEmptyStateType: (state, action: PayloadAction<EmptyStateType>) => {
      state.emptyStateType = action.payload;
    },
    updateAnalysisListSorting: (state, action: PayloadAction<AnalysisListSortPayload>) => {
      state.currentPage = 1;
      state.sortOrder = action.payload.sortOrder;
      state.sortColumn = action.payload.sortColumn;
    },
    changeListView: (state, action: PayloadAction<AnalysisListView>) => {
      state.currentPage = 1;
      state.listView = action.payload;
      state.sortOrder = SortingOrder.DESC;
      state.sortColumn = AnalysisSortingParameter.LastUpdated;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAnalyses.pending, (state) => {
      if (state.changedListView) {
        state.isLoading = true;
        state.changedListView = false;
      }
    });

    builder.addCase(getAnalyses.fulfilled, (state, action) => {
      const { itemCount, totalCount } = action.payload;
      const newAnalyses = action.payload.analysisJobSummary.map((a) =>
        mapRESTAPIDataToAnalysisModel(a)
      );
      state.isLoading = false;
      state.analysisList = newAnalyses;
      state.currentAnalysisCount = itemCount;
      state.totalAnalysisCount = totalCount;
    });

    builder.addCase(getAnalyses.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getMyAnalysesCount.fulfilled, (state, action: PayloadAction<number>) => {
      state.myAnalysesCount = action.payload;
    });
    builder.addCase(deleteSelectedAnalysis.fulfilled, (state) => {
      state.isRowInDeleteMode = false;
    });
    builder.addCase(deleteSelectedAnalysis.pending, (state) => {
      state.isRowInDeleteMode = true;
      state.showDeleteConfirmation = false;
    });
  },
});

export const {
  setAnalysisList,
  setEmptyStateType,
  setDropdownMenuContent,
  setCurrentEditedRow,
  setShowDeleteConfirmation,
  changeSelectedDropdownItem,
  changeListView,
  setCurrentPage,
  setListView,
  setRetryUploadAnalysisId,
  clearState,
  updateAnalysisListSorting,
} = analysisListSlice.actions;

export default analysisListSlice.reducer;
