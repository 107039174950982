import React, { useState } from "react";
import styled from "styled-components";

import {
  DiscussionThreadNoteType,
  setCurrentEditedNoteId,
  setEditedInput,
} from "store/notes/discussionThreadSlice";
import { deleteSubmittedNote, editNote } from "store/notes/thunks";
import { useAppDispatch, useAppSelector } from "store";
import { getLocalTime } from "utils/getLocalTime";
import { useTimeStamp } from "hooks/useTimeStamp";

import { UserAvatar } from "components/UserAvatar.tsx";
import { DotsMenu } from "components/DotsMenu";
import { Text } from "components/Text";
import { TextTruncator } from "components/TextTruncator";
import { Button } from "components/_buttons/Button";

import { Color } from "ts/enums/color";
import { DropdownSize } from "ts/dropdown";
import { DotsButtonVariant } from "ts/enums/dotsButton";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { NOTE_CHAR_LIMIT } from "assets/constants/noteCharacterLimit";
import { isAnyAdmin } from "utils/isAnyAdmin";

type Props = {
  noteContent: DiscussionThreadNoteType;
  isEditing: boolean;
  updateCommentNotesCount: (id: number, notesCount: number) => void;
};

export const DiscussionThreadNote = ({
  noteContent,
  isEditing,
  updateCommentNotesCount,
}: Props) => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const state = useAppSelector((state) => state.discussionThread);

  const dispatch = useAppDispatch();

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const getTimeStamp = useTimeStamp();

  const noteCreationDate = getLocalTime(new Date(noteContent.createdDate));

  const enableEditingHandler = () => {
    dispatch(setEditedInput(noteContent.note));
    dispatch(setCurrentEditedNoteId(noteContent.id));
  };

  const deleteNoteHandler = () => {
    dispatch(deleteSubmittedNote(noteContent.id));
    updateCommentNotesCount(state.comment.id, state.discussionThreadNotes.length - 1);
  };

  const submitEditedNote = () => {
    dispatch(editNote({ noteId: noteContent.id, note: state.editedInput }));
    dispatch(setCurrentEditedNoteId(null));
  };

  const menuContents = [
    {
      label: <Text resource="comments.discussionThread.sideDrawer.dotsDropdown.edit" />,
      onClick: enableEditingHandler,
      isDisabled: parseInt(noteContent.createdBy) !== currentUser.id || isEditing,
    },

    {
      label: <Text resource="comments.discussionThread.sideDrawer.dotsDropdown.delete" />,
      onClick: deleteNoteHandler,
      isDisabled:
        parseInt(noteContent.createdBy) !== currentUser.id && !isAnyAdmin(currentUser.roleType),
    },
  ];

  return (
    <StyledNoteContainer>
      <StyledAvatarContainer>
        <UserAvatar
          firstName={noteContent.firstname}
          lastName={noteContent.lastname}
          userId={parseInt(noteContent.createdBy)}
        />
      </StyledAvatarContainer>
      <StyledNoteBubble>
        <StyledTopRow>
          <StyledNameDate>
            <TextTruncator
              value={noteContent.firstname + " " + noteContent.lastname}
              customWidth="150px"
            />
            <StyledDot>•</StyledDot>
            <div>{getTimeStamp(noteCreationDate)}</div>
            {noteContent.isEdited && (
              <>
                <StyledDot>•</StyledDot>
                <StyledEditLabelContainer>
                  <Text resource="comments.discussionThread.sideDrawer.note.edited" />
                </StyledEditLabelContainer>
              </>
            )}
          </StyledNameDate>
          <DotsMenu
            menuContents={menuContents}
            showDotsButton
            shouldCloseMenuOnClick
            size={DropdownSize.md}
            dotsButtonVariant={DotsButtonVariant.Transparent}
            onDotsMenuClick={() => setIsDropdownOpen((prev) => !prev)}
            dotsMenuOpen={isDropdownOpen}
            setDotsMenuOpen={setIsDropdownOpen}
            menuWidth={130}
          />
        </StyledTopRow>
        {isEditing ? (
          <div>
            <StyledEditInputContainer>
              <StyledInput
                maxLength={NOTE_CHAR_LIMIT}
                onChange={(e) => dispatch(setEditedInput(e.target.value))}
                value={state.editedInput}
              />

              <StyledCharacterCount>
                <span>{state.editedInput.length}</span>
                <Text
                  resource={{
                    key: "input.validation.characterCount",
                    args: [`${NOTE_CHAR_LIMIT}`],
                  }}
                />
              </StyledCharacterCount>
            </StyledEditInputContainer>
            <StyledActionSection>
              <Button
                variant={ButtonVariant.light}
                onClick={() => dispatch(setCurrentEditedNoteId(null))}
                size={ButtonSize.sm}
              >
                <Text resource="button.cancel" />
              </Button>
              <Button
                variant={ButtonVariant.primary}
                size={ButtonSize.sm}
                onClick={submitEditedNote}
                disabled={state.editedInput.length === 0}
              >
                <Text resource="button.save" />
              </Button>
            </StyledActionSection>
          </div>
        ) : (
          <StyledComment>{noteContent.note}</StyledComment>
        )}
      </StyledNoteBubble>
    </StyledNoteContainer>
  );
};

const StyledNoteContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
`;

const StyledTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  font-weight: bold;
  font-size: 14px;
  color: ${Color.gray30};
`;

const StyledNoteBubble = styled.div`
  flex-grow: 1;
  padding: 12px;
  background-color: ${Color.sky15};
  border-radius: 12px;
  overflow-wrap: anywhere;
`;

const StyledNameDate = styled.div`
  display: flex;
  gap: 5px;
`;

const StyledComment = styled.div`
  white-space: pre-line;
  color: ${Color.gray50};
  font-size: 15px;
`;

const StyledAvatarContainer = styled.div`
  margin-top: 12px;
`;

const StyledDot = styled.div`
  font-weight: normal;
`;

const StyledEditInputContainer = styled.div`
  height: 120px;
  border: 1px solid ${Color.sky50};
  background-color: ${Color.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 10px;
  :focus-within {
    border: 1px solid ${Color.blue50};
  }
`;

const StyledInput = styled.textarea`
  height: 100%;
  border-radius: 2px;
  display: flex;
  align-items: center;
  color: ${Color.gray50};
  border-radius: 2px;
  font-size: 15px;
  font-family: Lato;
  resize: none;
  border: none;
  cursor: auto;
  :focus {
    outline: none;
  }
`;

const StyledCharacterCount = styled.div`
  color: ${Color.gray40};
  font-size: 14px;
  span {
    font-weight: bold;
  }
`;

const StyledActionSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  gap: 12px;
`;

const StyledEditLabelContainer = styled.div`
  font-style: italic;
`;
