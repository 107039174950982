import React from "react";
import styled from "styled-components";
import { ButtonSize, ButtonVariant } from "ts/enums/button";

import { Modal, StyledModalCaption, StyledModalTitle } from "components/_modals/Modal";
import { Button } from "components/_buttons/Button";
import { CloseButton } from "components/_buttons/CloseButton";
import { Text } from "components/Text";

type Props = {
  isOpen: boolean;
  onCancel(): void;
  onConfirm(): void;
};

export const OverwriteAnalysisConfirmationModal = (props: Props) => {
  const { isOpen, onCancel, onConfirm } = props;

  return (
    <Modal isOpen={isOpen}>
      <StyledTopSection>
        <StyledModalTitle>
          <Text resource="modal.overwriteAnalysis.title" />
        </StyledModalTitle>
        <CloseButton closeHandler={onCancel} />
      </StyledTopSection>
      <StyledModalCaption>
        <Text resource="modal.overwriteAnalysis.caption" />
      </StyledModalCaption>
      <StyledBottomSection>
        <Button
          variant={ButtonVariant.outline}
          size={ButtonSize.sm}
          onClick={onCancel}
          style={{ fontSize: "0.775em" }}
        >
          <Text resource="button.cancel" />
        </Button>
        <Button
          variant={ButtonVariant.primary}
          size={ButtonSize.sm}
          onClick={onConfirm}
          style={{ fontSize: "0.775em" }}
        >
          <Text resource="button.overwrite" />
        </Button>
      </StyledBottomSection>
    </Modal>
  );
};

const StyledTopSection = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const StyledBottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;
