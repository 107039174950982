import React, { createContext, useState, useContext, useEffect, useCallback } from "react";
import { LanguageCode, RoleType } from "@explorance/mly-types";
import { CreateUserBody, SSOMapping, UpdateUserBody, User } from "ts/user";
import { getAllUserDemographics } from "services/users";
import { getDemographicFields } from "./helpers";
import { partition, isArray } from "lodash";
import { getSsos } from "services/sso";

const UserProfileContext = createContext(null);

export type UserDemographic = {
  name: string;
  values: string[] | null;
};

export type UserProfileFields = {
  // Basic info
  username: string;
  roleType: RoleType;
  firstname: string;
  lastname: string;
  preferredLanguage: LanguageCode;
  // Authentication
  basicAuth: boolean;
  ssoMappings: SSOMapping[];
  // Demographics
  mainDemographics: Record<string, string>;
  additionalDemographics: Record<string, string>;
  // Password
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  // Misc
  sendEmailNotification?: boolean;
};

export type UserProfileFieldErrors = {
  // Basic info
  username: string;
  roleType: string;
  firstname: string;
  lastname: string;
  preferredLanguage: string;
  // Authentication
  basicAuthentication: string;
  ssoMappings: string[];
  // Demographics
  mainDemographics: string;
  additionalDemographics: string;
  // Password
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  // Misc
  sendEmailNotification?: string;
  noAuthMethodCurrentUser?: string;
};

type UserProfileReturnType = {
  fields: UserProfileFields;
  setField: (fieldName: string, value: any) => void;
  setFields: (arg: any) => void;
  mainDemographics: UserDemographic[];
  additionalDemographics: UserDemographic[];
  fieldErrors: UserProfileFieldErrors;
  setFieldError: (field: string, errorMessage: string | string[]) => void;
  resetFields: () => void;
};

export const useUserProfile = (): UserProfileReturnType => useContext(UserProfileContext);

const getInitialFields = (user: User = {} as User): UserProfileFields => {
  return {
    // Basic info
    username: user.username || "",
    roleType: user.roleType || RoleType.Analyst,
    firstname: user.firstname || "",
    lastname: user.lastname || "",
    preferredLanguage: user.preferredLanguage || LanguageCode.Eng,
    // Authentication
    basicAuth: user.basicAuth || false,
    ssoMappings: user.ssoMappings || [],
    // Demographics
    mainDemographics: {},
    additionalDemographics: {},
    // Password
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    // Misc
    sendEmailNotification: false,
  };
};

export const getUpdateUserRequestBody = (fields: UserProfileFields): UpdateUserBody => {
  return {
    // Basic info
    username: fields.username,
    roleType: fields.roleType,
    firstname: fields.firstname,
    lastname: fields.lastname,
    preferredLanguage: fields.preferredLanguage,
    // FOR TESTING PURPOSES COMMENT OUT lines 101-109
    // SSOs: [],
    // demographics: { },
    // Authentication
    basicAuth: fields.basicAuth,
    SSOs: fields.ssoMappings,
    // Demographics
    demographics: { ...fields.mainDemographics, ...fields.additionalDemographics },
    // Password
    ...(fields.oldPassword && { oldPassword: fields.oldPassword }),
    ...(fields.newPassword && { newPassword: fields.newPassword }),
    ...(fields.confirmPassword && { confirmPassword: fields.confirmPassword }),
    // Misc
    emailNotification: fields.sendEmailNotification,
  } as UpdateUserBody;
};
export const getCreateUserRequestBody = (fields: UserProfileFields): CreateUserBody => {
  return {
    // Basic info
    username: fields.username,
    roleType: fields.roleType,
    firstname: fields.firstname,
    lastname: fields.lastname,
    preferredLanguage: fields.preferredLanguage,
    // Authentication
    basicAuth: fields.basicAuth,
    SSOs: fields.ssoMappings,
    // Demographics
    demographics: { ...fields.mainDemographics, ...fields.additionalDemographics },
    // Misc
    emailNotification: fields.sendEmailNotification,
  };
};

export const getDefaultUserProfileFieldErrors = (user: User): UserProfileFieldErrors => ({
  // Basic info
  username: "",
  roleType: "",
  firstname: "",
  lastname: "",
  preferredLanguage: "",
  // Authentication
  basicAuthentication: "",
  ssoMappings: user ? new Array(user.ssoMappings.length).fill("") : [],
  // Demographics
  mainDemographics: "",
  additionalDemographics: "",
  // Password
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
  // Misc
  sendEmailNotification: "",
  noAuthMethodCurrentUser: "",
});

type Props = {
  children: any;
  user?: User;
};

export const UserProfileProvider = ({ children, user }: Props) => {
  const [fields, setFields] = useState<UserProfileFields>(getInitialFields(user));
  const [fieldErrors, setFieldErrors] = useState<UserProfileFieldErrors>(
    getDefaultUserProfileFieldErrors(user)
  );
  const [mainDemographics, setMainDemographics] = useState<UserDemographic[]>([]);
  const [additionalDemographics, setAdditionalDemographics] = useState<UserDemographic[]>([]);

  const handleAllUserDemographics = useCallback(() => {
    getAllUserDemographics()
      .then(({ data }) => {
        const [main, additional] = partition(data.userDemographics, (apiDemographic) =>
          isArray(apiDemographic.values)
        );

        setMainDemographics(main);
        setAdditionalDemographics(additional);

        const [mainDemographics, additionalDemographics] = getDemographicFields(
          user?.demographics,
          data.userDemographics
        );

        setFields((f) => ({ ...f, mainDemographics, additionalDemographics }));
      })
      .catch((e) => {
        console.error("Error fetching user demographics", e.message);
      });
  }, [user?.demographics]);

  const handleSsoMappings = useCallback(() => {
    getSsos()
      .then(({ data }) => {
        setField(
          "ssoMappings",
          data.SSOs.map((sso) => ({
            activated: false,
            metaId: sso.metaId,
            username: "",
            ssoType: sso.description,
          }))
        );
        setField("basicAuth", data.basicAuth);
        setFieldErrors({
          ...fieldErrors,
          ssoMappings: new Array(data.SSOs.length).fill(""),
        });
      })
      .catch((e) => {
        console.error("Error fetching sso mappings", e.message);
      });
  }, []); //eslint-disable-line

  useEffect(() => {
    handleAllUserDemographics();
  }, [handleAllUserDemographics]);

  useEffect(() => {
    if (!user) {
      handleSsoMappings();
      return;
    }
    setFields(getInitialFields(user));
  }, [user]); // eslint-disable-line

  const setField = (fieldName: string, value: string | boolean | Record<string, string>) => {
    if (Object.keys(fields).includes(fieldName)) {
      setFields((fields) => ({ ...fields, [fieldName]: value }));
    }
  };

  const setFieldError = (fieldName: string, errorMessage: string | string[]) => {
    if (Object.keys(fieldErrors).includes(fieldName)) {
      setFieldErrors((fe) => ({ ...fe, [fieldName]: errorMessage }));
    }
  };

  const resetFields = () => {
    setFields(getInitialFields());
    handleAllUserDemographics();
    handleSsoMappings();
  };

  return (
    <UserProfileContext.Provider
      value={{
        fields,
        setFields,
        setField,
        mainDemographics,
        additionalDemographics,
        fieldErrors,
        setFieldError,
        resetFields,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};
