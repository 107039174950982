import { mlyApi, getHeaders } from "config/mlyApi";
import { getUrlQueryString } from "utils/getUrlQueryString";
import {
  CommentsSearchType,
  DemographicFilterMethod,
  ExportsHistorySortingParameter,
  SortingOrder,
  StatisticsType,
} from "@explorance/mly-types";

export type ExportAnalysisRequestBody = {
  analysisId: number;
  name: string;
  view?: string;
  limit?: number;
  sort?: string;
  custom_model_id?: number;
  statistic?: StatisticsType;
  search?: string;
  filters?: {
    topics?: string[];
    demographics?: {
      id: number;
      name: string;
      method: DemographicFilterMethod;
      values: string[];
    }[];
    threshold?: number;
    selectedColumns?: string[];
    searchType?: CommentsSearchType;
    alertScore?: {
      min: number;
      max: number;
    };
    statistics?: string[];
  };
};

export async function getDownloadedFiles(
  sort_column?: ExportsHistorySortingParameter,
  sort_order?: SortingOrder,
  limit?: number,
  page?: number,
  search?: string
) {
  const requestConfig = await getHeaders();
  const url = `/exports` + getUrlQueryString({ sort_column, sort_order, limit, page, search });
  return mlyApi.get(url, requestConfig);
}

export async function exportAnalysis(requestBody: ExportAnalysisRequestBody) {
  const requestConfig = await getHeaders();
  const url = `/exports`;
  return mlyApi.post<any>(url, requestBody, requestConfig);
}

export async function deleteDownloadedFileById(exportId: number) {
  const requestConfig = await getHeaders();
  const url = `/exports/${exportId}`;
  return mlyApi.delete(url, requestConfig);
}

export async function getExport(exportId: number) {
  const requestConfig = await getHeaders();
  const url = `/exports/${exportId}`;
  return mlyApi.get(url, requestConfig);
}

export async function retryDownloadedFileById(exportId: number) {
  const requestConfig = await getHeaders();
  const url = `/exports/${exportId}`;
  return mlyApi.put(url, requestConfig);
}
