import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StatisticsType, TopicsListView } from "@explorance/mly-types";
import useClickOutside from "hooks/useClickOutside";

import { Icon, IconType } from "components/_icons/Icon";
import { DropdownMenu } from "components/DropdownMenu";
import { useResource } from "hooks/useResource";
import { Text } from "components/Text";
import { StatSectionSkeleton } from "./Placeholder";

import { getAvailableStats, getSelectedStatColor } from "./helpers";
import { Color } from "ts/enums/color";

type Props = {
  view: TopicsListView;
  selectedStat: StatisticsType;
  onChangeStat: (stat: StatisticsType) => void;
  isLoading: boolean;
  showSentimentFeatures: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export const StatSection = ({
  view,
  selectedStat,
  onChangeStat,
  isLoading,
  showSentimentFeatures,
  setIsLoading,
}: Props) => {
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState<boolean>(false);
  const [isToggleActive, setIsToggleActive] = useState<boolean>(false);
  const [activeDropdownItem, setActiveDropdownItem] = useState<StatisticsType>(selectedStat);

  const { getResource } = useResource();
  const statSelectRef = useClickOutside(() => setDropdownMenuOpen(false));

  const dropdownItems = getAvailableStats(view).map((stat) => ({
    label: getResource(`statTab.${stat}`),
    value: stat,
    isActive: stat === activeDropdownItem,
  }));

  const handleStatChange = (sentiment: StatisticsType) => {
    if (sentiment === activeDropdownItem) return;
    setIsLoading(true);
    onChangeStat(sentiment);
  };

  useEffect(() => {
    if (!dropdownMenuOpen) {
      setIsToggleActive(false);
    }
  }, [dropdownMenuOpen]);

  useEffect(() => {
    setDropdownMenuOpen(false);
  }, [view]);

  useEffect(() => {
    setActiveDropdownItem(selectedStat);
  }, [selectedStat]);

  return (
    <>
      <StyledStatSection isLoading={isLoading}>
        {isLoading ? (
          <StatSectionSkeleton view={view} />
        ) : (
          <StyledStatSelect
            onClick={() => setDropdownMenuOpen((prev) => !prev)}
            onMouseLeave={dropdownMenuOpen ? undefined : () => setIsToggleActive(false)}
            onMouseMove={() => setIsToggleActive(true)}
            ref={statSelectRef}
            dropdownHidden={showSentimentFeatures}
          >
            <StyledStatIconContainer>
              <Icon
                type={IconType[selectedStat]}
                color={getSelectedStatColor(selectedStat, Color.gray50, view)}
              />
            </StyledStatIconContainer>
            <StyledSelectedStat>
              <StyledSelectedView color={getSelectedStatColor(selectedStat, Color.gray30, view)}>
                <Text
                  resource={{
                    key: `statTab.${view}`,
                  }}
                />
              </StyledSelectedView>
              <StyledSelectedStatisticsType
                color={getSelectedStatColor(selectedStat, Color.gray50, view)}
              >
                <Text resource={`statTab.${selectedStat}`} />
              </StyledSelectedStatisticsType>
            </StyledSelectedStat>

            {showSentimentFeatures && (
              <>
                <StyledDropdownToggle style={{ width: 16, height: 16 }} isActive={isToggleActive}>
                  <Icon type={IconType.triangleFilledDown} color={Color.gray30} size={8} />
                </StyledDropdownToggle>
                <DropdownMenu
                  items={dropdownItems}
                  position={{ top: 56, left: 56 }}
                  show={dropdownMenuOpen}
                  onChange={handleStatChange}
                  width={"auto"}
                />
              </>
            )}
          </StyledStatSelect>
        )}
      </StyledStatSection>
    </>
  );
};

const StyledStatSection = styled.div<{ isLoading: boolean }>`
  height: ${({ isLoading }) => isLoading && "82px"};
  padding: 16px 0 12px;
  user-select: none;
`;

const StyledDropdownToggle = styled.button<{ isActive: boolean }>`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? Color.blue20 : Color.neutral10)};
  position: relative;
  top: 24px;
`;

const StyledStatSelect = styled.div<{ dropdownHidden: boolean }>`
  display: inline-flex;
  position: relative;
  cursor: ${({ dropdownHidden }) => (dropdownHidden ? "default" : "pointer")};
`;

const StyledStatIconContainer = styled.div`
  background-color: ${Color.neutral10};
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

const StyledSelectedStat = styled.div`
  font-weight: bold;
  padding-right: 12px;
  animation: fadeIn ease 1s;
`;

const StyledSelectedStatisticsType = styled.div<{ color: Color }>`
  font-size: 28px;
  color: ${({ color }) => color};
`;

const StyledSelectedView = styled.div<{ color: Color }>`
  font-size: 14px;
  color: ${({ color }) => color};
`;
