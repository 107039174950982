import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import styled, { css } from "styled-components";
import { useHistory } from "react-router";
import axios, { AxiosError } from "axios";
import { useAppSelector } from "store";
import { AnalysisContext } from "context/AnalysisContext";

import { routes } from "routes";

import { setAnalysisError, handleAxiosError } from "context/AnalysisContext/actions";
import { getTopicStatisticsByAnalysisId } from "services/analysis";
import { getCustomModelId } from "utils/getCustomModelId";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { isAnyAdmin } from "utils/isAnyAdmin";
import { getAbbreviatedNumber } from "utils/formatters";
import { useResource } from "hooks/useResource";
import { useQueryParams } from "hooks/useQueryParams";

import { TotalBlockCount } from "../../TotalBlockCount";
import { BarChart } from "../../charts/BarChart";
import { BlockEmptyState } from "../BlockEmptyState";
import { BarChartSkeleton } from "./BarChartSkeleton";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

import { SharingCommentExplorerAccess, TopicsListView, TreeView } from "@explorance/mly-types";
import { Topic } from "ts/topic";
import { TopicView } from "ts/enums/topicView";
import { BlockEmptyStateType } from "ts/enums/blockEmptyStateType";
import { Color } from "ts/enums/color";
import { PageErrorType } from "ts/enums/pageErrorType";
import { PreviewUser } from "ts/analysis";

type Props = {
  topicView: TopicView;
  isEmptyState?: boolean;
  sharedUser: PreviewUser;
};

export const HighLevelTopics = ({ topicView, isEmptyState, sharedUser }: Props) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  const [topics, setTopics] = useState<Topic[]>([]);
  const [topicsLoading, setTopicsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>();
  const [maxWidth, setMaxWidth] = useState<number>();
  const [showScrollButtons, setShowScrollButtons] = useState<boolean>(false);
  const [isPreviousButtonDisabled, setIsPreviousButtonDisabled] = useState<boolean>(false);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(false);
  const [addJustifyProperty, setAddJustifyProperty] = useState<boolean>(false);
  const [state, dispatch] = useContext(AnalysisContext);

  const barTopicsContainerRef = useRef<HTMLDivElement>();
  const history = useHistory();
  const customModelId = getCustomModelId(state);
  const { getResource } = useResource();
  const { sharingPreview, sharingId, sharedWithPage, expandedRowId, step } = useQueryParams();

  const viewType =
    topicView === TopicView.insights ? TopicsListView.Sentiments : TopicsListView.Recommendations;

  const cancelTokenSource = axios.CancelToken.source();
  const barChartWidth = topicView === TopicView.insights ? 160 : 158;

  const userCanAccessComments = sharingPreview
    ? sharedUser?.commentExplorerAccess === SharingCommentExplorerAccess.Shared
    : isAnyAdmin(currentUser.roleType) ||
      !state.analysisDetails?.sharing ||
      state.analysisDetails?.sharing?.commentExplorerAccess === SharingCommentExplorerAccess.Shared;

  const handleClickPrevious = () => (barTopicsContainerRef.current.scrollLeft += -maxWidth);

  const handleClickNext = () => (barTopicsContainerRef.current.scrollLeft += maxWidth);

  const handleTotalCountClick = () => {
    const commentsView =
      topicView === TopicView.insights ? "categorizedComments" : "categorizedRecommendations";
    history.push(
      routes.commentsPage(
        state.analysisDetails.id,
        getUrlQueryString({
          view: commentsView,
          sharingPreview,
          sharingId,
          sharedWithPage,
          expandedRowId,
          step,
        })
      )
    );
  };

  const countInfo = getAbbreviatedNumber(totalCount, isEmptyState);

  useEffect(() => {
    const func = async () => {
      if (isNaN(Number(state.analysisDetails.id))) {
        return dispatch(setAnalysisError(PageErrorType.AnalysisNotFound));
      }
      try {
        setTopicsLoading(true);
        const { data } = await getTopicStatisticsByAnalysisId(
          state.analysisDetails.id,
          getUrlQueryString({
            custom_model_id: customModelId,
            view: viewType,
            tree: TreeView.Overview,
            user_id: state.previewUser.id,
            sharing_id: sharingId,
          }),
          undefined,
          cancelTokenSource.token
        );
        const sortedData = data.topics.sort((a, b) => b[topicView] - a[topicView]);
        setTopics(sortedData);
        setTotalCount(data.total[topicView]);
        setTopicsLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          dispatch(handleAxiosError(err as AxiosError<any>));
        }
      }
    };
    if (!isEmptyState) func();

    return () => {
      cancelTokenSource.cancel("High Level Topics unmounted");
    };
    //eslint-disable-next-line
  }, [
    state.analysisDetails.id,
    customModelId,
    topicView,
    viewType,
    isEmptyState,
    state.previewUser.id,
    dispatch,
  ]);

  useEffect(() => {
    const handleMaxWidth = () => {
      window.innerWidth > 1365
        ? setMaxWidth(topicView === TopicView.insights ? 960 : 948)
        : setMaxWidth(topicView === TopicView.insights ? 640 : 632);
    };

    handleMaxWidth();

    window.addEventListener("resize", handleMaxWidth);
    return () => window.removeEventListener("resize", handleMaxWidth);
  }, [topicView]);

  const updateButtonDisabledState = useCallback(() => {
    const ref = barTopicsContainerRef.current;
    if (!ref || !topics) return;

    const isAtStart = ref.scrollLeft === 0;
    const isAtEnd = ref.scrollLeft + maxWidth >= topics.length * barChartWidth;
    setIsPreviousButtonDisabled(isAtStart);
    setIsNextButtonDisabled(isAtEnd);
  }, [topics, barChartWidth, maxWidth]);

  useEffect(() => {
    const ref = barTopicsContainerRef.current;
    if (!ref || !topics) return;

    // Check if scroll buttons should be displayed
    const showScrollButtons = ref.offsetWidth < topics.length * barChartWidth;
    setShowScrollButtons(showScrollButtons);
    setAddJustifyProperty(!showScrollButtons);

    if (showScrollButtons) {
      // Initialize button and scroll states
      updateButtonDisabledState();
      ref.scrollLeft = 0;

      ref.addEventListener("scroll", updateButtonDisabledState);
    } else {
      ref.removeEventListener("scroll", updateButtonDisabledState);
    }

    return () => ref.removeEventListener("scroll", updateButtonDisabledState);
  }, [topics, barChartWidth, maxWidth, updateButtonDisabledState]);

  return (
    <StyledHighLevelTopicsContainer addVerticalStack={!isEmptyState}>
      <StyledContentContainer topicView={topicView} isEmptyState={isEmptyState}>
        <TotalBlockCount
          title={
            <Text
              resource={
                topicView === TopicView.insights
                  ? "overview.totalBlockCount.topicInsights"
                  : "overview.totalBlockCount.topicRecommendations"
              }
            />
          }
          displayedCount={countInfo?.isResource ? getResource(countInfo?.count) : countInfo?.count}
          hasTooltip={true}
          addMaxWidth={showScrollButtons}
          customSkeletonWidth={84}
          originalCount={totalCount}
          isEmptyState={isEmptyState}
          handleClick={
            !userCanAccessComments ||
            !state?.analysisDetails?.availableResults?.topics ||
            (!isAnyAdmin(currentUser.roleType) &&
              state.analysisDetails.sharing &&
              state?.analysisDetails?.sharing?.commentExplorerAccess ===
                SharingCommentExplorerAccess.Hidden) ||
            sharedUser?.commentExplorerAccess === SharingCommentExplorerAccess.Hidden
              ? undefined
              : () => handleTotalCountClick()
          }
        >
          <Icon type={IconType.hierarchySolid} size={12} />
        </TotalBlockCount>
        {isEmptyState ? (
          <BlockEmptyState
            type={
              topicView === TopicView.insights
                ? BlockEmptyStateType.topicInsights
                : BlockEmptyStateType.topicRecommendations
            }
          />
        ) : (
          <StyledBarChartContainer
            ref={barTopicsContainerRef}
            maxWidth={maxWidth}
            addJustifyProperty={addJustifyProperty}
          >
            {topicsLoading || state.loadingAnalysisModels
              ? Array.from({ length: 6 }).map((_, index) => (
                  <BarChartSkeleton topicView={topicView} topics={topics} key={index} />
                ))
              : topics.map((t, index) => (
                  <BarChart
                    key={index}
                    topic={t}
                    attributeType={viewType}
                    maxWidth={barChartWidth}
                    sharedUser={sharedUser}
                  />
                ))}
          </StyledBarChartContainer>
        )}
      </StyledContentContainer>

      {showScrollButtons && (
        <StyledButtonContainer>
          {topics && (
            <>
              <button
                disabled={isPreviousButtonDisabled}
                onClick={isPreviousButtonDisabled ? undefined : handleClickPrevious}
              >
                <Icon
                  type={IconType.chevronLeft}
                  color={isPreviousButtonDisabled ? Color.gray20 : Color.blue50}
                  size={16}
                />
              </button>
              <button
                disabled={isNextButtonDisabled}
                onClick={isNextButtonDisabled ? undefined : handleClickNext}
              >
                <Icon
                  type={IconType.chevronRight}
                  color={isNextButtonDisabled ? Color.gray20 : Color.blue50}
                  size={16}
                />
              </button>
            </>
          )}
        </StyledButtonContainer>
      )}
    </StyledHighLevelTopicsContainer>
  );
};

const StyledHighLevelTopicsContainer = styled.div<{ addVerticalStack: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ addVerticalStack }) => addVerticalStack && "column"};
  row-gap: 16px;
`;

const StyledContentContainer = styled.div<{ topicView: TopicView; isEmptyState: boolean }>`
  display: flex;
  align-items: center;

  @media (max-width: 1365px) {
    ${({ isEmptyState, topicView }) =>
      !isEmptyState &&
      css`
        gap: ${topicView === TopicView.insights ? "100px" : "40px"};
      `}
  }
`;

const StyledBarChartContainer = styled.div<{ maxWidth: number; addJustifyProperty: boolean }>`
  display: flex;
  overflow-x: hidden;
  ${({ addJustifyProperty }) =>
    addJustifyProperty &&
    css`
      justify-content: flex-end;
    `};

  width: ${({ maxWidth }) => maxWidth}px;
  scroll-behavior: smooth;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: auto;
  height: 24px;

  button {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    background-color: ${Color.neutral10};
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
