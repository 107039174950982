import React, { useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store";

import { routes } from "routes";
import { AnalysisContext } from "context/AnalysisContext";

import { getUrlQueryString } from "utils/getUrlQueryString";
import { useQueryParams } from "hooks/useQueryParams";
import { isAnyAdmin } from "utils/isAnyAdmin";

import { Skeleton } from "components/Skeleton";
import { Pill } from "components/_pills/Pill";
import { SentimentIcon } from "components/_icons/SentimentIcon";
import { RecommendationIcon } from "components/_icons/RecommendationIcon";
import { StackedBarChart } from "components/StackedBarChart";
import { formatStat } from "components/StatTab/helpers";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";
import {
  CommentsView,
  RecommendationType,
  SentimentType,
  SharingCommentExplorerAccess,
  TopicsListView,
} from "@explorance/mly-types";
import { PreviewUser } from "ts/analysis";
import { StackedBarChartCount } from "ts/overview";
import { StatType } from "ts/enums/statsMenu";

type Props = {
  counts: StackedBarChartCount[];
  attributeType: TopicsListView;
  sharedUser: PreviewUser;
};

export const StackedBarChartContainer = ({ counts, attributeType, sharedUser }: Props) => {
  const { currentUser } = useAppSelector((state) => state.auth);

  const [state] = useContext(AnalysisContext);

  const history = useHistory();

  const { sharingPreview, sharedWithPage, sharingId, expandedRowId, step } = useQueryParams();

  const userCanAccessComments = sharingPreview
    ? sharedUser?.commentExplorerAccess === SharingCommentExplorerAccess.Shared
    : isAnyAdmin(currentUser.roleType) ||
      !state.analysisDetails?.sharing ||
      state.analysisDetails?.sharing?.commentExplorerAccess === SharingCommentExplorerAccess.Shared;

  const skeletonArrayCount = attributeType === TopicsListView.Sentiments ? 5 : 7;

  const resourceLabel = attributeType.toLowerCase().substring(0, attributeType.length - 1) + "Pill";

  const getLegendCount = (c: StackedBarChartCount) => {
    const type = attributeType === TopicsListView.Sentiments ? StatType.Percentage : StatType.Count;
    const count = c.recommendationCount ?? c.percentage;
    return formatStat(count, type);
  };

  const handleClick = (attribute: SentimentType | RecommendationType) => {
    const commentsView =
      attributeType === TopicsListView.Sentiments
        ? CommentsView.allComments
        : CommentsView.allRecommendations;
    history.push(
      routes.commentsPage(
        state.analysisDetails.id,
        getUrlQueryString({
          view: commentsView,
          statistics: attribute,
          sharingPreview,
          sharingId,
          expandedRowId,
          sharedWithPage,
          step,
        })
      )
    );
  };

  const getLegendIcon = (type: SentimentType | RecommendationType) => {
    if (attributeType === TopicsListView.Sentiments)
      return <SentimentIcon type={type as SentimentType} size={14} />;
    return <RecommendationIcon type={type as RecommendationType} />;
  };

  return (
    <StyledStackedBarChartContainer>
      {counts.length > 0 ? (
        <>
          <StackedBarChart
            chartPieces={counts.map((c) => ({ portion: c.percentage, color: c.color }))}
          />
          <StyledLegendContainer>
            {counts.map((c, index) => (
              <Pill
                key={index}
                color={c.percentage === 0 ? Color.gray20 : Color.gray50}
                backgroundColor={c.percentage === 0 ? Color.white : Color.neutral10}
                hoverColor={c.percentage > 0 && Color.sky40}
                onClick={
                  !userCanAccessComments ||
                  c.percentage === 0 ||
                  (!isAnyAdmin(currentUser.roleType) &&
                    state.analysisDetails.sharing &&
                    state?.analysisDetails?.sharing?.commentExplorerAccess ===
                      SharingCommentExplorerAccess.Hidden) ||
                  sharedUser?.commentExplorerAccess === SharingCommentExplorerAccess.Hidden
                    ? undefined
                    : () => handleClick(c.attribute)
                }
              >
                <StyledLegend>
                  {getLegendIcon(c.attribute)}
                  <b>{getLegendCount(c)}</b>
                  <span>
                    <Text resource={`${resourceLabel}.${c.attribute}`} />
                  </span>
                </StyledLegend>
              </Pill>
            ))}
          </StyledLegendContainer>
        </>
      ) : (
        <StyledSkeletonContainer className="pulse">
          <Skeleton width={1134} height={12} backgroundColor={Color.blue20} responsive={true} />
          {[...Array(skeletonArrayCount)].map((_, index) => (
            <Skeleton key={index} width={100} height={25} backgroundColor={Color.neutral10} />
          ))}
        </StyledSkeletonContainer>
      )}
    </StyledStackedBarChartContainer>
  );
};

const StyledStackedBarChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

const StyledLegendContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  @media (max-width: 1365px) {
    column-gap: 10px;
  }
`;

const StyledLegend = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  b {
    margin: 0 3px;
  }
`;

const StyledSkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  row-gap: 15px;
  flex-flow: row wrap;
`;
