import React, { ComponentType } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

type Props = {
  render: ComponentType<any>;
  isOpen: boolean;
  closeHandler: () => any;
  renderComponentProps?: Record<string, unknown>;
  width?: string;
};

export const SideDrawer = (props: Props) => {
  const { render: Render, isOpen, closeHandler, renderComponentProps, width = "550px" } = props;

  const renderedJsx = (
    <StyledDrawerContainer isOpen={isOpen}>
      <StyledBackdrop onClick={closeHandler} />
      <StyledDrawer width={width} isOpen={isOpen}>
        {isOpen && <Render {...props} {...renderComponentProps} />}
      </StyledDrawer>
    </StyledDrawerContainer>
  );

  //#Root is not found in storybook runtime so we need to check if it exists
  return document.querySelector("#root")
    ? createPortal(renderedJsx, document.querySelector("#root"))
    : renderedJsx;
};

type StyledDrawerContainerProps = {
  isOpen: boolean;
};

const StyledDrawerContainer = styled.div<StyledDrawerContainerProps>`
  position: fixed;
  z-index: ${ZIndexStackingContext.high + 1};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.2s ease-out;
`;

const StyledDrawer = styled.aside<{ width: string; isOpen: boolean }>`
  position: fixed;
  width: ${({ width }) => width};
  background-color: ${Color.white};
  height: 100%;
  top: 0;
  right: 0;
  transform: translateX(${({ isOpen }) => (isOpen ? "0" : "100%")});
  transition: transform 0.2s ease-out;
`;

const StyledBackdrop = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.07);
`;
