import React, { useState, useMemo, useEffect, ReactNode } from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";
import { useAppDispatch, useAppSelector } from "store";

import useClickOutside from "hooks/useClickOutside";
import { getRandomNumber } from "utils/getRandomNumber";
import { UserDemographic } from "context/UserProfileContext";
import { setSelectedUserDemographicFilters } from "store/sharing/userSelectionSlice";

import { StyledTablePageTitle } from "components/Table/TablePageHeader";
import { SearchRow } from "components/SearchRow";
import { Pagination } from "components/Pagination";
import { UserDemographicFilterMenu } from "pages/analysis/[id]/sharing/_layouts/UserDemographicFilterMenu";
import { ExpandableFilterPillWrapper } from "common-layouts/FilterSelectionSection/FilterPillList/ExpandableFilterPillWrapper";
import { Button } from "components/_buttons/Button";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { FilterPillContainer } from "common-layouts/FilterSelectionSection/FilterPillList/FilterPillContainer";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { FilterType } from "ts/filters/filterType";
import { FilterPillStyle } from "ts/enums/filterPillStyle";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { SharingView } from "ts/enums/sharing";

const SHARING_PAGE_LIMIT = 30;

type Props = {
  pageTitle: string;
  searchCountDataType: string;
  placeholderText?: string;
  currentPage: number;
  showUserDemographicFilters?: boolean;
  isUserDemographicFiltersReset?: boolean;
  totalItemCount?: number;
  currentItemCount?: number;
  isModal?: boolean;
  updatePage: (updatedPage: number) => void;
  refetchList?: (demographicFilters: UserDemographic[]) => void;
  onSearch?: () => void;
};

export const Header = ({
  pageTitle,
  searchCountDataType,
  placeholderText,
  currentPage,
  showUserDemographicFilters = false,
  isUserDemographicFiltersReset,
  totalItemCount = null,
  currentItemCount = null,
  isModal = false,
  updatePage,
  refetchList,
  onSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const sharing = useAppSelector((state) => state.sharing);
  const userSelectionState = useAppSelector((state) => state.sharingUserSelection);
  const sharedWithState = useAppSelector((state) => state.sharedWith);

  const [localSelectedDemographicFilters, setLocalSelectedDemographicFilters] = useState<
    UserDemographic[]
  >([]);
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);

  const userDemographicFilterRef = useClickOutside(() => setShowFilterDropdown(false));

  const { getResource } = useResource();

  const selectedFilterIds = useMemo(() => {
    return userSelectionState.selectedUserDemographicFilters.map((f) => ({
      filterName: f.name,
      id: getRandomNumber(),
    }));
  }, [userSelectionState.selectedUserDemographicFilters]);

  if (currentItemCount === null) {
    currentItemCount =
      sharing.view === SharingView.UsersSharedWith
        ? sharedWithState.sharedUsersApiData.itemCount
        : sharing.view === SharingView.GroupsSharedWith
        ? sharedWithState.sharedGroupsApiData.itemCount
        : userSelectionState.shareableUsersApiData.itemCount;
  }

  if (totalItemCount === null) {
    totalItemCount =
      sharing.view === SharingView.UsersSharedWith
        ? sharedWithState.sharedUsersApiData.totalCount
        : sharing.view === SharingView.GroupsSharedWith
        ? sharedWithState.sharedGroupsApiData.totalCount
        : userSelectionState.shareableUsersApiData.totalCount;
  }

  const applyFilters = () => {
    const filters = localSelectedDemographicFilters.filter((df) => df.values.length > 0);
    dispatch(setSelectedUserDemographicFilters(filters));
    setLocalSelectedDemographicFilters(filters);
    refetchList(filters);
  };
  const deleteAllFilters = () => {
    dispatch(setSelectedUserDemographicFilters([]));
    setLocalSelectedDemographicFilters([]);
    refetchList([]);
  };

  const deletePill = (filterName: string) => {
    const updatedFilters = localSelectedDemographicFilters.filter((sf) => sf.name !== filterName);
    setLocalSelectedDemographicFilters(updatedFilters);
    dispatch(setSelectedUserDemographicFilters(updatedFilters));
    refetchList(updatedFilters);
  };

  const resetLocalFilters = () => {
    const areFiltersSynced =
      userSelectionState.selectedUserDemographicFilters.every((sud) =>
        localSelectedDemographicFilters
          .find((lsud) => lsud.name === sud.name)
          ?.values.every((v) => sud.values.includes(v))
      ) &&
      localSelectedDemographicFilters.every((lsud) =>
        userSelectionState.selectedUserDemographicFilters
          .find((sud) => lsud.name === sud.name)
          ?.values.every((v) => lsud.values.includes(v))
      );

    if (
      !areFiltersSynced ||
      (userSelectionState.selectedUserDemographicFilters.length === 0 &&
        localSelectedDemographicFilters.length > 0)
    ) {
      setLocalSelectedDemographicFilters(userSelectionState.selectedUserDemographicFilters);
    }
  };

  useEffect(() => {
    if (isUserDemographicFiltersReset) setLocalSelectedDemographicFilters([]);
  }, [isUserDemographicFiltersReset]);

  useEffect(() => {
    if (!showFilterDropdown) resetLocalFilters();
  }, [showFilterDropdown]); // eslint-disable-line

  const renderAltMenu = (
    filter: UserDemographic,
    resetLocalState: boolean,
    onSubmit: () => void
  ): ReactNode => {
    if (resetLocalState) resetLocalFilters();

    return (
      <UserDemographicFilterMenu
        selectedFilterId={selectedFilterIds.find((i) => i.filterName === filter.name)?.id}
        selectedFilter={sharing.allUserDemographics.find((ud) => ud.name === filter.name)}
        currentlySelectedFilters={localSelectedDemographicFilters}
        allFiltersList={sharing.allUserDemographics}
        sectionTitle={filter.name}
        selectedFilterCount={
          localSelectedDemographicFilters.find((sf) => sf.name === filter.name)?.values.length
        }
        parentCheckboxLabel={getResource("columnFilterSection.selectAll")}
        updateFilters={(filters) => setLocalSelectedDemographicFilters(filters)}
        applyFilters={() => {
          applyFilters();
          onSubmit();
        }}
      />
    );
  };

  return (
    <StyledHeaderContainer>
      <StyledHeader>
        <StyledTablePageTitle>{pageTitle}</StyledTablePageTitle>
        <SearchRow
          currentCount={currentItemCount}
          totalCount={totalItemCount}
          dataType={searchCountDataType}
          isModal={isModal}
          customStyles={{
            border: `1px solid ${Color.sky50}`,
            width: "480px",
          }}
          placeholderText={placeholderText}
          onSearch={onSearch}
        />
        {currentItemCount > 0 && (
          <StyledPaginationContainer>
            <Pagination
              currentPage={currentPage}
              currentItemsCount={currentItemCount}
              pageSize={SHARING_PAGE_LIMIT}
              handlePageSelection={updatePage}
            />
          </StyledPaginationContainer>
        )}
      </StyledHeader>
      {showUserDemographicFilters && (
        <ExpandableFilterPillWrapper
          showDeleteFilters={userSelectionState.selectedUserDemographicFilters.length > 0}
          deleteFilters={deleteAllFilters}
        >
          <StyledUserDemographicContainer ref={userDemographicFilterRef}>
            <Button
              variant={ButtonVariant.secondary}
              size={ButtonSize.md}
              style={{ height: 30 }}
              onClick={() => setShowFilterDropdown((show) => !show)}
            >
              <Text resource="button.filter.byUserDemographic" />
            </Button>
            <StyledDropdownTransition show={showFilterDropdown}>
              <UserDemographicFilterMenu
                allFiltersList={sharing.allUserDemographics}
                currentlySelectedFilters={localSelectedDemographicFilters}
                sectionTitle={getResource("userList.detailsPopup.title")}
                selectAllLabel={getResource("columnFilterSection.selectAll")}
                isMenuClosed={!showFilterDropdown}
                updateFilters={(filters) => setLocalSelectedDemographicFilters(filters)}
                applyFilters={() => {
                  applyFilters();
                  setShowFilterDropdown(false);
                }}
              />
            </StyledDropdownTransition>
          </StyledUserDemographicContainer>
          {userSelectionState.selectedUserDemographicFilters.map((filter) => (
            <FilterPillContainer
              key={selectedFilterIds.find((i) => i.filterName === filter.name)?.id}
              filterType={FilterType.UserDemographic}
              styleType={FilterPillStyle.outlineBlue}
              demographicFilterName={filter.name}
              demographicFilterId={selectedFilterIds.find((i) => i.filterName === filter.name)?.id}
              filterCount={
                userSelectionState.selectedUserDemographicFilters.find(
                  (ud) => ud.name === filter.name
                ).values.length
              }
              renderAltMenu={(resetLocalState, onSubmit) =>
                renderAltMenu(filter, resetLocalState, onSubmit)
              }
              deletePill={() => deletePill(filter.name)}
            />
          ))}
        </ExpandableFilterPillWrapper>
      )}
    </StyledHeaderContainer>
  );
};

const StyledUserDemographicContainer = styled.div`
  z-index: ${ZIndexStackingContext.medium};
`;

const StyledHeaderContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${Color.white};
  z-index: ${ZIndexStackingContext.medium};
  cursor: default;
`;

const StyledPaginationContainer = styled.div`
  margin-left: auto;
`;
