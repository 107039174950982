import { snakeCase } from "lodash";

// config
import { mlyApi, getHeaders } from "config/mlyApi";

// models
import { AnalysisJobInput } from "ts/analysis";
import {
  AnalysisListView,
  CommentsSearchType,
  CommentsSortingParameter,
  ImportProcess,
  LanguageCode,
  StatisticsType,
} from "@explorance/mly-types";
import { Widget } from "ts/widget";

// utils
import { getUrlQueryString } from "utils/getUrlQueryString";

// Analysis List endpoints
type GetAnalysisListOptions = {
  view?: AnalysisListView;
  limit?: number;
  page?: number;
  search?: string;
  sort_column?: string;
  sort_order?: string;
};

export async function getAnalysisList(options: GetAnalysisListOptions = {}) {
  const requestConfig = await getHeaders();
  const url = "/analysis" + getUrlQueryString({ ...options, view: snakeCase(options.view) });
  return mlyApi.get(url, requestConfig);
}

export async function deleteAnalysis(analysisId: number) {
  const requestConfig = await getHeaders();
  const url = `/analysis/${analysisId}`;
  return mlyApi.delete(url, requestConfig);
}

export async function getRecentAnalyses(limit?: number) {
  const requestConfig = await getHeaders();
  const url = "/analysis/recent" + getUrlQueryString({ limit });
  return mlyApi.get(url, requestConfig);
}

// Single analysis endpoints
export async function createAnalysis(name?: string) {
  const requestConfig = await getHeaders();
  const url = `/analysis`;
  return mlyApi.post(url, { name }, requestConfig);
}

export async function uploadFile(analysisId: number, file: File) {
  const updatedFile = new File([file], file.name, {
    type: file.type === "application/vnd.ms-excel" ? "text/csv" : file.type,
  });
  const requestConfig = await getHeaders("multipart/form-data");
  const url = `/analysis/${analysisId}/file`;
  const formData = new FormData();
  formData.append("file", updatedFile);
  formData.append("importProcess", ImportProcess.TwoStep);
  return mlyApi.post(url, formData, requestConfig);
}

export async function getAnalysisDetails(analysisId: number, user_id?: number) {
  const requestConfig = await getHeaders();
  const queryString = getUrlQueryString({
    user_id,
  });
  const url = `/analysis/${analysisId}${queryString}`;
  return mlyApi.get(url, requestConfig);
}

export async function runAnalysis(id: number, jobInput: AnalysisJobInput) {
  const requestConfig = await getHeaders();
  const url = `/analysis/${id}/jobs`;
  const requestBody = jobInput;
  return mlyApi.post(url, requestBody, requestConfig);
}

export async function updateAnalysis(analysisId: number, body?: Record<string, unknown>) {
  const requestConfig = await getHeaders();
  const url = `/analysis/${analysisId}`;
  return mlyApi.put(url, body, requestConfig);
}

export async function getTopicsByAnalysisId(analysisId: number, cancelToken?: any) {
  const requestConfig = await getHeaders();
  requestConfig.cancelToken = cancelToken;
  const url = `/analysis/${analysisId}/topics`;
  return mlyApi.get(url, requestConfig);
}

type GetDemographicsByAnalysisIdParams = {
  analysisId: number;
  cancelToken?: any;
  limit?: number;
  user_id?: number;
  sharing_id?: string;
};

export async function getDemographicsByAnalysisId(params: GetDemographicsByAnalysisIdParams) {
  const requestConfig = await getHeaders();
  const { analysisId, cancelToken, limit, user_id, sharing_id } = params;
  requestConfig.cancelToken = cancelToken;
  const queryParams = getUrlQueryString({ limit, user_id, sharing_id });
  const url = `/analysis/${analysisId}/demographics${queryParams}`;
  return mlyApi.get(url, requestConfig);
}

export async function getTopicStatisticsByAnalysisId(
  analysisId: number,
  queryString?: string,
  requestBody?: Record<string, unknown>,
  cancelToken?: any
) {
  const requestConfig = await getHeaders();
  requestConfig.cancelToken = cancelToken;
  const url = `/analysis/${analysisId}/topics/statistics${queryString || ""}`;
  return mlyApi.post(url, requestBody, requestConfig);
}

export async function getCommentsByAnalysisId(
  analysisId: number,
  customModelId?: number,
  page?: number,
  view?: string,
  search?: string,
  sort?: CommentsSortingParameter,
  userId?: number,
  sharingId?: string,
  searchLanguage?: LanguageCode,
  searchType?: CommentsSearchType,
  requestBody?: Record<string, unknown>,
  cancelToken?: any
) {
  const requestConfig = await getHeaders();
  requestConfig.cancelToken = cancelToken;
  const baseUrl = `/analysis/${analysisId}/comments`;
  const url =
    baseUrl +
    getUrlQueryString(
      {
        customModelId,
        page,
        view,
        sort,
        userId,
        sharingId,
        searchLanguage,
        searchType,
        ...(search?.length > 0 && { search }),
      },
      { snakeCasify: true }
    );
  return mlyApi.post<any>(url, requestBody, requestConfig);
}

export async function getCommentStatisticsByAnalysisId(
  analysisId: number,
  custom_model_id?: number,
  view?: string,
  user_id?: number,
  sharingId?: string,
  body?: Record<string, unknown>,
  cancelToken?: any
) {
  const requestConfig = await getHeaders();
  requestConfig.cancelToken = cancelToken;

  const queryString = getUrlQueryString({
    custom_model_id,
    view,
    user_id,
    sharing_id: sharingId,
  });
  const url = `/analysis/${analysisId}/comments/statistics${queryString || ""}`;
  return mlyApi.post<any>(url, body, requestConfig);
}

type getWidgetParams = {
  analysisId: number;
  user_id?: number;
  sharingId?: string;
};

export async function getWidgetsByAnalysisId(params: getWidgetParams) {
  const requestConfig = await getHeaders();
  const { analysisId, user_id, sharingId } = params;
  const queryString = getUrlQueryString({ user_id, sharing_id: sharingId });
  const url = `/analysis/${analysisId}/widgets${queryString}`;
  return mlyApi.get<any>(url, requestConfig);
}

export async function createNewWidgetByGroupId(groupId: number, newWidget: Widget) {
  const requestConfig = await getHeaders();
  const url = `/widget-groups/${groupId}/widget`;
  const widgetForBackend = {
    ...newWidget,
    filters: { ...newWidget.filters, topics: newWidget.filters.topics.map((tn) => tn.fullPath) },
  };
  return mlyApi.post(url, widgetForBackend, requestConfig);
}

export async function getCommentersCount(analysisId: number) {
  const requestConfig = await getHeaders();
  const url = `/analysis/${analysisId}/unique-commenters`;
  return mlyApi.get(url, requestConfig);
}

export async function getEmptyCellCount(analysisId: number) {
  const requestConfig = await getHeaders();
  const url = `/analysis/${analysisId}/comments/empty`;
  return mlyApi.get(url, requestConfig);
}

export async function getExportName(analysisId: number) {
  const requestConfig = await getHeaders();
  const url = `/analysis/${analysisId}/exports`;
  return mlyApi.get(url, requestConfig);
}

type GetRelevantCommentsParams = {
  analysisId: number;
  statistic: StatisticsType;
  requestBody: Record<string, unknown>;
  custom_model_id?: number;
  limit?: number;
  sharing_id?: string;
  user_id?: number;
};

export async function getRelevantComments(params: GetRelevantCommentsParams) {
  const requestConfig = await getHeaders();
  const { analysisId, sharing_id, custom_model_id, limit, statistic, user_id, requestBody } =
    params;
  const queryString = getUrlQueryString({
    custom_model_id,
    limit,
    user_id,
    statistic,
    sharing_id,
  });
  const url = `/analysis/${analysisId}/comments/relevant${queryString || ""}`;
  return mlyApi.post(url, requestBody, requestConfig);
}

export async function getAnalysisAvailableModels(analysisId: number) {
  const requestConfig = await getHeaders();
  const url = `/analysis/${analysisId}/models`;
  return mlyApi.get(url, requestConfig);
}

type GetPrefilteringWordSuggestionsParams = {
  analysisId: number;
  selectedColumns: string[];
  wordCountThreshold?: number;
  limit?: number;
  page?: number;
};

export async function getPreFilteringWordSuggestions(params: GetPrefilteringWordSuggestionsParams) {
  const requestConfig = await getHeaders();
  const { analysisId, selectedColumns, wordCountThreshold, limit, page } = params;
  let paramReformat = "";

  selectedColumns.forEach((e, index) => {
    const encodedValue = e.includes("&") ? e.replace("&", "%26") : e;
    paramReformat += `${index === 0 ? "?" : "&"}selectedColumns[]=${encodedValue}`;
  });

  const queryString =
    paramReformat +
    getUrlQueryString({
      wordCountThreshold,
      limit,
      page,
    }).replace("?", "&");
  const url = `/analysis/${analysisId}/pre-filtering/word-suggestions${queryString}`;
  return mlyApi.get(url, requestConfig);
}

export async function getAnalysisStatus(analysisId: number) {
  const requestConfig = await getHeaders();
  const url = `/analysis/${analysisId}/status`;
  return mlyApi.get(url, requestConfig);
}

export async function getAnalysisRedactionVersions(analysisId: number) {
  const requestConfig = await getHeaders();
  const url = `/analysis/${analysisId}/redaction-versions`;
  return mlyApi.get(url, requestConfig);
}
