import { QuickAnalysisState as QAState } from "ts/enums/quickAnalysis";
import { Model, ModelSelectionDropdownData, ModelSelectionOptions } from "ts/models";
import { generatedComments as generatedCommentsData } from "assets/constants/generatedComments";
import { CommentAnalysisResults, GeneratedComments } from "ts/comments";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getAvailableModelOptions } from "utils/getModelsByEnv";
import { executeQuickAnalysis, fetchQuickAnalysisModels } from "./thunks";
import { getModelSelectionDropdownData } from "components/ModelSelection/helpers";
import { getUniqueEnvs } from "utils/getUniqueEnvs";
import { random } from "lodash";

type QuickAnalysisState = {
  comment: string;
  quickAnalysisState: QAState;
  isDisabled: boolean;
  selectedModel: Model | null;
  envDropdownOptions: string[];
  selectedEnv: string;
  selectedPrecision: number;
  pickableGeneratedComments: GeneratedComments;
  quickAnalysisResult: CommentAnalysisResults | null;
  showCharacterCount: boolean;
  models: ModelSelectionOptions;
  modelOptionsDropdownData: ModelSelectionDropdownData;
  quickAnalysisFailedStatus: number;
};

const initialState: QuickAnalysisState = {
  comment: "",
  quickAnalysisState: QAState.edit,
  isDisabled: true,
  selectedModel: null,
  envDropdownOptions: [],
  selectedEnv: "",
  selectedPrecision: 8,
  pickableGeneratedComments: generatedCommentsData,
  quickAnalysisResult: null,
  showCharacterCount: false,
  models: null,
  modelOptionsDropdownData: null,
  quickAnalysisFailedStatus: null,
};

const quickAnalysisSlice = createSlice({
  name: "quickAnalysis",
  initialState,
  reducers: {
    setQuickAnalysisState: (state, action: PayloadAction<QAState>) => {
      state.quickAnalysisState = action.payload;
    },
    setComment: (state, action: PayloadAction<string>) => {
      state.comment = action.payload;
      state.isDisabled = state.comment.trim().length === 0;
    },
    setIsDisabled: (state, action: PayloadAction<boolean>) => {
      state.isDisabled = action.payload;
    },
    setSelectedModel: (state, action: PayloadAction<Model | null>) => {
      if (state.selectedModel === action.payload) return;
      state.selectedModel = action.payload;
      state.isDisabled = state.comment.trim().length === 0;
    },
    setSelectedEnv: (state, action: PayloadAction<string>) => {
      if (state.selectedEnv === action.payload) return;
      state.selectedEnv = action.payload;
      const availableModels = getAvailableModelOptions(
        state.modelOptionsDropdownData,
        state.selectedEnv
      );
      state.models = availableModels;
      state.selectedModel = availableModels.models[Object.keys(availableModels.models)[0]][0];
      state.isDisabled = state.comment.trim().length === 0;
    },
    handleGenerateComment: (state) => {
      const model = state.selectedModel?.categorizationType || "default";
      const pickableCommentsArray = state.pickableGeneratedComments[model];
      const pickedComment = pickableCommentsArray[random(0, pickableCommentsArray.length - 1)];
      state.comment = pickedComment;
      state.pickableGeneratedComments = {
        ...state.pickableGeneratedComments,
        [model]:
          pickableCommentsArray.length === 1
            ? generatedCommentsData[model]
            : pickableCommentsArray.filter((c) => c !== pickedComment),
      };
      state.isDisabled = false;
    },
    clearAll: (state) => {
      state.comment = "";
      state.quickAnalysisState = QAState.edit;
      state.quickAnalysisResult = null;
      state.isDisabled = state.comment.trim().length === 0;
    },
    setSelectedPrecision: (state, action: PayloadAction<number>) => {
      state.selectedPrecision = action.payload;
    },
    setQuickAnalysisResult: (state, action: PayloadAction<CommentAnalysisResults | null>) => {
      state.quickAnalysisResult = action.payload;
    },
    setShowCharacterCount: (state, action: PayloadAction<boolean>) => {
      state.showCharacterCount = action.payload;
    },
    setQuickAnalysisFailedStatus: (state, action: PayloadAction<number>) => {
      state.quickAnalysisFailedStatus = action.payload;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuickAnalysisModels.fulfilled, (state, action) => {
      const modelOptionsDropdownData = getModelSelectionDropdownData(action.payload, true);
      state.modelOptionsDropdownData = modelOptionsDropdownData;
      const uniqueEnvs = getUniqueEnvs(modelOptionsDropdownData.options);
      state.envDropdownOptions = uniqueEnvs;
      state.selectedEnv = uniqueEnvs[0];
      state.models = getAvailableModelOptions(modelOptionsDropdownData, uniqueEnvs[0]);
      state.selectedModel = modelOptionsDropdownData.categorizedModelList[0];
    }),
      builder.addCase(executeQuickAnalysis.pending, (state) => {
        state.isDisabled = true;
        state.quickAnalysisState = QAState.loading;
      });
    builder.addCase(executeQuickAnalysis.fulfilled, (state, action) => {
      //if we left the page we don't care about the thunk finishing
      if (state.comment.length === 0) return;
      state.quickAnalysisResult = action.payload.analysisResult;
      state.quickAnalysisState = QAState.results;
    });
  },
});

export const {
  setQuickAnalysisState,
  setComment,
  setIsDisabled,
  setSelectedModel,
  setSelectedEnv,
  setSelectedPrecision,
  setQuickAnalysisResult,
  setShowCharacterCount,
  handleGenerateComment,
  setQuickAnalysisFailedStatus,
  clearAll,
  clearState,
} = quickAnalysisSlice.actions;

export default quickAnalysisSlice.reducer;
