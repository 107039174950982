export enum SystemOS {
  Windows = "Windows",
  Mac = "Mac",
  Unknown = "Unknown",
}

export const getSystemOs = () => {
  if (navigator.userAgent.includes("Windows")) return SystemOS.Windows;
  if (navigator.userAgent.includes("Macintosh")) return SystemOS.Mac;
  return SystemOS.Unknown;
};
