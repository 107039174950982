import React, { useContext } from "react";
import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "store";
import { AnalysisContext } from "context/AnalysisContext";

import { useGetModelName } from "hooks/useGetModelName";
import { isDegraded } from "utils/isDegraded";
import { isSettingsPageUnavailable } from "utils/isSettingsPageUnavailable";
import { useResource } from "hooks/useResource";
import { getAvailableCustomModelOptions, getAvailableModelOptions } from "utils/getModelsByEnv";
import { fetchPreFilteringWordSuggestions } from "store/analysisSettings/thunks";
import {
  setIsMultilingualEnabled,
  setSelectedColumns,
  setSelectedCommenterColumn,
  setSelectedCustomModel,
  setSelectedEnv,
  setSelectedModel,
  setSelectedPrecision,
  setSelectedVariableMapping,
} from "store/analysisSettings/settingsSlice";

import {
  AnalysisSettingsDropdownField,
  StyledSettingsSelection,
  StyledSettingsSelectionDescription,
  StyledSettingsSelectionTitle,
} from "common-layouts/AnalysisSettingsDropdownField";
import { PreFiltering } from "./PreFiltering";
import { CommentColumnsSelection } from "./CommentColumnsSelection";
import { DiscreteSlider } from "components/DiscreteSlider";
import { ModelSelection } from "components/ModelSelection";
import { FeatureFlag } from "components/FeatureFlag";
import { CategorizationTypeSymbol } from "components/_icons/CategorizationTypeSymbol";
import { Text } from "components/Text";
import { Icon, IconType } from "components/_icons/Icon";
import { Select } from "components/_inputs/Select";
import { ToggleSwitch } from "components/_inputs/ToggleSwitch";

import { Color } from "ts/enums/color";
import { Feature } from "ts/enums/feature";
import { Model, getModelFromSelectedAnalysisModel } from "ts/models";
import { precisionSelectionRange } from "assets/constants/precisionSelection";
import { ButtonVariant } from "ts/enums/button";

type Props = {
  isModelOutdated: boolean;
};

export const AnalysisSettingsFields = ({ isModelOutdated }: Props) => {
  //  redux
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);
  const state = useAppSelector((state) => state.settings);

  // local states
  const [analysisState] = useContext(AnalysisContext);

  const { getResource } = useResource();
  const getModelName = useGetModelName(getResource);

  const handleSelectModel = (selectedModel: Model | null) => {
    if (!selectedModel) dispatch(setSelectedModel(null));
    dispatch(setSelectedModel(selectedModel));
  };

  const handleCommentColumnsSelection = async (selectedColumns: string[]) => {
    await dispatch(setSelectedColumns(selectedColumns));
    if (selectedColumns.length === 0) return;
    dispatch(fetchPreFilteringWordSuggestions());
  };

  const modelOptions = getAvailableModelOptions(state.modelOptionsDropdownData, state.selectedEnv);

  return (
    <>
      <CommentColumnsSelection
        selectedColumns={state.selectedColumns}
        options={analysisState.analysisDetails.allColumns}
        handleSelectOption={handleCommentColumnsSelection}
        isDisabled={isSettingsPageUnavailable(analysisState)}
      />
      <PreFiltering />
      {!state.loadingModels && (
        <StyledModelSettingsSection>
          {state.envDropdownOptions.length > 1 && (
            <>
              <StyledSettingsSelectionTitle marginTop="0px">
                <Text resource="analysisSettings.modelEnv.label" />
              </StyledSettingsSelectionTitle>
              <Select
                options={state.envDropdownOptions.map((env) => ({ label: env, value: env }))}
                selectedOption={{ label: state.selectedEnv, value: state.selectedEnv }}
                handleChange={(arg: string) => dispatch(setSelectedEnv(arg))}
                dropdownWidth="342px"
                buttonStyle={{ width: "342px" }}
                dropdownPosition={{ top: 35 }}
                buttonVariant={ButtonVariant.neutral}
                labelStyle={{ fontWeight: "normal", color: Color.gray50 }}
                iconColor={Color.gray20}
                disabled={isSettingsPageUnavailable(analysisState)}
              />
            </>
          )}
          <StyledSettingsSelectionTitle marginTop="12px">
            <Text resource="analysisSettings.categorizationModel.label" />
          </StyledSettingsSelectionTitle>
          <StyledSettingsSelectionDescription>
            <Text resource="analysisSettings.categorizationModel.description" />
          </StyledSettingsSelectionDescription>
          {isModelOutdated ? (
            <StyledUnavailableModelContainer>
              <StyledUnavailableModelMessage>
                <Icon type={IconType.info} color={Color.gray50} size={12} />
                <span>
                  <Text resource="analysisSettings.outdatedModel" />
                </span>
              </StyledUnavailableModelMessage>
            </StyledUnavailableModelContainer>
          ) : (
            analysisState.analysisDetails.selectedModel?.isAvailable === false && (
              <>
                <StyledUnavailableModelContainer>
                  <StyledUnavailableModelMessage>
                    <Icon type={IconType.info} color={Color.gray50} size={12} />
                    <span>
                      <Text resource="analysisSettings.unavailableModel" />
                    </span>
                  </StyledUnavailableModelMessage>
                </StyledUnavailableModelContainer>
                <StyledCurrentModelContainer>
                  <b>
                    <Text resource="analysisSettings.unavailableModel.currentlyUsed" />
                  </b>
                  <div>
                    <CategorizationTypeSymbol
                      categorizationType={analysisState.analysisDetails.selectedModel.family}
                    />
                    <span>
                      {getModelName(
                        getModelFromSelectedAnalysisModel(
                          analysisState.analysisDetails.selectedModel
                        )
                      )}
                    </span>
                  </div>
                </StyledCurrentModelContainer>
              </>
            )
          )}
          <StyledSelectionMethodContainer>
            <ModelSelection
              selectedModel={state.selectedModel}
              options={modelOptions}
              handleSelectModel={handleSelectModel}
              isDisabled={
                isDegraded(currentUser, analysisState) || isSettingsPageUnavailable(analysisState)
              }
            />
          </StyledSelectionMethodContainer>

          <FeatureFlag feature={Feature.multilingualAnalysis}>
            <StyledToggleSwitchContainer>
              <ToggleSwitch
                checked={state.isMultilingualEnabled}
                labelKey="analysisSettings.multilingual.switch.label"
                customStyles={{ width: 27, height: 14 }}
                showLabelToRight
                onChange={() => dispatch(setIsMultilingualEnabled(!state.isMultilingualEnabled))}
                isDisabled={isSettingsPageUnavailable(analysisState)}
              />
            </StyledToggleSwitchContainer>
          </FeatureFlag>

          <StyledSettingsSelectionTitle>
            <Text resource="analysisSettings.customModel.label" />
          </StyledSettingsSelectionTitle>
          <StyledSettingsSelectionDescription>
            <Text resource="analysisSettings.customModel.description" />
          </StyledSettingsSelectionDescription>

          <StyledSelectionMethodContainer>
            <ModelSelection
              selectedModel={state.selectedCustomModel}
              options={getAvailableCustomModelOptions(
                state.selectedModel,
                state.modelOptionsDropdownData.options
              )}
              handleSelectModel={(model) => dispatch(setSelectedCustomModel(model))}
              isDisabled={
                isDegraded(currentUser, analysisState) || isSettingsPageUnavailable(analysisState)
              }
              customModelSelection
              selectedEnv={state.selectedEnv}
            />
          </StyledSelectionMethodContainer>
        </StyledModelSettingsSection>
      )}
      <FeatureFlag feature={Feature.precision}>
        {state.selectedModel && (
          <StyledSettingsSelection>
            <StyledSettingsSelectionTitle>
              <Text resource="quickAnalysis.precision.label" />
            </StyledSettingsSelectionTitle>
            <StyledSettingsSelectionDescription>
              <Text resource="analysisSettings.precision.description" />
            </StyledSettingsSelectionDescription>
            <StyledSelectionMethodContainer>
              <DiscreteSlider
                range={precisionSelectionRange.map((x) => x.value)}
                selectedValue={state.selectedPrecision}
                onChange={(value) => dispatch(setSelectedPrecision(value))}
              />
            </StyledSelectionMethodContainer>
          </StyledSettingsSelection>
        )}
      </FeatureFlag>
      <StyledBottomContainer>
        <AnalysisSettingsDropdownField
          titleKey="analysisSettings.commentersID.label"
          descriptionKey="analysisSettings.commentersID.description"
          isStringSelection={true}
          selectedOption={state.selectedCommenterColumn}
          options={[...analysisState.analysisDetails.allColumns].filter(
            (col) => !state.selectedColumns.some((sc) => sc === col)
          )}
          customWidth={"342px"}
          handleSelectOption={(arg: string) => dispatch(setSelectedCommenterColumn(arg))}
          isDisabled={
            isDegraded(currentUser, analysisState) || isSettingsPageUnavailable(analysisState)
          }
        />
        {analysisState.analysisDetails.availableVariableMappings && (
          <AnalysisSettingsDropdownField
            titleKey="analysisSettings.variableMapping.label"
            descriptionKey="analysisSettings.variableMapping.description"
            isStringSelection={false}
            selectedOption={analysisState.analysisDetails.availableVariableMappings.find(
              (vm) => vm.id === state.selectedVariableMapping
            )}
            options={analysisState.analysisDetails.availableVariableMappings}
            customWidth={"342px"}
            handleSelectOption={(arg: number) => dispatch(setSelectedVariableMapping(arg))}
            isDisabled={
              isDegraded(currentUser, analysisState) || isSettingsPageUnavailable(analysisState)
            }
          />
        )}
      </StyledBottomContainer>
    </>
  );
};

const StyledModelSettingsSection = styled.div`
  margin-bottom: 15px;
  border: 1px solid ${Color.blue20};
  padding: 16px;
  border-radius: 5px;
`;

const StyledSelectionMethodContainer = styled.div`
  margin-top: 8px;
`;

const StyledBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledUnavailableModelContainer = styled.div`
  display: inline-block;
`;

const StyledUnavailableModelMessage = styled.div`
  background-color: ${Color.orange20};
  border: 1px solid ${Color.orange30};
  border-radius: 5px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 6px 0;

  span {
    font-size: 14px;
    color: ${Color.gray50};
  }
`;

const StyledCurrentModelContainer = styled.div`
  b {
    font-size: 14px;
    color: ${Color.gray50};
  }

  div {
    margin: 6px 0;
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;

const StyledToggleSwitchContainer = styled.div`
  margin-top: 12px;
`;
