import React, { useState } from "react";
import styled from "styled-components";

import { Icon, IconType } from "components/_icons/Icon";
import { DropdownMenu } from "components/DropdownMenu";

import useClickOutside from "hooks/useClickOutside";

import { Color } from "ts/enums/color";
import { DropdownMenuItem } from "ts/dropdown";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

type Props = {
  dropdownMenuContent: DropdownMenuItem[];
  handleSelectView: (view) => void;
  dropdownWidth: string;
};

export const ViewSelection = ({ dropdownMenuContent, dropdownWidth, handleSelectView }: Props) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  const selectDropdownItem = (newView) => {
    handleSelectView(newView);
    setShowDropdown(false);
  };

  return (
    <div ref={dropdownRef}>
      <StyledViewSelectionButton onClick={() => setShowDropdown((show) => !show)}>
        <span>{dropdownMenuContent.find((c) => c.isActive).label}</span>
        <StyledIconContainer>
          <Icon type={IconType.triangleFilledDown} size={8} color={Color.gray20} />
        </StyledIconContainer>
      </StyledViewSelectionButton>
      <DropdownMenu
        items={dropdownMenuContent}
        show={showDropdown}
        width={dropdownWidth}
        position={{ top: 44, zIndex: ZIndexStackingContext.high + 1 }}
        onChange={selectDropdownItem}
      />
    </div>
  );
};

const StyledViewSelectionButton = styled.div`
  background-color: ${Color.neutral10};
  width: fit-content;
  padding: 8px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  cursor: pointer;

  span {
    color: ${Color.gray50};
    font-size: 16px;
    font-weight: bold;
  }
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
`;
