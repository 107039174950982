import React from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";

type Props = {
  height?: string;
  style?: React.CSSProperties;
};

export const VerticalSeparator = ({ height, style }: Props) => (
  <StyledVerticalSeparator height={height} style={style} />
);

const StyledVerticalSeparator = styled.div<{ height: string }>`
  height: ${({ height }) => height};
  width: 1px;
  background-color: ${Color.blue20};
`;
