import React, { useState } from "react";

import useClickOutside from "hooks/useClickOutside";

import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";
import { CommentDetailsPopup } from "./CommentDetailsPopup";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { Comment } from "ts/comments";

type Props = {
  comment: Comment;
  disabled?: boolean;
};

export const DetailsActionButton = ({ comment, disabled }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const detailsActionIconRef = useClickOutside(() => setIsOpen(false));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  return (
    <div ref={detailsActionIconRef}>
      <Button
        variant={ButtonVariant.light}
        square
        disabled={disabled}
        tooltipContentKey="comments.detailsPopup.tooltip"
        active={isOpen}
        size={ButtonSize.sm}
        onClick={handleClick}
        style={{ color: Color.gray20 }}
      >
        <Icon type={IconType.searchFile} color={Color.gray20} size={12} />
      </Button>
      <CommentDetailsPopup comment={comment} isOpen={isOpen} anchorEl={anchorEl} />
    </div>
  );
};
