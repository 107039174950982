import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";

import { UserProfileProvider } from "context/UserProfileContext";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";
import { routes } from "routes";
import { parseIntIfNumber } from "utils/parseIntIfNumber";
import { getUser, updateUser } from "services/users";
import { setCurrentUser } from "store/auth/authSlice";

import { UserProfileEditor } from "common-layouts/UserProfileEditor";

import { FormType } from "ts/enums/formType";
import { ErrorType } from "@explorance/mly-types";
import { UpdateUserBody, User } from "ts/user";

export const UpdateProfilePage = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [userToEdit, setUserToEdit] = useState<User>();

  const { userId } = useParams<{ userId: string }>();
  const userIdToEdit = parseIntIfNumber(userId) as number;

  const history = useHistory();

  const handleUpdateUser = async (requestBody: UpdateUserBody) => {
    let isError = false;
    let isRoleQuotaReached = false;
    await updateUser(userIdToEdit, requestBody)
      .then(() => {
        dispatch(showToastSuccess("toast.userProfileUpdated"));
        getUser(userIdToEdit).then(({ data }) => {
          if (userIdToEdit === currentUser.id) dispatch(setCurrentUser(data));
          setUserToEdit(data);
        });
      })
      .catch((err) => {
        isError = true;
        if (err.response?.status === 423) {
          err.response?.data.type === ErrorType.ResourceLocked
            ? dispatch(showToastError("toast.userListImporting"))
            : (isRoleQuotaReached = err.response?.data.type === ErrorType.QuotaExceeded);
        }
      });
    return { isError, isUsernameDuplicate: false, isRoleQuotaReached };
  };

  useEffect(() => {
    if (userIdToEdit === currentUser.id) return setUserToEdit(currentUser);

    getUser(userIdToEdit)
      .then(({ data }) => setUserToEdit(data))
      .catch(() => history.push(routes.error));
  }, [userIdToEdit, currentUser, history]);

  return (
    <>
      {userToEdit && (
        <UserProfileProvider user={userToEdit}>
          <UserProfileEditor
            user={userToEdit}
            formType={FormType.Edit}
            handleSaveUser={handleUpdateUser}
          />
        </UserProfileProvider>
      )}
    </>
  );
};
