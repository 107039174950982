import React, { useState } from "react";
import styled from "styled-components";
import { Tooltip } from "components/Tooltip";
import { TopicModalData } from "ts/topic";
import { Icon, IconType } from "components/_icons/Icon";

import { Color } from "ts/enums/color";
import { useResource } from "hooks/useResource";

type Props = {
  topic: TopicModalData;
  onClick(): void;
};

export const TopicSummaryButton = ({ topic, onClick }: Props) => {
  const [showIconTooltip, setShowIconTooltip] = useState<boolean>(false);
  const { getResource } = useResource();
  const toolTipId = `${topic?.id}-tooltip`;

  return (
    <StyledTopicSummaryButton
      onMouseOver={() => setShowIconTooltip(true)}
      onMouseOut={() => setShowIconTooltip(false)}
      onClick={onClick}
      data-tooltip-id={toolTipId}
    >
      <Icon
        type={IconType.topicSummary}
        size={16}
        color={Color.gray30}
        secondaryColor={Color.sky40}
      />
      {showIconTooltip && <Tooltip tooltipId={toolTipId} content={getResource("topics.summary")} />}
    </StyledTopicSummaryButton>
  );
};

const StyledTopicSummaryButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
