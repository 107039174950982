import React, { useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";

import { useResource } from "hooks/useResource";
import {
  checkForErrors,
  clearState,
  discardChanges,
  newMappingInit,
  setHasBeenEdited,
  setMappingId,
  setShowDiscardConfirmationModal,
  setShowSavePromptModal,
  setVariableMapping,
} from "store/editVariableMapping/editVariableMappingSlice";
import {
  createVariableMappingThunk,
  fetchVariableMapping,
  updateVariableMappingThunk,
} from "store/editVariableMapping/thunks";
import { routes } from "routes";
import {
  undoShortcutEvent,
  generateMappingRows,
  prepareVariableMappingsForBackend,
} from "utils/variableMapping";
import { showToastSuccess } from "store/toast/toastSlice";
import { CreateUpdateVariableMappingBody } from "services/variableMappings";

import { VariableMappingDefinitionList } from "./_layouts/VariableMappingDefinitionList";
import { NameInput } from "pages/variable-mappings/[id]/_layouts/NameInput";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";
import { UnsavedChangesModal } from "components/_modals/UnsavedChangesModal";
import { EditPageBottomContainer } from "common-layouts/EditPageBottomContainer";
import { ErrorScreen } from "components/ErrorScreen";
import { Text } from "components/Text";

import { PageErrorType } from "ts/enums/pageErrorType";

export const VariableMappingEditPage = () => {
  // redux
  const state = useAppSelector((state) => state.editVariableMapping);
  const dispatch = useAppDispatch();

  // hooks
  const unblockingRef = useRef<() => void>();
  const history = useHistory();
  const { getResource } = useResource();
  const mappingType: string = useParams<{ mappingId: string }>().mappingId;

  const id = parseInt(mappingType);
  const isNewMapping = Number.isNaN(id);

  // functions
  const onClickDiscardChangesModalButton = () => {
    dispatch(discardChanges());
    dispatch(setShowSavePromptModal(false));
    unblockingRef.current();
    history.replace(routes.customizeVariableMappingsPage);
  };

  const saveMapping = async () => {
    dispatch(setHasBeenEdited(false));
    dispatch(checkForErrors());

    const variableMappingForBackend: CreateUpdateVariableMappingBody = {
      title: state.updatedName,
      mappings: prepareVariableMappingsForBackend(state.updatedMappings),
    };

    if (isNewMapping) {
      const { payload } = await dispatch(createVariableMappingThunk(variableMappingForBackend));
      const mappingId = payload.message.replace(/[^0-9]/g, "");

      if (unblockingRef.current && payload) {
        history.replace(routes.customizeVariableMappingsPage);
      } else if (!unblockingRef.current && payload) {
        history.replace(routes.editSelectedVariableMapping(mappingId));
        dispatch(setVariableMapping({ name: state.updatedName, mappings: state.updatedMappings }));
      }
      dispatch(showToastSuccess("variableMappingGroup.toast.successMessage"));
      dispatch(setMappingId(mappingId));
    } else {
      const { payload } = await dispatch(updateVariableMappingThunk(variableMappingForBackend));
      if (payload?.updateSuccessful) {
        dispatch(showToastSuccess("variableMappingGroup.toast.successMessage"));
        if (state.showSavePromptModal) {
          history.replace(routes.customizeVariableMappingsPage);
          dispatch(setShowSavePromptModal(false));
        }
      }
    }
  };

  useEffect(() => {
    if (isNewMapping) {
      const updatedName = getResource("variableMappingGroup.nameInput.placeholder");
      const updatedMappings = generateMappingRows([]);
      dispatch(newMappingInit({ updatedName, updatedMappings }));
    } else {
      dispatch(setMappingId(id));
      dispatch(fetchVariableMapping());
    }
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (state.hasBeenEdited) {
      const unblock = history.block(() => {
        dispatch(setShowSavePromptModal(true));
        unblockingRef.current = unblock;
        return false;
      });

      return () => {
        unblock();
      };
    }
  }, [state.hasBeenEdited, history, dispatch]);

  if (state.apiErrorCode) {
    return (
      <ErrorScreen
        errorType={
          state.apiErrorCode === 403
            ? PageErrorType.VariableMappingInsufficientPermission
            : PageErrorType.Other
        }
      />
    );
  }

  return (
    <div className="fade-in" onKeyDown={undoShortcutEvent}>
      <NameInput
        name={state.updatedName}
        inputTitle={<Text resource="variableMappingGroup.nameInput.label" />}
        lastSavedName={state.variableMapping?.name}
        removeLeftMargin={true}
      />

      <VariableMappingDefinitionList />
      <EditPageBottomContainer
        backToListButtonLabel={<Text resource="button.backToVariableMappings" />}
        showErrors={state.showErrors}
        errorInfo={[
          {
            hasError: state.showMissingValueError,
            errorLabel: getResource("variableMappingGroup.error.missingValue"),
          },
          {
            hasError: state.showDuplicateVariableError,
            errorLabel: getResource("variableMappingGroup.error.duplicateKeys"),
          },
          {
            hasError: state.showEmptyPresetError,
            errorLabel: getResource("variableMappingGroup.error.emptyPreset"),
          },
        ]}
        pageHasBeenEdited={state.hasBeenEdited}
        discardButtonLabel={<Text resource="button.discardChanges" />}
        saveButtonLabel={<Text resource="button.saveChanges" />}
        onClickBackToButton={() => history.push(routes.customizeVariableMappingsPage)}
        onClickDiscard={() => dispatch(setShowDiscardConfirmationModal(true))}
        onClickSave={saveMapping}
      />
      <ActionConfirmationModal
        isOpen={state.showDiscardConfirmationModal}
        title={<Text resource={{ key: "modal.discardChanges.title", args: [state.updatedName] }} />}
        actionButtonLabel={<Text resource="button.discard" />}
        onCancel={() => dispatch(setShowDiscardConfirmationModal(false))}
        onClickActionButton={() => dispatch(discardChanges())}
      />
      {state.showSavePromptModal && (
        <UnsavedChangesModal
          captionTitle={
            <Text resource={{ key: "modal.unsavedChanges.title", args: [state.updatedName] }} />
          }
          onSave={saveMapping}
          onDiscard={onClickDiscardChangesModalButton}
          onClose={() => dispatch(setShowSavePromptModal(false))}
        />
      )}
    </div>
  );
};
