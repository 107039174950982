import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  fetchLicensePeriodById,
  fetchLicensePeriods,
  fetchLicensePeriodStatistics,
} from "./thunks";
import { LicensePeriod, ModelStatistics } from "ts/licensing";
import { SelectOption } from "ts/select";

type QuotaMenuState = {
  showQuotaDropdown: boolean;
  commentsLimitInputValue: number;
  userLimitInputValue: number;
  emailNotificationThresholdInputValue: number;
  emailToNotifyInputValue: string;
  isEmailError: boolean;
  isActivePeriod: boolean;
  totalCommentsAnalyzed: number;
  modelStatistics: ModelStatistics[];
  percentageUsed: number;
  isLoading: boolean;
  licensingPeriods: LicensePeriod[];
  periodDropdownOptions: SelectOption[];
  selectedPeriod: SelectOption;
};

const initialState: QuotaMenuState = {
  showQuotaDropdown: false,
  commentsLimitInputValue: null,
  userLimitInputValue: null,
  emailNotificationThresholdInputValue: null,
  emailToNotifyInputValue: "",
  isEmailError: false,
  isActivePeriod: false,
  totalCommentsAnalyzed: null,
  modelStatistics: [],
  percentageUsed: null,
  isLoading: true,
  licensingPeriods: [],
  periodDropdownOptions: [],
  selectedPeriod: null,
};

const QuotaMenuSlice = createSlice({
  name: "quotaMenuSlice",
  initialState,
  reducers: {
    setShowQuotaDropdown: (state, action: PayloadAction<boolean>) => {
      state.showQuotaDropdown = action.payload;
    },
    setCommentsLimitInputValue: (state, action: PayloadAction<number>) => {
      state.commentsLimitInputValue = action.payload;
    },
    setUserLimitInputValue: (state, action: PayloadAction<number>) => {
      state.userLimitInputValue = action.payload;
    },
    setEmailNotificationThresholdInputValue: (state, action: PayloadAction<number>) => {
      state.emailNotificationThresholdInputValue = action.payload;
    },
    setEmailToNotifyInputValue: (state, action: PayloadAction<string>) => {
      state.emailToNotifyInputValue = action.payload;
    },
    setIsEmailError: (state, action: PayloadAction<boolean>) => {
      state.isEmailError = action.payload;
    },
    setTotalCommentsAnalyzed: (state, action: PayloadAction<number>) => {
      state.totalCommentsAnalyzed = action.payload;
    },
    setModelStatistics: (state, action: PayloadAction<ModelStatistics[]>) => {
      state.modelStatistics = action.payload;
    },
    setPercentageUsed: (state, action: PayloadAction<number>) => {
      state.percentageUsed = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setPeriodDropdownOptions: (state, action: PayloadAction<SelectOption[]>) => {
      state.periodDropdownOptions = action.payload;
    },
    setSelectedPeriod: (state, action: PayloadAction<SelectOption>) => {
      state.selectedPeriod = action.payload;
    },
    updateSelectedPeriod: (state) => {
      state.selectedPeriod = state.periodDropdownOptions.find(
        (o) => parseInt(o.value) === parseInt(state.selectedPeriod.value)
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLicensePeriodById.fulfilled, (state, action) => {
      state.userLimitInputValue = action.payload.userLimit;
      state.commentsLimitInputValue = action.payload.commentLimit;
      state.emailNotificationThresholdInputValue = action.payload.notificationQuota;
      state.isActivePeriod = action.payload.isActive;
      state.isEmailError = false;
      state.isLoading = false;
    });
    builder.addCase(fetchLicensePeriodStatistics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLicensePeriodStatistics.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchLicensePeriods.fulfilled, (state, action) => {
      if (action.payload.currentPeriod) {
        state.selectedPeriod = action.payload.currentPeriod;
        state.licensingPeriods = action.payload.typedLicensingPeriods;
        state.periodDropdownOptions = action.payload.typedDropdownOptions;
      }
      state.isLoading = false;
    });
  },
});

export const {
  setShowQuotaDropdown,
  setCommentsLimitInputValue,
  setUserLimitInputValue,
  setEmailNotificationThresholdInputValue,
  setEmailToNotifyInputValue,
  setIsEmailError,
  setTotalCommentsAnalyzed,
  setModelStatistics,
  setPercentageUsed,
  setIsLoading,
  setPeriodDropdownOptions,
  setSelectedPeriod,
  updateSelectedPeriod,
} = QuotaMenuSlice.actions;

export default QuotaMenuSlice.reducer;
