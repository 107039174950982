import React from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { AvatarSize } from "ts/enums/avatarSize";
import { TextTruncator } from "components/TextTruncator";
import { getUserFullName } from "utils/getUserFullName";

type CustomStyles = {
  margin?: string;
  padding?: string;
};

type Props = {
  firstName: string;
  lastName: string;
  userId: number;
  avatarSize?: AvatarSize;
  customStyles?: CustomStyles;
  showUserFullName?: boolean;
};

export const UserAvatar = React.memo(
  ({
    firstName,
    lastName,
    userId,
    avatarSize = AvatarSize.sm,
    showUserFullName,
    customStyles,
  }: Props) => {
    // variables
    const colors = {
      1: "#F29FC8",
      2: "#D68CDC",
      3: "#FDC36A",
      4: "#FF8670",
      5: "#FF2874",
      6: "#51A6CF",
      7: "#1A6B91",
      8: "#C2E3D0",
      9: "#7E9DAE",
      0: "#63A57F",
    };
    const initials = `${firstName?.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase()}`;
    const sizes = {
      [AvatarSize.sm]: 32,
      [AvatarSize.md]: 100,
      [AvatarSize.lg]: 120,
    };
    const size = sizes[avatarSize];

    return (
      <StyledUserInfo>
        <StyledUserAvatar
          size={size}
          backgroundColor={colors[userId?.toString().slice(-1)] || Color.black}
          fontSize={Math.round(size / 2)}
          customStyles={customStyles}
        >
          {initials}
        </StyledUserAvatar>
        {showUserFullName && (
          <TextTruncator value={getUserFullName({ firstname: firstName, lastname: lastName })} />
        )}
      </StyledUserInfo>
    );
  }
);

const StyledUserAvatar = styled.div<{
  size: number;
  backgroundColor: string;
  fontSize: number;
  customStyles: CustomStyles;
}>`
  width: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font: ${({ fontSize }) => `bold ${fontSize}px Lato, sans-serif`};
  color: ${Color.white};
  line-height: 0;
  margin: ${({ customStyles }) => customStyles?.margin};
  padding: ${({ customStyles }) => customStyles?.padding};
`;

const StyledUserInfo = styled.span`
  display: flex;
  gap: 10px;
  font-weight: bold;
  align-items: center;
`;
