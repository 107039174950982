import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { Modal, StyledModalTitle, StyledModalCaption } from "components/_modals/Modal";
import analysisLoading from "assets/animations/analysis-loading.gif";

import { routes } from "routes";
import { Color } from "ts/enums/color";
import { AnalysisContext } from "context/AnalysisContext";
import { AnalysisStatus } from "@explorance/mly-types";

import { getAnalysisDetails } from "services/analysis";
import { setAlertThreshold, setAnalysisDetails } from "context/AnalysisContext/actions";
import { formatAnalysisDetails } from "context/AnalysisContext/helpers";
import { Text } from "components/Text";

export const AnalysisJobProcessingModal = () => {
  const [showJobProcessingModal, setShowJobProcessingModal] = useState<boolean>(false);
  const [state, dispatch] = useContext(AnalysisContext);

  const history = useHistory();

  useEffect(() => {
    const fn = async () => {
      if (showJobProcessingModal && state.analysisDetails.status === AnalysisStatus.Completed) {
        const { data: analysisDetailsData } = await getAnalysisDetails(state.analysisDetails.id);
        dispatch(
          setAnalysisDetails(formatAnalysisDetails(state.analysisDetails.id, analysisDetailsData))
        );
        dispatch(setAlertThreshold(analysisDetailsData.settings?.alert?.threshold));
        history.push(routes.overviewPage(state.analysisDetails.id));
      }
    };

    fn();
  }, [state.analysisDetails, showJobProcessingModal, dispatch, history]);

  useEffect(() => {
    setShowJobProcessingModal(state.analysisDetails.status === AnalysisStatus.InProgress);
  }, [state.analysisDetails.status]);

  return (
    <Modal isOpen={showJobProcessingModal} width="360px" height="auto">
      <>
        <StyledJobProcessingModalTop>
          <img src={analysisLoading} alt="analysis loading" />
          <StyledJobProcessingModalContentContainer>
            <StyledJobProcessingTitle>
              <Text resource="modal.jobProcessing.title" />
            </StyledJobProcessingTitle>
            <StyledJobProcessingCaption>
              <Text resource="modal.jobProcessing.caption" />
            </StyledJobProcessingCaption>
          </StyledJobProcessingModalContentContainer>
        </StyledJobProcessingModalTop>
        <StyledHomepageLink>
          <div onClick={() => history.push(routes.homePage)}>
            <Text resource="modal.homepageLink" />
          </div>
        </StyledHomepageLink>
      </>
    </Modal>
  );
};

const StyledJobProcessingModalTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 75px;
    height: 75px;
  }
`;

const StyledJobProcessingModalContentContainer = styled.div`
  width: 80%;
`;

const StyledJobProcessingTitle = styled(StyledModalTitle)`
  margin-bottom: 10px;
  text-align: center;
`;

const StyledJobProcessingCaption = styled(StyledModalCaption)`
  line-height: 1.6;
  text-align: center;
`;

const StyledHomepageLink = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: ${Color.blue50};

  :hover {
    cursor: pointer;
  }
`;
