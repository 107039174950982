import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToastType } from "components/Toast";
import { CustomToastContentType } from "ts/enums/customToastContentType";

type ToastMessage = {
  titleKey: string;
  captionKey?: string;
  customToastContentType?: CustomToastContentType;
  args?: Record<string, string>[];
};

type ToastOptions = {
  noFade?: boolean;
  delay?: number;
};

type ToastParams = {
  message?: ToastMessage;
  type?: ToastType;
  options?: ToastOptions;
};

type ToastState = {
  isOpen: boolean;
  noFade: boolean;
  delay: number;
  toastType: ToastType;
  toastMessage: ToastMessage;
};

const initialState: ToastState = {
  isOpen: false,
  noFade: false,
  delay: 3000,
  toastType: null,
  toastMessage: { titleKey: "", captionKey: "", customToastContentType: null },
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    cancelFade: (state) => {
      state.noFade = false;
    },
    closeToast: (state) => {
      state.isOpen = false;
    },
    showToast: (state, action: PayloadAction<ToastParams>) => {
      if (typeof action.payload === "string" || typeof action.payload === "object") {
        state.toastMessage =
          typeof action.payload === "string"
            ? {
                titleKey: action.payload,
                captionKey: "",
                customToastContentType: null,
                args: null,
              }
            : action.payload.message;

        state.noFade = action.payload?.options?.noFade ?? false;
        state.delay = action.payload?.options?.delay || 3000;
        state.isOpen = true;
        state.toastType = action.payload?.type ?? state.toastType;
      } else {
        return;
      }
    },
    showToastSuccess: (state, action: PayloadAction<ToastParams>) => {
      toastSlice.caseReducers.showToast(state, action);
      state.toastType = ToastType.Success;
    },
    showToastError: (state, action: PayloadAction<ToastParams>) => {
      toastSlice.caseReducers.showToast(state, action);
      state.toastType = ToastType.Error;
    },
  },
});

export const { showToast, showToastSuccess, showToastError, closeToast, cancelFade } =
  toastSlice.actions;
export default toastSlice.reducer;
