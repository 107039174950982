import React from "react";
import styled from "styled-components";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { StyledButton } from "./StyledButton";

type Props = {
  children: any;
  variant?: ButtonVariant;
  disabled?: boolean;
  size?: ButtonSize;
  onClick?: (args) => void;
  // function for when capture phase is needed for click handler
  onClickCapture?: (args) => void;
  // Style properties from variant
  border: string;
  backgroundColor: string;
  backgroundColorHover: string;
  color: string;
  colorDisabled: string;
  // Custom styles (overrides variants)
  style?: Record<string, any>;
};

export const SquareButton = ({ children, size, ...props }: Props) => {
  const squareSizes = {
    [ButtonSize.sm]: "24px",
    [ButtonSize.md]: "28px",
    [ButtonSize.lg]: "36px",
  };

  return (
    <StyledSquareButton {...props} size={squareSizes[size]}>
      <span>{children}</span>
    </StyledSquareButton>
  );
};

const StyledSquareButton = styled((props) => <StyledButton {...props} />)<{
  size: string;
}>`
  position: relative;
  > span {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Styles from size */
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;
