import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { routes } from "routes";
import { AnalysisContext } from "context/AnalysisContext";

import { Icon, IconType } from "components/_icons/Icon";
import { Modal, StyledModalCaption, StyledModalTitle } from "components/_modals/Modal";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";
import { AnalysisStatus } from "@explorance/mly-types";

export const JobFailedModal = () => {
  const [showJobFailedModal, setShowJobFailedModal] = useState<boolean>(false);
  const [state] = useContext(AnalysisContext);
  const history = useHistory();

  useEffect(() => {
    setShowJobFailedModal(state.analysisDetails.status === AnalysisStatus.Failed);
  }, [state.analysisDetails.status]);

  return (
    <Modal isOpen={showJobFailedModal} width={"375px"} height={"auto"}>
      <StyledModal>
        <StyledIconContainer>
          <Icon type={IconType.xCircle} color={Color.red55} size={20} />
        </StyledIconContainer>
        <StyledMainContentContainer>
          <StyledModalTitle style={{ marginBottom: "10px" }}>
            <Text resource="modal.jobFailed.title" />
          </StyledModalTitle>

          <StyledModalCaption>
            <Text resource="modal.jobFailed.caption" />
          </StyledModalCaption>
          <StyledLinksContainer>
            <div onClick={() => history.push(routes.homePage)}>
              <Text resource="modal.homepageLink" />
            </div>
            <div onClick={() => setShowJobFailedModal(false)}>
              <Text resource="button.okay" />
            </div>
          </StyledLinksContainer>
        </StyledMainContentContainer>
      </StyledModal>
    </Modal>
  );
};

const StyledModal = styled.div`
  display: flex;
  margin-top: 10px;
`;

const StyledIconContainer = styled.div`
  margin-left: 10px;
  margin-right: 20px;
`;

const StyledMainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    font-size: 0.875em;
    color: ${Color.blue50};
    font-weight: bold;
    cursor: pointer;
  }
`;
