import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TreeItem } from "@atlaskit/tree";

import { Icon, IconType } from "components/_icons/Icon";
import { Tooltip } from "components/Tooltip";
import { Text } from "components/Text";

import { ApiTopicNode } from "ts/topic";
import { Color } from "ts/enums/color";

type Props = {
  item: TreeItem;
  handleDeleteMappedTopic: (item: TreeItem, deletedMapping: ApiTopicNode) => void;
};

export const MergedTopic = ({ item, handleDeleteMappedTopic }: Props) => {
  const [currentMappedTopics, setCurrentMappedTopics] = useState<ApiTopicNode[]>(
    item?.data?.mappedTopics || []
  );

  const handleDeleteTopic = (deletedMapping: ApiTopicNode) => {
    const copy = [...currentMappedTopics].filter((c) => c.fullPath !== deletedMapping.fullPath);
    setCurrentMappedTopics(copy);
    handleDeleteMappedTopic(item, deletedMapping);
  };

  useEffect(() => {
    if (item?.data?.mappedTopics?.length > 0) setCurrentMappedTopics(item.data.mappedTopics);
  }, [item]);

  const formatFullPath = (fullPath: string) => {
    const split = fullPath.split(">");
    return (
      <div className="full-path">
        {split.map((x, index) => (
          <div className="full-path-text" key={`${x}-${index}`}>
            <span>
              <Text resource={`ML.topic.[${x}]`} />
            </span>
            {split.length - 1 !== index && (
              <>
                <Icon
                  type={IconType.triangleFilledDown}
                  color={Color.indigo50}
                  className="full-path-split"
                  size={8}
                />
              </>
            )}
          </div>
        ))}
      </div>
    );
  };

  if (!item.data.mappedTopics || item.data.mappedTopics.length === 0) return null;

  return (
    <StyledMergedTopic>
      <StyledMergedIconContainer data-tooltip-id={`${item.id}-mapped-topic-tooltip`}>
        <Icon type={IconType.merge} color={Color.white} size={12} />
      </StyledMergedIconContainer>
      <Tooltip
        tooltipId={`${item.id}-mapped-topic-tooltip`}
        place="right"
        arrowColor={Color.white}
        backgroundColor={Color.white}
        delayHide={400}
        className="merged-topic-tooltip"
        clickable
        float={false}
      >
        <StyledMergedTopicTitle>
          <Text
            resource={{
              key:
                currentMappedTopics.length > 1
                  ? "customModels.mergedTopics"
                  : "customModels.mergedTopic",
              args: [item.data.title, `${currentMappedTopics.length}`],
            }}
          />
        </StyledMergedTopicTitle>
        {currentMappedTopics.map((cmt) => {
          return (
            <StyledFullPathContainer key={cmt.fullPath}>
              {formatFullPath(cmt.fullPath)}
              <StyledDeleteTopicButton onClick={() => handleDeleteTopic(cmt)}>
                <Icon type={IconType.x} size={16} color={Color.gray30} />
              </StyledDeleteTopicButton>
            </StyledFullPathContainer>
          );
        })}
      </Tooltip>
    </StyledMergedTopic>
  );
};

const StyledMergedTopic = styled.div`
  position: relative;
  margin-bottom: 25px;

  .merged-topic-tooltip {
    color: ${Color.black};
    box-shadow: 0px 2px 4px #00000026;
    padding: 10px 18px 10px 12px;
    pointer-events: auto;
    cursor: default;
    max-height: 320px;
    overflow-y: auto !important;
    max-width: none !important;
  }
`;

const StyledMergedIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  margin-right: 5px;
  border-radius: 3px;
  background: ${Color.black};
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const StyledMergedTopicTitle = styled.div`
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 3px;
`;

const StyledFullPathContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.975em;
  font-weight: normal;
  padding: 3px 0;

  .full-path {
    display: flex;
    align-items: center;

    .full-path-text {
      display: flex;
      align-items: center;

      .full-path-split {
        transform: rotate(-90deg);
        margin: 1px 5px 0;
        display: flex;
        align-items: center;
      }
    }
  }

  .delete-topic-icon {
    padding: 1px;
    margin: 2px 0 0 5px;
    border-radius: 3px;
    background: ${Color.sky15};
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;

const StyledDeleteTopicButton = styled.button`
  border: none;
  padding: 1px;
  margin: 2px 0 0 5px;
  border-radius: 3px;
  background: ${Color.sky15};
  cursor: pointer;
  display: flex;
  align-items: center;
`;
