import { ImportMode } from "@explorance/mly-types";
import { getHeaders, mlyApi } from "config/mlyApi";
import { UpdatedFileDemographicParams } from "ts/file";

export async function getFileById(fileId: number) {
  const requestConfig = await getHeaders();
  const url = `/files/${fileId}`;
  return mlyApi.get(url, requestConfig);
}

export async function deleteImportedFileById(fileId: number) {
  const requestConfig = await getHeaders();
  const url = `/files/${fileId}`;
  return mlyApi.delete(url, requestConfig);
}

export async function getAnalysisData(analysisId: number) {
  const requestConfig = await getHeaders();
  const url = `/analysis/${analysisId}/files`;
  return mlyApi.get(url, requestConfig);
}

type FileSettingsParams = {
  demographics: UpdatedFileDemographicParams[];
  selectedUniqueColumn: string | null;
  importMode: ImportMode;
};

export async function saveFileSettings(fileId: number, settings: FileSettingsParams) {
  const requestConfig = await getHeaders();
  const url = `/files/${fileId}`;
  return mlyApi.put(url, settings, requestConfig);
}

export async function updateAnalysisFileSettings(
  analysisId: number,
  demographics: UpdatedFileDemographicParams[]
) {
  const requestConfig = await getHeaders();
  const url = `/analysis/${analysisId}/files`;
  return mlyApi.put(url, { demographics }, requestConfig);
}
