import React from "react";
import styled from "styled-components";

import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { DEFAULT_ALERT_THRESHOLD } from "assets/constants/defaultAlertThreshold";
import { Text } from "components/Text";

type Props = {
  value: number;
};

export const AlertScore = ({ value }: Props) => {
  const requiresAttention = Math.round(value) >= DEFAULT_ALERT_THRESHOLD;
  return (
    <StyledAlertScoreInterpretation>
      <Icon
        type={IconType.alertNoBgColor}
        size={16}
        style={{ position: "relative", top: 3, marginRight: 5 }}
      />
      <Text resource="quickAnalysis.interpretation.alertScore.firstPart" />
      <StyledScoreValue isRed={requiresAttention}>{Math.round(value)}%</StyledScoreValue>,
      {requiresAttention ? (
        <Text resource="quickAnalysis.interpretation.alertScore.requiresAttention" />
      ) : (
        <Text resource="quickAnalysis.interpretation.alertScore.nothingToReport" />
      )}
      <Text resource="quickAnalysis.interpretation.alertScore.defaultThreshold" />
      <StyledScoreValue isRed={true}>{DEFAULT_ALERT_THRESHOLD}%.</StyledScoreValue>
    </StyledAlertScoreInterpretation>
  );
};

const StyledAlertScoreInterpretation = styled.p`
  margin-top: 12px;
  margin-bottom: 4px;
`;

const StyledScoreValue = styled.span<{ isRed: boolean }>`
  font-weight: bold;
  color: ${({ isRed }) => (isRed ? Color.red50 : Color.gray50)};
`;
