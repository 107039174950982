import React, { createRef, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { useAppSelector, useAppDispatch } from "store";

import { Button } from "components/_buttons/Button";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";

import {
  clearState,
  setIsSearchActive,
  setKeepSearchState,
  setModalIsSearchActive,
  setModalSearchTerm,
  setShowModalCancelSearchButton,
  setShowModalSearchButton,
  setSearchTerm,
  setShowCancelSearchButton,
  setShowSearchButton,
} from "store/search/searchbarSlice";

export type SearchbarInputCustomStyle = {
  border?: string;
  placeholderTextSize?: string;
  width?: string;
};

enum SearchButton {
  cancel = "cancel",
  active = "active",
  default = "default",
}

type Props = {
  onSearch?: () => void;
  placeholder?: string;
  customStyles?: SearchbarInputCustomStyle;
  isModal?: boolean;
};
export const Searchbar = ({ onSearch, placeholder, customStyles, isModal }: Props) => {
  const history = useHistory();
  const state = useAppSelector((state) => state.search);
  const dispatch = useAppDispatch();

  const queryParams = new URLSearchParams(location.search);
  const searchValueRef = createRef<HTMLInputElement>();

  const buttonType: SearchButton =
    (state.showCancelSearchButton && state.isSearchActive && !isModal) ||
    (state.showModalCancelSearchButton && state.isModalSearchActive && isModal)
      ? SearchButton.cancel
      : (state.showSearchButton && !isModal) || state.showModalSearchButton
      ? SearchButton.active
      : SearchButton.default;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isModal) {
      dispatch(
        setShowModalCancelSearchButton(
          e.currentTarget.value !== state.modalSearchTerm && e.currentTarget.value.length === 0
        )
      );
      dispatch(setShowModalSearchButton(e.currentTarget.value.length > 0));
      return;
    }
    dispatch(
      setShowCancelSearchButton(
        e.currentTarget.value !== state.searchTerm && e.currentTarget.value.length === 0
      )
    );
    dispatch(setShowSearchButton(e.currentTarget.value.length > 0));
  };

  const updateSearchInQueryParams = (searchTerm: string) => {
    const queryParams = new URLSearchParams(location.search);
    if (searchTerm.length > 0) {
      queryParams.set("search", searchTerm);
      history.push({ search: queryParams.toString() });
    } else if (!state.keepSearchState) {
      queryParams.delete("search");
      history.push({ search: queryParams.toString() });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isSameValueSearch = searchValueRef.current.value === queryParams.get("search");
    if (e.key === "Enter" && searchValueRef.current.value.length > 0 && !isSameValueSearch) {
      e.preventDefault();
      handleSearch();
    } else if (
      e.key === "Enter" &&
      searchValueRef.current.value.length === 0 &&
      !isSameValueSearch &&
      (state.isSearchActive || state.isModalSearchActive)
    ) {
      handleCancel();
    }
  };

  const handleSearch = () => {
    if (isModal) {
      dispatch(setModalSearchTerm(searchValueRef.current.value));
      dispatch(setShowModalCancelSearchButton(true));
      dispatch(setModalIsSearchActive(true));
      dispatch(setShowModalSearchButton(false));
      onSearch && onSearch();
      return;
    }
    dispatch(setSearchTerm(searchValueRef.current.value));
    updateSearchInQueryParams(searchValueRef.current.value);
    dispatch(setShowCancelSearchButton(true));
    dispatch(setIsSearchActive(true));
    dispatch(setShowSearchButton(false));
    onSearch && onSearch();
  };

  const handleCancel = () => {
    if (isModal) {
      dispatch(setModalSearchTerm(""));
      searchValueRef.current.value = "";
      dispatch(setShowModalCancelSearchButton(false));
      dispatch(setModalIsSearchActive(false));
      dispatch(setShowModalSearchButton(false));
      onSearch && onSearch();
      return;
    }
    dispatch(setSearchTerm(""));
    searchValueRef.current.value = "";
    updateSearchInQueryParams("");
    dispatch(setShowCancelSearchButton(false));
    dispatch(setIsSearchActive(false));
    dispatch(setShowSearchButton(false));
    onSearch && onSearch();
  };

  //Initialize the default value and keep the state if we are going to the analysis list page.
  useEffect(() => {
    if (searchValueRef.current && !isModal) {
      searchValueRef.current.value =
        queryParams.get("search") || (state.keepSearchState ? state.searchTerm : "");
      dispatch(setSearchTerm(searchValueRef.current.value));
      dispatch(setKeepSearchState(false));
      if (queryParams.get("search")) {
        onSearch && onSearch();
      }
    }
    return () => {
      if (!isModal) {
        dispatch(clearState());
      } else {
        dispatch(setModalSearchTerm(""));
        dispatch(setShowModalCancelSearchButton(false));
        dispatch(setModalIsSearchActive(false));
        dispatch(setShowModalSearchButton(false));
      }
    };
  }, [searchValueRef.current]); // eslint-disable-line

  //If search value is cleared in redux we want to clear it in the state.
  useEffect(() => {
    if (
      searchValueRef.current?.value.length > 0 &&
      ((state.searchTerm.length === 0 && !isModal) ||
        (state.modalSearchTerm.length === 0 && isModal)) &&
      state.loadedInitialSearch
    ) {
      handleCancel();
    }
  }, [state.searchTerm, state.modalSearchTerm, searchValueRef.current]); // eslint-disable-line

  return (
    <StyledSearchBar>
      <StyledSearchInput
        type="text"
        placeholder={placeholder}
        customStyles={customStyles}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        value={searchValueRef?.current?.value}
        ref={searchValueRef}
      />
      <StyledInputIcon>
        <Button
          variant={
            buttonType === SearchButton.default ? ButtonVariant.light : ButtonVariant.primaryPurple
          }
          disabled={buttonType === SearchButton.default}
          square
          size={ButtonSize.md}
          onClick={
            buttonType === SearchButton.cancel
              ? handleCancel
              : buttonType === SearchButton.active
              ? handleSearch
              : undefined
          }
        >
          {buttonType === SearchButton.cancel ? (
            <Icon type={IconType.x} size={16} style={{ fill: "none" }} />
          ) : (
            <Icon type={IconType.search} size={16} style={{ fill: "none" }} />
          )}
        </Button>
      </StyledInputIcon>
    </StyledSearchBar>
  );
};

type StyledProps = {
  customStyles: SearchbarInputCustomStyle;
};

const StyledSearchBar = styled.div`
  display: flex;
  position: relative;
`;

const StyledSearchInput = styled.input<StyledProps>`
  width: ${({ customStyles }) => customStyles?.width ?? "400px"};
  padding: 10px 40px 10px 10px;
  flex-grow: 1;
  color: ${Color.gray50};
  font-family: Lato, sans-serif;
  font-weight: normal;
  float: left;
  border: ${({ customStyles }) => customStyles?.border ?? `1px solid ${Color.blue20}`};
  background-color: ${Color.white};
  border-radius: 5px;

  &::placeholder {
    font-size: ${({ customStyles }) => customStyles?.placeholderTextSize ?? "1em"};
    color: ${Color.neutral40};
  }

  :focus {
    border: 1px solid ${Color.blue50};
    outline-style: none;
  }
`;

const StyledInputIcon = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 6px;
  display: flex;
  align-items: center;
`;
