import React from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "store";
import {
  addAllRedactionSuggestions,
  clearSuggestedRelatedTerms,
  removeAllRedactionSuggestions,
  setInputtedRedactionList,
  setIsSelectAllSelected,
} from "store/redaction/redactionBuilderSlice";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { ToggeablePill } from "components/_pills/ToggeablePill";
import { Text } from "components/Text";
import { EmptyState } from "components/EmptyState";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { PillType } from "ts/enums/pillType";
import { FindSuggestedTermsButton } from "./FindSuggestedTermsButton";

export const SuggestedRedactionTerms = () => {
  const state = useAppSelector((state) => state.redactionBuilder);
  const dispatch = useAppDispatch();

  const handleSelectAll = () => {
    state.isSelectAllSelected
      ? dispatch(removeAllRedactionSuggestions())
      : dispatch(addAllRedactionSuggestions());
    dispatch(setIsSelectAllSelected(!state.isSelectAllSelected));
  };

  return (
    <StyledSuggestedTermsContainer>
      <StyledHeaderSection>
        <Text resource="redaction.body.suggestedTerms.label" />
        {state.suggestedRelatedTerms.length > 0 && (
          <Button
            size={ButtonSize.sm}
            variant={ButtonVariant.secondary}
            style={{ gap: "6px", padding: "6px 12px" }}
            onClick={() => dispatch(clearSuggestedRelatedTerms())}
          >
            <Icon type={IconType.x} size={16} />
            <Text resource="button.clearAll" />
          </Button>
        )}
      </StyledHeaderSection>

      <StyledSuggestedTermsSection>
        {state.suggestedRelatedTerms.length === 0 ? (
          <EmptyStateContainer>
            <EmptyState
              type={EmptyStateType.noSuggestedRedactionTerms}
              customStyles={{ marginTop: "0" }}
            />
          </EmptyStateContainer>
        ) : (
          <StyledSuggestedTermsList>
            <ToggeablePill pillType={PillType.SelectAll} handleClick={handleSelectAll}>
              <StyledSelectButton>
                <Icon
                  type={state.isSelectAllSelected ? IconType.minusCircle : IconType.plusCircle}
                  size={14}
                  color={Color.white}
                />
                <Text
                  resource={
                    state.isSelectAllSelected
                      ? `redaction.button.revertSelectAll`
                      : `redaction.button.selectAll`
                  }
                />
                {!state.isSelectAllSelected && (
                  <StyledCount>
                    {state.suggestedRelatedTerms.filter((listItem) => !listItem.isSelected).length}
                  </StyledCount>
                )}
              </StyledSelectButton>
            </ToggeablePill>
            <div>
              {state.suggestedRelatedTerms.map((suggestion, index) => (
                <ToggeablePill
                  key={index}
                  pillType={PillType.SingleSelect}
                  showPill={suggestion.isSelected}
                  handleClick={() => dispatch(setInputtedRedactionList(suggestion.term))}
                >
                  <Icon
                    type={IconType.plusCircle}
                    size={14}
                    color={Color.blue50}
                    style={{ marginRight: "8px" }}
                  />
                  {suggestion.term}
                </ToggeablePill>
              ))}
            </div>
          </StyledSuggestedTermsList>
        )}
        <StyledSimilarTermsButtonContainer>
          <FindSuggestedTermsButton />
        </StyledSimilarTermsButtonContainer>
      </StyledSuggestedTermsSection>
    </StyledSuggestedTermsContainer>
  );
};

const StyledSuggestedTermsContainer = styled.div`
  width: 50%;
`;

const StyledHeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const StyledSuggestedTermsSection = styled.div`
  position: relative;
  height: 600px;
  border: 2px solid ${Color.blue20};
  border-radius: 5px;
  height: calc(100% - 40px);
`;

const StyledSuggestedTermsList = styled.div`
  padding: 11px;
  overflow-y: auto;
  height: calc(100% - 64px);
`;

const StyledSimilarTermsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 64px;
`;

const StyledSelectButton = styled.button`
  border: none;
  background-color: ${Color.blue50};
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${Color.white};
  font-size: 15px;
`;

const StyledCount = styled.span`
  width: fit-content;
  height: 16px;
  color: ${Color.blue50};
  background-color: ${Color.sky30};
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 10px;
  font-weight: bold;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
