import React, { useState } from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";
import { useGetModelName } from "hooks/useGetModelName";

import { CategorizationTypeSymbol } from "components/_icons/CategorizationTypeSymbol";
import { BaseModelPill } from "components/_pills/BaseModelPill";
import { Tooltip } from "components/Tooltip";
import { Text } from "components/Text";

import { CategorizationType } from "@explorance/mly-types";

type Props = {
  languages: string[];
  isPowerset: boolean;
  family?: CategorizationType;
  isOutdated?: boolean;
};

export const CategorizationTypeInfo = React.memo(
  ({ languages, isPowerset, family, isOutdated }: Props) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const { getResource } = useResource();
    const getModelName = useGetModelName(getResource);

    const modelName = getModelName({ languages, isPowerset });

    const handleModelNameHover = (e: React.MouseEvent<HTMLSpanElement>) => {
      setShowTooltip(e.currentTarget.offsetWidth < e.currentTarget.scrollWidth);
    };

    return (
      <StyledCategorizationTypeInfo>
        {family ? (
          <>
            <CategorizationTypeSymbol categorizationType={family} />
            <StyledModelNameDetails>
              <span
                data-tooltip-id={modelName}
                onMouseOver={handleModelNameHover}
                onMouseOut={() => setShowTooltip(false)}
              >
                {modelName}
                {isOutdated && <Text resource="analysisSettings.outdatedModel.label" />}
              </span>
              {showTooltip && <Tooltip tooltipId={modelName} content={modelName} />}
            </StyledModelNameDetails>
          </>
        ) : (
          <BaseModelPill />
        )}
      </StyledCategorizationTypeInfo>
    );
  }
);

const StyledCategorizationTypeInfo = styled.div`
  display: flex;
  align-items: center;
`;

const StyledModelNameDetails = styled.span`
  margin-left: 6px;
  display: inline-flex;
  min-width: 0;
  padding-right: 10px;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
