import styled from "styled-components";
import React, { useState } from "react";
import { BOTTOM_NAV_HEIGHT, FRAME_TRANSITION } from "./constants";
import { TextType } from "ts/TextType";

type Props = {
  slideData: {
    title: TextType;
    description: TextType;
    backgroundColor: string;
    animationAsset: string;
  };
  isCurrent: boolean;
};

export const Slide = ({ slideData, isCurrent }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <StyledSlide backgroundColor={slideData.backgroundColor} isCurrent={isCurrent}>
      <StyledAnimationContainer
        backgroundColor={slideData.backgroundColor}
        isAssetLoaded={isLoaded}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          key={slideData.animationAsset}
          onLoadedData={() => setIsLoaded(true)}
        >
          <source src={slideData.animationAsset} type="video/mp4" />
        </video>
      </StyledAnimationContainer>
      <StyledSlideText>
        <h1>{slideData.title}</h1>
        <p>{slideData.description}</p>
      </StyledSlideText>
    </StyledSlide>
  );
};

const StyledSlide = styled.div<{ backgroundColor: string; isCurrent: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: ${(props) => (props.isCurrent ? 1 : 0)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: opacity ${FRAME_TRANSITION}, background-color ${FRAME_TRANSITION};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: ${BOTTOM_NAV_HEIGHT}px;
  padding-top: 100px;
`;

const StyledSlideText = styled.div`
  max-width: 1040px;
  margin: 0 100px;

  p,
  h1 {
    color: white;
    text-align: center;
  }
  h1 {
    font-size: 36px;
  }
  p {
    font-size: 20px;
  }
`;

const StyledAnimationContainer = styled.div<{
  backgroundColor: string;
  isAssetLoaded: boolean;
}>`
  display: flex;
  justify-content: center;
  height: 50vh;
  max-height: 500px;

  video {
    object-fit: cover;
    height: 100%;
    max-height: 100%;
    opacity: ${(props) => (props.isAssetLoaded ? 1 : 0)};
    transition: opacity ${FRAME_TRANSITION};
  }

  @media (max-height: 640px) {
    margin-bottom: 80px;
    video {
      display: none;
    }
  }
`;
