import React from "react";
import styled from "styled-components";
import { useAppDispatch } from "store";
import { setUpdatedName } from "store/editVariableMapping/editVariableMappingSlice";
import { Color } from "ts/enums/color";
import { TextType } from "ts/TextType";

type Props = {
  name: string;
  inputTitle: TextType;
  lastSavedName: string;
  removeLeftMargin?: boolean;
  onType?(): void;
};

const inputMaxLength = 100;

export const NameInput = ({ name, inputTitle, removeLeftMargin, lastSavedName, onType }: Props) => {
  const dispatch = useAppDispatch();

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.substring(0, inputMaxLength);
    dispatch(setUpdatedName(value));
    onType != null && onType();
  };

  const onBlurHandler = () => {
    if (name.trim().length === 0) {
      dispatch(setUpdatedName(lastSavedName));
    } else {
      if (name !== name.trim()) {
        dispatch(setUpdatedName(name.trim()));
      }
    }
  };

  return (
    <StyledPreferenceNameInput removeLeftMargin={removeLeftMargin}>
      <StyledTitle> {inputTitle} </StyledTitle>
      <StyledInput
        type="text"
        value={name}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        onChange={onChangeName}
        onBlur={onBlurHandler}
      />
    </StyledPreferenceNameInput>
  );
};

const StyledPreferenceNameInput = styled.div<{ removeLeftMargin: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 507px;
  margin: ${({ removeLeftMargin }) =>
    removeLeftMargin ? "16px 0px 16px 0px" : "16px 0px 16px 20px"};
  height: 38px;
  font-size: 0.8125em;
`;

const StyledTitle = styled.div`
  font-size: 13px;
  color: ${Color.gray40};
  margin-right: 10px;
  min-width: fit-content;
`;

const StyledInput = styled.input`
  width: 375px;
  height: 36px;
  margin-left: 9px;
  padding: 0px 11px;
  border: 1px solid ${Color.blue20};
  border-radius: 2px;
  display: flex;
  align-items: center;

  &:focus {
    border: 1px solid ${Color.blue50};
    outline-style: none;
  }
`;
