import React, { useState } from "react";
import styled from "styled-components";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import welcomeSlide1 from "assets/animations/welcome-slide-1.mp4";
import welcomeSlide2 from "assets/animations/welcome-slide-2.mp4";
import welcomeSlide3 from "assets/animations/welcome-slide-3.mp4";
import welcomeSlide4 from "assets/animations/welcome-slide-4.mp4";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import mlyLogo from "assets/images/mly-logo-white.svg";
import { getSystemOs, SystemOS } from "utils/getSystemOS";
import { Slide } from "./Slide";
import { BOTTOM_NAV_HEIGHT, FRAME_TRANSITION, PROGRESS_TRANSITION } from "./constants";
import { useHistory } from "react-router-dom";
import { Text } from "components/Text";

type BackgroundPerOs = {
  [SystemOS.Mac]: string;
  [SystemOS.Windows]: string;
};

const getBackgroundPerOS = (background: BackgroundPerOs, OS: SystemOS) => {
  return background[OS] || background[SystemOS.Windows];
};

// Note on colors: They must be perfectly matching with the colors in compressed video assets,
// which is why they are hardcoded here.
const slides = [
  {
    title: <Text resource="welcomeScreen.slide1.title" />,
    description: <Text resource="welcomeScreen.slide1.description" />,
    backgroundColor: { [SystemOS.Mac]: "#4C37E9", [SystemOS.Windows]: "#4330e6" },
    activeColor: "#80dcaa",
    animationAsset: welcomeSlide1,
  },
  {
    title: <Text resource="welcomeScreen.slide2.title" />,
    description: <Text resource="welcomeScreen.slide2.description" />,
    backgroundColor: { [SystemOS.Mac]: "#7053D1", [SystemOS.Windows]: "#654ACB" },
    activeColor: "#9c70f9",
    animationAsset: welcomeSlide2,
  },
  {
    title: <Text resource="welcomeScreen.slide3.title" />,
    description: <Text resource="welcomeScreen.slide3.description" />,
    backgroundColor: { [SystemOS.Mac]: "#4C37EA", [SystemOS.Windows]: "#4330E6" },
    activeColor: "#6fb5fb",
    animationAsset: welcomeSlide3,
  },
  {
    title: <Text resource="welcomeScreen.slide4.title" />,
    description: <Text resource="welcomeScreen.slide4.description" />,
    backgroundColor: { [SystemOS.Mac]: "#0B887C", [SystemOS.Windows]: "#0E7C71" },
    activeColor: "#80dcaa",
    animationAsset: welcomeSlide4,
  },
];

export const WelcomeScreen = ({ handleClose }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isClosing, setIsClosing] = useState(false);
  const isLast = currentSlideIndex === slides.length - 1;
  const history = useHistory();

  const onClose = () => {
    setIsClosing(true);
    history.push("/");
    handleClose();
  };

  const systemOS = getSystemOs();

  return (
    <>
      {!isLast && <StyledWhiteScreenForBgTransition />}
      <StyledWelcomeScreenFrame
        isClosing={isClosing}
        className="fade-in-slow"
        backgroundColor={getBackgroundPerOS(slides[currentSlideIndex].backgroundColor, systemOS)}
      >
        <StyledContainer>
          {slides.map((slide, index) => (
            <Slide
              slideData={{
                ...slide,
                backgroundColor: getBackgroundPerOS(slide.backgroundColor, systemOS),
              }}
              isCurrent={index === currentSlideIndex}
              key={index}
            />
          ))}
          <StyledCommonContent>
            <StyledLogoSection>
              <img src={mlyLogo} alt="mly-logo" width={144} />
            </StyledLogoSection>
            <StyledNavigationSection>
              <StyledBackButton
                onClick={() => setCurrentSlideIndex(currentSlideIndex - 1)}
                disabled={currentSlideIndex === 0}
              >
                <Text resource="button.previous" />
              </StyledBackButton>
              <StyledProgressBalls>
                {[...Array(slides.length)].map((_, index) => (
                  <StyledProgressBall
                    key={index}
                    isSelected={index === currentSlideIndex}
                    activeColor={slides[currentSlideIndex].activeColor}
                    onClick={() => setCurrentSlideIndex(index)}
                    isClickable={currentSlideIndex !== index}
                  />
                ))}
              </StyledProgressBalls>
              <StyledNextButton
                onClick={isLast ? onClose : () => setCurrentSlideIndex(currentSlideIndex + 1)}
              >
                <Text resource={isLast ? "button.getStarted" : "button.next"} />
                <Icon type={IconType.arrowRightFat} style={{ marginBottom: -2 }} size={24} />
              </StyledNextButton>
            </StyledNavigationSection>
          </StyledCommonContent>
        </StyledContainer>
      </StyledWelcomeScreenFrame>
    </>
  );
};

const StyledWelcomeScreenFrame = styled.div<{ backgroundColor: string; isClosing: boolean }>`
  /* Frame structure */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: none;
  z-index: ${ZIndexStackingContext.high + 1};

  /* Styling & transitions */
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: background-color ${FRAME_TRANSITION}, opacity ${FRAME_TRANSITION};
  opacity: ${(props) => (props.isClosing ? 0 : 1)};
`;

const StyledWhiteScreenForBgTransition = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${ZIndexStackingContext.high};
  background-color: white;
`;

const StyledContainer = styled.div`
  margin: 0 auto;
  position: relative;
  height: 100vh;
`;

const StyledCommonContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1300px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
`;

const StyledLogoSection = styled.div`
  padding: 56px 100px;
`;

const StyledNavigationSection = styled.div`
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  height: ${BOTTOM_NAV_HEIGHT}px;
`;

const StyledBackButton = styled.button<{ disabled: boolean }>`
  background: none;
  outline: none;
  border: 1px solid white;
  height: 80px;
  width: 180px;
  border-radius: 18px;
  color: white;
  font-size: 20px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: opacity ${PROGRESS_TRANSITION};
`;

const StyledNextButton = styled.button`
  background: white;
  outline: none;
  border: 1px solid white;
  height: 80px;
  width: 180px;
  border-radius: 18px;
  color: ${Color.indigo50};
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  svg {
    margin-bottom: -2px;
  }
`;

const StyledProgressBalls = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledProgressBall = styled.div<{
  isSelected: boolean;
  activeColor: string;
  isClickable: boolean;
}>`
  height: 16px;
  width: ${(props) => (props.isSelected ? "80px" : "40px")};
  border-radius: 999px;
  background-color: ${(props) => (props.isSelected ? props.activeColor : "#d6d3f7")};

  transition: width ${PROGRESS_TRANSITION}, background-color ${PROGRESS_TRANSITION};
  cursor: pointer;
`;
