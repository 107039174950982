import React, { useState } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import { ConditionalWrapper } from "components/ConditionalWrapper";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";
import { Color } from "ts/enums/color";

type SidebarLinkProps = {
  href?: string;
  hrefs?: string[];
  show?: boolean;
  icon: IconType;
  labelKey: string;
  isExpanded: boolean;
  isActive?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
};

const SidebarItem = ({
  href,
  show = true,
  icon,
  labelKey,
  isExpanded,
  isActive,
  onClick,
  onMouseEnter,
}: SidebarLinkProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  if (!show) return null;
  isActive = isActive || location.pathname.startsWith(href);

  const handleMouseEnter = () => {
    onMouseEnter && onMouseEnter();
    setIsHovered(true);
  };

  return (
    <StyledSidebarItem
      isActive={isActive}
      isExpanded={isExpanded}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ConditionalWrapper
        wrapper={(children) => <Link to={href}>{children}</Link>}
        condition={!!href}
      >
        <StyledIconContainer>
          <Icon
            type={icon}
            size={14}
            color={isActive ? Color.indigo45 : isHovered ? Color.gray50 : Color.gray20}
          />
        </StyledIconContainer>
        <StyledExpandedNameContainer isActive={isActive} isExpanded={isExpanded}>
          <Text resource={labelKey} />
          {!href && <Icon type={IconType.chevronRight} color={Color.gray40} size={14} />}
        </StyledExpandedNameContainer>
      </ConditionalWrapper>
    </StyledSidebarItem>
  );
};

const StyledIconContainer = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg * {
    transition: fill 50ms ease-in-out;
  }
`;

const StyledSidebarItem = styled.div<{
  isActive: boolean;
  isExpanded: boolean;
}>`
  background-color: ${({ isActive }) => (isActive ? Color.sky15 : "none")};
  border-radius: 5px;
  width: ${({ isExpanded }) => (isExpanded ? "204px" : "28px")};
  height: 28px;
  display: flex;
  align-items: center;
  margin: 8px auto;
  cursor: pointer;
  transition: background-color 50ms ease-in-out, width 250ms ease-in-out;

  &:hover {
    background-color: ${Color.indigo10};
    transition: background-color 50ms ease-in-out;

    span {
      color: ${({ isActive }) => !isActive && Color.gray50};
      transition: color 50ms ease-in-out;
    }
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const StyledExpandedNameContainer = styled.div<{
  isExpanded: boolean;
  isActive: boolean;
}>`
  display: flex;
  align-items: center;
  opacity: ${({ isExpanded }) => (isExpanded ? "1" : "0")};
  position: relative;
  left: 10px;
  visibility: ${({ isExpanded }) => (isExpanded ? "visible" : "hidden")};
  width: ${({ isExpanded }) => (isExpanded ? "80%" : "0px")};
  transition: visibility 150ms ease-in-out 150ms, opacity 150ms ease-in-out 150ms,
    width 250ms ease-in-out;
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;

  span {
    color: ${({ isActive }) => (isActive ? Color.indigo45 : Color.gray20)};
    font-size: 0.875em;
    padding-right: 55px;
    font-weight: ${({ isActive }) => isActive && "bold"};
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
    transition: color 50ms ease-in-out;
  }

  svg {
    margin-top: 1px;
  }
`;

export { SidebarItem };
