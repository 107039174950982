import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "store";
import { AnalysisContext } from "context/AnalysisContext";

import { useResource } from "hooks/useResource";
import { isDegraded } from "utils/isDegraded";
import { isSettingsPageUnavailable } from "utils/isSettingsPageUnavailable";
import { setExcludedCommentList } from "store/analysisSettings/settingsSlice";
import { fetchPreFilteringWordSuggestions } from "store/analysisSettings/thunks";

import { SuggestedColumnsBanner } from "./SuggestedColumnsBanner";
import { CheckboxField } from "components/_inputs/CheckboxField";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";
import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { ExcludedCommentWordCount, RoleType, AnalysisStatus } from "@explorance/mly-types";
import {
  StyledSettingsSelection,
  StyledSettingsSelectionDescription,
  StyledSettingsSelectionTitle,
} from "common-layouts/AnalysisSettingsDropdownField";

type Props = {
  selectedColumns: string[];
  options: string[];
  handleSelectOption: (selectedColumns: string[]) => void;
  isDisabled?: boolean;
};

export const CommentColumnsSelection = ({
  selectedColumns,
  options,
  handleSelectOption,
  isDisabled,
}: Props) => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const settingsState = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const [state] = useContext(AnalysisContext);

  const { getResource } = useResource();

  const isMaxNumOfColumnsSelected =
    selectedColumns.length === state.analysisDetails.maxSelectableColumns;

  const isColumnDisabled = (col: string): boolean =>
    isMaxNumOfColumnsSelected && !selectedColumns.some((sc) => sc === col);

  const handleSelectColumn = (option: string) => {
    const copy = [...selectedColumns];
    // if column is already selected
    if (copy.includes(option)) return handleSelectOption(copy.filter((c) => c !== option));
    handleSelectOption([...copy, option]);
  };

  useEffect(() => {
    if (settingsState.analysisDetails) {
      dispatch(fetchPreFilteringWordSuggestions());

      const rearrangedExcludedValues = [];
      settingsState.analysisDetails.excludedValues?.forEach((filteredComment) => {
        Object.values(ExcludedCommentWordCount).includes(
          filteredComment as ExcludedCommentWordCount
        )
          ? rearrangedExcludedValues.unshift(filteredComment)
          : rearrangedExcludedValues.push(filteredComment);
      });

      rearrangedExcludedValues.forEach((filteredComment) => {
        // If selectAll enum exist in payload, it's added to redux here
        if (Object.values(ExcludedCommentWordCount).find((val) => filteredComment === val)) {
          const exclusionCount =
            Object.values(ExcludedCommentWordCount).findIndex((ind) => ind === filteredComment) + 1;
          dispatch(
            setExcludedCommentList({
              comment: getResource(
                exclusionCount === 1
                  ? "analysisSettings.prefiltering.selectAll.pill.singular"
                  : "analysisSettings.prefiltering.selectAll.pill.plural",
                exclusionCount
              ),
              selectAllExclusionCount: exclusionCount,
              selectAllEnum: Object.values(ExcludedCommentWordCount)[exclusionCount - 1],
            })
          );
        } else {
          // all other comments added here
          dispatch(
            setExcludedCommentList({
              comment: filteredComment,
            })
          );
        }
      });
    }
  }, [settingsState.analysisDetails, dispatch]); //eslint-disable-line

  return (
    <StyledSettingsSelection>
      <StyledSettingsSelectionTitle>
        <Text resource="analysisSettings.commentColumn.label" />
        <span>*</span>
      </StyledSettingsSelectionTitle>
      <StyledSettingsSelectionDescription>
        <span>
          <Text resource="analysisSettings.commentColumn.required" />
        </span>
        <Text
          resource={{
            key: "analysisSettings.commentColumn.description",
            args: [`${state.analysisDetails.maxSelectableColumns}`],
          }}
        />
      </StyledSettingsSelectionDescription>
      {state.analysisDetails.status === AnalysisStatus.NotAnalyzed &&
        settingsState.suggestedColumnsBannerIsOpen &&
        state.analysisDetails.suggestedColumns?.length > 0 &&
        !isSettingsPageUnavailable(state) && <SuggestedColumnsBanner />}
      <StyledCheckboxSelection>
        <StyledChecklist>
          {options.map((o, i) => (
            <CheckboxField
              key={i}
              label={o}
              disabled={
                isDisabled ||
                isDegraded(currentUser, state) ||
                currentUser.roleType === RoleType.Viewer
              }
              variant={CheckboxCheckedAppearance.Default}
              checked={selectedColumns.find((sc) => sc === o)?.length > 0}
              onChange={isColumnDisabled(o) ? undefined : () => handleSelectColumn(o)}
            />
          ))}
        </StyledChecklist>
      </StyledCheckboxSelection>
    </StyledSettingsSelection>
  );
};

const StyledCheckboxSelection = styled.div`
  overflow-y: auto;
  margin-top: 8px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Color.gray20};
  }

  ::-webkit-scrollbar-track {
    background: ${Color.sky20};
    border-radius: 5px;
    margin: 2px 0px;
  }

  @media (min-height: 10px) and (max-height: 499px) {
    max-height: 80px;
  }

  @media (min-height: 500px) {
    max-height: 90px;
  }

  @media (min-height: 650px) {
    max-height: 150px;
  }

  @media (min-height: 768px) {
    max-height: 265px;
  }
`;

const StyledChecklist = styled.ul`
  padding-inline-start: 18px;
  margin-block-end: 0;
  margin-top: 5px;
  color: ${Color.gray50};
  font-size: 0.925em;
`;
