import { InsightType } from "@explorance/mly-types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendFeedback } from "services/comments";
import { AppState } from "store/appReducer";
import { setIsLoading } from "./feedbackSlice";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";

export const sendCommentFeedback = createAsyncThunk<void, void, { state: AppState }>(
  "feedback/sendFeedback",
  async (_, { getState, dispatch }) => {
    const state = getState();
    const feedbackState = state.feedbackPopup;
    const hasTopics =
      [...feedbackState.selectedTopics, ...feedbackState.selectedRecommendations].length > 0;

    //send feedback if feedbackInputValue is not empty add it to function params
    try {
      dispatch(setIsLoading(true));
      await sendFeedback({
        commentId: feedbackState.comment.id,
        topics: [...feedbackState.selectedTopics, ...feedbackState.selectedRecommendations],
        feedbackType: feedbackState.analysisSentiment,
        insight:
          feedbackState.insightType == InsightType.TopicRecommendation && !hasTopics
            ? InsightType.Recommendation
            : feedbackState.insightType,
        ...(feedbackState.feedbackInputValue && { feedback: feedbackState.feedbackInputValue }),
      });
      dispatch(showToastSuccess("comments.detailsPopup.feedbackSuccess.toast.message"));
    } catch {
      dispatch(showToastError("toast.defaultError"));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
