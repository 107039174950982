import React, { useEffect, useState } from "react";

import useClickOutside from "hooks/useClickOutside";

import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { Comment } from "ts/comments";
import { useAppDispatch, useAppSelector } from "store";
import { clearState, setComment } from "store/feedback/feedbackSlice";
import { FeedbackPopup } from "./FeedbackPopup";

type Props = {
  comment: Comment;
  disabled?: boolean;
};

export const FeedbackActionButton = ({ comment, disabled }: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.feedbackPopup);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(null);

  const feedbackActionIconRef = useClickOutside(() => setIsPopupOpen(false));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsPopupOpen((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    dispatch(setComment(comment));
    if (!isPopupOpen) {
      dispatch(clearState());
    }
  }, [comment, isPopupOpen, dispatch]);

  return (
    <div ref={feedbackActionIconRef}>
      <Button
        variant={ButtonVariant.light}
        square
        disabled={disabled}
        tooltipContentKey="comments.feedback.tooltip"
        active={isPopupOpen}
        size={ButtonSize.sm}
        onClick={handleClick}
        style={{ color: Color.gray20 }}
      >
        <Icon type={IconType.horn} color={isPopupOpen ? Color.blue20 : Color.gray20} size={12} />
      </Button>
      {isPopupOpen && !!state.comment && (
        <FeedbackPopup anchorEl={anchorEl} setIsPopupOpen={setIsPopupOpen} />
      )}
    </div>
  );
};
