import React, { useState } from "react";

import { FileUploadInput } from "components/_inputs/FileUpload";
import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";

import { createAnalysis, uploadFile } from "services/analysis";
import { stripFileExtension } from "utils/stripFileExtension";

type Props = {
  inputFileRef: React.RefObject<HTMLInputElement>;
};

export const ImportDataButton = ({ inputFileRef }: Props) => {
  const [isImportError, setIsImportError] = useState<boolean>(false);

  const createAnalysisAndSaveFile = async (file: File) => {
    try {
      const { data } = await createAnalysis(stripFileExtension(file.name));
      await uploadFile(data.analysisId, file);
    } catch (e: any) {
      setIsImportError(true);
      return e.response?.data?.details?.[0]?.rule === "string.max"
        ? "toast.fileUpload.fileNameTooLong"
        : "toast.fileUpload.error";
    }
  };

  return (
    <>
      <Button onClick={() => inputFileRef.current.click()} style={{ marginLeft: "auto" }}>
        <Text resource="button.importData" />
      </Button>
      <FileUploadInput
        inputFileRef={inputFileRef}
        onSelectFile={createAnalysisAndSaveFile}
        resetFileRef={isImportError}
      />
    </>
  );
};
