import React from "react";
import { useAppSelector, useAppDispatch } from "store";
import { useHistory } from "react-router-dom";

import { createCustomModel, SaveModelValues } from "services/customModels";
import { useQueryParams } from "hooks/useQueryParams";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";

import { CustomModelBuilder } from "common-layouts/CustomModelBuilder";
import { ErrorScreen } from "components/ErrorScreen";

import { RoleType } from "@explorance/mly-types";
import { PageErrorType } from "ts/enums/pageErrorType";

export const CustomModelNewPage = () => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { name, modelId, isTemplate } = useQueryParams();

  const history = useHistory();

  const initialValues = { name, modelId: Number(modelId), isTemplate };

  const handleSave = async (values: SaveModelValues, skipCallback = false) => {
    try {
      const { data } = await createCustomModel(values);
      if (!skipCallback) {
        history.replace(`/custom-models/${data.id}/edit`);
      }
      dispatch(showToastSuccess("toast.customModelSaved"));
      return true;
    } catch (error: any) {
      dispatch(showToastError("toast.defaultError"));
      return false;
    }
  };

  if (currentUser.roleType === RoleType.Viewer) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return <CustomModelBuilder initialValues={initialValues} onSave={handleSave} />;
};
