import React, { useState } from "react";
import styled from "styled-components";

import { Tooltip } from "components/Tooltip";
import { TextType } from "ts/TextType";

type Props = {
  value: TextType | React.ReactNode;
  id?: number;
  customWidth?: string;
};

export const TextTruncator = React.memo(({ id, value, customWidth }: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleHover = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) setShowTooltip(true);
  };

  return (
    <StyledTextContainer
      onMouseOver={handleHover}
      onMouseOut={() => setShowTooltip(false)}
      data-tooltip-id={`${id}-tooltip`}
      customWidth={customWidth}
    >
      <span>{value}</span>
      {showTooltip && (
        <Tooltip tooltipId={`${id}-tooltip`} content={value as TextType} isOpen={showTooltip} />
      )}
    </StyledTextContainer>
  );
});

const StyledTextContainer = styled.div<{ customWidth: string }>`
  max-width: ${({ customWidth }) => customWidth || "100%"};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
