import React, { useState, useRef } from "react";
import styled from "styled-components";

import { useHistory } from "react-router-dom";
import { useAppSelector } from "store";
import { AboutMlyModal } from "components/_modals/AboutMlyModal";
import { Icon, IconType } from "components/_icons/Icon";
import { DropdownMenu } from "components/DropdownMenu";
import { Tooltip } from "components/Tooltip";
import { UserAvatar } from "components/UserAvatar.tsx";
import { Text } from "components/Text";

import { authenticationManager } from "index";
import { getAppConfig } from "config/configProviders";
import useClickOutside from "hooks/useClickOutside";

import { Color } from "ts/enums/color";
import { AvatarSize } from "ts/enums/avatarSize";

type Props = {
  isExpanded: boolean;
};

export const UserProfileMenu = ({ isExpanded }: Props) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const [profileMenuIsOpen, setProfileMenuIsOpen] = useState<boolean>(false);
  const [profileIsOpen, setProfileIsOpen] = useState<boolean>(false);
  const [showAboutMlyModal, setShowAboutMlyModal] = useState<boolean>(false);
  const [showUsernameTooltip, setShowUsernameTooltip] = useState<boolean>(false);

  const history = useHistory();
  const onOpenHelpCenter = getAppConfig().helpCenter;
  const profileMenuRef = useClickOutside(() => setProfileMenuIsOpen(false));

  const usernameRef = useRef<HTMLDivElement>();

  history.listen(() => {
    setProfileIsOpen(
      history.location.pathname === `/user-management/update-profile/${currentUser.id}`
    );
  });

  const onOpenProfileForUpdate = () => {
    setProfileMenuIsOpen(!profileMenuIsOpen);
    history.push(`/user-management/update-profile/${currentUser.id}`);
  };

  const onOpenAboutMly = () => {
    setShowAboutMlyModal(!showAboutMlyModal);
    setProfileMenuIsOpen(!profileMenuIsOpen);
  };

  const handleRequestCloseFunc = () => {
    setShowAboutMlyModal(false);
  };

  const onLogoutHandler = () => {
    authenticationManager.logout();
  };

  const onLogout = () => {
    setProfileMenuIsOpen(!profileMenuIsOpen);
    onLogoutHandler();
  };

  const handleShowTooltip = () => {
    if (isExpanded && usernameRef.current) {
      setShowUsernameTooltip(usernameRef.current.scrollWidth > usernameRef.current.offsetWidth);
    }
  };

  const dropdownItems = [
    {
      onClick: onOpenProfileForUpdate,
      label: <Text resource="sidebar.profileDropdown.updateUserProfile" />,
      isActive: profileIsOpen,
    },
    {
      onClick: onOpenAboutMly,
      label: <Text resource="sidebar.profileDropdown.aboutBlueML" />,
      isActive: showAboutMlyModal,
    },
    {
      href: onOpenHelpCenter,
      label: <Text resource="sidebar.profileDropdown.helpCenter" />,
      external: true,
    },
    {
      onClick: onLogout,
      label: <Text resource="sidebar.profileDropdown.logout" />,
    },
  ];

  return (
    <>
      <AboutMlyModal isOpen={showAboutMlyModal} onClose={handleRequestCloseFunc} />
      <StyledUserProfileMenu
        ref={profileMenuRef}
        onMouseOver={() => setProfileMenuIsOpen(true)}
        onMouseOut={() => setProfileMenuIsOpen(false)}
      >
        <StyledContainer
          onClick={() => setProfileMenuIsOpen((open) => !open)}
          isExpanded={isExpanded}
          isMenuOpen={profileMenuIsOpen}
        >
          {/* keep this empty div as a wrapper for the avatar to prevent resizing on expand/collapse */}
          <div>
            <UserAvatar
              firstName={currentUser.firstname}
              lastName={currentUser.lastname}
              userId={currentUser.id}
              avatarSize={AvatarSize.sm}
            />
          </div>
          <StyledExpandedNameContainer isExpanded={isExpanded}>
            <StyledUserName
              isExpanded={isExpanded}
              data-tooltip-id={`${currentUser.firstname}-${currentUser.lastname}-tooltip`}
              ref={usernameRef}
              onMouseOver={handleShowTooltip}
              onMouseOut={() => setShowUsernameTooltip(false)}
            >
              {`${currentUser.firstname} ${currentUser.lastname}`}
            </StyledUserName>
            <Icon type={IconType.chevronRight} color={Color.gray50} size={16} />
            {showUsernameTooltip && (
              <Tooltip
                tooltipId={`${currentUser.firstname}-${currentUser.lastname}-tooltip`}
                content={`${currentUser.firstname} ${currentUser.lastname}`}
                isOpen
                place="top"
              />
            )}
          </StyledExpandedNameContainer>
        </StyledContainer>
        <DropdownMenu
          items={dropdownItems}
          show={profileMenuIsOpen}
          position={{ bottom: 0, left: isExpanded ? 234 : 45 }}
          width="180px"
        />
      </StyledUserProfileMenu>
    </>
  );
};

const StyledUserProfileMenu = styled.div`
  position: relative;
`;

const StyledContainer = styled.div<{ isExpanded: boolean; isMenuOpen: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background-color: ${({ isExpanded, isMenuOpen }) => isExpanded && isMenuOpen && Color.indigo10};
  width: ${({ isExpanded }) => isExpanded && "220px"};

  &:hover {
    background-color: ${({ isExpanded }) => isExpanded && Color.indigo10};
    cursor: pointer;
  }
`;

const StyledExpandedNameContainer = styled.div<{ isExpanded: boolean }>`
  display: flex;
  margin-left: 8px;
  align-items: center;
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
  width: ${({ isExpanded }) => (isExpanded ? "100%" : "0px")};
  visibility: ${({ isExpanded }) => (isExpanded ? "visible" : "hidden")};
  opacity: ${({ isExpanded }) => (isExpanded ? "1" : "0")};
  transition: visibility 150ms ease-in-out, opacity 150ms ease-in-out, width 250ms ease-in-out;

  svg {
    margin-top: 2px;
    visibility: ${({ isExpanded }) => (isExpanded ? "visible" : "hidden")};
    opacity: ${({ isExpanded }) => (isExpanded ? "1" : "0")};
    transition: visibility 100ms ease-in-out 300ms, opacity 100ms ease-in-out 300ms;
  }
`;

const StyledUserName = styled.div<{ isExpanded: boolean }>`
  color: ${Color.gray50};
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: ${({ isExpanded }) => (isExpanded ? "160px" : "0px")};
  transition: width 250ms ease-in-out 100ms;
`;
