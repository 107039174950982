import React, { useState } from "react";
import styled from "styled-components";

import { UserDemographic } from "context/UserProfileContext";
import { isAllSelected, selectFilter } from "utils/userDemographics";
import { getRandomNumber } from "utils/getRandomNumber";

import { ChecklistItem, NestedChecklist } from "components/NestedChecklist";
import { CheckboxField } from "components/_inputs/CheckboxField";
import { Tooltip } from "components/Tooltip";
import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";

import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { Color } from "ts/enums/color";
import { ButtonSize, ButtonVariant } from "ts/enums/button";

type Props = {
  allFiltersList: UserDemographic[];
  currentlySelectedFilters: UserDemographic[];
  sectionTitle: string;
  selectedFilter?: UserDemographic;
  selectedFilterId?: number;
  selectedFilterCount?: number;
  parentCheckboxLabel?: string;
  selectAllLabel?: string;
  isMenuClosed?: boolean;
  updateFilters: (filters: UserDemographic[]) => void;
  applyFilters: () => void;
};

export const UserDemographicFilterMenu = ({
  allFiltersList,
  currentlySelectedFilters,
  selectedFilter,
  selectedFilterId,
  selectedFilterCount,
  sectionTitle,
  parentCheckboxLabel,
  selectAllLabel,
  isMenuClosed,
  updateFilters,
  applyFilters,
}: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const tooltipId = getRandomNumber();

  const areAllSelected = selectAllLabel
    ? allFiltersList.every(
        (fil) =>
          currentlySelectedFilters.find((sel) => sel.name === fil.name)?.values === fil.values
      )
    : isAllSelected(
        selectedFilter.values,
        currentlySelectedFilters.filter((sel) => sel.name === selectedFilter.name)
      );

  const handleMouseOverTitle = (e: React.MouseEvent<HTMLSpanElement>) => {
    setShowTooltip(e.currentTarget.offsetWidth < e.currentTarget.scrollWidth);
  };

  const updateValues = (item: ChecklistItem) => {
    selectFilter(allFiltersList, currentlySelectedFilters, item.name, item.values, updateFilters);
  };

  return (
    <StyledUserDemographicFilterMenu>
      <StyledMenuContentContainer>
        <StyledTitleContainer>
          <StyledTitle
            data-tooltip-id={`sectionTitle-${tooltipId}`}
            onMouseOver={handleMouseOverTitle}
            onMouseOut={() => setShowTooltip(false)}
          >
            {sectionTitle.toUpperCase()}

            {showTooltip && (
              <Tooltip tooltipId={`sectionTitle-${tooltipId}`} content={sectionTitle} />
            )}
          </StyledTitle>
          {selectedFilterCount > 0 && <span>({selectedFilterCount})</span>}
        </StyledTitleContainer>
        <div>
          {selectAllLabel && (
            <CheckboxField
              label={selectAllLabel}
              variant={
                areAllSelected
                  ? CheckboxCheckedAppearance.Default
                  : CheckboxCheckedAppearance.Partial
              }
              checked={currentlySelectedFilters.length > 0}
              onChange={() => updateFilters(areAllSelected ? [] : allFiltersList)}
            />
          )}
          {selectedFilter ? (
            <NestedChecklist
              id={selectedFilterId}
              checklistItem={selectedFilter}
              selectedValues={currentlySelectedFilters}
              parentCheckboxLabel={parentCheckboxLabel}
              isExpandable={false}
              updateValues={updateValues}
            />
          ) : (
            allFiltersList?.map((filter, index) => (
              <NestedChecklist
                key={index}
                id={index}
                checklistItem={filter}
                selectedValues={currentlySelectedFilters}
                parentCheckboxLabel={parentCheckboxLabel}
                isMenuClosed={isMenuClosed}
                updateValues={updateValues}
              />
            ))
          )}
        </div>
      </StyledMenuContentContainer>
      <StyledSeparator />
      <Button
        variant={ButtonVariant.primaryPurple}
        size={ButtonSize.ml}
        style={{ float: "right", margin: "12px 0" }}
        onClick={applyFilters}
      >
        <Text resource="button.apply" />
      </Button>
    </StyledUserDemographicFilterMenu>
  );
};

const StyledUserDemographicFilterMenu = styled.div`
  width: 250px;
  padding: 12px;
  position: absolute;
  background-color: ${Color.white};
  box-shadow: 0px 3px 10px 0px rgba(53, 56, 77, 0.2);
  border-radius: 5px;
  margin-top: 5px;
  max-height: 500px;

  .checkbox input {
    margin: 0px 0px 0px 1px;
  }
`;

const StyledMenuContentContainer = styled.div`
  overflow-y: scroll;
  max-height: 420px;
`;

const StyledTitleContainer = styled.div`
  font-size: 0.75em;
  color: ${Color.gray20};
  font-weight: bold;
  margin-left: 2px;
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  gap: 2px;
`;

const StyledTitle = styled.span`
  max-width: 330px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledSeparator = styled.div`
  border-top: 1px solid ${Color.blue20};
  width: 100%;
`;
