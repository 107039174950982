import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";

import { AnalysisContext } from "context/AnalysisContext";
import { setAnalysisDetails } from "context/AnalysisContext/actions";
import { useAppSelector } from "store";

import { isAnyAdmin } from "utils/isAnyAdmin";
import useClickOutside from "hooks/useClickOutside";
import { useQueryParams } from "hooks/useQueryParams";
import { updateAnalysis } from "services/analysis";

import { DropdownMenu } from "components/DropdownMenu";
import { Icon, IconType } from "components/_icons/Icon";
import { Tooltip } from "components/Tooltip";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";
import { DropdownSize } from "ts/dropdown";
import { AnalysisStatus, PermissionLevel, RoleType } from "@explorance/mly-types";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

export const Notch = () => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  const [state, analysisContextDispatch] = useContext(AnalysisContext);

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [renaming, setRenaming] = useState<boolean>(false);
  const [analysisNameInputValue, setAnalysisNameInputValue] = useState<string>("");
  const [inputWidth, setInputWidth] = useState<number>(100);
  const [showAnalysisNameTooltip, setShowAnalysisNameTooltip] = useState<boolean>(false);

  const { location } = useHistory();
  const { sharingPreview } = useQueryParams();

  const notchRef = useClickOutside(() => setDropdownOpen(false));
  const inputRef = useRef<HTMLInputElement>();
  const hiddenSpanRef = useRef<HTMLSpanElement>();
  const analysisNameRef = useRef<HTMLDivElement>();

  const isAnalysisOwner = !state?.analysisDetails?.sharing;
  const isAdmin = isAnyAdmin(currentUser.roleType);
  const isGuest = currentUser.roleType === RoleType.Viewer;
  const hasContributorPermission =
    state.analysisDetails.sharing?.permissionLevel === PermissionLevel.Contribution && !isGuest;

  const handleActivateRenameMode = () => {
    if (
      sharingPreview ||
      (!isAdmin && !hasContributorPermission && state.analysisDetails.sharing)
    ) {
      return;
    }
    setRenaming(true);
    setAnalysisNameInputValue(state.analysisDetails.name);
    setDropdownOpen(false);
    setTimeout(() => inputRef?.current?.select());
  };

  const canAccessNotch =
    (isAdmin || hasContributorPermission || isAnalysisOwner) && !sharingPreview;
  const dropdownItems = [
    currentUser.roleType !== RoleType.Viewer &&
      (isAdmin || isAnalysisOwner || hasContributorPermission) && {
        label: <Text resource="analysis.notch.dropdown.renameFile" />,
        onClick: handleActivateRenameMode,
        isActive: renaming,
      },
    ...(state.analysisDetails.status === AnalysisStatus.Completed &&
    currentUser.roleType !== RoleType.Viewer &&
    (isAdmin || isAnalysisOwner)
      ? [
          {
            label: <Text resource="analysis.notch.dropdown.sharing" />,
            href: `/analysis/${state.analysisDetails.id}/sharing`,
            isActive: location.pathname.includes("sharing"),
          },
        ]
      : []),
    {
      label: <Text resource="analysis.notch.dropdown.settings" />,
      href: `/analysis/${state.analysisDetails.id}/settings`,
      isActive: location.pathname.includes("settings"),
    },
  ].filter(Boolean);

  const handleRenameAnalysis = async () => {
    if (analysisNameInputValue.trim().length > 0) {
      updateAnalysis(state.analysisDetails.id, {
        name: analysisNameInputValue,
        commenterColumn: state.analysisDetails.selectedCommentersColumn,
      })
        .then(({ data }) => {
          setRenaming(false);
          setDropdownOpen(false);
          analysisContextDispatch(
            setAnalysisDetails({ ...state.analysisDetails, name: data.name })
          );
        })
        .catch((e) => console.error(e.message));
    } else {
      setRenaming(false);
    }
  };

  const handleClickNotch = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const isInput = (e.target as HTMLInputElement).localName === "input";
    if ((isInput && !dropdownOpen) || !canAccessNotch) {
      return;
    }

    setDropdownOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    setInputWidth(Math.max(Math.min(hiddenSpanRef?.current?.offsetWidth, 252), 16));
  }, [analysisNameInputValue, renaming, state.analysisDetails.name]);

  useEffect(() => {
    setShowAnalysisNameTooltip(
      analysisNameRef?.current?.offsetWidth < analysisNameRef?.current?.scrollWidth && !dropdownOpen
    );
  }, [state.analysisDetails.name, dropdownOpen]);

  return (
    <StyledNotchContainer>
      <StyledNotch
        show={!!state.analysisDetails.name}
        onClick={handleClickNotch}
        ref={notchRef}
        canAccessNotch={canAccessNotch}
      >
        <StyledHiddenSpan style={{ border: "1px solid" }} ref={hiddenSpanRef}>
          {analysisNameInputValue || state.analysisDetails.name}
        </StyledHiddenSpan>
        {renaming ? (
          <StyledAnalysisNameInput
            ref={inputRef}
            value={analysisNameInputValue}
            onFocus={(e) => e.target.select()}
            onChange={(e) => setAnalysisNameInputValue(e.target.value)}
            style={{ width: inputWidth + 2 }}
            onBlur={handleRenameAnalysis}
            maxLength={100}
            onKeyDown={(e) => e.key === "Enter" && handleRenameAnalysis()}
            disabled={isGuest}
          />
        ) : (
          <>
            <StyledAnalysisName
              ref={analysisNameRef}
              data-tooltip-id={`${state.analysisDetails.name}-tooltip2`}
            >
              {state.analysisDetails.name}
            </StyledAnalysisName>
            {showAnalysisNameTooltip && (
              <Tooltip
                tooltipId={`${state.analysisDetails.name}-tooltip2`}
                className="analysis-name-tooltip"
                content={state.analysisDetails.name}
              />
            )}
          </>
        )}
        {canAccessNotch && (
          <Icon type={IconType.chevronDown} size={10} style={{ marginLeft: 10 }} />
        )}
        <DropdownMenu
          width="136px"
          show={dropdownOpen}
          items={dropdownItems}
          position={{ top: 34, right: 14 }}
          size={DropdownSize.lg}
        />
      </StyledNotch>
    </StyledNotchContainer>
  );
};

const StyledNotchContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${ZIndexStackingContext.high};
`;

const StyledNotch = styled.div<{ show: boolean; canAccessNotch: boolean }>`
  transform: ${({ show }) => (show ? "translateY(0px)" : "translateY(-30px)")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  height: 32px;
  padding: 8px 32px;
  border-radius: 0px 0px 6px 6px;
  display: flex;
  font-size: 14px;
  align-items: center;
  transition: 0.2s background-color, 0.4s opacity, 0.4s transform;
  cursor: ${({ canAccessNotch }) => (canAccessNotch ? "pointer" : "default")};
  position: relative;
  background-color: ${Color.gray50};
  color: ${Color.sky50};

  svg g {
    fill: ${Color.sky50};
  }

  ${({ canAccessNotch }) =>
    canAccessNotch &&
    css`
      &:hover {
        background-color: ${Color.gray70};
        color: ${Color.white};
        svg g {
          fill: ${Color.white};
        }
      }
    `};

  .analysis-name-tooltip {
    word-break: break-word;
    min-width: 320px;
  }
`;

const StyledHiddenSpan = styled.span`
  position: absolute;
  opacity: 0;
  top: -100px;
`;

const StyledAnalysisName = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 252px;
`;

const StyledAnalysisNameInput = styled.input`
  display: block;
  background-color: rgba(0, 0, 0, 0);
  color: ${Color.white};
  border: none;
  border-radius: 2px;
  margin-right: -2px;
  margin-left: -2px;
  &:focus {
    outline: none;
  }
  &::selection {
    background: ${Color.blue40};
  }
`;
