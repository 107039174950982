import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ModelLicense } from "ts/licensing";
import { fetchModelLicenseList, updateModelActivationStatus, syncModels } from "./thunks";

type ModelManagementState = {
  modelLicenses: ModelLicense[];
  filteredModelLicenses: ModelLicense[];
  isSynchronizing: boolean;
  currentEnvFilters: string[];
  allEnvFilters: string[];
  isLoading: boolean;
};

const initialState: ModelManagementState = {
  modelLicenses: [],
  filteredModelLicenses: [],
  isSynchronizing: false,
  currentEnvFilters: [],
  allEnvFilters: [],
  isLoading: false,
};

const ModelManagementSlice = createSlice({
  name: "modelManagementSlice",
  initialState,
  reducers: {
    setModelLicenses: (state, action: PayloadAction<ModelLicense[]>) => {
      state.modelLicenses = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateFilters: (state, action) => {
      const filterExists = state.currentEnvFilters.includes(action.payload);

      const updatedFilters = filterExists
        ? state.currentEnvFilters.filter((f) => f !== action.payload)
        : [...state.currentEnvFilters, action.payload];

      state.currentEnvFilters = updatedFilters;

      state.filteredModelLicenses = updatedFilters.length
        ? state.modelLicenses.filter((model) => updatedFilters.includes(model?.env))
        : state.modelLicenses;
    },
    clearFilters: (state) => {
      state.currentEnvFilters = [];
      state.filteredModelLicenses = state.modelLicenses;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(syncModels.pending, (state) => {
      state.isSynchronizing = true;
    }),
      builder.addCase(syncModels.fulfilled, (state, action) => {
        state.modelLicenses = action.payload;
        state.isSynchronizing = false;
      });
    builder.addCase(fetchModelLicenseList.fulfilled, (state, action) => {
      state.modelLicenses = action.payload;
      state.filteredModelLicenses = action.payload;
      const uniqueEnv = new Set<string>();
      Object.values(action.payload as ModelLicense[]).forEach((model) => {
        model.env && uniqueEnv.add(model.env);
      });
      state.allEnvFilters = Array.from(uniqueEnv);
    });
    builder.addCase(updateModelActivationStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateModelActivationStatus.fulfilled, (state, action) => {
      state.modelLicenses = state.modelLicenses.map((m) => {
        if (m.modelId === action.payload.modelId) {
          return { ...m, isActivated: action.payload.status };
        }
        return m;
      });
      state.isLoading = false;
    });
  },
});

export const { setModelLicenses, setIsLoading, updateFilters, clearFilters } =
  ModelManagementSlice.actions;

export default ModelManagementSlice.reducer;
