import React from "react";
import styled from "styled-components";

import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";

type CustomStyles = {
  marginLeft?: string;
  marginRight?: string;
};

type Props = {
  id: number;
  customStyles?: CustomStyles;
  moveItem: (direction: number, id: number | string) => void;
};

export const MoveUpDownButtons = ({ id, customStyles, moveItem }: Props) => (
  <StyledUpDownButtonGroup customStyles={customStyles}>
    <StyledArrowButton borderRadius="2px 2px 0 0" onClick={() => moveItem(1, id)}>
      <Icon type={IconType.chevronUp} size={8} color={Color.gray20} />
    </StyledArrowButton>
    <StyledArrowButton borderRadius="0px 0px 2px 2px" onClick={() => moveItem(-1, id)}>
      <Icon type={IconType.chevronDown} size={8} color={Color.gray20} />
    </StyledArrowButton>
  </StyledUpDownButtonGroup>
);

const StyledUpDownButtonGroup = styled.div<{ customStyles?: CustomStyles }>`
  display: flex;
  flex-direction: column;
  margin-right: ${({ customStyles }) => customStyles?.marginRight};
  margin-left: ${({ customStyles }) => customStyles?.marginLeft};
`;

const StyledArrowButton = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: ${Color.sky20};
  cursor: pointer;
  border-radius: ${({ borderRadius }) => borderRadius};
`;
