import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RedactionRule, RedactionVersion, RedactionSetItem } from "ts/redaction";
import { Range } from "ts/range";
import { RadioOption } from "ts/radio";
import {
  changeAnalysisRedactionVersion,
  fetchAnalysisRedactionVersions,
  fetchRedactionSets,
  fetchRedactionVersion,
  handleCreateRedactionVersion,
  handleEditRedactionVersion,
} from "./thunks";
import { RedactionDisplayMethod, RedactionRuleType } from "@explorance/mly-types";

type RedactionRuleReplaceValue = {
  index: number;
  replaceWith: string;
};

type RedactionRuleListNamePayload = {
  index: number;
  redactionSetId: number;
  isAlertType?: boolean;
};

type RedactionRuleMethodPayload = {
  index: number;
  displayMethod: RedactionDisplayMethod;
};

type RedactionRuleAlertPayload = {
  index: number;
  alertRange: Range;
};

type RedactionModalState = {
  redactionVersionName: string;
  isModalOpen: boolean;
  redactionVersionId: number;
  redactionRules: RedactionRule[];
  isManualRedactionEnabled: boolean;
  redactionSets: RedactionSetItem[];
  redactionVersions: RedactionVersion[];
  selectedRedactionVersion: RadioOption;
  redactionVersionsLoading: boolean;
  selectedVersionForDelete: RadioOption;
  loadingRedactionVersion: boolean;
};

const initialState: RedactionModalState = {
  redactionVersionName: "",
  isModalOpen: false,
  redactionVersionId: null,
  isManualRedactionEnabled: false,
  redactionRules: [],
  redactionVersions: [],
  selectedRedactionVersion: null,
  redactionVersionsLoading: true,
  redactionSets: [],
  selectedVersionForDelete: null,
  loadingRedactionVersion: true,
};

const RedactionModalSlice = createSlice({
  name: "RedactionModalSlice",
  initialState,
  reducers: {
    setModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    toggleManualRedactionEnabled: (state) => {
      state.isManualRedactionEnabled = !state.isManualRedactionEnabled;
    },
    addRedactionRule: (state, action: PayloadAction<RedactionRule>) => {
      state.redactionRules.push(action.payload);
    },
    removeRedactionRule: (state, action: PayloadAction<number>) => {
      const redactionSetIndex = state.redactionSets.findIndex(
        (set) => set.id === state.redactionRules[action.payload].redactionSetId
      );
      if (redactionSetIndex !== -1) state.redactionSets[redactionSetIndex].isSelected = false;
      state.redactionRules.splice(action.payload, 1);
    },
    setRedactionRuleSet: (state, action: PayloadAction<RedactionRuleListNamePayload>) => {
      const oldIndex = state.redactionSets.findIndex(
        (set) => set.id === state.redactionRules[action.payload.index].redactionSetId
      );

      const setIndex = state.redactionSets.findIndex(
        (set) => set.id === action.payload.redactionSetId
      );

      if (oldIndex !== -1) state.redactionSets[oldIndex].isSelected = false;
      if (setIndex !== -1) state.redactionSets[setIndex].isSelected = true;

      state.redactionRules[action.payload.index] = {
        ruleType: action.payload.isAlertType ? RedactionRuleType.Alert : RedactionRuleType.Term,
        displayMethod: state.redactionRules[action.payload.index].displayMethod,
        replacementValue: state.redactionRules[action.payload.index].replacementValue,
        redactionSetId: action.payload.redactionSetId,
        ...(action.payload.isAlertType && {
          alertRange: { min: 0, max: 100 },
        }),
      };
    },
    setRedactionRuleMethod: (state, action: PayloadAction<RedactionRuleMethodPayload>) => {
      state.redactionRules[action.payload.index].displayMethod = action.payload.displayMethod;
    },
    setRedactionRuleAlertRange: (state, action: PayloadAction<RedactionRuleAlertPayload>) => {
      state.redactionRules[action.payload.index].alertRange = action.payload.alertRange;
    },
    setSelectedVersionForDelete: (state, action: PayloadAction<RadioOption>) => {
      state.selectedVersionForDelete = action.payload;
    },
    setSelectedRedactionVersion: (state, action: PayloadAction<RadioOption>) => {
      state.selectedRedactionVersion = action.payload;
    },
    setRedactionVersionId: (state, action: PayloadAction<number>) => {
      state.redactionVersionId = action.payload;
    },
    setRedactionRuleReplaceWithValue: (state, action: PayloadAction<RedactionRuleReplaceValue>) => {
      state.redactionRules[action.payload.index].replacementValue = action.payload.replaceWith;
    },
    setRedactionVersionName: (state, action: PayloadAction<string>) => {
      state.redactionVersionName = action.payload;
    },
    clearState: (state) =>
      (state = { ...initialState, selectedRedactionVersion: state.selectedRedactionVersion }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnalysisRedactionVersions.fulfilled, (state, action) => {
      state.redactionVersions = action.payload;
      state.redactionVersionsLoading = false;
    });

    builder.addCase(fetchRedactionSets.fulfilled, (state, action) => {
      state.redactionSets = action.payload.redactionSets;
    });

    builder.addCase(handleCreateRedactionVersion.fulfilled, (state) => {
      state.isModalOpen = false;
    });

    builder.addCase(changeAnalysisRedactionVersion.fulfilled, (state, action) => {
      state.selectedRedactionVersion = action.payload;
    });

    builder.addCase(handleEditRedactionVersion.fulfilled, (state) => {
      state.isModalOpen = false;
    });

    builder.addCase(fetchRedactionVersion.fulfilled, (state, action) => {
      state.redactionVersionName = action.payload.name;
      state.redactionRules = action.payload.rules;
      state.isManualRedactionEnabled = action.payload.includeManual;
      state.loadingRedactionVersion = false;
    });
  },
});

export const {
  setModalOpen,
  toggleManualRedactionEnabled,
  addRedactionRule,
  removeRedactionRule,
  setRedactionRuleSet,
  setRedactionRuleMethod,
  setRedactionVersionName,
  setRedactionVersionId,
  setSelectedVersionForDelete,
  setRedactionRuleReplaceWithValue,
  setSelectedRedactionVersion,
  setRedactionRuleAlertRange,
  clearState,
} = RedactionModalSlice.actions;

export default RedactionModalSlice.reducer;
