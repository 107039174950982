import React from "react";
import { FlexTableType } from "ts/enums/flexTable";
import { Row } from "./Row";

type PlaceholderRowsProps = {
  columnWidths: number[];
  type: FlexTableType;
  customStyles?: Record<string, string>;
  tableWidth?: number;
};

export const PlaceholderRows = ({
  columnWidths,
  type,
  customStyles,
  tableWidth,
}: PlaceholderRowsProps) => {
  const placeholderRows = Array.from({ length: 8 }, (_, i) => (
    <Row
      key={i}
      row={Array(columnWidths.length).fill(null)}
      contentId={0}
      isSelected={false}
      isSelectable={false}
      customStyles={customStyles || {}}
      columnWidths={columnWidths}
      isDisabled={false}
      expandedContent={null}
      isLoading
      tableWidth={tableWidth || 0}
      type={type}
    />
  ));

  return <>{placeholderRows}</>;
};
