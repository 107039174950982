import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { AnalysisContext } from "context/AnalysisContext";
import { AnalysisStatus, FileUploadStatus } from "@explorance/mly-types";

import { Modal } from "../Modal";
import { LoadingDots } from "components/LoadingDots";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { Text } from "components/Text";

type Props = {
  // For convenience the various scearios of analysis pages, this component may be either controlled or uncontrolled.
  isOpen?: boolean;
};

export const FileUploadingModal = ({ isOpen }: Props) => {
  const [state] = useContext(AnalysisContext);
  const [localIsOpen, setLocalIsOpen] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    // Following condition explanation:
    // 1. If isOpen is passed, the component is controlled, so skip this code
    // 2. If the analysis status is completed, users should be able to access their analysis previous results normally even if an extra file is uploading.
    if (
      isOpen === undefined &&
      !["overview", "dashboard", "topics", "comments"].find((pageName) =>
        location.pathname.includes(pageName)
      ) &&
      state.analysisDetails.status !== AnalysisStatus.Completed
    ) {
      setLocalIsOpen(state.analysisDetails.uploadStatus === FileUploadStatus.Uploading);
    }
  }, [isOpen, location.pathname, state.analysisDetails.uploadStatus, state.analysisDetails.status]);

  return (
    <Modal
      isOpen={isOpen === undefined ? localIsOpen : isOpen}
      width="400px"
      height="auto"
      overlayIndex={ZIndexStackingContext.low}
    >
      <StyledFileUploadingModalContent>
        <StyledLoadingDotsContainer>
          <LoadingDots />
        </StyledLoadingDotsContainer>
        <StyledTextContainer>
          <StyledTitle>
            <Text resource="modal.fileUploading.title" />
          </StyledTitle>
          <StyledDescription>
            <Text resource="modal.fileUploading.description" />
          </StyledDescription>
        </StyledTextContainer>
      </StyledFileUploadingModalContent>
    </Modal>
  );
};

const StyledFileUploadingModalContent = styled.div`
  display: flex;
  padding-top: 10px;
  padding-left: 15px;
`;

const StyledLoadingDotsContainer = styled.div`
  margin-top: -6px;
`;

const StyledTextContainer = styled.div`
  padding: 0px;
  padding-left: 20px;
  width: 80%;
`;

const StyledTitle = styled.div`
  font-size: 1em;
  cursor: default;
  margin-top: 0px;
`;

const StyledDescription = styled.div`
  margin-top: 10px;
  font-size: 0.875em;
  cursor: default;
  margin-bottom: 10px;
`;
