import { mlyApi, getHeaders } from "config/mlyApi";

export async function getModelLicenseList() {
  const requestConfig = await getHeaders();
  const url = "/licences/models";
  return mlyApi.get(url, requestConfig);
}

export async function updateModelActivation(modelId: number, isActivated: boolean) {
  const requestConfig = await getHeaders();
  const url = `/licences/models/${modelId}?isActivated=${isActivated}`;
  return mlyApi.put(url, requestConfig);
}

export async function synchronizeModels() {
  const requestConfig = await getHeaders();
  const url = "/licences/models/synchronization";
  return mlyApi.post(url, requestConfig);
}

export async function getActiveLicencePeriod() {
  const requestConfig = await getHeaders();
  const url = `/licences/periods/active`;
  return mlyApi.get(url, requestConfig);
}

type LicencePeriodRequestBody = {
  commentLimit?: number;
  userLimitAdmin?: number;
  userLimitAnalyst?: number;
  userLimitGuest?: number;
  notificationQuota: number;
  notificationEmail: string[] | null;
  endDate?: Date;
};

export async function updateActiveLicencePeriod(body: LicencePeriodRequestBody) {
  const requestConfig = await getHeaders();
  const url = `/licences/periods/active`;
  return mlyApi.put(url, body, requestConfig);
}

export async function updateLicencePeriodById(periodId: number, body: LicencePeriodRequestBody) {
  const requestConfig = await getHeaders();
  const url = `/licences/periods/${periodId}`;
  return mlyApi.put(url, body, requestConfig);
}

export async function getLicensePeriodById(periodId: number) {
  const requestConfig = await getHeaders();
  const url = `/licences/periods/${periodId}`;
  return mlyApi.get(url, requestConfig);
}

export async function getLicensePeriodStatistics(periodId: number) {
  const requestConfig = await getHeaders();
  const url = `/licences/periods/${periodId}/statistics`;
  return mlyApi.get(url, requestConfig);
}

export async function getLicensePeriods() {
  const requestConfig = await getHeaders();
  const url = "/licences/periods";
  return mlyApi.get(url, requestConfig);
}
