import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";
import { useGetModelName } from "hooks/useGetModelName";

import { CategorizationTypeSymbol } from "components/_icons/CategorizationTypeSymbol";
import { Text } from "components/Text";

import { Model } from "ts/models";
import { Color } from "ts/enums/color";

type Props = {
  modelOption: Model | null;
  isActive: boolean;
  isButton?: boolean;
  handleSelectModel: (selectedModel: Model | null) => void;
};

export const ModelOption = ({ modelOption, isActive, isButton, handleSelectModel }: Props) => {
  const [isMultiLine, setIsMultiLine] = useState<boolean>(false);

  const modelNameRef = useRef<HTMLDivElement>();
  const { getResource } = useResource();
  const getModelName = useGetModelName(getResource);

  useEffect(() => {
    if (modelNameRef.current) {
      setIsMultiLine(modelNameRef.current.scrollHeight > modelNameRef.current.offsetHeight);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledModelOptionContainer
      isButton={isButton}
      showHover={!isActive && !isButton}
      onClick={() => handleSelectModel(modelOption)}
    >
      <CategorizationTypeSymbol categorizationType={modelOption?.categorizationType} />
      <StyledModelName
        ref={modelNameRef}
        isButton={isButton}
        isActive={isActive && !isButton}
        isMultiLine={isMultiLine}
      >
        {getModelName(modelOption)}{" "}
        {modelOption?.isOutdated ? <Text resource="analysisSettings.outdatedModel.label" /> : ""}
      </StyledModelName>
    </StyledModelOptionContainer>
  );
};

const StyledModelOptionContainer = styled.div<{ isButton: boolean; showHover: boolean }>`
  display: flex;
  align-items: center;
  margin-top: ${({ isButton }) => !isButton && "4px"};
  padding: ${({ isButton }) => (isButton ? "4px 8px 4px 0px" : "4px 8px")};

  :hover {
    background-color: ${({ showHover }) => showHover && Color.neutral10};
    cursor: ${({ showHover }) => showHover && "pointer"};

    div {
      color: ${({ showHover }) => showHover && Color.blue50};
    }
  }
`;

const StyledModelName = styled.div<{ isButton: boolean; isActive: boolean; isMultiLine: boolean }>`
  margin-left: 10px;
  margin-bottom: 1px;
  color: ${({ isActive }) => (isActive ? Color.blue50 : Color.gray50)};
  font-weight: 400;
  max-width: ${({ isButton }) => (isButton ? "250px" : "270px")};
  white-space: ${({ isButton }) => isButton && "nowrap"};
  line-height: 1.2em;
  max-height: 2.4em;
  display: ${({ isMultiLine }) => isMultiLine && "-webkit-box"};
  -webkit-line-clamp: ${({ isMultiLine }) => isMultiLine && 2};
  -webkit-box-orient: ${({ isMultiLine }) => isMultiLine && "vertical"};
  overflow: hidden;
  text-overflow: ellipsis;
`;
