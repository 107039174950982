import React, { forwardRef, Ref, useEffect } from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import {
  NotificationReadStatus,
  NotificationType,
  TaskStatus,
  Notification,
  SocketEvent,
} from "@explorance/mly-types";

import { updateNotificationStatus } from "services/notifications";

import { NotificationCard } from "components/NotificationCard";
import { EmptyState } from "./EmptyState";
import { Placeholder } from "./Placeholder";
import { Text } from "components/Text";
import { useAppDispatch, useAppSelector } from "store";
import { showToastSuccess } from "store/toast/toastSlice";
import { setNotifications } from "store/wsStream/wsSlice";

type Props = {
  show: boolean;
  leftOffset: number;
  closeHandler: () => void;
};

export const NotificationCenter = forwardRef(
  ({ show, leftOffset, closeHandler }: Props, ref: Ref<HTMLDivElement>) => {
    const dispatch = useAppDispatch();
    const { notifications, socket } = useAppSelector((state) => state.wsStream);
    const currentUser = useAppSelector((state) => state.auth.currentUser);

    useEffect(() => {
      if (show) {
        const unreadNotifsIds = notifications
          ?.filter((n) => n.readStatus === NotificationReadStatus.Unread)
          ?.map((n) => n.notificationId);
        if (unreadNotifsIds?.length > 0) {
          updateNotificationStatus(unreadNotifsIds, NotificationReadStatus.Read).catch((err) => {
            throw err;
          });
        }
      }
    }, [show, notifications]);

    // Stream notifications
    useEffect(() => {
      if (!socket || !currentUser) return;

      const handleNotifications = (notifs: Notification[]) => {
        if (
          notifs.length > 0 &&
          notifs[0].message.status === TaskStatus.Success &&
          notifs[0].type === NotificationType.UserImport &&
          notifs[0].readStatus === NotificationReadStatus.Unread
        ) {
          dispatch(showToastSuccess("toast.usersSuccesfullyImported"));
        }
        dispatch(setNotifications(notifs));
      };

      socket.on(SocketEvent.StreamNotifications, handleNotifications);

      return () => {
        socket.off(SocketEvent.StreamNotifications, handleNotifications);
      };
    }, [socket, currentUser, dispatch]);

    return (
      <StyledNotificationCenter show={show} leftOffset={leftOffset} ref={ref}>
        <StyledTitle show={show}>
          <Text resource="notificationCenter.title" />
        </StyledTitle>
        <StyledDescription show={show}>
          <Text resource="notificationCenter.description" />
        </StyledDescription>

        {!notifications ? (
          <StyledNotificationCardList>
            <Placeholder />
          </StyledNotificationCardList>
        ) : notifications.length === 0 ? (
          <EmptyState />
        ) : (
          <StyledNotificationCardList>
            {notifications.map((notification) => (
              <NotificationCard
                key={notification.notificationId}
                notification={notification}
                notificationCenterCloseHandler={closeHandler}
              />
            ))}
          </StyledNotificationCardList>
        )}
      </StyledNotificationCenter>
    );
  }
);

NotificationCenter.displayName = "Notification Center";

const StyledNotificationCenter = styled.div<{ show: boolean; leftOffset: number }>`
  position: absolute;
  width: 480px;
  height: 100vh;
  left: ${({ show, leftOffset }) => (show ? `calc(${leftOffset}px + 1px)` : "-300px")};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  transition: ${({ show }) => (show ? "300ms ease-in-out 100ms" : "200ms ease-in-out")};
  top: 0px;
  background-color: ${Color.white};
  box-shadow: 0px 3px 10px #0000001a;
  padding: 16px 24px;
  z-index: ${ZIndexStackingContext.medium + 2};
`;

const StyledTitle = styled.div<{ show: boolean }>`
  color: ${Color.gray50};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledDescription = styled.div<{ show: boolean }>`
  font-size: 14px;
  color: ${Color.gray40};
  margin-bottom: 16px;
`;

const StyledNotificationCardList = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 81px);
  padding-bottom: 16px;
`;
