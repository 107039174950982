import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { AnalysisContext } from "context/AnalysisContext";
import { setSelectedCategories } from "context/AnalysisContext/actions";
import { useResource } from "hooks/useResource";

import { ApplyFiltersButton } from "components/_buttons/ApplyFilterButton";
import { Button } from "components/_buttons/Button";
import { CheckboxField } from "components/_inputs/CheckboxField";
import { Text } from "components/Text";
import { TextTruncator } from "components/TextTruncator";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { Color } from "ts/enums/color";

type Props = {
  onSubmit: () => void;
};

const LOAD_MORE_LIMIT = 50;

export const PinnedCommentsMenu = ({ onSubmit }: Props) => {
  const [state, dispatch, handlers] = useContext(AnalysisContext);

  const [localSelectedCategories, setLocalSelectedCategories] = useState<string[]>([]);
  const [incrementedValue, setIncrementedValue] = useState<number>(LOAD_MORE_LIMIT);

  const { getResource } = useResource();

  const handleSelectAll = () => {
    if (state.availableCategories.length === localSelectedCategories.length)
      return setLocalSelectedCategories([]);
    setLocalSelectedCategories(state.availableCategories.map((cat) => cat.name));
  };

  const handleSelectFilter = (selectedCategory: string) => {
    const filterArray = [...localSelectedCategories];
    const updatedFilters = localSelectedCategories.some((lsc) => lsc === selectedCategory)
      ? filterArray.filter((f) => f !== selectedCategory)
      : [...filterArray, selectedCategory];
    setLocalSelectedCategories(updatedFilters);
  };

  const handleApplyFilters = () => {
    dispatch(setSelectedCategories(localSelectedCategories));
    onSubmit();
  };

  useEffect(() => {
    setLocalSelectedCategories(state.selectedCategories);

    handlers.fetchAndSetCategories();
  }, []); //eslint-disable-line

  return (
    <StyledMenuContainer>
      <StyledMenuTitle>
        <Text
          resource={{
            key: "filter.pinnedComments.category.label",
            args: [localSelectedCategories.length.toString()],
          }}
        />
      </StyledMenuTitle>
      <StyledCheckboxContainer>
        <CheckboxField
          variant={
            localSelectedCategories?.length === state.availableCategories?.length
              ? CheckboxCheckedAppearance.Default
              : CheckboxCheckedAppearance.Partial
          }
          label={getResource("columnFilterSection.selectAll")}
          onChange={handleSelectAll}
          checked={localSelectedCategories?.length > 0}
        />
        {state.availableCategories.map(
          (cat, index) =>
            index <= incrementedValue && (
              <CheckboxField
                key={index}
                label={<TextTruncator value={cat.name} customWidth="250px" />}
                onChange={() => handleSelectFilter(cat.name)}
                checked={localSelectedCategories?.some((selectedCat) => selectedCat === cat.name)}
                position={"inherit"}
              />
            )
        )}
        {state.availableCategories.length > LOAD_MORE_LIMIT &&
        incrementedValue <= state.availableCategories.length ? (
          <Button
            variant={ButtonVariant.secondary}
            size={ButtonSize.sm}
            style={{ backgroundColor: Color.white, fontWeight: "normal" }}
            onClick={() => setIncrementedValue(incrementedValue + LOAD_MORE_LIMIT)}
          >
            <Text resource="button.loadMore" />
          </Button>
        ) : (
          <Button
            variant={ButtonVariant.secondary}
            size={ButtonSize.sm}
            style={{ backgroundColor: Color.white }}
            onClick={() => setIncrementedValue(LOAD_MORE_LIMIT)}
          >
            <Text resource="button.showLess" />
          </Button>
        )}
      </StyledCheckboxContainer>
      <StyledButtonContainer>
        <ApplyFiltersButton handleClick={handleApplyFilters} />
      </StyledButtonContainer>
    </StyledMenuContainer>
  );
};

const StyledMenuContainer = styled.div`
  padding: 16px;
`;

const StyledMenuTitle = styled.div`
  font-size: 0.75em;
  color: ${Color.gray20};
  font-weight: bold;
  margin-left: 2px;
  display: flex;
`;

const StyledCheckboxContainer = styled.div`
  margin-top: 10px;
  overflow-y: auto;
  max-height: 300px;
  overflow-x: hidden;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 12px;
`;
