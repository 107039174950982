import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import { useResource } from "hooks/useResource";
import { getRandomNumber } from "utils/getRandomNumber";
import { selectCommenterDemographic, selectAllCommenterDemographics } from "./helpers";

import { DropdownContainer } from "./DropdownContainer";
import { ExpandableFilterPillWrapper } from "common-layouts/FilterSelectionSection/FilterPillList/ExpandableFilterPillWrapper";
import {
  StyledDataCustomizationContainer,
  StyledDropdownContentContainer,
} from "./StyledDataCustomizationContainer";
import { ChecklistItem, NestedChecklist } from "components/NestedChecklist";
import { Pill, PillSize } from "components/_pills/Pill";
import { Tooltip } from "components/Tooltip";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

import { DemographicFilterHeader } from "ts/filters/demographicFilter";
import { Color } from "ts/enums/color";

type DropdownContentProps = {
  allDemographics: DemographicFilterHeader[];
  selectedDemographics: DemographicFilterHeader[];
  updateSelectedDemographics: (updatedItem: ChecklistItem) => void;
};

const DropdownContent = ({
  allDemographics,
  selectedDemographics,
  updateSelectedDemographics,
}: DropdownContentProps) => {
  const { getResource } = useResource();

  return (
    <StyledDropdownContentContainer>
      <StyledFieldsContainer>
        <NestedChecklist
          id={getRandomNumber()}
          checklistItem={{
            name: getResource("analysisSettings.commentColumn.selectAll"),
            values: allDemographics.map((d) => d?.name),
          }}
          selectedValues={[
            {
              name: getResource("analysisSettings.commentColumn.selectAll"),
              values: selectedDemographics.map((sd) => sd?.name),
            },
          ]}
          isExpandable={false}
          hasTooltip={false}
          updateValues={updateSelectedDemographics}
        />
      </StyledFieldsContainer>
    </StyledDropdownContentContainer>
  );
};

type Props = {
  savedDemHeaders: DemographicFilterHeader[];
  analysisDemographicHeaders: DemographicFilterHeader[];
  updateSavedDemHeaders: (updatedHeaders: DemographicFilterHeader[]) => void;
};

// main component
export const VisibleDemographicsSelection = ({
  savedDemHeaders,
  analysisDemographicHeaders,
  updateSavedDemHeaders,
}: Props) => {
  const [localSelectedDemographics, setLocalSelectedDemographics] = useState<
    DemographicFilterHeader[]
  >([]);

  const { getResource } = useResource();

  const isAllSelected = analysisDemographicHeaders.every((sd) =>
    localSelectedDemographics.find((d) => d.id === sd.id)
  );

  const updateSelectedDemographics = (updatedItem: ChecklistItem) => {
    const { values } = updatedItem;
    const selectedDemographicsCopy = cloneDeep(localSelectedDemographics);
    let updatedDemographics: DemographicFilterHeader[];
    if (values.length === 1) {
      updatedDemographics = selectCommenterDemographic(
        analysisDemographicHeaders.find((d) => d?.name === values[0]),
        selectedDemographicsCopy
      );
    } else {
      updatedDemographics = selectAllCommenterDemographics(
        isAllSelected,
        selectedDemographicsCopy,
        analysisDemographicHeaders
      );
    }
    setLocalSelectedDemographics(updatedDemographics);
  };

  const getButtonLabel = (): string => {
    const demographicsLength = savedDemHeaders.length;
    if (demographicsLength === 0) return getResource("dropdown.none");

    const resourceKey =
      demographicsLength === 1
        ? "overview.analysisSettings.selectedColumnsSingular"
        : "overview.analysisSettings.selectedColumns";

    return getResource(resourceKey, demographicsLength);
  };

  const applyFilters = () => updateSavedDemHeaders(localSelectedDemographics);

  const deletePill = (pillId: number) => {
    const copy = cloneDeep(savedDemHeaders);
    const updatedDemArray = copy.filter((d) => d.id !== pillId);
    setLocalSelectedDemographics(updatedDemArray);
    updateSavedDemHeaders(updatedDemArray);
  };

  const deleteAllFilters = () => {
    setLocalSelectedDemographics([]);
    updateSavedDemHeaders([]);
  };

  // set local state value equal to saved value on initial load
  useEffect(() => {
    if (savedDemHeaders.length !== 0) {
      setLocalSelectedDemographics(savedDemHeaders);
    }
  }, [savedDemHeaders]); // eslint-disable-line

  return (
    <>
      <StyledTitleContainer>
        <b>
          <Text resource="sharing.permissionForm.commentersDemographics.title" />
        </b>
        <div data-tooltip-id="commenters-column-demographics-tooltip" style={{ display: "flex" }}>
          <Icon type={IconType.info} size={10} />

          <Tooltip
            tooltipId="commenters-column-demographics-tooltip"
            content={<Text resource="sharing.permissionForm.commentersDemographics.tooltip" />}
          />
        </div>
      </StyledTitleContainer>
      <StyledDataCustomizationContainer>
        <DropdownContainer
          buttonLabel={getButtonLabel()}
          renderDropdownContent={() => (
            <DropdownContent
              allDemographics={analysisDemographicHeaders}
              selectedDemographics={localSelectedDemographics}
              updateSelectedDemographics={updateSelectedDemographics}
            />
          )}
          handleApplyFilters={applyFilters}
          resetLocalChanges={() => {
            setLocalSelectedDemographics(savedDemHeaders);
          }}
        />
        {savedDemHeaders.length > 0 && (
          <ExpandableFilterPillWrapper
            showDeleteFilters={savedDemHeaders.length > 0}
            deleteButtonLabelKey="sharing.pill.clearSelection"
            deleteFilters={deleteAllFilters}
          >
            {savedDemHeaders.map((cd) => (
              <Pill
                key={cd.id}
                size={PillSize.sm}
                border={`1px solid ${Color.indigo50}`}
                style={{ fontSize: "14px", fontWeight: "bold" }}
                onDeletePill={() => deletePill(cd.id)}
              >
                {cd?.name}
              </Pill>
            ))}
          </ExpandableFilterPillWrapper>
        )}
      </StyledDataCustomizationContainer>
    </>
  );
};

const StyledFieldsContainer = styled.div`
  padding: 6px 0px 0px 4px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  gap: 2px;
  padding-bottom: 6px;
  font-size: 15px;
`;
