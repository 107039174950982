import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

import { useAppSelector, useAppDispatch } from "store";
import { routes } from "routes";

import { patchUser } from "services/users";
import { setCurrentUser } from "store/auth/authSlice";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { Tooltip } from "components/Tooltip";
import { UserAvatar } from "components/UserAvatar.tsx";
import { LanguageSelect } from "components/LanguageSelect";
import { Text } from "components/Text";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { LanguageCode } from "@explorance/mly-types";
import { AvatarSize } from "ts/enums/avatarSize";

export const ProfileCard = () => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const [showEmailTooltip, setShowEmailTooltip] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<LanguageCode>(LanguageCode.Eng);

  const dispatch = useAppDispatch();

  const emailRef = useRef<HTMLDivElement>();
  const name = `${currentUser.firstname} ${currentUser.lastname}`;

  const handleSwitchLanguage = async (option) => {
    setCurrentLanguage(option);
    await patchUser(currentUser.id, { preferredLanguage: option });
    dispatch(setCurrentUser({ ...currentUser, preferredLanguage: option }));
  };

  useEffect(() => {
    setCurrentLanguage(currentUser.preferredLanguage);
  }, [currentUser.preferredLanguage]);

  useEffect(() => {
    if (emailRef?.current?.scrollWidth > emailRef?.current?.offsetWidth) setShowEmailTooltip(true);
  }, []);

  return (
    <StyledProfileContainer>
      <StyledProfileCard>
        <StyledAvatarContainer className="fade-in">
          <UserAvatar
            firstName={currentUser.firstname}
            lastName={currentUser.lastname}
            userId={currentUser.id}
            avatarSize={AvatarSize.md}
          />
        </StyledAvatarContainer>
        <div className="fade-in">
          <StyledUserTitle>{name}</StyledUserTitle>
          <StyledUserDescription>
            <Text resource={`roleType.${currentUser.roleType}`} />
          </StyledUserDescription>
          <StyledEmail ref={emailRef} data-tooltip-id="email-tooltip">
            {currentUser.email}
          </StyledEmail>
          {showEmailTooltip && <Tooltip tooltipId="email-tooltip" content={currentUser.email} />}
          <Button
            href={routes.updateProfilePage(currentUser.id)}
            variant={ButtonVariant.secondary}
            size={ButtonSize.sm}
          >
            <Text resource="button.goToProfile" />
            <Icon
              type={IconType.chevronRight}
              color={Color.blue50}
              size={16}
              style={{ marginTop: 2, marginLeft: 2 }}
            />
          </Button>
        </div>
      </StyledProfileCard>
      <StyledLanguageExtension>
        <Text resource="profileCard.languageDropdown.label" />
        <LanguageSelect
          currentLanguage={currentLanguage}
          handleSelectLanguage={handleSwitchLanguage}
        />
      </StyledLanguageExtension>
    </StyledProfileContainer>
  );
};

const StyledProfileContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    width: 430px;
  }

  @media (min-width: 1366px) {
    width: 370px;
  }
`;

const StyledLanguageExtension = styled.div`
  background-color: ${Color.sky15};
  height: 49px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid ${Color.indigo10};
  border-radius: 0px 0px 5px 5px;
  padding: 8px 8px 8px 12px;
  gap: 8px;

  p {
    font-size: "14px";
    color: ${Color.gray50};
  }
`;

const StyledProfileCard = styled.div`
  display: flex;
  align-items: center;
  background-color: ${Color.white};
  border-radius: 5px 5px 0px 0px;
  padding: 24px;
  gap: 20px;
  height: 190px;
  position: relative; // keep to avoid neighbor shadow to overlap
`;

const StyledAvatarContainer = styled.div`
  @media (max-width: 1365px) {
    margin: 0 16px 0 18px;
  }
`;

const StyledUserTitle = styled.div`
  margin-top: 6px;
  font-size: 20px;
  font-weight: bold;
  color: ${Color.gray50};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 960px) {
    max-width: 230px;
  }

  @media (min-width: 1366px) {
    max-width: 154px;
  }
`;

const StyledUserDescription = styled.div`
  font-size: 14px;
  margin: 4px 0 16px 0;
  color: ${Color.gray30};
`;

const StyledEmail = styled.div`
  font-size: 0.875em;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 960px) {
    max-width: 300px;
  }

  @media (min-width: 1366px) {
    max-width: 176px;
  }
`;
