import styled from "styled-components";

import { Color } from "ts/enums/color";

type StyledProps = {
  top: number;
  isSharingPreview: boolean;
};

const StyledTopicsTable = styled.table`
  font-family: Lato, sans-serif;
  width: 100%;
  text-align: left;
  font-size: 0.975em;
  border-spacing: 0px;
  table-layout: fixed;

  th,
  td {
    padding: 16px 12px;
    color: ${Color.gray50};

    &:first-child:not(.loading) {
      padding-left: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:nth-child(2):not(.loading) {
      border-right: 1px solid ${Color.blue20};
    }
  }

  th {
    background-color: ${Color.sky20};
    border-top: 1px solid ${Color.blue20};
    border-bottom: 1px solid ${Color.blue20};
    /* remove list table styling used to keep borders on TH */
    box-shadow: none;
  }

  td {
    font-size: 0.85em;
  }
`;

export const StyledSentimentsViewTable = styled(StyledTopicsTable)<StyledProps>`
  margin-top: ${({ isSharingPreview }) => (isSharingPreview ? "40px" : "0")};
  th {
    top: ${(props) => props.top}px;

    div > span:not(:first-child) {
      margin-left: 6px;
    }
  }

  @media (min-width: 1080px) {
    th {
      &:first-child {
        width: 180px;
        padding-left: 16px;
      }

      &:nth-child(2) {
        width: 112px;
      }

      &:nth-child(3) {
        width: 92px;
      }

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        width: 65px;
        padding: 15px 4px;
      }

      .sentiment-label {
        width: 75px;
        justify-content: center;

        span {
          display: none;
        }
      }

      &:nth-child(9) {
        width: 105px;
      }
    }
  }

  @media (min-width: 1366px) {
    th {
      &:nth-child(5),
      &:nth-child(9) {
        width: 105px;
      }

      &:nth-child(3) {
        width: 100px;
      }

      &:nth-child(4) {
        padding: 16px 12px;
        width: 100px;
      }

      &:nth-child(5) {
        padding: 16px 12px;
      }

      &:nth-child(6) {
        padding: 16px 12px;
        width: 95px;
      }

      &:nth-child(7) {
        padding: 16px 12px;
        width: 85px;
      }

      &:nth-child(8) {
        padding: 16px 12px;
        width: 125px;
      }

      .sentiment-label {
        width: fit-content;

        span {
          display: revert;
        }
      }
    }

    td {
      padding: 16px 12px;
    }
  }
`;

export const StyledRecommendationsViewTable = styled(StyledTopicsTable)<StyledProps>`
  margin-top: ${({ isSharingPreview }) => (isSharingPreview ? "40px" : "0")};
  th {
    top: ${(props) => props.top}px;

    div > span:not(:first-child) {
      margin-left: 6px;
    }
  }

  @media (min-width: 1080px) {
    th {
      &:first-child {
        width: 220px;
        padding-left: 16px;
      }

      &:nth-child(2) {
        width: 125px;
      }

      &:nth-child(3) {
        width: 160px;
        padding-left: 24px;
      }

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9) {
        width: 67px;
      }

      .recommendation-label {
        width: 60px;
        justify-content: center;

        span {
          display: none;
        }
      }
    }
  }

  @media (min-width: 1366px) {
    th {
      &:first-child {
        width: 150px;
      }

      &:nth-child(2) {
        width: 90px;
      }

      &:nth-child(3) {
        width: 114px;
      }

      &:nth-child(4) {
        width: 80px;
      }

      &:nth-child(5) {
        width: 86px;
      }

      &:nth-child(6) {
        width: 92px;
      }

      &:nth-child(7) {
        width: 70px;
      }

      &:nth-child(8) {
        width: 82px;
      }

      &:nth-child(9) {
        width: 77px;
      }

      .recommendation-label {
        width: fit-content;
        justify-content: initial;

        span {
          display: revert;
        }
      }
    }
  }
`;
