import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  fetchDiscussionThreadNotes,
  submitDiscussionThreadNote,
  deleteSubmittedNote,
  editNote,
} from "./thunks";
import { Comment } from "ts/comments";

export type DiscussionThreadNoteType = {
  id: number;
  note: string;
  createdBy: string;
  createdDate: string;
  firstname: string;
  lastname: string;
  isEdited: boolean;
};

interface DiscussionThreadState {
  comment: Comment;
  userInput: string;
  showCharacterCount: boolean;
  inputHeight: number;
  discussionThreadNotes: DiscussionThreadNoteType[];
  isLoading: boolean;
  editedInput: string;
  currentEditedNoteId: number;
}

const INITIAL_INPUT_HEIGHT = 34;
const EXPANDED_INPUT_HEIGHT = 85;

const initialState: DiscussionThreadState = {
  comment: null,
  userInput: "",
  showCharacterCount: false,
  inputHeight: INITIAL_INPUT_HEIGHT,
  discussionThreadNotes: [],
  isLoading: false,
  editedInput: "",
  currentEditedNoteId: null,
};

const discussionThreadSlice = createSlice({
  name: "discussionThreadSlice",
  initialState,
  reducers: {
    setComment: (state, action: PayloadAction<Comment>) => {
      state.comment = action.payload;
    },
    setCurrentEditedNoteId: (state, action: PayloadAction<number>) => {
      state.currentEditedNoteId = action.payload;
    },

    setUserInput: (state, action: PayloadAction<string>) => {
      state.userInput = action.payload;
    },
    setShowCharacterCount: (state, action: PayloadAction<boolean>) => {
      state.showCharacterCount = action.payload;
    },
    setInitialInputHeight: (state) => {
      state.inputHeight = INITIAL_INPUT_HEIGHT;
    },
    setExpandedInputHeight: (state) => {
      state.inputHeight = EXPANDED_INPUT_HEIGHT;
    },
    setEditedInput: (state, action: PayloadAction<string>) => {
      state.editedInput = action.payload;
    },
    resetInput: (state) => {
      state.inputHeight = INITIAL_INPUT_HEIGHT;
      state.showCharacterCount = false;
      state.userInput = "";
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDiscussionThreadNotes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDiscussionThreadNotes.fulfilled, (state, action) => {
      state.discussionThreadNotes = action.payload;
      state.isLoading = false;
    });
    builder.addCase(submitDiscussionThreadNote.fulfilled, (state, action) => {
      state.discussionThreadNotes.push(action.payload);
    });
    builder.addCase(deleteSubmittedNote.fulfilled, (state, action) => {
      state.discussionThreadNotes.splice(action.payload, 1);
    });
    builder.addCase(editNote.fulfilled, (state, action) => {
      state.discussionThreadNotes[action.payload.index].note = action.payload.updatedNote;
      state.discussionThreadNotes[action.payload.index].isEdited = true;
    });
  },
});

export const {
  setComment,
  setUserInput,
  setShowCharacterCount,
  setInitialInputHeight,
  setExpandedInputHeight,
  setEditedInput,
  clearState,
  setCurrentEditedNoteId,
  resetInput,
} = discussionThreadSlice.actions;

export default discussionThreadSlice.reducer;
