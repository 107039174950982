import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";
import { Notification } from "@explorance/mly-types";

type wsState = {
  socket: Socket;
  notifications: Notification[];
};

const initialState: wsState = {
  socket: null,
  notifications: [],
};

const wsSlice = createSlice({
  name: "ws",
  initialState,
  reducers: {
    setSocket: (state, action: PayloadAction<any>) => {
      state.socket = action.payload;
    },
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload;
    },
  },
});

export const { setSocket, setNotifications } = wsSlice.actions;

export default wsSlice.reducer;
