import React, { useState } from "react";
import { Tooltip } from "components/Tooltip";
import styled from "styled-components";
import { SharingUser } from "ts/sharing";

type Props = {
  user: SharingUser;
};

export const LastNameCell = ({ user }: Props) => {
  const [showLastNameTooltip, setShowLastNameTooltip] = useState<boolean>();

  const handleHoverModelName = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) setShowLastNameTooltip(true);
  };

  return (
    <>
      <StyledLastnameCell key={user.id}>
        <span
          onMouseOver={handleHoverModelName}
          onMouseOut={() => setShowLastNameTooltip(false)}
          data-tooltip-id={`${user.id}-lastname-tooltip`}
        >
          {user.lastname}
        </span>
      </StyledLastnameCell>

      {showLastNameTooltip && (
        <Tooltip tooltipId={`${user.id}-lastname-tooltip`} content={user.lastname} isOpen />
      )}
    </>
  );
};

const StyledLastnameCell = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  > span {
    max-width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 15px;
  }
`;
