import React from "react";
import styled from "styled-components";

import { LoadingDots } from "components/LoadingDots";

export const LoadingDotsScreen = () => {
  return (
    <StyledLoadingDotsContainer>
      <LoadingDots />
    </StyledLoadingDotsContainer>
  );
};

const StyledLoadingDotsContainer = styled.div`
  margin-top: 25%;
  margin-left: 50%;
`;
