import React from "react";
import styled from "styled-components";

import { PaginationPlaceholder } from "components/Pagination/Placeholder";
import { Skeleton } from "components/Skeleton";
import { Color } from "ts/enums/color";

type Props = {
  showCreateButton: boolean;
  showFilterButton: boolean;
  showGoBack?: boolean;
  applyMorePadding: boolean;
  showNavigateToButton?: boolean;
  buttonPlaceholderWidth?: number;
  searchInputPlaceholderWidth?: number;
  isSingleRowHeader?: boolean;
};

export const ListPageHeaderPlaceholder = ({
  showCreateButton,
  showFilterButton,
  showGoBack,
  showNavigateToButton,
  applyMorePadding,
  buttonPlaceholderWidth,
  searchInputPlaceholderWidth,
  isSingleRowHeader = false,
}: Props) => (
  <StyledPlaceholderContainer isSingleRowHeader={isSingleRowHeader}>
    {showGoBack && (
      <StyledGoBackContainer>
        <Skeleton width={200} height={20} />
      </StyledGoBackContainer>
    )}
    <StyledPageHeaderTop applyMorePadding={applyMorePadding}>
      <StyledSearchRowPlaceholder className="pulse">
        <Skeleton width={111} height={10} />
        <Skeleton
          width={searchInputPlaceholderWidth || 400}
          height={35}
          backgroundColor={Color.sky15}
        />
        <Skeleton width={105} height={10} />
      </StyledSearchRowPlaceholder>
      <StyledActionButtonsContainer>
        {showNavigateToButton && (
          <StyledCreateButtonContainer>
            <Skeleton width={buttonPlaceholderWidth || 138} height={45} />
          </StyledCreateButtonContainer>
        )}
        {showCreateButton && (
          <StyledCreateButtonContainer>
            <Skeleton width={buttonPlaceholderWidth || 138} height={45} />
          </StyledCreateButtonContainer>
        )}
      </StyledActionButtonsContainer>
    </StyledPageHeaderTop>

    <StyledPageHeaderBottom className="pulse" isSingleRowHeader={isSingleRowHeader}>
      {showFilterButton && (
        <Skeleton width={115.22} height={36} backgroundColor={Color.neutral10} />
      )}
      <PaginationPlaceholder />
    </StyledPageHeaderBottom>
  </StyledPlaceholderContainer>
);

const StyledPlaceholderContainer = styled.div<{ isSingleRowHeader: boolean }>`
  display: ${({ isSingleRowHeader }) => isSingleRowHeader && "flex"};
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ isSingleRowHeader }) => isSingleRowHeader && "6px"};
`;

const StyledPageHeaderTop = styled.div<{ applyMorePadding: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ applyMorePadding }) => (applyMorePadding ? "36px 0 24px" : "15px 0 24px")};
`;

const StyledPageHeaderBottom = styled.div<{ isSingleRowHeader: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  > * {
    &:last-child {
      margin-left: auto;
    }
  }
`;

const StyledSearchRowPlaceholder = styled.div`
  display: flex;
  align-items: center;
  > * {
    :nth-child(1) {
      margin-right: 17px;
    }
    :nth-child(2) {
      margin-right: 17px;
      border: 1px solid ${Color.blue20};
      border-radius: 2px;
    }
    :nth-child(1),
    :nth-child(3) {
      border-radius: 10px;
    }
  }
`;

const StyledCreateButtonContainer = styled.div`
  margin-left: auto;
`;

const StyledGoBackContainer = styled.div`
  margin-top: 35px;
`;

const StyledActionButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;
