import { mlyApi, getHeaders } from "config/mlyApi";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { WidgetConfiguration, WidgetGroup } from "ts/widget";
import {
  DemographicFilter as ApiDemographicFilter,
  WidgetSize,
  WidgetType,
} from "@explorance/mly-types";
import { Range } from "ts/range";

interface UpdateWidgetProps {
  title?: string;
  type?: WidgetType;
  configuration?: WidgetConfiguration;
  size?: WidgetSize;
  filters: {
    modelId?: number;
    threshold?: number;
    topics?: string[];
    statistics?: string[];
    demographics?: ApiDemographicFilter[];
    categories?: string[];
    isVirtual: boolean;
    selectedColumns?: string[];
    alertRange: Range;
  };
}

export async function updateWidget(widgetId: number, body: UpdateWidgetProps) {
  const requestConfig = await getHeaders();
  const url = `/widgets/${widgetId}`;
  return mlyApi.patch<any>(url, body, requestConfig);
}

export async function deleteWidgetById(widgetId: number) {
  const requestConfig = await getHeaders();
  const url = `/widgets/${widgetId}`;
  return mlyApi.delete<any>(url, requestConfig);
}

export async function getWidgetStatisticsByWidgetId(
  widgetId: number,
  virtualModelId?: number,
  userId?: number,
  sharingId?: string
) {
  const requestConfig = await getHeaders();
  const baseUrl = `/widgets/${widgetId}/statistics`;
  const url =
    baseUrl +
    getUrlQueryString({ virtual_model_id: virtualModelId, user_id: userId, sharing_id: sharingId });
  return mlyApi.get(url, requestConfig);
}

export async function createWidgetGroup(analysisId: number, title: string) {
  const requestConfig = await getHeaders();
  const baseUrl = `/analysis/${analysisId}/widget-groups`;
  return mlyApi.post(baseUrl, { title }, requestConfig);
}

export async function renameWidgetGroup(widgetId: number, title: string) {
  const requestConfig = await getHeaders();
  const baseUrl = `widget-groups/${widgetId}`;
  return mlyApi.patch(baseUrl, { title }, requestConfig);
}

export async function deleteWidgetGroup(groupId: number) {
  const requestConfig = await getHeaders();
  const baseUrl = `widget-groups/${groupId}`;
  return mlyApi.delete(baseUrl, requestConfig);
}

export async function setGroupExpanded(groupId: number, isExpanded: boolean) {
  const requestConfig = await getHeaders();
  const baseUrl = `widget-groups/${groupId}/user-preference`;
  return mlyApi.patch(baseUrl, { isExpanded }, requestConfig);
}

export async function saveWidgetOrdering(analysisId: number, widgetGroups: WidgetGroup[]) {
  const requestConfig = await getHeaders();
  const baseUrl = `/analysis/${analysisId}/widgets`;
  return mlyApi.patch(baseUrl, { widgetGroups }, requestConfig);
}
