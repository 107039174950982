import {
  BreakdownTypes,
  DemographicFilterMethod,
  SentimentType,
  WidgetType,
} from "@explorance/mly-types";

import { WidgetStatistic } from "ts/widgetStatistics";
import { DemographicFilter } from "ts/filters/demographicFilter";
import { HandleClickCommentsParams, HandleClickTopicsParams } from "components/Widget";
import { getViewFromWidgetClick } from "../L1/helpers";

type Props = {
  id: number;
  fullPath: string;
  index: number;
  widgetType: WidgetType;
  l1Data: WidgetStatistic;
  l1BreakdownType: BreakdownTypes;
  l1DemographicData: DemographicFilter[];
  demographicValue: string;
  name: string;
  value: number;
  isL2LabelOrBarClicked?: boolean;
  onClickComments: (params: HandleClickCommentsParams) => void;
  onClickTopics: (params: HandleClickTopicsParams) => void;
};

const getL2DemographicData = (
  id: number,
  name: string,
  count: number,
  demographicValue: string
): DemographicFilter => {
  return {
    id: id,
    method: DemographicFilterMethod.List,
    name: name,
    listValues: [demographicValue],
    totalCount: count,
    searchValue: [],
  };
};

export const l2NavigationHandler = ({
  id,
  fullPath,
  index,
  widgetType,
  l1Data,
  l1BreakdownType,
  l1DemographicData,
  demographicValue,
  name,
  value: count,
  isL2LabelOrBarClicked,
  onClickComments,
  onClickTopics,
}: Props) => {
  const l2BreakdownType = l1Data.details.type;
  const l2Data = l1Data.details.data;
  if (l2Data[index].isOthers) return;

  const l2DemographicData = getL2DemographicData(id, name, count, demographicValue);
  const navigationPayload = {
    ...(l1BreakdownType === BreakdownTypes.Topics && {
      topic: [{ id: l1Data?.id, parentId: null, fullPath: l1Data.fullPath }],
      ...(widgetType === WidgetType.MostPositive && { stats: [SentimentType.positive] }),
      ...(widgetType === WidgetType.WhatToImprove && { stats: [SentimentType.negative] }),
    }),
    ...(l1BreakdownType === BreakdownTypes.SentimentType &&
      l2BreakdownType !== BreakdownTypes.Topics && {
        stats: [l1Data.dominantSentiment.sentimentType],
      }),
    ...(l1BreakdownType === BreakdownTypes.Demographics && {
      breakdownDemographics: l1DemographicData,
    }),
    ...(l1BreakdownType === BreakdownTypes.RecommendationType && {
      stats: [l1Data.name],
    }),
    ...(l2BreakdownType === BreakdownTypes.Topics && {
      topic: [{ id, parentId: null, fullPath }],
    }),
    ...(l2BreakdownType === BreakdownTypes.SentimentType && { stats: [l2Data[index]?.value] }),
    ...(l2BreakdownType === BreakdownTypes.Demographics && {
      breakdownDemographics: [l2DemographicData],
    }),
    ...(l2BreakdownType === BreakdownTypes.Demographics &&
      l1BreakdownType === BreakdownTypes.Demographics && {
        breakdownDemographics: [
          getL2DemographicData(id, name, count, demographicValue),
          ...l1DemographicData,
        ],
      }),
    ...(l2BreakdownType === BreakdownTypes.RecommendationType && {
      stats: [l2Data[index].value],
    }),
    ...(WidgetType.PrioritizedRecommendations === widgetType && {
      stats: [l1Data.recommendationType],
    }),
    ...(getViewFromWidgetClick({ widgetType, l1BreakdownType, l2BreakdownType }) && {
      view: getViewFromWidgetClick({
        widgetType,
        l1BreakdownType,
        l2BreakdownType,
        isL2LabelOrBarClicked,
      }),
    }),
  };

  l2BreakdownType === BreakdownTypes.Topics && isL2LabelOrBarClicked
    ? onClickTopics && onClickTopics(navigationPayload)
    : onClickComments && onClickComments(navigationPayload);
};
