import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "store";
import { updateModelActivationStatus } from "store/licenseManagement/thunks";

import { ToggleSwitch } from "components/_inputs/ToggleSwitch";
import { ModelLicense } from "ts/licensing";

type Props = {
  model: ModelLicense;
};

export const ModelToggleSwitch = ({ model }: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.modelManagement);

  const [isModelActive, setIsModelActive] = useState<boolean>();
  const [activationLabelKey, setActivationLabelKey] = useState<string>("");

  const updateModelInfo = (status: boolean) => {
    setActivationLabelKey(
      status ? "licensing.table.toggle.active" : "licensing.table.toggle.inactive"
    );
    setIsModelActive(status);
  };

  const handleUpdateActivationStatus = (status: boolean) => {
    dispatch(updateModelActivationStatus({ modelId: model.modelId, status }));
    updateModelInfo(status);
  };

  useEffect(() => {
    updateModelInfo(model.isActivated);
  }, [model]); // eslint-disable-line

  if (isModelActive === undefined && activationLabelKey.length === 0) {
    return null;
  }

  return (
    <ToggleSwitch
      labelKey={activationLabelKey}
      checked={isModelActive}
      showLabelToRight={true}
      isPending={state.isLoading}
      onChange={handleUpdateActivationStatus}
    />
  );
};
