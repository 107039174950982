import styled from "styled-components";
import { Color } from "ts/enums/color";

export const StyledButton = styled.button<{
  border: string;
  borderHover: string;
  borderActive: string;
  borderDisabled: string;
  backgroundColor: Color;
  backgroundColorHover: Color;
  backgroundColorActive: Color;
  backgroundColorDisabled: Color;
  borderFocus: Color;
  color: Color;
  colorActive: Color;
  colorDisabled: Color;
  loading: boolean;
  active: boolean;
}>`
  position: relative;
  font-weight: bold;
  border-radius: 2px;
  transition: 0.15s background-color, 0.15s color, 0.15s border-color;

  /* Styles from variant */
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border: ${({ border }) => border};

  > :not(.no-hide-loading-dots) {
    opacity: ${({ loading }) => (loading ? 0 : 1)};
  }

  svg:not(.is-stroke) * {
    fill: ${({ color }) => color};
  }

  svg.is-stroke * {
    stroke: ${({ color }) => color};
  }

  &:hover {
    background-color: ${({ backgroundColorHover }) => backgroundColorHover};
    border: ${({ borderHover }) => borderHover};
  }

  &:focus {
    border: ${({ borderFocus }) => borderFocus};
  }

  &:disabled {
    color: ${({ colorDisabled, loading }) => (loading ? "transparent" : colorDisabled)};
    background-color: ${({ backgroundColorDisabled }) => backgroundColorDisabled};
    border: ${({ borderDisabled }) => borderDisabled};

    svg:not(.is-stroke) * {
      fill: ${({ colorDisabled }) => colorDisabled};
    }

    svg.is-stroke * {
      stroke: ${({ colorDisabled }) => colorDisabled};
    }
  }

  /* Handle active state styling */
  ${({ active, colorActive, backgroundColorActive, borderActive }) =>
    active &&
    `
    color: ${colorActive};
    background-color: ${backgroundColorActive};
    border: ${borderActive};

    svg:not(.is-stroke) * {
      fill: ${Color.gray50};
    }

    svg.is-stroke * {
      stroke: ${Color.gray50};
    }
  `}
`;
