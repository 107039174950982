import React from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "store";
import { setCurrentPage } from "store/customModels/customModelListSlice";
import { fetchCustomModels } from "store/customModels/thunks";

import { CreateNewModel } from "./CreateNewModel";
import { Pagination } from "components/Pagination";
import { SearchRow } from "components/SearchRow";
import { StyledTablePageHeader, StyledTablePageTitle } from "components/Table/TablePageHeader";
import { Text } from "components/Text";

export const CustomModelsPageHeader = () => {
  const dispatch = useAppDispatch();

  const state = useAppSelector((state) => state.customModelList);

  const onSearch = () => {
    dispatch(setCurrentPage(1));
    dispatch(fetchCustomModels());
  };

  const handlePageSelection = (page: number) => {
    dispatch(setCurrentPage(page));
    dispatch(fetchCustomModels());
  };

  return (
    <>
      <StyledTablePageHeader>
        <StyledTopHeader>
          <StyledSearchContainer>
            <StyledTablePageTitle>
              <Text resource="customModels.title" />
            </StyledTablePageTitle>
            <SearchRow
              currentCount={state.currentModelCount}
              totalCount={state.totalModelCount}
              dataType="searchBar.dataType.customModels"
              onSearch={onSearch}
            />
          </StyledSearchContainer>
          <CreateNewModel />
        </StyledTopHeader>
      </StyledTablePageHeader>
      <StyledBottomHeader>
        {state.currentModelCount > 0 && (
          <StyledPaginationContainer>
            <Pagination
              currentPage={state.currentPage}
              currentItemsCount={state.currentModelCount}
              handlePageSelection={handlePageSelection}
            />
          </StyledPaginationContainer>
        )}
      </StyledBottomHeader>
    </>
  );
};

const StyledTopHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledSearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBottomHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  margin-bottom: 16px;
`;

export const StyledPaginationContainer = styled.div`
  margin-left: auto;
`;
