import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";

import {
  setModalOpen,
  setRedactionVersionId,
  setSelectedRedactionVersion,
  setSelectedVersionForDelete,
} from "store/redaction/redactionModalSlice";
import {
  changeAnalysisRedactionVersion,
  fetchAnalysisRedactionVersions,
} from "store/redaction/thunks";

import { CollapsibleSection } from "components/CollapsibleSection";
import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";
import { RadioGroup } from "components/_inputs/RadioGroup";
import { Icon, IconType } from "components/_icons/Icon";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { RadioOption } from "ts/radio";
import { RedactionVersionModal } from "pages/analysis/[id]/overview/_layouts/Redactions/RedactionVersionModal";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";
import { deleteRedactionVersion } from "services/redaction";
import { AnalysisContext } from "context/AnalysisContext";
import { setAnalysisDetails } from "context/AnalysisContext/actions";
import { RedactionVersion } from "ts/redaction";

export const Redactions = () => {
  const [state, analysisContextDispatch] = useContext(AnalysisContext);
  const redactionModalState = useAppSelector((state) => state.redactionModal);
  const dispatch = useAppDispatch();

  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);

  const handleDeleteRedactionVersion = async () => {
    try {
      await deleteRedactionVersion(Number(redactionModalState.selectedVersionForDelete.value));
      dispatch(setSelectedVersionForDelete(null));
      dispatch(fetchAnalysisRedactionVersions(analysisId));
    } finally {
      if (
        redactionModalState.selectedVersionForDelete.value ===
        redactionModalState.selectedRedactionVersion.value
      ) {
        dispatch(setSelectedRedactionVersion(null));
      }
    }
  };

  const handleChangeAnalysisVersion = (versionOption: RadioOption) => {
    dispatch(
      changeAnalysisRedactionVersion({
        versionOption,
        analysisId,
      })
    );
    analysisContextDispatch(
      setAnalysisDetails({ ...state.analysisDetails, redactionVersionId: versionOption.value })
    );
  };

  const getRedactionActionButtons = (option: RadioOption) => {
    return (
      <StyledActionSection>
        <Button
          variant={ButtonVariant.light}
          size={ButtonSize.sm}
          onClick={() => dispatch(setSelectedVersionForDelete(option))}
        >
          <StyledButtonContent>
            <Icon type={IconType.trash} size={10} />
            <Text resource="button.delete" />
          </StyledButtonContent>
        </Button>
        <Button
          variant={ButtonVariant.light}
          size={ButtonSize.sm}
          onClick={() => {
            dispatch(setRedactionVersionId(Number(option.value)));
            dispatch(setModalOpen(true));
          }}
        >
          <StyledButtonContent>
            <Icon type={IconType.searchFile} size={10} />
            <Text resource="button.edit" />
          </StyledButtonContent>
        </Button>
      </StyledActionSection>
    );
  };

  const initRedactionModalState = async () => {
    const analysisRedactionVersion = state.analysisDetails.redactionVersionId;
    const { payload: versions } = await dispatch(fetchAnalysisRedactionVersions(analysisId));
    dispatch(
      setSelectedRedactionVersion(
        analysisRedactionVersion
          ? {
              label: (versions as RedactionVersion[]).find(
                (version) => version.id === Number(analysisRedactionVersion)
              )?.name,
              value: `${analysisRedactionVersion}`,
            }
          : null
      )
    );
  };

  useEffect(() => {
    if (!state.loadingAnalysisDetails) initRedactionModalState();
  }, [analysisId, state.loadingAnalysisDetails, dispatch]); //eslint-disable-line

  if (state.loadingAnalysisDetails) {
    return null;
  }

  return (
    <>
      <ActionConfirmationModal
        isOpen={!!redactionModalState.selectedVersionForDelete}
        title={
          <Text
            resource={{
              key: "modal.deleteWithName.title",
              args: [redactionModalState.selectedVersionForDelete?.label as string],
            }}
          />
        }
        caption={<Text resource="modal.deleteRedaction.caption" />}
        actionButtonLabel={<Text resource="button.delete" />}
        onClose={() => dispatch(setSelectedVersionForDelete(null))}
        onCancel={() => dispatch(setSelectedVersionForDelete(null))}
        onClickActionButton={handleDeleteRedactionVersion}
      />
      {redactionModalState.isModalOpen && <RedactionVersionModal analysisId={analysisId} />}
      <CollapsibleSection
        titleLeftResource={{
          key:
            redactionModalState.redactionVersions.length > 1 ||
            redactionModalState.redactionVersions.length === 0
              ? "overview.analysisSettings.redaction.plural"
              : "overview.analysisSettings.redaction.singular",
          args: [`${redactionModalState.redactionVersions.length}`],
        }}
        titleRightComponent={
          <Button
            size={ButtonSize.sm}
            variant={ButtonVariant.secondary}
            onClick={() => dispatch(setModalOpen(true))}
          >
            <Text resource="button.createRedactionVersion" />
          </Button>
        }
        isLoading={!redactionModalState.redactionVersions}
        tooltipCaptionKey="tooltip.emptyCells"
        customStyles={{ padding: 10.5 }}
      >
        {!redactionModalState.redactionVersionsLoading && (
          <RadioGroup
            onChange={(versionOption: RadioOption) => handleChangeAnalysisVersion(versionOption)}
            options={redactionModalState.redactionVersions.map((redaction) => ({
              label: redaction.name,
              value: `${redaction.id}`,
            }))}
            selectedOption={redactionModalState.selectedRedactionVersion}
            actionSectionContent={getRedactionActionButtons}
            customStyles={{ itemGap: 10, margin: "10px 0px", maxHeight: 170 }}
            addNoneOption
          />
        )}
      </CollapsibleSection>
    </>
  );
};

const StyledButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
`;

const StyledActionSection = styled.div`
  display: flex;
  gap: 10px;
`;
