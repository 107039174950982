export enum EmptyStateType {
  noListResults = "no-list-results",
  noSearchResults = "no-search-results",
  noFilterResults = "no-filter-results",
  allTopicsMapped = "all-topics-mapped",
  noSharedUsers = "no-shared-users",
  noSharedGroups = "no-shared-groups",
  noUsersInGroup = "no-users-in-group",
  noRelevantComments = "no-relevant-comments",
  noLicensingPeriod = "no-licensing-period",
  noCommentColumnsSelected = "no-comment-columns-selected",
  noPreFilteringResultsFound = "no-prefiltering-results-found",
  noWidgetGroups = "no-widget-groups",
  noDiscussionThreadNotes = "no-discussion-thread-comments",
  noSuggestedRedactionTerms = "no-suggested-redaction-terms",
}
