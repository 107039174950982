import React, { useContext, useState } from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";
import { AnalysisContext } from "context/AnalysisContext";

import { WidgetSettingsDrawer } from "common-layouts/WidgetSettingsDrawer";
import { SideDrawer } from "components/SideDrawer";
import { Text } from "components/Text";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import {
  WidgetWidth,
  WidgetHeight,
  WidgetType,
  BreakdownTypes,
  Ranking,
  WidgetStat,
} from "@explorance/mly-types";
import { Widget } from "ts/widget";

type Props = {
  groupId: number;
  onCreate: (widgetId: number) => void;
};

export const CreateNewWidget = ({ groupId, onCreate }: Props) => {
  const [analysisState] = useContext(AnalysisContext);
  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const { getResource } = useResource();

  const isSRA = analysisState.analysisDetails.graphId === null;

  const defaultWidget = {
    title: isSRA ? getResource("widget.allRecommendations") : getResource("widget.mostPopular"),
    type: isSRA ? WidgetType.AllRecommendations : WidgetType.MostPopular,
    configuration: {
      stats: [WidgetStat.CommentCount, WidgetStat.DominantSentiment, WidgetStat.AlertCount],
      breakdowns: [
        {
          type: isSRA ? BreakdownTypes.RecommendationType : BreakdownTypes.Topics,
          limit: 5,
          ranking: Ranking.Top,
        },
      ],
      size: { width: WidgetWidth.OneThird, height: WidgetHeight.Full },
    },
    filters: null,
  } as Widget;

  return (
    <>
      <StyledCreateNewWidget onClick={() => setOpenSettings(true)}>
        <span>
          <Icon type={IconType.plusCircle} color={Color.blue45} size={64} />
        </span>
        <StyledCaptionContainer>
          <Text resource="widget.addButton.label" />
        </StyledCaptionContainer>
      </StyledCreateNewWidget>
      <SideDrawer
        render={WidgetSettingsDrawer}
        isOpen={openSettings}
        closeHandler={() => setOpenSettings(false)}
        width={"600px"}
        renderComponentProps={{
          isOpen: openSettings,
          selectedWidget: defaultWidget,
          groupId,
          onSave: onCreate,
          onClose: () => setOpenSettings(false),
          isCreate: true,
        }}
      />
    </>
  );
};

const StyledCreateNewWidget = styled.div`
  width: 375px;
  height: 255px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background-color: ${Color.sky15};
  border: 1px dashed ${Color.sky20};
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 3px 10px 0px ${Color.shadow20};
  @media (max-width: 1366px) {
    width: 448px;
  }
`;

const StyledCaptionContainer = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${Color.blue50};
`;
