import React, { RefObject, useContext } from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";

import { GroupsSharedWith } from "./GroupsSharedWith";
import { UsersSharedWith } from "./UsersSharedWith";
import { StyledHorizontalField } from "./PermissionForm/PermissionFormContainer";
import { ShareTo } from "./ShareTo";
import { Text } from "components/Text";
import { useAppDispatch, useAppSelector } from "store";
import { setPermissionFields, setShowGroupNameError } from "store/sharing/permissionSlice";

import { Color } from "ts/enums/color";
import { SharingView } from "ts/enums/sharing";
import { saveSharingDraft } from "store/sharing/thunks";
import { AnalysisContext } from "context/AnalysisContext";

const CHAR_LIMIT = 100;

type ShareBuilderProps = {
  groupNameInputRef?: RefObject<HTMLInputElement>;
  groupNameButtonRef?: RefObject<HTMLInputElement>;
};

export const SharingBuilder = ({ groupNameInputRef, groupNameButtonRef }: ShareBuilderProps) => {
  const { getResource } = useResource();
  const dispatch = useAppDispatch();
  const [state] = useContext(AnalysisContext);
  const sharing = useAppSelector((state) => state.sharing);
  const permissionsState = useAppSelector((state) => state.permissions);

  const getSharingBuilderContent = () => {
    if ([SharingView.ShareToUsers, SharingView.ShareToGroups].includes(sharing.view)) {
      return <ShareTo />;
    }
    if (sharing.view === SharingView.UsersSharedWith) {
      return <UsersSharedWith />;
    }
    if (sharing.view === SharingView.GroupsSharedWith) {
      return <GroupsSharedWith />;
    }
  };

  const handleUpdateGroupName = (e) => {
    const groupNameButtonClick = groupNameButtonRef.current?.contains(e.relatedTarget);
    const isGroupNameEmpty = groupNameInputRef.current.value.trim() === "";

    if (isGroupNameEmpty && !groupNameButtonClick) {
      groupNameInputRef.current.value = getResource("sharing.groupName.default");
    }
    if (isGroupNameEmpty && groupNameButtonClick) {
      dispatch(setShowGroupNameError(true));
    }
    dispatch(
      setPermissionFields({
        ...permissionsState.permissionFields,
        groupName:
          !groupNameButtonClick && isGroupNameEmpty
            ? getResource("sharing.groupName.default")
            : groupNameInputRef.current.value,
      })
    );
    dispatch(saveSharingDraft(state.analysisDetails.id));
    dispatch(setShowGroupNameError(false));
  };

  return (
    <>
      {sharing.view === SharingView.ShareToGroups && (
        <StyledHorizontalField hasError={permissionsState.showGroupNameError}>
          <label htmlFor="group-name">
            <Text resource="sharing.permissionForm.groupName.label" />
            {""}
          </label>
          <StyledGroupNameInput
            id="group-name"
            type="text"
            ref={groupNameInputRef}
            defaultValue={permissionsState.permissionFields.groupName}
            maxLength={CHAR_LIMIT}
            onBlur={handleUpdateGroupName}
          />
        </StyledHorizontalField>
      )}

      <StyledSharingBuilder>{getSharingBuilderContent()}</StyledSharingBuilder>
    </>
  );
};

const StyledSharingBuilder = styled.div`
  padding: 16px;
  border-radius: 5px;
  border: 1px solid ${Color.sky50};
`;

const StyledGroupNameInput = styled.input`
  width: 450px;
  outline-style: none;
  &:focus {
    border: 1px solid ${Color.blue50};
  }
`;
