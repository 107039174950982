import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";

import { routes } from "routes";
import { useResource } from "hooks/useResource";
import { useQueryParams } from "hooks/useQueryParams";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { unshare } from "services/analysis/sharing";
import { setSearchTerm } from "store/search/searchbarSlice";
import { setStep, setView } from "store/sharing/sharingSlice";

import { FirstNameCell } from "pages/analysis/[id]/sharing/_layouts/SharingBuilder/FlexTableCells/FirstNameCell";
import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { Header } from "./Header";
import { EmptyState } from "components/EmptyState";
import { LastNameCell } from "./FlexTableCells/LastNameCell";
import { EmailCell } from "./FlexTableCells/EmailCell";
import { EditSharingBlock } from "./EditSharingBlock";

import { EmptyStateType } from "ts/enums/emptyStateType";
import { ShareStep, SharingView } from "ts/enums/sharing";
import { RecipientUsersSortingParameter, SortingOrder } from "@explorance/mly-types";
import { Color } from "ts/enums/color";
import { FlexTableType } from "ts/enums/flexTable";
import {
  setCurrentPage,
  setExpandedRowId,
  setReturningFromPreview,
  setSortOrder,
  setSortingColumnUsers,
} from "store/sharing/sharedWithSlice";
import { fetchSharedUsersData } from "store/sharing/thunks";
import { TextTruncator } from "components/TextTruncator";
import { Text } from "components/Text";
import { ActionsCell } from "../ActionsCell";

const USERS_SHARED_WITH_HEADERS = [
  { columnNameKey: "firstName", sortingParameter: RecipientUsersSortingParameter.FirstName },
  { columnNameKey: "lastName", sortingParameter: RecipientUsersSortingParameter.LastName },
  { columnNameKey: "email", sortingParameter: RecipientUsersSortingParameter.Email },
  {
    columnNameKey: "permissionLevel",
    sortingParameter: RecipientUsersSortingParameter.PermissionLevel,
  },
];

export const UsersSharedWith = () => {
  const sharedWithState = useAppSelector((state) => state.sharedWith);
  const searchTerm = useAppSelector((state) => state.search.searchTerm);
  const dispatch = useAppDispatch();

  const { getResource } = useResource();
  const history = useHistory();

  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);
  const { expandedRowId: expandedRowIdFromUrl, sharedWithPage } = useQueryParams();

  const navigateToShareEditPage = () => {
    dispatch(setStep(ShareStep.UserSelection));
    dispatch(setView(SharingView.ShareToUsers));
    history.push(
      routes.sharingPage(analysisId) +
        getUrlQueryString({ view: SharingView.ShareToUsers, step: ShareStep.UserSelection })
    );
  };

  const deleteSharedUser = async (sharingId: number) => {
    await unshare(sharingId)
      .then(() => {
        if (sharedWithState.expandedRowId) dispatch(setExpandedRowId(null));
        dispatch(setReturningFromPreview(false));
        dispatch(fetchSharedUsersData(analysisId));
      })
      .catch((err) => console.error(err));
  };

  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const { columnIndex, order } = updatedSorting;
    dispatch(setSortingColumnUsers(USERS_SHARED_WITH_HEADERS[columnIndex].sortingParameter));
    dispatch(setSortOrder(order));
    dispatch(setCurrentPage(1));
    dispatch(fetchSharedUsersData(analysisId));
  };

  const expandCollapseEditBlock = (userId: number) => {
    dispatch(setExpandedRowId(sharedWithState.expandedRowId === userId ? null : userId));
    dispatch(setReturningFromPreview(false));
  };

  useEffect(() => {
    dispatch(fetchSharedUsersData(analysisId));

    if (expandedRowIdFromUrl) {
      dispatch(setExpandedRowId(parseInt(expandedRowIdFromUrl)));
      dispatch(setCurrentPage(parseInt(sharedWithPage)));
      dispatch(setReturningFromPreview(true));
      //remove &expandedRowId from URL
      const params = new URLSearchParams(history.location.search);
      params.delete("expandedRowId");
      params.delete("sharedWithPage");
      history.replace(history.location.pathname + "?" + params.toString());
    }

    return () => {
      dispatch(setCurrentPage(1));
      dispatch(setSortOrder(SortingOrder.DESC));
      dispatch(setSortingColumnUsers(RecipientUsersSortingParameter.FirstName));
      dispatch(setExpandedRowId(null));
      dispatch(setReturningFromPreview(false));
    };
  }, []); // eslint-disable-line

  return (
    <div className="fade-in">
      <Header
        pageTitle={getResource("sharing.title.users")}
        searchCountDataType="searchBar.dataType.userList"
        placeholderText={getResource("sharing.searchBar.placeholder.users")}
        currentPage={sharedWithState.currentPage}
        updatePage={(updatedPage) => {
          setCurrentPage(updatedPage);
          dispatch(fetchSharedUsersData(analysisId));
        }}
        onSearch={() => dispatch(fetchSharedUsersData(analysisId))}
      />
      {sharedWithState.sharedUsersApiData.totalCount === 0 ? (
        <EmptyState
          type={searchTerm ? EmptyStateType.noSearchResults : EmptyStateType.noSharedUsers}
          customStyles={{ marginTop: "85px", marginBottom: "85px" }}
          handleClickCaptionLink={
            searchTerm.length > 0 ? () => dispatch(setSearchTerm("")) : navigateToShareEditPage
          }
        />
      ) : (
        <FlexTable
          type={FlexTableType.Card}
          data={{
            headers: USERS_SHARED_WITH_HEADERS.map((header) =>
              getResource(`sharing.table.${header.columnNameKey}`)
            ),
            rows: sharedWithState.sharedUsersApiData.users.map((user) => ({
              contentId: user.id,
              data: [
                <FirstNameCell key={user.id} user={user} />,
                <LastNameCell key={user.id} user={user} />,
                <EmailCell key={user.id} user={user} />,
                <TextTruncator
                  key={user.id}
                  value={
                    <Text resource={`sharing.table.permissionLevel.[${user.permissionLevel}]`} />
                  }
                />,
                <ActionsCell
                  key={user.id}
                  editButtonStyle={
                    sharedWithState.expandedRowId === user.id
                      ? { backgroundColor: Color.gray50, color: Color.white }
                      : null
                  }
                  disabled={
                    sharedWithState.expandedRowId && sharedWithState.expandedRowId !== user.id
                  }
                  handleClickEdit={() => expandCollapseEditBlock(user.id)}
                  handleClickDelete={() => deleteSharedUser(user.sharingId)}
                />,
              ],
              expandedContent: (
                <EditSharingBlock
                  show={sharedWithState.expandedRowId === user.id}
                  sharingId={user.sharingId}
                  closeShowEditSharing={() => expandCollapseEditBlock(user.id)}
                  refetch={() => dispatch(fetchSharedUsersData(analysisId))}
                />
              ),
              isDisabled:
                sharedWithState.expandedRowId && sharedWithState.expandedRowId !== user.id,
              isSelected: sharedWithState.expandedRowId === user.id,
            })),
            columnWidths: ["20%", "20%", "20%", "15%", "20%"],
          }}
          customStyles={{
            rows: {
              backgroundColor: Color.white,
              flexWrap: "wrap",
            },
          }}
          initialSorting={{
            columnIndex: USERS_SHARED_WITH_HEADERS.findIndex(
              (h) => h.sortingParameter === sharedWithState.sortingColumnUsers
            ),
            order: sharedWithState.sortOrder,
          }}
          isLoading={sharedWithState.isSharedUsersApiDataLoading}
          onSortingChange={updateSorting}
        />
      )}
    </div>
  );
};
