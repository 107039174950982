import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserDemographic } from "context/UserProfileContext";
import { ShareByOption, ShareStep, SharingPageTab, SharingView } from "ts/enums/sharing";
import { SelectOption } from "ts/select";
import {
  fetchAllUserDemographics,
  fetchDemographicHeaders,
  fetchRedactionVersions,
} from "./thunks";
import { DemographicFilterHeader } from "ts/filters/demographicFilter";
import { RedactionVersion } from "ts/redaction";

type sharingState = {
  step: ShareStep;
  view: SharingView;
  shareBy: SelectOption;
  activeTab: SharingPageTab;
  shareByDropdownOptions: SelectOption[];
  isSavingSharing: boolean;
  allUserDemographics: UserDemographic[];
  analysisDemographicHeaders: DemographicFilterHeader[];
  redactionVersions: RedactionVersion[];
};

const initialState: sharingState = {
  step: null,
  view: SharingView.UsersSharedWith,
  activeTab: SharingPageTab.Show,
  shareBy: { label: ShareByOption.Users, value: ShareByOption.Users },
  shareByDropdownOptions: [],
  allUserDemographics: [],
  isSavingSharing: false,
  analysisDemographicHeaders: [],
  redactionVersions: [],
};

const sharingSlice = createSlice({
  name: "sharingSlice",
  initialState,
  reducers: {
    initShareByDropdownOptions: (state, action: PayloadAction<SelectOption[]>) => {
      state.shareByDropdownOptions = action.payload;
    },
    setShareBy: (state, action: PayloadAction<SelectOption>) => {
      state.shareBy = action.payload;
    },
    setView: (state, action: PayloadAction<SharingView>) => {
      state.view = action.payload;
    },
    setStep: (state, action: PayloadAction<ShareStep>) => {
      state.step = action.payload;
    },
    setIsSavingSharing: (state, action: PayloadAction<boolean>) => {
      state.isSavingSharing = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<SharingPageTab>) => {
      state.activeTab = action.payload;
    },
    setAnalysisDemographicHeaders: (state, action: PayloadAction<DemographicFilterHeader[]>) => {
      state.analysisDemographicHeaders = action.payload;
    },
    clearSharingState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllUserDemographics.fulfilled, (state, action) => {
      state.allUserDemographics = action.payload;
    });
    builder.addCase(fetchDemographicHeaders.fulfilled, (state, action) => {
      state.analysisDemographicHeaders = action.payload;
    });
    builder.addCase(fetchRedactionVersions.fulfilled, (state, action) => {
      state.redactionVersions = action.payload;
    });
  },
});

export const {
  initShareByDropdownOptions,
  setShareBy,
  setView,
  setStep,
  setIsSavingSharing,
  setActiveTab,
  setAnalysisDemographicHeaders,
  clearSharingState,
} = sharingSlice.actions;

export default sharingSlice.reducer;
