import React from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import { UserDemographic } from "context/UserProfileContext";

import { Select } from "components/_inputs/Select";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

import { RedactionCustomCondition } from "ts/sharing";
import { Color } from "ts/enums/color";
import { DemographicFilterHeader } from "ts/filters/demographicFilter";
import { ButtonVariant } from "ts/enums/button";
import { useAppSelector } from "store";
import { isOneCustomConditionEmpty } from "utils/sharing";

type Props = {
  index: number;
  customConditionRedactions: RedactionCustomCondition;
  savedCustomConditions: RedactionCustomCondition[];
  analysisDemographicHeaders: DemographicFilterHeader[];
  userDemographics: UserDemographic[];
  showRedactionCustomConditionError: boolean;
  updateRedactionCustomConditions: (
    updatedCustomConditions: RedactionCustomCondition[],
    noSave?: boolean
  ) => void;
  handleHideRedactionCustomConditionError: () => void;
};

export const CustomRedactionCondition = ({
  index,
  customConditionRedactions,
  savedCustomConditions,
  analysisDemographicHeaders,
  userDemographics,
  showRedactionCustomConditionError,
  updateRedactionCustomConditions,
  handleHideRedactionCustomConditionError,
}: Props) => {
  const state = useAppSelector((state) => state.sharing);

  const { analysisDemographic, redactionVersion, userDemographic } = customConditionRedactions;

  const commentsDemographicHasError =
    showRedactionCustomConditionError &&
    !analysisDemographic &&
    (userDemographic || redactionVersion);

  const userDemographicHasError =
    showRedactionCustomConditionError &&
    !userDemographic &&
    (analysisDemographic || redactionVersion);

  const redactionVersionHasError =
    showRedactionCustomConditionError &&
    !redactionVersion &&
    (analysisDemographic || userDemographic);

  // functions
  const selectRedactionVersion = (name: string) => {
    const selectedVersion = state.redactionVersions?.find((version) => version.name === name);
    const copy = cloneDeep(savedCustomConditions);
    copy[index].redactionVersion = selectedVersion.name;
    validateConditions(copy);
  };

  const selectCommentersDemographicOption = (name: string) => {
    const selectedHeader = analysisDemographicHeaders.find(
      (analysisDemHeader) => analysisDemHeader.name === name
    );
    const copy = cloneDeep(savedCustomConditions);
    copy[index].analysisDemographic = selectedHeader.name;
    updateRedactionCustomConditions(copy);
    validateConditions(copy);
  };

  const selectUserDemographicOption = (name: string) => {
    const selectedUserDemographic = userDemographics.find((userDem) => userDem.name === name);
    const copy = cloneDeep(savedCustomConditions);
    copy[index].userDemographic = selectedUserDemographic.name;
    updateRedactionCustomConditions(copy);
    validateConditions(copy);
  };

  const deleteCondition = () => {
    const copy = cloneDeep(savedCustomConditions);
    copy.splice(index, 1);
    updateRedactionCustomConditions(copy);
    validateConditions(copy);
  };

  const validateConditions = (updatedRedactionCustomConditions: RedactionCustomCondition[]) => {
    if (showRedactionCustomConditionError) {
      const hasError = isOneCustomConditionEmpty(updatedRedactionCustomConditions);
      !hasError && handleHideRedactionCustomConditionError();
    }
  };

  return (
    <StyledCustomCondition>
      <span>
        <Text resource="sharing.redaction.customCondition.label" />
      </span>

      <StyledRedactionSelectContainer>
        <Select
          selectedOption={{
            label: redactionVersion,
            value: redactionVersion,
          }}
          options={state.redactionVersions?.map((version) => ({
            label: version.name,
            value: version.id?.toString(),
          }))}
          handleChange={selectRedactionVersion}
          buttonStyle={{ width: "100%" }}
          selectStyle={{ width: "100%" }}
          placeholderKey="sharing.permissionForm.customCondition.defaultRedactionVersion.label"
          buttonVariant={redactionVersionHasError ? ButtonVariant.error : ButtonVariant.outlineGray}
        />
      </StyledRedactionSelectContainer>
      <Text resource="sharing.redaction.customCondition.label.ifUsers" />

      <Select
        selectedOption={{
          label: analysisDemographic,
          value: analysisDemographic,
        }}
        options={analysisDemographicHeaders.map((analysisDemHeader) => ({
          label: analysisDemHeader.name,
          value: analysisDemHeader.name,
        }))}
        handleChange={selectCommentersDemographicOption}
        dropdownWidth="100%"
        buttonStyle={{ width: "100%" }}
        dropdownMaxHeight="260px"
        placeholderKey="sharing.permissionForm.customCondition.defaultCommentersDemographic.label"
        buttonVariant={
          commentsDemographicHasError ? ButtonVariant.error : ButtonVariant.outlineGray
        }
      />

      <span>
        <Text resource="sharing.redaction.customCondition.label.equalsTo" />
      </span>

      <Select
        selectedOption={{
          label: userDemographic,
          value: userDemographic,
        }}
        options={userDemographics.map((userDem) => ({
          label: userDem.name,
          value: userDem.name,
        }))}
        handleChange={selectUserDemographicOption}
        dropdownWidth="100%"
        buttonStyle={{ width: "100%" }}
        dropdownMaxHeight="260px"
        placeholderKey="sharing.permissionForm.customCondition.defaultUserDemographic.label"
        buttonVariant={userDemographicHasError ? ButtonVariant.error : ButtonVariant.outlineGray}
      />

      <StyledDeleteContainer onClick={deleteCondition}>
        <Icon type={IconType.xCircle} color={Color.gray30} size={14} />
      </StyledDeleteContainer>
    </StyledCustomCondition>
  );
};

const StyledCustomCondition = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    font-size: 15px;
    color: ${Color.gray50};
  }

  /* setting the widths of Select dropdowns */
  @media (max-width: 1365px) {
    > div:nth-of-type(1) {
      width: 15%;
    }

    > div:nth-of-type(2),
    > div:nth-of-type(3) {
      width: 21%;
    }
  }

  @media (min-width: 1366px) {
    > div:nth-of-type(1) {
      width: 20%;
    }
    > div:nth-of-type(2),
    > div:nth-of-type(3) {
      width: 24%;
    }
  }
`;

const StyledDeleteContainer = styled.span`
  cursor: pointer;
`;

const StyledRedactionSelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
