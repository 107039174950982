import React from "react";
import styled from "styled-components";

import { CategorizedTopicPill } from "components/_pills/CategorizedTopicPill";
import { CategorizedTopic } from "ts/enums/categorizedTopic";
import { Color } from "ts/enums/color";
import { sentimentStyles } from "assets/constants/sentimentStyles";
import { humanize } from "utils/humanize";
import { Text } from "components/Text";

type Props = {
  sentiment: CategorizedTopic | "notCategorized";
  topics: string[];
  headerColor?: Color;
  isNonCatRec?: boolean;
};

export const ResultTagSection = ({ sentiment, topics, headerColor, isNonCatRec }: Props) => (
  <div>
    <StyledResultTagHeader color={headerColor || sentimentStyles[sentiment].color}>
      <Text resource={`quickAnalysis.interpretation.resultTagSectionTitle.${sentiment}`} /> (
      {topics.length})
    </StyledResultTagHeader>
    <div>
      {topics.map((topic) => (
        <CategorizedTopicPill
          key={topic}
          type={(isNonCatRec ? topic : sentiment) as CategorizedTopic}
          label={isNonCatRec ? humanize(topic) : topic}
          style={{
            marginRight: 5,
            marginBottom: 5,
            overflowWrap: "break-word",
          }}
          isNonCatRec={isNonCatRec}
        />
      ))}
    </div>
  </div>
);

const StyledResultTagHeader = styled.b<{ color: Color }>`
  display: inline-block;
  color: ${({ color }) => color};
  margin: 8px 0 4px 0;
`;
