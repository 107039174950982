import React, { useState } from "react";
import styled from "styled-components";
import { useResource } from "hooks/useResource";
import { Tooltip } from "components/Tooltip";
import { Text } from "components/Text";
import { Color } from "ts/enums/color";

type Props = {
  id: number;
  isSmallScreen: boolean;
};

export const OverrideSettingsCell = ({ id, isSmallScreen }: Props) => {
  const [showOverrideTooltip, setShowOverrideTooltip] = useState<boolean>();
  const { getResource } = useResource();

  const handleHover = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) setShowOverrideTooltip(true);
  };

  return (
    <>
      <StyledOverrideSettingsPill
        key={id}
        onMouseOver={handleHover}
        onMouseOut={() => setShowOverrideTooltip(false)}
        data-tooltip-id={`${id}-override-tooltip`}
        isSmallScreen={isSmallScreen}
      >
        <Text resource="sharing.pill.overrideSettings" />
      </StyledOverrideSettingsPill>
      {showOverrideTooltip && (
        <StyledOverrideSettingsTooltip>
          <Tooltip
            tooltipId={`${id}-override-tooltip`}
            content={getResource("sharing.pill.overrideSettings")}
          />
        </StyledOverrideSettingsTooltip>
      )}
    </>
  );
};

const StyledOverrideSettingsPill = styled.div<{ isSmallScreen: boolean }>`
  display: inline;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background-color: ${Color.indigo45};
  max-width: ${({ isSmallScreen }) => (isSmallScreen ? "95px" : "100%")};
  color: ${Color.white};
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  justify-self: start;
`;

const StyledOverrideSettingsTooltip = styled.div`
  position: absolute;
`;
