import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";
import { DotsButtonVariant } from "ts/enums/dotsButton";

type DotsMenuButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  isDotsMenuOpen?: boolean;
  dotsVariantButton: DotsButtonVariant;
};

const variantStyles = {
  backgroundColor: {
    [DotsButtonVariant.Primary]: Color.white,
    [DotsButtonVariant.Transparent]: "transparent",
  },
};

export const DotsMenuButton = ({
  isDotsMenuOpen,
  dotsVariantButton,
  ...buttonProps
}: DotsMenuButtonProps) => {
  return (
    <StyledDotsMenuButton
      isDotsMenuOpen={isDotsMenuOpen}
      variant={dotsVariantButton}
      {...buttonProps}
    >
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
    </StyledDotsMenuButton>
  );
};

const StyledDotsMenuButton = styled.button<{
  isDotsMenuOpen: boolean;
  variant: DotsButtonVariant;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: ${({ isDotsMenuOpen, variant }) =>
    isDotsMenuOpen ? Color.blue20 : variantStyles.backgroundColor[variant]};
  gap: 3px;
  cursor: pointer;
  border-radius: 2px;
  border: none;
  padding: 0;

  .dot {
    height: 3px;
    width: 3px;
    background: ${Color.gray50};
    border-radius: 10px;
    display: inline-block;
  }
`;
