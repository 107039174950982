import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import { useResource } from "hooks/useResource";
import { AnalysisContext } from "context/AnalysisContext";
import { UserDemographic } from "context/UserProfileContext";

import {
  StyledDataCustomizationContainer,
  StyledDropdownContentContainer,
} from "./StyledDataCustomizationContainer";
import { DemographicFilterContainer } from "common-layouts/FilterSelectionSection/DemographicFilterContainer";
import { FilterPillContainer } from "common-layouts/FilterSelectionSection/FilterPillList/FilterPillContainer";
import { DemographicsMenu } from "common-layouts/FilterSelectionSection/FilterPillList/DemographicsMenu";
import { ExpandableFilterPillWrapper } from "common-layouts/FilterSelectionSection/FilterPillList/ExpandableFilterPillWrapper";
import { CustomConditionSection } from "./CustomConditionSection";
import { DropdownContainer } from "./DropdownContainer";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { FilterType } from "ts/filters/filterType";
import { FilterPillStyle } from "ts/enums/filterPillStyle";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { DemographicFilterMethod } from "@explorance/mly-types";
import { DemographicFilter, DemographicFilterHeader } from "ts/filters/demographicFilter";
import { DemographicCustomCondition } from "ts/sharing";

type Props = {
  selectedDemographics: DemographicFilter[];
  selectedCustomConditions: DemographicCustomCondition[];
  showCustomConditionError: boolean;
  analysisDemographicHeaders: DemographicFilterHeader[];
  userDemographics: UserDemographic[];
  // function props
  updateSelectedDemographics: (updatedDemographics: DemographicFilter[]) => void;
  updateDemographicCustomConditions: (
    updatedCustomConditions: DemographicCustomCondition[],
    noSave?: boolean
  ) => void;
  handleHideCustomConditionError: () => void;
};

export const VisibleAnalysisData = ({
  selectedDemographics,
  selectedCustomConditions,
  showCustomConditionError,
  analysisDemographicHeaders,
  userDemographics,
  // function props
  updateSelectedDemographics,
  updateDemographicCustomConditions,
  handleHideCustomConditionError,
}: Props) => {
  const [localSelectedDemographics, setLocalSelectedDemographics] = useState<DemographicFilter[]>(
    []
  );
  const [state] = useContext(AnalysisContext);

  const { getResource } = useResource();

  const deletePill = (demographicId: number) => {
    const copy = cloneDeep(selectedDemographics);
    const updatedArr = copy.filter((d) => d.id !== demographicId);

    setLocalSelectedDemographics(updatedArr);
    updateSelectedDemographics(updatedArr);
  };

  const updateSelectedFilters = (updatedFilters: DemographicFilter[]) => {
    setLocalSelectedDemographics(updatedFilters);
    updateSelectedDemographics(updatedFilters);
  };

  const addCustomCondition = () =>
    updateDemographicCustomConditions(
      [...selectedCustomConditions, { analysisDemographic: "", userDemographic: "" }],
      false
    );

  useEffect(() => {
    if (selectedDemographics.length !== 0) {
      setLocalSelectedDemographics(selectedDemographics);
    }
  }, [selectedDemographics]);

  return (
    <>
      <StyledTitleContainer>
        <b>
          <Text resource="sharing.permissionForm.showAnalysisData.title" />
        </b>
      </StyledTitleContainer>
      <StyledVisibleAnalysisDataContainer>
        <StyledTopContainer>
          <DropdownContainer
            buttonLabel={getResource("sharing.permissionForm.visibleAnalysisData.dropdown.label")}
            renderDropdownContent={(isOpen) => {
              return (
                <StyledDropdownContentContainer>
                  {state.availableDemographicFilters?.length > 0 &&
                    state.availableDemographicFilters.map((d) => (
                      <DemographicFilterContainer
                        key={d.id}
                        demographic={d}
                        currentFilterState={localSelectedDemographics}
                        handleCurrentFilterState={(x) => {
                          setLocalSelectedDemographics(x);
                        }}
                        isOpen={isOpen}
                      />
                    ))}
                </StyledDropdownContentContainer>
              );
            }}
            handleApplyFilters={() => updateSelectedFilters(localSelectedDemographics)}
            resetLocalChanges={() => {
              setLocalSelectedDemographics(selectedDemographics);
            }}
          />
          <Button
            variant={ButtonVariant.secondary}
            size={ButtonSize.sm}
            style={{ gap: "6px" }}
            onClick={addCustomCondition}
          >
            <Icon type={IconType.gear} size={12} />
            <Text resource="button.addCustomCondition" />
          </Button>
        </StyledTopContainer>
        {selectedDemographics.length > 0 && (
          <ExpandableFilterPillWrapper
            showDeleteFilters={selectedDemographics.length > 0}
            deleteFilters={() => {
              setLocalSelectedDemographics([]);
              updateSelectedDemographics([]);
            }}
          >
            {selectedDemographics.map((d) => (
              <FilterPillContainer
                key={d.id}
                filterType={FilterType.Demographic}
                styleType={FilterPillStyle.outlineIndigo}
                demographicFilterName={d.name}
                demographicFilterId={d.id}
                filterCount={
                  d.method === DemographicFilterMethod.List ? d.listValues.length : undefined
                }
                renderAltMenu={(resetLocalState, onSubmit) => {
                  return (
                    <DemographicsMenu
                      selectedDemographicId={d.id}
                      selectedDemographics={selectedDemographics}
                      resetLocalState={resetLocalState}
                      onSubmit={onSubmit}
                      updateFilters={updateSelectedDemographics}
                    />
                  );
                }}
                deletePill={() => deletePill(d.id)}
              />
            ))}
          </ExpandableFilterPillWrapper>
        )}
        {selectedCustomConditions.length > 0 && (
          <CustomConditionSection
            savedCustomConditions={selectedCustomConditions}
            analysisDemographicHeaders={analysisDemographicHeaders}
            userDemographics={userDemographics}
            showCustomConditionError={showCustomConditionError}
            updateDemographicCustomConditions={updateDemographicCustomConditions}
            handleHideCustomConditionError={handleHideCustomConditionError}
          />
        )}
      </StyledVisibleAnalysisDataContainer>
    </>
  );
};

const StyledVisibleAnalysisDataContainer = styled(StyledDataCustomizationContainer)`
  z-index: ${ZIndexStackingContext.medium + 1};
  position: relative;
`;

const StyledTopContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  > :first-child {
    flex: 1 0 auto;
  }
`;

const StyledTitleContainer = styled.div`
  padding-bottom: 6px;
  font-size: 15px;
`;
