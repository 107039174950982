import { RecommendationType, SentimentType } from "@explorance/mly-types";
import { sentimentStyles } from "assets/constants/sentimentStyles";
import { GlobalSentiment, StackedBarChartCount, SentimentCounts } from "ts/overview";

export const formatSentimentsForStackedBarChart = (
  sentimentCounts: [string, number]
): StackedBarChartCount => ({
  attribute: sentimentCounts[0] as SentimentType,
  percentage: sentimentCounts[1],
  color: sentimentStyles[sentimentCounts[0]].color,
});

export const getGlobalSentiment = (counts: SentimentCounts): GlobalSentiment => {
  const countArray = Object.entries(counts).filter((e) => e[0] !== "total");
  const gs = [...countArray].sort((a, b) => b[1] - a[1])[0];
  return { type: gs[0] as SentimentType, percentage: Math.round(gs[1] * 100) };
};

export const formatRecommendationsForStackedBarChart = (
  recommendationCounts: [string, number],
  total: number
): StackedBarChartCount => ({
  attribute: recommendationCounts[0] as RecommendationType,
  percentage: recommendationCounts[1] > 0 ? Math.ceil((recommendationCounts[1] / total) * 100) : 0,
  color: sentimentStyles[recommendationCounts[0]].color,
  recommendationCount: recommendationCounts[1],
});
