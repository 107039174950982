import { FeedbackType, InsightType } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Comment } from "ts/comments";
import { sendCommentFeedback } from "./thunks";

type FeedbackPopupState = {
  comment: Comment;
  analysisSentiment: FeedbackType;
  selectedTopics: string[];
  selectedRecommendations: string[];
  feedbackInputValue: string;
  insightType: InsightType;
  isLoading: boolean;
};

const initialState: FeedbackPopupState = {
  comment: null,
  analysisSentiment: null,
  selectedTopics: [],
  selectedRecommendations: [],
  feedbackInputValue: "",
  insightType: InsightType.Polarity,
  isLoading: false,
};

const feedbackPopupSlice = createSlice({
  name: "feedbackPopupSlice",
  initialState,
  reducers: {
    setSelectedTopics: (state, action: PayloadAction<string[]>) => {
      state.selectedTopics = action.payload;
    },
    setSelectedRecommendations: (state, action: PayloadAction<string[]>) => {
      state.selectedRecommendations = action.payload;
    },
    setInsightType: (state, action: PayloadAction<InsightType>) => {
      state.insightType = action.payload;
    },
    setAnalysisSentiment: (state, action: PayloadAction<FeedbackType>) => {
      state.analysisSentiment = action.payload;
    },
    setFeedbackInputValue: (state, action: PayloadAction<string>) => {
      state.feedbackInputValue = action.payload;
    },
    setComment: (state, action: PayloadAction<Comment>) => {
      state.comment = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(sendCommentFeedback.fulfilled, (state) => {
      state.analysisSentiment = null;
      state.feedbackInputValue = "";
      state.insightType = InsightType.Polarity;
      state.selectedRecommendations = [];
      state.selectedTopics = [];
    });
  },
});

export const {
  setSelectedTopics,
  setSelectedRecommendations,
  setInsightType,
  setAnalysisSentiment,
  setFeedbackInputValue,
  clearState,
  setIsLoading,
  setComment,
} = feedbackPopupSlice.actions;

export default feedbackPopupSlice.reducer;
