import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { useResource } from "hooks/useResource";

import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";
import { TextTruncator } from "components/TextTruncator";
import { Text } from "components/Text";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { PillType } from "ts/enums/pillType";
import { ToggeablePill } from "components/_pills/ToggeablePill";

type Props = {
  pillList: string[];
  inputHeaderKey: string;
  placeholderKey: string;
  height: string;
  isSelectAllPill?: boolean;
  inputMaxLength?: number;
  hasErrors?: boolean;
  submitListItem: (list: string) => void;
  removeListItem: (item: Record<any, any>) => void;
  clearAll: () => void;
};

export const PillInput = ({
  pillList,
  height,
  inputHeaderKey,
  placeholderKey,
  isSelectAllPill,
  inputMaxLength,
  hasErrors,
  submitListItem,
  clearAll,
  removeListItem,
}: Props) => {
  const [isInputActive, setIsInputActive] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>("");

  const { getResource } = useResource();

  const inputRef = useRef(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && userInput.trim() !== "") {
      submitListItem(userInput);
      setUserInput("");
    }
  };

  const handleOnBlur = () => {
    setIsInputActive(false);
    if (userInput.trim() !== "") {
      submitListItem(userInput.trim());
    }
    setUserInput("");
  };

  const onInputMouseDown = () => {
    setIsInputActive(true);
    inputRef.current.focus();
  };

  useEffect(() => {
    if (hasErrors) inputRef.current.focus();
  }, [hasErrors]);

  return (
    <StyledParentContainer>
      <StyledHeaderContainer>
        <Text resource={inputHeaderKey} />
        {pillList.length > 0 && (
          <Button
            size={ButtonSize.sm}
            variant={ButtonVariant.secondary}
            style={{
              gap: "6px",
              padding: "6px 12px",
            }}
            onClick={clearAll}
          >
            <Icon type={IconType.x} size={16} />
            <Text resource="button.clearAll" />
          </Button>
        )}
      </StyledHeaderContainer>

      <StyledUserInputFieldContainer
        isInputActive={isInputActive}
        onClick={onInputMouseDown}
        height={height}
        hasErrors={hasErrors}
      >
        <StyledInputPillContainer>
          {pillList.length > 0 &&
            pillList.map((pillItem, index) => (
              <ToggeablePill
                key={index}
                pillType={
                  isSelectAllPill && index === 0 ? PillType.SelectAll : PillType.SingleSelect
                }
                customStyles={{ maxWidth: "325px", maxHeight: "42px", marginTop: 0 }}
                handleDelete={() => removeListItem({ pillItem, index })}
              >
                <TextTruncator id={index} value={pillItem} />
              </ToggeablePill>
            ))}
          <StyledInputField
            onFocus={() => setIsInputActive(true)}
            onBlur={handleOnBlur}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={getResource(placeholderKey)}
            value={userInput}
            ref={inputRef}
            maxLength={inputMaxLength}
          />
        </StyledInputPillContainer>
      </StyledUserInputFieldContainer>
    </StyledParentContainer>
  );
};

const StyledParentContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const StyledHeaderContainer = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 15px;
`;

const StyledUserInputFieldContainer = styled.div<{
  isInputActive: boolean;
  height: string;
  hasErrors: boolean;
}>`
  border: ${({ isInputActive, hasErrors }) =>
    `2px solid ${hasErrors ? Color.red50 : isInputActive ? Color.blue50 : Color.blue20}`};
  border-radius: 5px;
  padding: 11px;
  overflow-y: auto;
  cursor: text;
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `};
`;

const StyledInputPillContainer = styled.div`
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
`;

const StyledInputField = styled.input`
  border: none;
  outline: none;
  margin: 3px;
  padding: 8px 5px;
  flex-grow: 1;
  max-height: 40px;
`;
