import React, { useState, useRef } from "react";
import styled from "styled-components";

import useClickOutside from "hooks/useClickOutside";

import { Icon, IconType } from "components/_icons/Icon";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { Text } from "components/Text";

import { Skeleton } from "components/Skeleton";

import { Color } from "ts/enums/color";
import { VariablePresetDetail } from "ts/variableMappings";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

type CommonProps = {
  titleKey?: string;
  descriptionKey?: string;
  customWidth?: string;
  customMarginTop?: string;
  showSkeleton?: boolean;
  isDisabled?: boolean;
  isNoneOptionAvailable?: boolean;
  marginBottom?: string;
};

type StringSelectionProps = {
  isStringSelection: true;
  selectedOption: string | null;
  options: string[];
  handleSelectOption: (arg: string | null) => void;
} & CommonProps;

type VariableMappingProps = {
  isStringSelection: false;
  selectedOption: VariablePresetDetail | null;
  options: VariablePresetDetail[];
  handleSelectOption: (arg: number | null) => void;
} & CommonProps;

type Props = StringSelectionProps | VariableMappingProps;

export const AnalysisSettingsDropdownField = ({
  titleKey,
  descriptionKey,
  customWidth,
  customMarginTop = "8px",
  showSkeleton,
  isStringSelection,
  selectedOption,
  options,
  handleSelectOption,
  isDisabled,
  isNoneOptionAvailable = true,
  marginBottom,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [openDropdownFromTop, setOpenDropdownFromTop] = useState<boolean>(true);

  const dropdownContainerRef = useClickOutside(() => {
    setIsMenuOpen(false);
  });
  const dropdownBodyRef = useRef<HTMLDivElement>(null);

  const handleSelect = (option: string | number | null) => {
    isStringSelection === false
      ? handleSelectOption(option as number | null)
      : handleSelectOption(option as string | null);
    setIsMenuOpen(false);
  };

  const handleClickDropdown = () => {
    setIsMenuOpen((isOpen) => !isOpen);

    const dropdownHeight = dropdownBodyRef.current.getBoundingClientRect().height;
    const bottomEdgeDropdown =
      dropdownContainerRef.current.getBoundingClientRect().bottom + dropdownHeight;
    if (window.innerHeight - dropdownHeight >= dropdownHeight) {
      setOpenDropdownFromTop(window.innerHeight - bottomEdgeDropdown <= 10);
    }
  };

  return (
    <StyledSettingsSelection marginBottom={marginBottom}>
      {(titleKey || descriptionKey) && (
        <>
          <StyledSettingsSelectionTitle>
            <Text resource={titleKey} />
          </StyledSettingsSelectionTitle>
          <StyledSettingsSelectionDescription>
            <Text resource={descriptionKey} />
          </StyledSettingsSelectionDescription>
        </>
      )}
      {selectedOption === null && showSkeleton ? (
        <Skeleton width={220} height={32} backgroundColor={Color.neutral10} responsive={true} />
      ) : (
        <StyledDropdownContainer
          marginTop={customMarginTop}
          width={customWidth}
          ref={dropdownContainerRef}
        >
          <StyledDropdownButton
            width={customWidth}
            onClick={isDisabled ? undefined : handleClickDropdown}
            isDisabled={isDisabled}
          >
            <span>
              {!selectedOption ? (
                <Text resource="dropdown.none" />
              ) : isStringSelection === false ? (
                selectedOption.name
              ) : (
                selectedOption
              )}
            </span>
            <Icon type={IconType.triangleFilledDown} color={Color.gray20} size={8} />
          </StyledDropdownButton>
          <StyledDropdownBody
            width={customWidth}
            show={isMenuOpen}
            ref={dropdownBodyRef}
            openDropdownFromTop={openDropdownFromTop}
          >
            {isNoneOptionAvailable && (
              <StyledDropdownOption isSelected={!selectedOption} onClick={() => handleSelect(null)}>
                <span>
                  <Text resource="dropdown.none" />
                </span>
              </StyledDropdownOption>
            )}
            {options.map((option, index) => (
              <StyledDropdownOption
                key={index}
                isSelected={
                  isStringSelection === false
                    ? selectedOption?.id === option.id
                    : selectedOption === option
                }
                onClick={() => handleSelect(isStringSelection ? option : option.id)}
              >
                <span>{option.name ?? option}</span>
              </StyledDropdownOption>
            ))}
          </StyledDropdownBody>
        </StyledDropdownContainer>
      )}
    </StyledSettingsSelection>
  );
};

const StyledDropdownButton = styled.button<{ width: string; isDisabled: boolean }>`
  height: 34px;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px solid ${Color.sky50};
  border-radius: 2px;
  color: ${({ isDisabled }) => (isDisabled ? Color.gray20 : Color.gray50)};
  background-color: ${({ isDisabled }) => (isDisabled ? Color.neutral10 : Color.white)};
  font-size: 0.94em;
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "inset")};

  span {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:focus {
    border: 1px solid ${Color.blue50};
  }

  &:hover {
    background-color: ${Color.neutral10};
  }

  @media (min-width: 1080px) {
    width: ${({ width }) => width ?? "190px"};
  }

  @media (min-width: 1366px) {
    width: ${({ width }) => width ?? "220px"};
  }
`;

const StyledDropdownBody = styled(StyledDropdownTransition)<{
  width: string;
  show: boolean;
  openDropdownFromTop: boolean;
}>`
  position: absolute;
  width: ${({ width }) => width};
  background: ${Color.white};
  border: 1px solid ${Color.sky50};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 8px 0;
  margin: ${({ openDropdownFromTop }) => (openDropdownFromTop ? "0 0 2px 0" : "2px 0 0 0")};
  max-height: 260px;
  bottom: ${({ openDropdownFromTop }) => openDropdownFromTop && "100%"};
  overflow-y: auto;
  z-index: ${ZIndexStackingContext.low};

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Color.gray20};
  }

  ::-webkit-scrollbar-track {
    background: ${Color.sky20};
    border-radius: 5px;
    margin: 2px 0px;
  }
`;

const StyledDropdownOption = styled.div<{ isSelected: boolean }>`
  color: ${({ isSelected }) => (isSelected ? Color.blue50 : Color.gray50)};
  background-color: ${({ isSelected }) => isSelected && Color.neutral10};
  padding: 8px 16px;
  font-size: 0.94em;

  :hover {
    background-color: ${({ isSelected }) => !isSelected && Color.neutral10};
    cursor: ${({ isSelected }) => !isSelected && "pointer"};

    span {
      color: ${({ isSelected }) => !isSelected && Color.blue50};
    }
  }
`;

const StyledDropdownContainer = styled.div<{ marginTop: string; width: string }>`
  margin-top: ${({ marginTop }) => marginTop};
  position: relative;
  width: ${({ width }) => width};
`;

export const StyledSettingsSelection = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom || "15px"};
`;

export const StyledSettingsSelectionTitle = styled.div<{ marginTop?: string }>`
  color: ${Color.gray50};
  font-size: 0.875em;
  font-weight: bold;
  margin-bottom: 4px;
  margin-top: ${({ marginTop }) => marginTop || "8px"};
  span {
    color: ${Color.red50};
  }
`;

export const StyledSettingsSelectionDescription = styled.div`
  color: ${Color.gray40};
  font-size: 0.875em;

  span {
    font-weight: bold;
    color: ${Color.red50};
  }
`;
