import React from "react";
import styled from "styled-components";
import parseHTML from "html-react-parser";
import { useResource } from "hooks/useResource";

import { UserSelection } from "./UserSelection";
import { PermissionFormContainer } from "./PermissionForm/PermissionFormContainer";
import { Text } from "components/Text";
import { useAppSelector } from "store";

import { Color } from "ts/enums/color";
import { ShareStep } from "ts/enums/sharing";

const SHARE_STEP_NUMBERS = {
  [ShareStep.UserSelection]: 1,
  [ShareStep.PermissionSettings]: 2,
};

export const ShareTo = () => {
  const sharing = useAppSelector((state) => state.sharing);
  const { selectedUsersCount } = useAppSelector((state) => state.sharingUserSelection);
  const { getResource } = useResource();

  const stepContent = {
    [ShareStep.UserSelection]: <UserSelection />,
    [ShareStep.PermissionSettings]: <PermissionFormContainer />,
  };

  return (
    <>
      <StyledSharingStepHeader>
        <span>
          {parseHTML(
            getResource(
              "sharing.stepHeader.step",
              SHARE_STEP_NUMBERS[sharing.step],
              Object.keys(SHARE_STEP_NUMBERS).length
            )
          )}
        </span>
        <b>
          <Text
            resource={{
              key:
                sharing.step === ShareStep.UserSelection
                  ? "sharing.stepHeader.description.selectUsers"
                  : "sharing.stepHeader.description.selectPermissionLevel",
              args: [
                getResource(
                  selectedUsersCount === 1
                    ? "sharing.table.usersInGroup.cell.singular"
                    : "sharing.table.usersInGroup.cell",
                  selectedUsersCount
                ),
              ],
            }}
          />
        </b>
      </StyledSharingStepHeader>
      {stepContent[sharing.step]}
    </>
  );
};

const StyledSharingStepHeader = styled.div`
  color: ${Color.white};
  background-color: ${Color.gray50};
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 10px;
  gap: 16px;
  display: flex;
  align-items: center;
`;
