import styled from "styled-components";

import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

export const StyledTableHeader = styled.thead<{ topOffset?: number }>`
  width: 100%;

  th {
    z-index: ${ZIndexStackingContext.low + 1};
    font-weight: bold;
    padding: 15px 10px;
    text-align: left;
    cursor: pointer;
    position: sticky;
    font-size: 14px;
    top: ${({ topOffset }) => topOffset}px;
    background-color: ${Color.neutral10};
    white-space: nowrap;
    box-shadow: inset 0px 1px ${Color.sky50}, inset 0px -1px ${Color.sky50};
  }
`;
