import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCategories } from "services/categories";
import { Category, Tag } from "@explorance/mly-types";
import { getTags } from "services/tags";

export const fetchCategories = createAsyncThunk<Category[], void>(
  "pinComment/fetchCategories",
  async (): Promise<Category[]> => {
    const { data } = await getCategories();
    if (!data) {
      throw new Error("Failed to fetch categories.");
    }
    return data.categories;
  }
);

export const fetchTags = createAsyncThunk<Tag[], void>(
  "pinComment/fetchTags",
  async (): Promise<Tag[]> => {
    const { data } = await getTags();
    if (!data) {
      throw new Error("Failed to fetch tags.");
    }

    return data.tags;
  }
);
