import React from "react";
import styled from "styled-components";

import { Skeleton } from "components/Skeleton";
import { Color } from "ts/enums/color";

export const DiscussionThreadPlaceholder = () => (
  <>
    {[...Array(2)].map((_, i) => (
      <StyledSkeletonContainer key={i}>
        <Skeleton
          width={32}
          height={32}
          borderRadius="50%"
          backgroundColor={Color.blue20}
          customStyles={{ marginTop: "12px" }}
        />
        <StyledSkeletonBubble>
          <StyledTopRow>
            <StyledLeftSide>
              <Skeleton width={75} height={9} borderRadius="5px" backgroundColor={Color.blue20} />
              <Skeleton width={57} height={9} borderRadius="5px" backgroundColor={Color.blue20} />
            </StyledLeftSide>
            <StyledSkeletonEllipsis>
              {[...Array(3)].map((_, i) => (
                <Skeleton
                  key={i}
                  width={3.5}
                  height={3.5}
                  borderRadius="50%"
                  backgroundColor={Color.gray20}
                />
              ))}
            </StyledSkeletonEllipsis>
          </StyledTopRow>
          <StyledSkeletonNote>
            <Skeleton width={354} height={9} borderRadius="5px" />
            <Skeleton width={235} height={9} borderRadius="5px" />
          </StyledSkeletonNote>
        </StyledSkeletonBubble>
      </StyledSkeletonContainer>
    ))}
  </>
);

const StyledSkeletonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
`;

const StyledSkeletonBubble = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: ${Color.sky15};
  border-radius: 12px;
  gap: 2px;
`;

const StyledTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledSkeletonEllipsis = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  height: 28px;
  width: 28px;
`;

const StyledSkeletonNote = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
