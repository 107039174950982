import { ShareableUsersSortingParameter, SortingOrder } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SharingUser } from "ts/sharing";

type userSelectionPopupSliceState = {
  sortingColumn: ShareableUsersSortingParameter;
  sortOrder: SortingOrder;
  currentPage: number;
  isUserDemographicFiltersReset: boolean;
  availableUsers: SharingUser[];
  totalUserCount: number;
  currentUserCount: number;
};

const initialState: userSelectionPopupSliceState = {
  sortingColumn: ShareableUsersSortingParameter.FirstName,
  sortOrder: SortingOrder.ASC,
  currentPage: 1,
  isUserDemographicFiltersReset: false,
  availableUsers: [],
  totalUserCount: 0,
  currentUserCount: 0,
};

const userSelectionPopupSlice = createSlice({
  name: "userSelectionPopup",
  initialState,
  reducers: {
    setSortingColumn: (state, action: PayloadAction<ShareableUsersSortingParameter>) => {
      state.sortingColumn = action.payload;
    },
    setSortOrder: (state, action: PayloadAction<SortingOrder>) => {
      state.sortOrder = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setIsUserDemographicFiltersReset: (state, action: PayloadAction<boolean>) => {
      state.isUserDemographicFiltersReset = action.payload;
    },
    setAvailableUsers: (state, action: PayloadAction<SharingUser[]>) => {
      state.availableUsers = action.payload;
    },
    setTotalUserCount: (state, action: PayloadAction<number>) => {
      state.totalUserCount = action.payload;
    },
    setCurrentUserCount: (state, action: PayloadAction<number>) => {
      state.currentUserCount = action.payload;
    },
    clearUserSelectionPopupState: () => initialState,
  },
});

export const {
  setSortingColumn,
  setSortOrder,
  setCurrentPage,
  setIsUserDemographicFiltersReset,
  setAvailableUsers,
  setTotalUserCount,
  setCurrentUserCount,
  clearUserSelectionPopupState,
} = userSelectionPopupSlice.actions;

export default userSelectionPopupSlice.reducer;
