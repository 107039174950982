import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { AnalysisContext } from "context/AnalysisContext";
import { useResource } from "hooks/useResource";

import { BackButton } from "components/_buttons/BackButton";
import { Text } from "components/Text";
import { ImportMoreDataButton } from "./ImportMoreDataButton";

import { Color } from "ts/enums/color";
import { AnalysisStatus, FileUploadStatus, TaskStatusDetails } from "@explorance/mly-types";
import { isSettingsPageUnavailable } from "utils/isSettingsPageUnavailable";
import { Icon, IconType } from "components/_icons/Icon";
import { getFileById } from "services/files";
import { DataSourceErrorDetails } from "store/analysisSettings/dataSourceSlice";

type Props = {
  titleKey: string;
  descriptionKey?: string;
  totalCount?: string;
  handleClickBackToPrevious: () => void;
  handleDiscardFile: () => void;
};

export const Header = ({
  titleKey,
  descriptionKey,
  totalCount,
  handleClickBackToPrevious,
  handleDiscardFile,
}: Props) => {
  const [state] = useContext(AnalysisContext);

  const { getResource } = useResource();

  const [errorType, setErrorType] = useState<TaskStatusDetails>(null);
  const [typeValidationError, setTypeValidationError] = useState<DataSourceErrorDetails>(null);

  const getErrorType = async () => {
    const { data } = await getFileById(state.analysisDetails.lastUploadedFileId);
    if (data.errorDetails.details) {
      setErrorType(data.errorDetails.details);
    } else if (!data.errorDetails.isValid) {
      setTypeValidationError(data.errorDetails);
    }
  };

  const discardLastImportedFile = () => {
    setTypeValidationError(null);
    setErrorType(null);
    handleDiscardFile();
  };

  useEffect(() => {
    if (
      state.analysisDetails.uploadStatus === FileUploadStatus.ImportUploadError ||
      state.analysisDetails.uploadStatus === FileUploadStatus.ImportValidationError
    ) {
      getErrorType();
    } else {
      setErrorType(null);
      setTypeValidationError(null);
    }
  }, [state.analysisDetails.uploadStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledHeader>
      <StyledBackButtonContainer>
        <BackButton
          resourceKey={
            state.analysisDetails.status === AnalysisStatus.NotAnalyzed ||
            state.analysisDetails.status === AnalysisStatus.Failed
              ? "button.backToHome"
              : "button.backToOverviewPage"
          }
          onClick={handleClickBackToPrevious}
        />
      </StyledBackButtonContainer>
      <StyledTopContainer>
        <div>
          <StyledTitle>
            <Text resource={titleKey} />
          </StyledTitle>
          <StyledDescription>
            <Text resource={descriptionKey} />
            {totalCount && <StyledTotalCount>{totalCount}</StyledTotalCount>}
          </StyledDescription>
        </div>
        <StyledImportContainer>
          <ImportMoreDataButton isDisabled={isSettingsPageUnavailable(state)} />
        </StyledImportContainer>
      </StyledTopContainer>
      {(typeValidationError || errorType) && (
        <StyledImportErrorContainer>
          <StyledErrorTitle>
            <Icon type={IconType.mostAlerts} size={16} color={Color.red50} />
            <Text resource="analysisSettings.error.title" />
          </StyledErrorTitle>
          <StyledErrorDescription>
            {typeValidationError ? (
              <Text
                resource={{
                  key: "dataSource.importValidationError.description",
                  args: [
                    typeValidationError.column,
                    `${typeValidationError.rowNum}`,
                    typeValidationError.expectedFormat ||
                      getResource(
                        `dataSource.importValidationError.wrongType.[${typeValidationError.expectedType}]`
                      ),
                  ],
                }}
              />
            ) : (
              <Text resource={`analysisSettings.error.[${errorType}]`} />
            )}
            <span onClick={discardLastImportedFile}>
              <Text resource="analysisSettings.error.discard" />
            </span>
          </StyledErrorDescription>
        </StyledImportErrorContainer>
      )}
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  cursor: default;
  width: 100%;
`;

const StyledBackButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const StyledTopContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
`;

const StyledTitle = styled.div`
  font-size: 1em;
  display: block;
  color: ${Color.gray50};
  font-weight: bold;
`;

const StyledDescription = styled.div`
  display: flex;
  margin-top: 5px;
  font-size: 0.875em;
`;

const StyledTotalCount = styled.span`
  font-weight: bold;
`;

const StyledImportContainer = styled.div`
  margin-left: auto;
`;

const StyledImportErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 22px 0px;
`;

const StyledErrorTitle = styled.div`
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  color: ${Color.red50};
`;

const StyledErrorDescription = styled.div`
  display: flex;
  margin-top: 10px;
  font-size: 14px;
  color: ${Color.red50};

  span {
    margin-left: 10px;
    color: ${Color.blue50};
    text-decoration: underline;
    cursor: pointer;
  }
`;
