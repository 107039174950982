import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";

import { useResource } from "hooks/useResource";
import { useQueryParams } from "hooks/useQueryParams";
import { setSearchTerm } from "store/search/searchbarSlice";
import { fetchSharedGroupsData } from "store/sharing/thunks";
import { setStep, setView } from "store/sharing/sharingSlice";

import { unshare } from "services/analysis/sharing";
import { routes } from "routes";
import { getUrlQueryString } from "utils/getUrlQueryString";

import { ActionsCell } from "pages/analysis/[id]/sharing/_layouts/ActionsCell";
import { GroupNameCell } from "./FlexTableCells/GroupNamePill";
import { Header } from "./Header";
import { EditSharingBlock } from "./EditSharingBlock";
import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { EmptyState } from "components/EmptyState";

import { Color } from "ts/enums/color";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { ShareStep, SharingView } from "ts/enums/sharing";
import { GroupSharingSortingParameter, SortingOrder } from "@explorance/mly-types";
import { FlexTableType } from "ts/enums/flexTable";
import {
  setCurrentPage,
  setExpandedRowId,
  setReturningFromPreview,
  setSortOrder,
  setSortingColumnGroups,
} from "store/sharing/sharedWithSlice";
import { TextTruncator } from "components/TextTruncator";

const GROUPS_SHARED_WITH_HEADERS = [
  { columnNameKey: "groupName", sortingParameter: GroupSharingSortingParameter.GroupName },
  { columnNameKey: "usersInGroup", sortingParameter: GroupSharingSortingParameter.UserCount },
  {
    columnNameKey: "permissionLevel",
    sortingParameter: GroupSharingSortingParameter.PermissionLevel,
  },
];

// Main component
export const GroupsSharedWith = () => {
  const searchTerm = useAppSelector((state) => state.search.searchTerm);
  const sharedWithState = useAppSelector((state) => state.sharedWith);
  const dispatch = useAppDispatch();

  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);
  const { expandedRowId: expandedRowIdFromUrl, sharedWithPage } = useQueryParams();

  const queryParams = new URLSearchParams(location.search); // eslint-disable-line

  const { getResource } = useResource();
  const history = useHistory();

  const navigateToShareEditPage = () => {
    dispatch(setStep(ShareStep.UserSelection));
    dispatch(setView(SharingView.ShareToGroups));
    history.push(
      routes.sharingPage(analysisId) +
        getUrlQueryString({ view: SharingView.ShareToGroups, step: ShareStep.UserSelection })
    );
  };

  const deleteSharedGroup = async (sharingId: number) => {
    await unshare(sharingId)
      .then(() => {
        dispatch(fetchSharedGroupsData(analysisId));
        dispatch(setReturningFromPreview(false));
        dispatch(setExpandedRowId(null));
      })
      .catch((err) => console.error(err));
  };

  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const { columnIndex, order } = updatedSorting;
    dispatch(setSortingColumnGroups(GROUPS_SHARED_WITH_HEADERS[columnIndex].sortingParameter));
    dispatch(setSortOrder(order));
    dispatch(setCurrentPage(1));
    dispatch(fetchSharedGroupsData(analysisId));
  };

  const clearSearch = () => {
    dispatch(setSearchTerm(""));
    dispatch(setReturningFromPreview(false));
  };

  const expandCollapseEditBlock = (groupId: number) => {
    if (!sharedWithState.expandedRowId) return dispatch(setExpandedRowId(groupId));
    dispatch(setReturningFromPreview(false));
    return sharedWithState.expandedRowId === groupId ? dispatch(setExpandedRowId(null)) : null;
  };
  useEffect(() => {
    dispatch(fetchSharedGroupsData(analysisId));
    if (expandedRowIdFromUrl) {
      dispatch(setExpandedRowId(parseInt(expandedRowIdFromUrl)));
      dispatch(setCurrentPage(parseInt(sharedWithPage)));
      dispatch(setReturningFromPreview(true));
      //remove &expandedRowId from URL
      const params = new URLSearchParams(history.location.search);
      params.delete("expandedRowId");
      params.delete("sharedWithPage");
      history.replace(history.location.pathname + "?" + params.toString());
    }
    return () => {
      dispatch(setSortingColumnGroups(GroupSharingSortingParameter.GroupName));
      dispatch(setSortOrder(SortingOrder.DESC));
      dispatch(setCurrentPage(1));
      dispatch(setExpandedRowId(null));
      dispatch(setReturningFromPreview(false));
    };
  }, []); // eslint-disable-line

  return (
    <div className="fade-in">
      <Header
        pageTitle={getResource("sharing.title.groups")}
        searchCountDataType="searchBar.dataType.sharing.groups"
        placeholderText={getResource("sharing.searchBar.placeholder.groups")}
        currentPage={sharedWithState.currentPage}
        updatePage={(updatedPage) => {
          dispatch(setCurrentPage(updatedPage));
          dispatch(fetchSharedGroupsData(analysisId));
        }}
        onSearch={() => dispatch(fetchSharedGroupsData(analysisId))}
      />
      {sharedWithState.sharedGroupsApiData.totalCount === 0 ? (
        <EmptyState
          type={
            queryParams.get("search")
              ? EmptyStateType.noSearchResults
              : EmptyStateType.noSharedGroups
          }
          customStyles={{ marginTop: "85px", marginBottom: "85px" }}
          handleClickCaptionLink={searchTerm.length > 0 ? clearSearch : navigateToShareEditPage}
        />
      ) : (
        <FlexTable
          type={FlexTableType.Card}
          data={{
            headers: GROUPS_SHARED_WITH_HEADERS.map((header) =>
              getResource(`sharing.table.${header.columnNameKey}`)
            ),
            rows: sharedWithState.sharedGroupsApiData.groups.map((group) => ({
              contentId: group.groupId,
              data: [
                <GroupNameCell group={group} key={group.groupId} />,
                getResource(
                  `sharing.table.usersInGroup.cell${group.userCount === 1 ? ".singular" : ""}`,
                  group.userCount
                ),
                <TextTruncator key={group.groupId} value={group.permissionLevel} />,
                <ActionsCell
                  key={group.groupId}
                  editButtonStyle={
                    sharedWithState.expandedRowId === group.groupId
                      ? { backgroundColor: Color.gray50, color: Color.white }
                      : null
                  }
                  disabled={
                    sharedWithState.expandedRowId && sharedWithState.expandedRowId !== group.groupId
                  }
                  handleClickEdit={() => expandCollapseEditBlock(group.groupId)}
                  handleClickDelete={() => deleteSharedGroup(group.sharingId)}
                />,
              ],
              expandedContent: (
                <EditSharingBlock
                  show={sharedWithState.expandedRowId === group.groupId}
                  sharingId={group.sharingId}
                  usersSharedWithCount={group.userCount}
                  showEditAddUsers={true}
                  groupName={group.name}
                  closeShowEditSharing={() => expandCollapseEditBlock(group.groupId)}
                  refetch={() => dispatch(fetchSharedGroupsData(analysisId))}
                />
              ),
              isDisabled:
                sharedWithState.expandedRowId && sharedWithState.expandedRowId !== group.groupId,
              isSelected: sharedWithState.expandedRowId === group.groupId,
            })),
            columnWidths: ["30%", "20%", "29%", "20%"],
          }}
          customStyles={{
            rows: {
              backgroundColor: Color.white,
              padding: "16px 12px",
              flexWrap: "wrap",
            },
          }}
          initialSorting={{
            columnIndex: GROUPS_SHARED_WITH_HEADERS.findIndex(
              (h) => h.sortingParameter === sharedWithState.sortingColumnGroups
            ),
            order: sharedWithState.sortOrder,
          }}
          isLoading={sharedWithState.isGroupsApiDataLoading}
          onSortingChange={updateSorting}
        />
      )}
    </div>
  );
};
