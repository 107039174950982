import React from "react";
import { useAppDispatch, useAppSelector } from "store";
import { useParams } from "react-router-dom";
import { getSharingMethodFromView } from "utils/getSharingMethodFromSharingView";

import {
  deletePillByUserId,
  deselectAllUsers,
  setPillsPage,
  setSelectedUsers,
} from "store/sharing/userSelectionSlice";
import {
  removeAllUsersFromDraft,
  updateSelectedUsers,
  updateUsersInNewSharingDraft,
} from "store/sharing/thunks";

import { getUserFullName } from "utils/getUserFullName";

import { ExpandableFilterPillWrapper } from "common-layouts/FilterSelectionSection/FilterPillList/ExpandableFilterPillWrapper";
import { Pill, PillSize } from "components/_pills/Pill";
import { Text } from "components/Text";

import { outlineBluePillStyle } from "ts/filters/pillStyle";
import { Color } from "ts/enums/color";
import { PILLS_LIMITS } from "assets/constants/sharingDraftLimits";

export const SelectedUserPills = () => {
  const dispatch = useAppDispatch();
  const userSelectionState = useAppSelector((state) => state.sharingUserSelection);
  const sharing = useAppSelector((state) => state.sharing);
  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);

  const loadMoreCount =
    userSelectionState.selectedUsersCount -
    userSelectionState.selectedUsers.filter((u) => u.isShown).length;
  const shownUsersCount = userSelectionState.selectedUsers.filter((u) => u.isShown).length;

  const handleDeletePill = (selectedUserId: number) => {
    dispatch(deletePillByUserId(selectedUserId));
    dispatch(
      updateUsersInNewSharingDraft({
        analysisId,
        sharingMethod: getSharingMethodFromView(sharing.view),
        userIds: [selectedUserId],
        isSelected: true,
      })
    );
  };

  const loadMorePills = () => {
    const newLimit = shownUsersCount + PILLS_LIMITS.REQUEST_LIMIT;
    if (userSelectionState.selectedUsers.length < newLimit) {
      dispatch(setPillsPage(userSelectionState.pillsPage + 1));
      dispatch(updateSelectedUsers(analysisId));
    } else {
      dispatch(
        setSelectedUsers(
          userSelectionState.selectedUsers.map((u, i) => ({ ...u, isShown: i < newLimit }))
        )
      );
    }
  };

  const handleDeleteAllSelected = () => {
    dispatch(deselectAllUsers());
    dispatch(
      removeAllUsersFromDraft({ analysisId, sharingMethod: getSharingMethodFromView(sharing.view) })
    );
  };

  return (
    <ExpandableFilterPillWrapper
      showDeleteFilters={true}
      deleteButtonLabelKey="sharing.pill.clearSelection"
      deleteFilters={handleDeleteAllSelected}
    >
      <b>
        <Text
          resource={{
            key:
              userSelectionState.selectedUsersCount === 1
                ? "sharing.pill.usersSelectedCount.singular"
                : "sharing.pill.usersSelectedCount",
            args: [`${userSelectionState.selectedUsersCount}`],
          }}
        />
      </b>
      {userSelectionState.selectedUsers.map(
        (selectedUser) =>
          selectedUser.isShown && (
            <Pill
              key={selectedUser.id}
              backgroundColor={outlineBluePillStyle.backgroundColor}
              hoverColor={outlineBluePillStyle.hoverBackgroundColor}
              color={outlineBluePillStyle.color}
              border={outlineBluePillStyle.border}
              size={PillSize.sm}
              deleteButtonHoverBackgroundColor={
                outlineBluePillStyle.deleteButtonHoverBackgroundColor
              }
              style={{ fontSize: "14px" }}
              onDeletePill={() => handleDeletePill(selectedUser.id)}
            >
              {getUserFullName({
                firstname: selectedUser.firstname,
                lastname: selectedUser.lastname,
              })}
            </Pill>
          )
      )}
      {loadMoreCount > 0 && (
        <Pill
          backgroundColor={Color.blue50}
          style={{ cursor: "pointer" }}
          color={Color.white}
          round={true}
          size={PillSize.sm}
          onClick={loadMorePills}
        >
          +{loadMoreCount}
        </Pill>
      )}
    </ExpandableFilterPillWrapper>
  );
};
