import { snakeCase } from "lodash";

type Options = {
  snakeCasify?: boolean;
};

export const getUrlQueryString = (params: Record<string, unknown>, options?: Options) => {
  let result = "";

  for (const key in params) {
    if (params[key] || params[key] === 0) {
      const left = options?.snakeCasify ? snakeCase(key) : key;
      const right = params[key];
      result += `&${left}=${right}`;
    }
  }

  if (result) result = result.replace("&", "?");

  return result;
};
