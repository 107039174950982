import { mlyApi, getHeaders } from "config/mlyApi";

export async function deleteNote(noteId: number) {
  const requestConfig = await getHeaders();
  const url = `/notes/${noteId}`;
  return mlyApi.delete(url, requestConfig);
}

export async function updateNote(noteId: number, note: string) {
  const requestConfig = await getHeaders();
  const baseUrl = `/notes/${noteId}`;
  return mlyApi.patch(baseUrl, { note: note }, requestConfig);
}
