import React, { useContext } from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";

import { AnalysisContext } from "context/AnalysisContext";
import { resetAllFilters } from "context/AnalysisContext/actions";
import { useResource } from "hooks/useResource";
import { useQueryParams } from "hooks/useQueryParams";
import { isAlertRangeActive } from "utils/isAlertRangeActive";

import { FilterPillContainer } from "./FilterPillContainer";
import { Icon, IconType } from "components/_icons/Icon";
import { Pill, PillSize } from "components/_pills/Pill";
import { ExpandableFilterPillWrapper } from "./ExpandableFilterPillWrapper";

import { FilterType } from "ts/filters/filterType";
import { FilterPillStyle } from "ts/enums/filterPillStyle";
import { Color } from "ts/enums/color";
import { DemographicFilterMethod, CommentsView } from "@explorance/mly-types";
import { Text } from "components/Text";

type Props = {
  containerMargin: string;
  openFiltersMenu: () => void;
};

export const FilterPillList = ({ containerMargin, openFiltersMenu }: Props) => {
  // hooks
  const [state, dispatch] = useContext(AnalysisContext);
  const { getResource } = useResource();
  const location = useLocation();
  const { view } = useQueryParams();

  // functions
  const deleteAllFilters = () => dispatch(resetAllFilters(true));

  // variables
  const addFiltersPillContent = (
    <StyledAddFilterPillContainer>
      <Icon type={IconType.plusCircle} color={Color.white} size={14} />
      <Text resource="button.addFilters" />
    </StyledAddFilterPillContainer>
  );

  // the topics filter pill shall be shown by default in the topics page and in the categorized comments/recommendations views in the comments page
  // the threshold filter pill shall only be displayed in the topics page and in the categorized comments/recommendations views in the comments page
  const showTopicThresholdFilters =
    location.pathname.includes("topics") ||
    view === CommentsView.categorizedComments ||
    view === CommentsView.categorizedRecommendations;

  const areFiltersActive =
    (state &&
      ((showTopicThresholdFilters && !isEmpty(state.selectedTopicNodes)) ||
        !isEmpty(state.selectedDemographicFilters) ||
        (showTopicThresholdFilters && state.threshold !== null) ||
        isAlertRangeActive(state.selectedAlertRange) ||
        state.selectedColumnFilters.length > 0)) ||
    state.selectedCategories.length > 0;

  return (
    <ExpandableFilterPillWrapper
      showDeleteFilters={areFiltersActive}
      margin={containerMargin}
      deleteFilters={deleteAllFilters}
    >
      <Pill
        size={PillSize.sm}
        backgroundColor={Color.gray50}
        color={Color.white}
        hoverColor={Color.gray70}
        style={{ fontSize: "14px", padding: "5px 7px" }}
        onClick={openFiltersMenu}
      >
        {addFiltersPillContent}
      </Pill>
      {showTopicThresholdFilters && (
        <FilterPillContainer
          key="topic-filters"
          styleType={
            state.selectedTopicNodes.length > 0
              ? FilterPillStyle.outlineBlue
              : FilterPillStyle.defaultTopics
          }
          filterType={FilterType.Topics}
          filterCount={
            state.selectedTopicNodes.length > 0 ? state.selectedTopicNodes.length : undefined
          }
        />
      )}
      {showTopicThresholdFilters && state.threshold !== null && (
        <FilterPillContainer
          key="threshold-filter"
          styleType={FilterPillStyle.outlineBlue}
          filterCount={state.threshold}
          filterType={FilterType.Threshold}
        />
      )}
      {state.analysisDetails?.availableResults?.alerts === true &&
        isAlertRangeActive(state.selectedAlertRange) && (
          <FilterPillContainer
            key="alerts-filter"
            styleType={FilterPillStyle.outlineBlue}
            filterCount={getResource(
              "filterPill.alerts.count",
              state.selectedAlertRange?.min,
              state.selectedAlertRange?.max
            )}
            filterType={FilterType.Alerts}
          />
        )}
      {state.selectedColumnFilters.length > 0 && (
        <FilterPillContainer
          key="column-filter"
          styleType={FilterPillStyle.outlineBlue}
          filterCount={state.selectedColumnFilters.length}
          filterType={FilterType.Feedback}
        />
      )}
      {state.selectedDemographicFilters.map((sdf) => {
        if (!state.availableDemographicFilters.find((adf) => adf.id === sdf.id)) return null;
        return (
          <FilterPillContainer
            key={`demographic-${sdf.id}-filter`}
            styleType={FilterPillStyle.outlineIndigo}
            filterType={FilterType.Demographic}
            filterCount={
              sdf.method === DemographicFilterMethod.List ? sdf.listValues.length : undefined
            }
            demographicFilterName={sdf.name}
            demographicFilterId={sdf.id}
          />
        );
      })}
      {state.selectedCategories.length > 0 && (
        <FilterPillContainer
          filterType={FilterType.PinnedComments}
          styleType={FilterPillStyle.outlineIndigo}
          filterCount={state.selectedCategories.length}
        />
      )}
    </ExpandableFilterPillWrapper>
  );
};

const StyledAddFilterPillContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
