import {
  RedactionAction,
  RedactionDisplayMethod,
  RedactionListSortingParameter,
  RedactionListType,
  SortingOrder,
} from "@explorance/mly-types";
import { getHeaders, mlyApi } from "config/mlyApi";
import { RequestsView } from "ts/enums/requestListView";
import { RedactionRequestListSortingParameter } from "ts/enums/sorting";
import { RedactionRule } from "ts/redaction";
import { getUrlQueryString } from "utils/getUrlQueryString";

export type CreateRedactionParams = {
  name: string;
  type: RedactionListType;
  terms: string[];
  includeVariations?: boolean;
};

export type EditRedactionSetParams = {
  id: number;
  name: string;
  terms: string[];
  includeVariations: boolean;
};

export async function getRedactionLists(
  sort_column?: RedactionListSortingParameter,
  sort_order?: SortingOrder,
  search?: string,
  limit?: number,
  page?: number
) {
  const requestConfig = await getHeaders();
  const url =
    "/redaction-sets/" + getUrlQueryString({ search, page, limit, sort_column, sort_order });
  return mlyApi.get(url, requestConfig);
}

export async function getRedactionSet(id: number, limit?: number) {
  const requestConfig = await getHeaders();
  const url = `/redaction-sets/${id}` + getUrlQueryString({ limit });
  return mlyApi.get(url, requestConfig);
}

export async function updateRedactionSet(params: EditRedactionSetParams) {
  const requestConfig = await getHeaders();
  const { id, name, terms, includeVariations } = params;
  const requestBody = { name, terms, includeVariations };
  const url = `/redaction-sets/${id}`;
  return mlyApi.put(url, requestBody, requestConfig);
}

export async function createRedactionSet(params: CreateRedactionParams) {
  const requestConfig = await getHeaders();
  const { name, type, terms, includeVariations } = params;
  const requestBody = { name, type, terms, includeVariations };
  return mlyApi.post("/redaction-sets", requestBody, requestConfig);
}

export async function deleteRedactionList(redactionListId: number) {
  const requestConfig = await getHeaders();
  const url = `/redaction-sets/${redactionListId}`;
  return mlyApi.delete(url, requestConfig);
}

export async function getAnalysisRedactionVersions(analysisId: number) {
  const requestConfig = await getHeaders();
  const url = `/analysis/${analysisId}/redaction-versions`;
  return mlyApi.get(url, requestConfig);
}

export async function deleteRedactionVersion(id: number) {
  const requestConfig = await getHeaders();
  const url = `/redaction-versions/${id}`;
  return mlyApi.delete(url, requestConfig);
}

export async function getRedactionVersion(id: number) {
  const requestConfig = await getHeaders();
  const url = `/redaction-versions/${id}`;
  return mlyApi.get(url, requestConfig);
}

type RedactionVersionBody = {
  name: string;
  rules?: RedactionRule[];
  includeManual: boolean;
};

export async function createRedactionVersion(analysisId: number, body: RedactionVersionBody) {
  const requestConfig = await getHeaders();
  return mlyApi.post(`/analysis/${analysisId}/redaction-versions`, body, requestConfig);
}

export async function updateRedactionVersion(versionId: number, body: RedactionVersionBody) {
  const requestConfig = await getHeaders();
  const url = `/redaction-versions/${versionId}`;
  return mlyApi.put(url, body, requestConfig);
}

export async function updateAnalysisRedactionVersion(
  redactionVersionId: number,
  analysisId: number
) {
  const requestConfig = await getHeaders();
  const url = `/analysis/${analysisId}`;
  const body = { ...(redactionVersionId && { redactionVersionId }) };
  return mlyApi.patch(url, body, requestConfig);
}

export async function getRedactionSuggestions(terms: string[]) {
  const requestConfig = await getHeaders();
  const requestBody = { terms };
  const url = "redaction-sets/suggestions";
  return mlyApi.post(url, requestBody, requestConfig);
}

export async function getRedactionRequestList(
  view?: RequestsView,
  sort_column?: RedactionRequestListSortingParameter,
  sort_order?: SortingOrder,
  search?: string,
  limit?: number,
  page?: number,
  status?: string[]
) {
  const requestConfig = await getHeaders();
  const queryParams = {
    search,
    page,
    limit,
    view,
    ...(status.length !== 0 && { status: [status] }),
    sort_column,
    sort_order,
  };
  const url = "/redaction-requests/" + getUrlQueryString(queryParams);
  return mlyApi.get(url, requestConfig);
}
export const getCommentRedactionDetails = async (commentId: number) => {
  const requestConfig = await getHeaders();
  const url = `/comments/${commentId}/redaction`;
  return mlyApi.get(url, requestConfig);
};

type UpdateCommentRedactionBody = {
  redactionAction: RedactionAction;
  redactionDisplayMethod?: RedactionDisplayMethod;
  redactionValue?: string;
};

export const updateCommentRedaction = async (
  commentId: number,
  requestBody: UpdateCommentRedactionBody
) => {
  const requestConfig = await getHeaders();
  const url = `/comments/${commentId}`;
  return mlyApi.patch(url, requestBody, requestConfig);
};

type updateRedactionBody = {
  redactionAction: RedactionAction;
  editerValue?: string;
};

export const updateRedactionRequest = async (redactionId: number, body: updateRedactionBody) => {
  const requestConfig = await getHeaders();
  const url = `/redaction-requests/${redactionId}`;
  return mlyApi.patch(url, body, requestConfig);
};

type updateRedactionsBody = {
  requestIds: number[];
  redactionAction: RedactionAction;
  editerValue?: string;
};

export const updateRedactionRequests = async (body: updateRedactionsBody) => {
  const requestConfig = await getHeaders();
  const url = `/redaction-requests/`;
  return mlyApi.patch(url, body, requestConfig);
};
