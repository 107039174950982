import { createListenerMiddleware } from "@reduxjs/toolkit";
import { fetchCommentRedactionDetails } from ".";
import { openRedactCommentModal } from "../redactCommentSlice";

const redactCommentListener = createListenerMiddleware();

redactCommentListener.startListening({
  actionCreator: openRedactCommentModal,
  effect: async (action, listenerApi) => {
    const commentId = action.payload;
    await listenerApi.dispatch(fetchCommentRedactionDetails(commentId));
  },
});

export { redactCommentListener };
