import React from "react";
import { useAppSelector, useAppDispatch } from "store";

import { UserProfileProvider } from "context/UserProfileContext";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";
import { createUser } from "services/users";

import { UserProfileEditor } from "common-layouts/UserProfileEditor";
import { ErrorScreen } from "components/ErrorScreen";

import { CreateUserBody } from "ts/user";
import { FormType } from "ts/enums/formType";
import { RoleType, ErrorType } from "@explorance/mly-types";
import { PageErrorType } from "ts/enums/pageErrorType";

export const CreateProfilePage = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const createNewUser = async (requestBody: CreateUserBody) => {
    let isUsernameDuplicate = false;
    let isError = false;
    let isRoleQuotaReached = false;
    await createUser(requestBody)
      .then(() => dispatch(showToastSuccess("toast.userProfileUpdated")))
      .catch((error) => {
        isError = true;
        const errorStatus = error.response?.status;
        switch (errorStatus) {
          case 409:
            isUsernameDuplicate = true;
            break;
          case 423:
            error.response?.data.type === ErrorType.ResourceLocked
              ? dispatch(showToastError("toast.userListImporting"))
              : (isRoleQuotaReached = error.response?.data.type === ErrorType.QuotaExceeded);
            break;
          default:
            dispatch(showToastError("toast.defaultError"));
        }
      });
    return { isUsernameDuplicate, isRoleQuotaReached, isError };
  };

  if (currentUser.roleType === RoleType.Viewer || currentUser.roleType === RoleType.Analyst) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <UserProfileProvider>
      <UserProfileEditor formType={FormType.Create} handleSaveUser={createNewUser} />
    </UserProfileProvider>
  );
};
