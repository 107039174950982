import React, { useEffect } from "react";
import styled from "styled-components";
import { upperFirst } from "lodash";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import { useResource } from "hooks/useResource";

import emptySvg from "assets/images/redaction-request-list-empty-state.svg";

import { useAppDispatch, useAppSelector } from "store";
import { getDayMonthYearFromDate } from "utils/formatters";
import { getInternationalDateFormat } from "utils/getInternationalDateFormat";
import { isAnyAdmin } from "utils/isAnyAdmin";
import { voidFunc } from "utils/voidFunc";
import useTableSize from "hooks/useTableSize";
import {
  changeListView,
  changeSelectedDropdownItem,
  clearSelection,
  clearState,
  setAllStatusFilters,
  setCurrentEditedRow,
  setCurrentPage,
  setCurrentStatusFilters,
  setDropdownMenuContent,
  setIsLoading,
  setListView,
  toggleRedactionRequestSelection,
  toggleSelectAll,
  updateRedactionRequestListSorting,
} from "store/redaction/redactionRequestListSlice";
import { setSearchTerm } from "store/search/searchbarSlice";

import { SearchRow } from "components/SearchRow";
import { Pagination } from "components/Pagination";
import { EmptyState } from "components/EmptyState";
import { StyledTablePageHeader } from "components/Table/TablePageHeader";
import { ErrorScreen } from "components/ErrorScreen";
import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { TextTruncator } from "components/TextTruncator";
import { Text } from "components/Text";
import { ListPageHeaderPlaceholder } from "common-layouts/ListPagePlaceholder/ListPageHeaderPlaceholder";
import { TableInformation } from "components/Table/ClickableTableHeader";
import { ViewSelection } from "components/ViewSelection/ViewSelection";
import { BackButton } from "components/_buttons/BackButton";
import { TableFilterDropdown } from "components/TableFilterDropdown";
import { UserAvatar } from "components/UserAvatar.tsx";

import { FlexTableSize, FlexTableType } from "ts/enums/flexTable";
import { Color } from "ts/enums/color";
import { RedactionAction, RedactionListSortingParameter, RoleType } from "@explorance/mly-types";
import { PageErrorType } from "ts/enums/pageErrorType";
import { RedactionRequestListSortingParameter } from "ts/enums/sorting";
import { AvatarSize } from "ts/enums/avatarSize";
import { RedactionRequestStatus } from "ts/enums/redaction";
import { RequestsView } from "ts/enums/requestListView";
import { Button } from "components/_buttons/Button";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { fetchRedactionRequests, patchRedactionRequest } from "store/redaction/thunks";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { RedactionRequest } from "ts/redaction";

export const RedactionRequestList = () => {
  const { getResource } = useResource();
  const { currentUser } = useAppSelector((state) => state.auth);
  const searchTerm = useAppSelector((state) => state.search.searchTerm);
  const state = useAppSelector((state) => state.redactionRequestList);
  const tableSize = useTableSize();
  const queryParams = new URLSearchParams(location.search);

  const hasNoResults =
    state.redactionRequests?.length === 0 &&
    (state.currentStatusFilters.length !== 0 || searchTerm !== "");

  const REDACTION_REQUEST_HEADERS: TableInformation<RedactionRequestListSortingParameter>[] =
    Object.values(RedactionRequestListSortingParameter)
      .filter((param) => {
        if (tableSize === FlexTableSize.Limited && state.listView !== RequestsView.MyRequests) {
          return param !== RedactionRequestListSortingParameter.AnalysisName;
        }
        return state.listView === RequestsView.MyRequests
          ? param !== RedactionRequestListSortingParameter.SubmittedBy
          : true;
      })
      .map((vm) => ({
        headerName: vm,
        sortingParameter: RedactionListSortingParameter[upperFirst(vm)],
      }));

  // hooks
  const dispatch = useAppDispatch();
  const history = useHistory();
  // functions
  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const { columnIndex, order } = updatedSorting;
    const sortColumn = REDACTION_REQUEST_HEADERS[columnIndex]
      .headerName as RedactionRequestListSortingParameter;

    dispatch(updateRedactionRequestListSorting({ sortColumn, sortOrder: order }));
    dispatch(fetchRedactionRequests());
  };

  const getDotsMenuContents = (redactionRequest: RedactionRequest) => {
    return [
      {
        label: <Text resource="redaction.request.viewRequest" />,
        onClick: voidFunc,
      },
      {
        label: <Text resource="redaction.request.approve" />,
        onClick: async () => {
          await dispatch(
            patchRedactionRequest({
              redactionRequestId: redactionRequest.requestId,
              redactionAction: RedactionAction.Approve,
            })
          );
          dispatch(fetchRedactionRequests());
        },
        show:
          state.listView === RequestsView.AllRequests &&
          redactionRequest.status === RedactionRequestStatus.pending,
      },
      {
        label: <Text resource="redaction.request.reject" />,
        onClick: async () => {
          await dispatch(
            patchRedactionRequest({
              redactionRequestId: redactionRequest.requestId,
              redactionAction: RedactionAction.Reject,
            })
          );
          dispatch(fetchRedactionRequests());
        },
        show:
          state.listView === RequestsView.AllRequests &&
          redactionRequest.status === RedactionRequestStatus.pending,
      },
      {
        label: <Text resource="redaction.request.archive" />,
        onClick: async () => {
          await dispatch(
            patchRedactionRequest({
              redactionRequestId: redactionRequest.requestId,
              redactionAction: RedactionAction.Archive,
            })
          );
          dispatch(fetchRedactionRequests());
        },
        show: state.listView === RequestsView.AllRequests,
      },
      {
        label: <Text resource="redaction.request.removeFromArchive" />,
        onClick: async () => {
          await dispatch(
            patchRedactionRequest({
              redactionRequestId: redactionRequest.requestId,
              redactionAction: RedactionAction.UnArchive,
            })
          );
          dispatch(fetchRedactionRequests());
        },
        show: state.listView === RequestsView.ArchivedRequests,
      },
    ];
  };

  const getRequestStatusLabel = (status: RedactionRequestStatus) => {
    const statusColorMapping = {
      [RedactionRequestStatus.pending]: [Color.orange90, Color.orange20],
      [RedactionRequestStatus.approved]: [Color.green50, Color.green10],
      [RedactionRequestStatus.rejected]: [Color.red40, Color.red10],
    };
    const [mainColor, secondaryColor] = statusColorMapping[status];

    return (
      <StyledStatusPill backgroundColor={secondaryColor}>
        <StyledStatusCircle backgroundColor={mainColor} />
        <TextTruncator
          customWidth="70%"
          value={
            <p style={{ color: mainColor }}>
              <Text resource={`redaction.request.status.[${status}]`} />
            </p>
          }
        />
      </StyledStatusPill>
    );
  };

  const getColumnWidths = () => {
    if (state.listView === RequestsView.MyRequests) {
      return ["17%", "26%", "26%", "14%", "14%", "3%"];
    }
    return tableSize === FlexTableSize.Full
      ? ["12%", "12%", "24%", "24%", "12.5%", "12.5%", "3%"]
      : ["17%", "26%", "26%", "14%", "14%", "3%"];
  };

  const clearSearchFilters = () => {
    dispatch(setSearchTerm(""));
    dispatch(setCurrentStatusFilters([]));
    dispatch(fetchRedactionRequests());
  };

  const handleChangeStatusFilters = (statusFilters: string[]) => {
    dispatch(setCurrentStatusFilters(statusFilters));
    dispatch(setCurrentPage(1));
    dispatch(fetchRedactionRequests());
  };

  const onSearch = () => {
    dispatch(setCurrentPage(1));
    dispatch(fetchRedactionRequests());
  };

  const handlePageSelection = (page: number) => {
    dispatch(setCurrentPage(page));
    dispatch(fetchRedactionRequests());
  };

  const updateFilters = (newFilter: string) => {
    if (!state.currentStatusFilters.includes(newFilter)) {
      const copy = [...state.currentStatusFilters];
      copy.push(newFilter);
      handleChangeStatusFilters(copy);
    } else {
      handleChangeStatusFilters(state.currentStatusFilters.filter((f) => f !== newFilter));
    }
    dispatch(fetchRedactionRequests());
  };

  const handleChangeListView = (view: RequestsView) => {
    if (view !== state.listView) {
      dispatch(setIsLoading(true));
      dispatch(changeListView(view));
      dispatch(changeSelectedDropdownItem(view));
      queryParams.set("view", view);
      history.replace(location.pathname + location.hash + "?" + queryParams.toString());
      dispatch(fetchRedactionRequests());
    }
  };

  useEffect(() => {
    const listViewParam = queryParams.get("view") as RequestsView;
    const isListViewValid = Object.values(RequestsView).includes(listViewParam);

    const initialListView = listViewParam || RequestsView.MyRequests;

    if (!isListViewValid) {
      queryParams.set("view", initialListView);
      history.push(location.pathname + location.hash + "?" + queryParams.toString());
    }

    dispatch(setListView(initialListView));
    dispatch(
      setDropdownMenuContent(
        [
          isAnyAdmin(currentUser.roleType) && {
            label: <Text resource="redaction.request.list.view.AllRequests" />,
            value: RequestsView.AllRequests,
            isActive: initialListView === RequestsView.AllRequests,
          },
          {
            label: <Text resource="redaction.request.list.view.MyRequests" />,
            value: RequestsView.MyRequests,
            isActive: initialListView === RequestsView.MyRequests,
          },
          {
            label: <Text resource="redaction.request.list.view.ArchivedRequests" />,
            value: RequestsView.ArchivedRequests,
            isActive: initialListView === RequestsView.ArchivedRequests,
          },
        ].filter(Boolean)
      )
    );

    dispatch(setAllStatusFilters(Object.values(RedactionRequestStatus)));

    dispatch(fetchRedactionRequests());

    return () => {
      dispatch(clearState());
    };
  }, [dispatch]); //eslint-disable-line

  if (currentUser.roleType === RoleType.Viewer) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <>
      {state.isLoading || !state.dropdownMenuContent ? (
        <ListPageHeaderPlaceholder
          showCreateButton={false}
          showGoBack
          showFilterButton={true}
          buttonPlaceholderWidth={236}
          applyMorePadding={false}
        />
      ) : (
        <div>
          <StyledTablePageHeader>
            <BackButton
              resourceKey="button.backToRedactionList"
              onClick={() => history.push(routes.redactionListPage)}
            />
            <StyledHeaderTopRow>
              <ViewSelection
                dropdownMenuContent={state.dropdownMenuContent}
                handleSelectView={handleChangeListView}
                dropdownWidth="200px"
              />
              <SearchRow
                dataType="searchBar.dataType.redaction.request"
                currentCount={state.currentRedactionRequestCount}
                totalCount={state.totalRedactionRequestCount}
                customStyles={{ width: "500px" }}
                onSearch={onSearch}
              />
            </StyledHeaderTopRow>
          </StyledTablePageHeader>
          {state.emptyStateType !== EmptyStateType.noListResults && (
            <StyledPaginationContainer>
              <TableFilterDropdown
                currentFilters={state.currentStatusFilters}
                allFilters={state.allStatusFilters}
                nameKey="button.filter.byStatus"
                valueLabelWrapper={(val) => getResource(`redaction.request.status.[${val}]`)}
                updateFilters={updateFilters}
                deleteFilters={() => handleChangeStatusFilters([])}
              />
              {!hasNoResults && (
                <Pagination
                  currentPage={state.currentPage}
                  currentItemsCount={state.currentRedactionRequestCount}
                  handlePageSelection={handlePageSelection}
                />
              )}
            </StyledPaginationContainer>
          )}
          {state.selectedRedactionRequests.length > 0 && (
            <StyledBulkSelectionContainer>
              <StyledCountContainer>
                <StyledSelectCount>
                  <Text
                    resource={{
                      key:
                        state.selectedRedactionRequests.length > 1
                          ? "redaction.request.selectedComments"
                          : "redaction.request.selectedComments.singular",
                      args: [`${state.selectedRedactionRequests.length}`],
                    }}
                  />
                </StyledSelectCount>
                <StyledClearAll onClick={() => dispatch(clearSelection())}>
                  <Text resource="redaction.request.clearSelection" />
                </StyledClearAll>
              </StyledCountContainer>
              <StyledActionsContainer>
                <Button variant={ButtonVariant.outline} size={ButtonSize.sm}>
                  <Text resource="redaction.request.bulk.rejectAll" />
                </Button>
                <Button variant={ButtonVariant.outlineBlue} size={ButtonSize.sm}>
                  <Text resource="redaction.request.bulk.approveAll" />
                </Button>
                <Button variant={ButtonVariant.primary} size={ButtonSize.sm}>
                  <Text resource="redaction.request.bulk.view" />
                </Button>
              </StyledActionsContainer>
            </StyledBulkSelectionContainer>
          )}
        </div>
      )}
      {state.totalRedactionRequestCount > 0 || state.isLoading ? (
        <FlexTable
          type={FlexTableType.Table}
          data={{
            headers: REDACTION_REQUEST_HEADERS.map((h, i) => (
              <Text key={i} resource={`redaction.request.table.[${h.headerName}]`} />
            )).concat(<></>),
            rows: state.redactionRequests?.map((redactionRequest) => ({
              isSelected: redactionRequest.isSelected,
              contentId: redactionRequest.requestId,
              data: [
                (tableSize === FlexTableSize.Full ||
                  state.listView === RequestsView.MyRequests) && [
                  <StyledAnalysisName key={redactionRequest.requestId}>
                    <TextTruncator
                      value={redactionRequest.analysisName}
                      id={redactionRequest.requestId}
                      key={redactionRequest.requestId}
                      customWidth="85%"
                    />
                  </StyledAnalysisName>,
                ],
                state.listView !== RequestsView.MyRequests && [
                  <UserAvatar
                    key={`avatar-${redactionRequest.requestId}`}
                    firstName={redactionRequest.requesterFirstname}
                    lastName={redactionRequest.requesterLastname}
                    userId={null}
                    avatarSize={AvatarSize.sm}
                    showUserFullName
                  />,
                ],
                <StyledRedactionRequestComment key={redactionRequest.requestId}>
                  {redactionRequest.originalValue}
                </StyledRedactionRequestComment>,
                <StyledRedactionRequestComment key={redactionRequest.requestId}>
                  {redactionRequest.redactedValue}
                </StyledRedactionRequestComment>,
                getRequestStatusLabel(redactionRequest.status),
                <span key={`last-updated-${redactionRequest.requestId}`}>
                  {getInternationalDateFormat(
                    getDayMonthYearFromDate(redactionRequest.requestSent)
                  )}
                </span>,
              ].filter(Boolean),
              dotsMenuParams: {
                rowId: redactionRequest.requestId,
                currentEditedRowId: state.currentEditedRow?.requestId,
                clickToClose: true,
                hideDotsButton: state.selectedRedactionRequests.length !== 0,
                menuContents: getDotsMenuContents(redactionRequest),
                onDotMenuClick: () => dispatch(setCurrentEditedRow(redactionRequest)),
              },
            })),
            columnWidths: getColumnWidths(),
          }}
          handleDoubleClickRow={voidFunc}
          isSelectable={state.listView === RequestsView.AllRequests}
          customStyles={{
            rows: {
              padding: "10px",
              backgroundColor: Color.white,
            },
          }}
          initialSorting={{
            columnIndex: REDACTION_REQUEST_HEADERS.findIndex(
              (h) => h.headerName === state.sortColumn
            ),
            order: state.sortOrder,
          }}
          onSortingChange={updateSorting}
          onSelectionChange={(id) => dispatch(toggleRedactionRequestSelection(id))}
          handleSelectAll={() => dispatch(toggleSelectAll())}
          disableCardSelection
          isLoading={state.isLoading}
        />
      ) : (
        <EmptyState
          type={state.emptyStateType}
          image={emptySvg}
          titleKey="emptyState.noRedactionRequests.title"
          captionKey="emptyState.noRedactionRequests.caption"
          handleClickCaptionLink={() => clearSearchFilters()}
        />
      )}
    </>
  );
};

const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
`;

const StyledHeaderTopRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const StyledRedactionRequestComment = styled.div`
  display: -webkit-box;
  overflow: hidden;
  margin: 6px;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  color: ${Color.gray50};
`;

const StyledStatusPill = styled.div<{ backgroundColor: Color }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 5px;
  max-width: 90px;
  height: 35px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const StyledStatusCircle = styled.div<{ backgroundColor: Color }>`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const StyledBulkSelectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledActionsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledCountContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  font-weight: bold;
`;

const StyledSelectCount = styled.div`
  font-size: 16px;
`;

const StyledClearAll = styled.div`
  font-size: 12px;
  text-decoration: underline;
  color: ${Color.blue50};
  cursor: pointer;
`;

const StyledAnalysisName = styled.div`
  font-size: 14px;
`;
