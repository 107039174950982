export enum PopupPosition {
  topStart = "top-start",
  topEnd = "top-end",
  top = "top",
  bottomStart = "bottom-start",
  bottomEnd = "bottom-end",
  bottom = "bottom",
  rightStart = "right-start",
  rightEnd = "right-end",
  right = "right",
  leftStart = "left-start",
  leftEnd = "left-end",
  left = "left",
  autoStart = "auto-start",
  autoEnd = "auto-end",
  auto = "auto",
}
