import React from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";
import { SearchbarInputCustomStyle, Searchbar } from "components/_inputs/Searchbar";
import { Text } from "components/Text";

type Props = {
  currentCount: number;
  totalCount: number;
  dataType: string;
  placeholderText?: string;
  customStyles?: SearchbarInputCustomStyle;
  isModal?: boolean;
  onSearch?: () => void;
};

export const SearchRow = ({
  currentCount,
  totalCount,
  dataType,
  placeholderText,
  customStyles,
  isModal,
  onSearch,
}: Props) => {
  const { getResource } = useResource();
  return (
    <StyledSearchBarRow>
      <StyledSearchContainer>
        <Searchbar
          onSearch={onSearch}
          placeholder={placeholderText ?? getResource("searchBar.placeholder")}
          customStyles={customStyles}
          isModal={isModal}
        />
      </StyledSearchContainer>

      <StyledTotalDataset>
        <StyledCount>
          {totalCount !== currentCount ? (
            <>
              {currentCount}
              <Text resource={{ key: "searchBar.label.countOfTotal", args: [`${totalCount}`] }} />
            </>
          ) : (
            currentCount + " "
          )}
        </StyledCount>{" "}
        <span>
          <Text
            resource={{
              key:
                totalCount > 1 || totalCount === 0 ? `${dataType}.plural` : `${dataType}.singular`,
            }}
          />
        </span>
      </StyledTotalDataset>
    </StyledSearchBarRow>
  );
};

const StyledSearchBarRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSearchContainer = styled.div`
  margin: 0 15px;
`;

const StyledTotalDataset = styled.div`
  font-family: Lato, sans-serif;
  font-size: 0.875em;
  font-weight: normal;
  margin: 6px 12px 6px 0;
  float: left;
`;

const StyledCount = styled.span`
  font-weight: bold;
`;
