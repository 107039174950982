import { combineReducers } from "@reduxjs/toolkit";
import analysisListSlice from "store/analysis/analysisListSlice";
import authSlice from "./auth/authSlice";
import customModelListSlice from "./customModels/customModelListSlice";
import exportedFilesListSlice from "./exportedFiles/exportedFilesListSlice";
import glossariesListSlice from "./glossaries/glossariesListSlice";
import userListSlice from "./users/usersListSlice";
import feedbackSlice from "./feedback/feedbackSlice";
import userListHeaderSlice from "./users/usersListHeaderSlice";
import importUsersSlice from "./users/importUsersSlice";
import editVariableMappingSlice from "./editVariableMapping/editVariableMappingSlice";
import settingsSlice from "./analysisSettings/settingsSlice";
import dataSourceSlice from "./analysisSettings/dataSourceSlice";
import homePageSlice from "./home/homePageSlice";
import widgetGroupsSlice from "./widgets/widgetGroupsSlice";
import searchbarSlice from "./search/searchbarSlice";
import toastSlice from "./toast/toastSlice";
import quickAnalysisSlice from "./analysis/quickAnalysisSlice";
import widgetOrderingModal from "./widgetOrdering/widgetOrderingModal";
import resourceSlice from "./resources/resourceSlice";
import redactionListSlice from "./redaction/redactionListSlice";
import modelManagementSlice from "./licenseManagement/modelManagementSlice";
import quotaMenuSlice from "./licenseManagement/quotaMenuSlice";
import sharingSlice from "./sharing/sharingSlice";
import sharedWithSlice from "./sharing/sharedWithSlice";
import userSelectionSlice from "./sharing/userSelectionSlice";
import permissionSlice from "./sharing/permissionSlice";
import editSharingBlockSlice from "./sharing/editSharingBlockSlice";
import userSelectionPopupSlice from "./sharing/userSelectionPopupSlice";
import socketSlice from "./wsStream/wsSlice";
import discussionThreadSlice from "./notes/discussionThreadSlice";
import pinSlice from "./pin/pinSlice";
import redactionBuilderSlice from "./redaction/redactionBuilderSlice";
import redactCommentSlice from "./redaction/redactCommentSlice";
import redactionModalSlice from "./redaction/redactionModalSlice";
import redactionRequestListSlice from "./redaction/redactionRequestListSlice";

const appReducer = combineReducers({
  analysisList: analysisListSlice,
  auth: authSlice,
  commentPin: pinSlice,
  redactionBuilder: redactionBuilderSlice,
  customModelList: customModelListSlice,
  dataSource: dataSourceSlice,
  discussionThread: discussionThreadSlice,
  editSharingBlock: editSharingBlockSlice,
  editVariableMapping: editVariableMappingSlice,
  exportedFilesList: exportedFilesListSlice,
  feedbackPopup: feedbackSlice,
  glossariesList: glossariesListSlice,
  home: homePageSlice,
  importUsers: importUsersSlice,
  modelManagement: modelManagementSlice,
  permissions: permissionSlice,
  quickAnalysis: quickAnalysisSlice,
  quotaMenu: quotaMenuSlice,
  redactComment: redactCommentSlice,
  redactionList: redactionListSlice,
  redactionModal: redactionModalSlice,
  redactionRequestList: redactionRequestListSlice,
  resourcesSlice: resourceSlice,
  search: searchbarSlice,
  settings: settingsSlice,
  sharedWith: sharedWithSlice,
  sharing: sharingSlice,
  sharingUserSelection: userSelectionSlice,
  toast: toastSlice,
  userList: userListSlice,
  userListHeader: userListHeaderSlice,
  userSelectionPopup: userSelectionPopupSlice,
  widgetGroups: widgetGroupsSlice,
  widgetOrdering: widgetOrderingModal,
  wsStream: socketSlice,
});

export type AppState = ReturnType<typeof appReducer>;

export default appReducer;
