import React, { useState } from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";
import { useQueryParams } from "hooks/useQueryParams";
import { deleteWidgetGroup } from "services/widgets";

import { CreateNewWidget } from "./CreateNewWidget";
import { Header } from "./Header";
import { Widget } from "components/Widget";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";

import { WidgetGroup } from "ts/widget";
import { Color } from "ts/enums/color";

type Props = {
  title: string;
  widgetGroup: WidgetGroup;
  updatedWidgetId: number;
  showCreateNewWidget: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  refetch: () => void;
  onWidgetSave: (widgetId: number) => void;
  onWidgetAdd: (widgetId: number) => void;
  onWidgetDelete: () => void;
};

export const ExpandableWidgetGroup = ({
  title,
  widgetGroup,
  updatedWidgetId,
  showCreateNewWidget,
  setIsExpanded,
  refetch,
  onWidgetSave,
  onWidgetAdd,
  onWidgetDelete,
}: Props) => {
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState<boolean>(false);

  const { getResource } = useResource();
  const { sharingPreview } = useQueryParams();

  const isEmptyGroupInSharing = sharingPreview && widgetGroup.widgets.length === 0;

  const handleDeleteGroup = async () => {
    try {
      await deleteWidgetGroup(widgetGroup.id);
      setShowDeleteGroupModal(false);
      refetch();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <StyledExpandableWidgetGroup
      isExpanded={isEmptyGroupInSharing ? false : widgetGroup.isExpanded}
    >
      <Header
        title={title}
        handleExpand={setIsExpanded}
        isEmptyGroupInSharing={isEmptyGroupInSharing}
        handleShowDeleteGroupModal={() => setShowDeleteGroupModal(true)}
        widgetGroup={widgetGroup}
      />
      <StyledWidgetListContainer isExpanded={widgetGroup.isExpanded}>
        <StyledWidgetList>
          {widgetGroup.widgets?.map((widget) => (
            <Widget
              key={widget.id}
              onDelete={onWidgetDelete}
              onSave={onWidgetSave}
              groupId={widgetGroup.id}
              widget={widget as any}
              updatedWidgetId={updatedWidgetId}
            />
          ))}
          {showCreateNewWidget && (
            <CreateNewWidget onCreate={onWidgetAdd} groupId={widgetGroup.id} />
          )}
        </StyledWidgetList>
      </StyledWidgetListContainer>
      <ActionConfirmationModal
        isOpen={showDeleteGroupModal}
        title={getResource("modal.deleteGroup")}
        caption={getResource("modal.deleteGroup.caption")}
        actionButtonLabel={getResource("button.delete")}
        onCancel={() => setShowDeleteGroupModal(false)}
        onClose={() => setShowDeleteGroupModal(false)}
        onClickActionButton={handleDeleteGroup}
        margin="200px auto"
      />
    </StyledExpandableWidgetGroup>
  );
};

const StyledExpandableWidgetGroup = styled.div<{ isExpanded: boolean }>`
  margin-bottom: 24px;
  padding: 16px 24px;
  border-radius: 5px;
  border: 1px solid ${Color.sky60};
  background-color: ${({ isExpanded }) => (isExpanded ? Color.sky30 : Color.sky15)};
  font-size: 15px;
`;

const StyledWidgetListContainer = styled.div<{ isExpanded: boolean }>`
  opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
  max-height: ${({ isExpanded }) => !isExpanded && "0px"};
  overflow: ${({ isExpanded }) => (isExpanded ? "visible" : "hidden")};
  transition: ${({ isExpanded }) => isExpanded && "opacity 300ms ease-out"};
  cursor: default;
`;

const StyledWidgetList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
`;
