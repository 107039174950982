import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Tooltip } from "components/Tooltip";
import { Color } from "ts/enums/color";

type Props = {
  id: number;
  demographicTitle: string;
  demographicValue: string;
};

export const UserDetailRow = ({ id, demographicTitle, demographicValue }: Props) => {
  const [showTitleTooltip, setShowTitleTooltip] = useState<boolean>(false);

  const titleRef = useRef<HTMLDivElement>();

  const handleShowTooltip = () => {
    if (titleRef.current) {
      setShowTitleTooltip(titleRef.current.scrollHeight > titleRef.current.offsetHeight);
    }
  };

  return (
    <>
      <StyledDemographicTitle
        ref={titleRef}
        data-tooltip-id={`demographic-${id}`}
        onMouseOver={handleShowTooltip}
        onMouseOut={() => setShowTitleTooltip(false)}
      >
        {demographicTitle}
        {showTitleTooltip && <Tooltip tooltipId={`demographic-${id}`} content={demographicTitle} />}
      </StyledDemographicTitle>
      <StyledPopupValue>{demographicValue}</StyledPopupValue>
    </>
  );
};

const StyledPopupValue = styled.div`
  color: ${Color.gray50};
  font-size: 14px;
`;

const StyledDemographicTitle = styled(StyledPopupValue)`
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-lines: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;
