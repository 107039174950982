import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import styled from "styled-components";
import axios, { AxiosError } from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { pullAt, concat, findIndex, isEmpty, cloneDeep } from "lodash";

import { getTopicStatisticsByAnalysisId } from "services/analysis";

import {
  sortTopicResults,
  recurseOverTopics,
  getMappedTopic,
  getDominantSentimentForTopic,
} from "utils/topics";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { getCustomModelId } from "utils/getCustomModelId";
import { formatDemographicsForApi } from "utils/filters";

import { useQueryParams } from "hooks/useQueryParams";
import { useResource } from "hooks/useResource";

import { AnalysisContext } from "context/AnalysisContext";
import { setAnalysisError, handleAxiosError } from "context/AnalysisContext/actions";

import { AnalysisFilterDrawerLayout } from "common-layouts/FilterDrawerLayout";
import { FilterPillList } from "common-layouts/FilterSelectionSection/FilterPillList";
import { TopicsTable } from "pages/analysis/[id]/topics/_layouts/TopicsTable";
import { SideDrawer } from "components/SideDrawer";
import { EmptyState } from "components/EmptyState";
import { TopicViewToggle } from "components/TopicViewToggle";
import { ErrorScreen } from "components/ErrorScreen";

import { SHARING_PREVIEW_HEADER_HEIGHT } from "assets/constants/sharingPreviewHeaderHeight";
import { TopicsSortingParameter } from "ts/enums/sorting";
import { Topic, mapDataToTopic } from "ts/topic";
import { PageErrorType } from "ts/enums/pageErrorType";
import { SharingTopicExplorerAccess, SortingOrder, TopicsListView } from "@explorance/mly-types";
import { Color } from "ts/enums/color";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

export const TopicsPage = () => {
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>(SortingOrder.DESC);
  const [sortingParameter, setSortingParameter] = useState<TopicsSortingParameter>(
    TopicsSortingParameter.Comments
  );
  const [topics, setTopics] = useState<Topic[]>([]);
  const [sideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [view, setView] = useState<TopicsListView>(TopicsListView.Sentiments);
  const [totals, setTotals] = useState<Topic>();

  const [state, dispatch] = useContext(AnalysisContext);

  const history = useHistory();
  const { getResource } = useResource();
  const cancelTokenSourceRef = useRef(null);
  const location = useLocation();
  const { sharingPreview, sharingId, expandedRowId, sharedWithPage, step } = useQueryParams();

  const customModelId = getCustomModelId(state);

  // functions
  const sortTopics = (newSortingParameter: TopicsSortingParameter) => {
    if (newSortingParameter === sortingParameter) {
      return sortingOrder === SortingOrder.DESC
        ? setSortingOrder(SortingOrder.ASC)
        : setSortingOrder(SortingOrder.DESC);
    }
    setSortingOrder(SortingOrder.DESC);
    setSortingParameter(newSortingParameter);
  };

  const changeView = (view: TopicsListView) => {
    setView(view);
    setTopics([]);
    setTotals(null);
    setSortingParameter(TopicsSortingParameter.Comments);
    setSortingOrder(SortingOrder.DESC);
    history.replace(
      history.location.pathname +
        getUrlQueryString({
          custom_model_id: customModelId,
          view: view.toLowerCase(),
          sharingPreview,
          expandedRowId,
          sharedWithPage,
          sharingId,
          step,
        })
    );
  };

  useEffect(() => {
    cancelTokenSourceRef.current = axios.CancelToken.source();
    return () => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel("Topics page unmounted");
      }
    };
  }, [view]);

  // change view if navigating from a recommendations view in the comments page
  useEffect(() => {
    if (location.search.includes("recommendations") && view === TopicsListView.Sentiments) {
      return setView(TopicsListView.Recommendations);
    }
  }, []); // eslint-disable-line

  // fetch the table data
  useEffect(() => {
    if (state.loadingAnalysisModels) return;

    const fn = async () => {
      // if the analysis id is not a number, go to the Analysis Not Found error page
      if (isNaN(state.analysisDetails.id)) {
        return dispatch(setAnalysisError(PageErrorType.AnalysisNotFound));
      }
      try {
        setIsLoading(true);

        const requestBody = {
          topics: state.selectedTopicNodes.map((tn) => tn.fullPath),
          demographics: formatDemographicsForApi(state.selectedDemographicFilters),
          threshold: state.threshold,
          categories: state.selectedCategories,
          ...(state.selectedColumnFilters.length > 0 && {
            selectedColumns: state.selectedColumnFilters,
          }),
        };

        const tableDataResponse = await getTopicStatisticsByAnalysisId(
          state.analysisDetails.id,
          getUrlQueryString({
            custom_model_id: customModelId,
            view,
            user_id: state.previewUser.id,
            sharing_id: sharingId,
          }),
          requestBody,
          cancelTokenSourceRef.current.token
        );

        const mappedTopics =
          view === TopicsListView.Sentiments
            ? tableDataResponse.data.topics.map((t) => mapDataToTopic(t))
            : tableDataResponse.data.topics.map((t) => t as Topic);
        setTopics([...mappedTopics].map((t) => recurseOverTopics(t, getMappedTopic)));

        const topicWithTotal: Topic = {
          ...tableDataResponse.data.total,
          id: state.analysisDetails.id,
          parentId: null,
          name: getResource("topics.totalRow"),
          fullPath: getResource("topics.totalRow"),
          dominantSentiment: getDominantSentimentForTopic(tableDataResponse.data.total),
          topics: [],
        };
        setTotals(topicWithTotal);
        setIsLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          setIsLoading(false);
          dispatch(handleAxiosError(err as AxiosError<any>));
        }
      }
    };

    fn();

    //eslint-disable-next-line
  }, [
    state.analysisDetails.id,
    state.selectedDemographicFilters,
    state.selectedCategories,
    state.selectedTopicNodes,
    state.threshold,
    state.selectedColumnFilters,
    state.previewUser.id,
    state.loadingAnalysisModels,
    customModelId,
    view,
    dispatch,
  ]);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    customModelId
      ? params.set("custom_model_id", customModelId.toString())
      : params.delete("custom_model_id");
    history.replace(history.location.pathname + "?" + params.toString());
  }, [history, customModelId]);

  const sortedCatTopics = useMemo(() => {
    if (topics?.length < 0) return [];
    const sortedTopics = sortTopicResults([...topics], sortingParameter, sortingOrder);

    const withSortedChildTopicsArray = (t) => {
      if (isEmpty(t.topics)) return t;
      const topicsArray = cloneDeep(t.topics);
      const generalIndex = findIndex(
        topicsArray,
        ({ name }) => name === getResource("ML.topic.[General]")
      );
      if (generalIndex >= 0) {
        const generalTopic = pullAt(topicsArray, generalIndex);
        if (sortingOrder === SortingOrder.ASC) {
          return { ...t, topics: concat(generalTopic, topicsArray) };
        } else if (sortingOrder === SortingOrder.DESC) {
          return { ...t, topics: concat(topicsArray, generalTopic) };
        }
      } else return t;
    };

    const resortedTopicsByGeneral = isEmpty(sortedTopics)
      ? []
      : sortedTopics.map((t) => recurseOverTopics(t, withSortedChildTopicsArray));

    const finalArray = totals ? [...resortedTopicsByGeneral, totals] : [];
    return finalArray;
  }, [topics, sortingParameter, sortingOrder, totals]); //eslint-disable-line

  if (
    state.analysisDetails.sharing &&
    state.analysisDetails.sharing.topicExplorerAccess !== SharingTopicExplorerAccess.Shared
  ) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <div className="fade-in">
      <SideDrawer
        render={AnalysisFilterDrawerLayout}
        isOpen={sideDrawerOpen}
        closeHandler={() => setSideDrawerOpen(false)}
        width="600px"
      />
      <StyledButtonsContainer
        whiteBackground={state.showWhiteBackground}
        isSharingPreview={!!sharingPreview}
      >
        <StyledFilterPillListContainer>
          <FilterPillList
            containerMargin="12px 0 16px 0"
            openFiltersMenu={() => setSideDrawerOpen(true)}
          />
        </StyledFilterPillListContainer>
        <TopicViewToggle currentView={view} toggleView={changeView} isLoading={isLoading} />
      </StyledButtonsContainer>
      <TopicsTable
        topics={sortedCatTopics}
        sortingOrder={sortingOrder}
        sortingParameter={sortingParameter}
        showPlaceholder={isLoading}
        view={view}
        analysisHeaderHeight={168}
        onSort={sortTopics}
      />
      {topics.every((t) => t.comments === 0) && totals?.comments === 0 && !isLoading && (
        <EmptyState type={EmptyStateType.noFilterResults} customStyles={{ marginTop: "100px" }} />
      )}
    </div>
  );
};

const StyledFilterPillListContainer = styled.div`
  width: 100%;
`;

const StyledButtonsContainer = styled.div<{ whiteBackground: boolean; isSharingPreview: boolean }>`
  display: flex;
  align-items: baseline;
  background-color: ${({ whiteBackground }) => (whiteBackground ? Color.white : "transparent")};
  transition: background-color 100ms ease-in-out 50ms;
  z-index: ${ZIndexStackingContext.medium};
  width: 100%;
  position: sticky;
  top: ${({ isSharingPreview }) =>
    isSharingPreview ? `calc(100px + ${SHARING_PREVIEW_HEADER_HEIGHT}px)` : "100px"};
`;
