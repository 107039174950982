import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type SearchBarState = {
  showSearchButton: boolean;
  showCancelSearchButton: boolean;
  isSearchActive: boolean;
  searchTerm: string | null;
  modalSearchTerm: string | null;
  isModalSearchActive: boolean;
  showModalSearchButton: boolean;
  showModalCancelSearchButton: boolean;
  loadedInitialSearch: boolean;
  keepSearchState: boolean;
};

const initialState: SearchBarState = {
  showSearchButton: false,
  showCancelSearchButton: false,
  isSearchActive: false,
  showModalCancelSearchButton: false,
  showModalSearchButton: false,
  isModalSearchActive: false,
  searchTerm: "",
  modalSearchTerm: "",
  loadedInitialSearch: false,
  keepSearchState: false,
};

const searchBarSlice = createSlice({
  name: "searchBar",
  initialState,
  reducers: {
    setShowSearchButton: (state, action: PayloadAction<boolean>) => {
      state.showSearchButton = action.payload;
    },
    setShowCancelSearchButton: (state, action: PayloadAction<boolean>) => {
      state.showCancelSearchButton = action.payload;
    },
    setIsSearchActive: (state, action: PayloadAction<boolean>) => {
      state.isSearchActive = action.payload;
    },
    setShowModalSearchButton: (state, action: PayloadAction<boolean>) => {
      state.showModalSearchButton = action.payload;
    },
    setShowModalCancelSearchButton: (state, action: PayloadAction<boolean>) => {
      state.showModalCancelSearchButton = action.payload;
    },
    setModalIsSearchActive: (state, action: PayloadAction<boolean>) => {
      state.isModalSearchActive = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string | null>) => {
      state.searchTerm = action.payload;
      if (action.payload.length > 0 && !state.loadedInitialSearch) {
        state.loadedInitialSearch = true;
        state.isSearchActive = true;
        state.showCancelSearchButton = true;
      }
      if (action.payload.length === 0) {
        state.isSearchActive = false;
        state.showCancelSearchButton = false;
      }
    },
    setModalSearchTerm: (state, action: PayloadAction<string | null>) => {
      state.modalSearchTerm = action.payload;
      if (action.payload.length > 0 && !state.loadedInitialSearch) {
        state.loadedInitialSearch = true;
        state.isModalSearchActive = true;
        state.showModalCancelSearchButton = true;
      }
      if (action.payload.length === 0) {
        state.isModalSearchActive = false;
        state.showModalCancelSearchButton = false;
      }
    },
    setLoadedInitialSearch: (state, action: PayloadAction<boolean>) => {
      state.loadedInitialSearch = action.payload;
    },
    setKeepSearchState: (state, action: PayloadAction<boolean>) => {
      state.keepSearchState = action.payload;
    },
    clearState: (state) => {
      if (!state.keepSearchState) return initialState;
    },
  },
});

export const {
  setShowSearchButton,
  setShowCancelSearchButton,
  setIsSearchActive,
  setModalSearchTerm,
  setShowModalSearchButton,
  setShowModalCancelSearchButton,
  setModalIsSearchActive,
  setSearchTerm,
  setLoadedInitialSearch,
  setKeepSearchState,
  clearState,
} = searchBarSlice.actions;

export default searchBarSlice.reducer;
