import React from "react";
import styled from "styled-components";
import { routes } from "routes";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import robot from "assets/images/robot.png";
import { Color } from "ts/enums/color";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { useHistory } from "react-router-dom";
import { Text } from "components/Text";

export const ExploreAnalysesCard = () => {
  const history = useHistory();

  return (
    <>
      <StyledExploreAnalysesCard>
        <img src={robot} alt="robot" className="img" />
        <StyledRightContent>
          <h3>
            <Text resource="home.card.exploreAnalyses" />
          </h3>
          <p>
            <Text resource="home.card.exploreAnalyses.description" />
          </p>
          <Button
            size={ButtonSize.sm}
            variant={ButtonVariant.primaryPurple}
            style={{ gap: "6px" }}
            onClick={() => history.push(routes.analysisListPage)}
          >
            <Icon type={IconType.barChart} size={12} />
            <Text resource="button.goToAnalysisList" />
          </Button>
        </StyledRightContent>
      </StyledExploreAnalysesCard>
    </>
  );
};

const StyledExploreAnalysesCard = styled.div`
  display: flex;
  align-items: center;
  background-color: ${Color.white};
  width: 370px;
  height: 239px;
  padding: 40px 31px 40px 36px;
  border-radius: 5px;
  .img {
    height: 140px;
    width: 95px;
  }

  @media (max-width: 1366px) {
    padding: 40px 31px 40px 43px;
    margin-top: 24px;
  }
`;

const StyledRightContent = styled.div`
  margin-left: 21px;
  width: 188px;
  height: 110px;
  h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 4px;
    color: ${Color.gray50};
    margin-top: 0;
  }
  p {
    font-size: 14px;
    color: ${Color.gray50};
    margin-bottom: 18px;
    margin-top: 0;
  }

  @media (max-width: 1366px) {
    margin-left: 38px;
    width: 250px;
  }
`;
