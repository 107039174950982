import appConfig from "appSettings.json";

import { LanguageCode } from "@explorance/mly-types";
import { useAppSelector } from "store";
import { useCallback } from "react";

const formatResource = (resource: string, args: string[]) =>
  resource.replace(/{(\d+)}/g, (match, number) => args[number] ?? match);

export type GetResourceFunction = (resourceKey: string, ...args: any[]) => string;

const logWarnings = (missingKeys, resourceKey, preferredLanguage) => {
  // Condition prevents logging duplicate warnings
  if (missingKeys.has(resourceKey)) return;

  missingKeys.add(resourceKey);
  console.warn(`${resourceKey} is missing from ${preferredLanguage} resources.`);
};

export const useResource = (userLanguage = null, resources = null, topicResources = null) => {
  // redux
  const reduxUseResource = resources !== null && topicResources !== null;
  if (!reduxUseResource) {
    const state = useAppSelector((state) => state.resourcesSlice); //eslint-disable-line
    const { currentUser } = useAppSelector((state) => state.auth); //eslint-disable-line
    resources = state.resources;
    topicResources = state.topicResources;
    userLanguage = currentUser?.preferredLanguage;
  }

  const missingKeys = new Set<string>();

  const getResource: GetResourceFunction = (resourceKey: string, ...args) => {
    if (!topicResources || !resources) return;

    const preferredLanguage = userLanguage || LanguageCode.Eng; // Default to ENG if user language is not defined
    const resource = resources[preferredLanguage][resourceKey];

    // Conditions
    const foundResource = resource !== undefined;
    const foundEnglishResource = resources.Eng[resourceKey] !== undefined;
    const isDev = appConfig.env === "Dev";

    // Returns
    if (foundResource) return formatResource(resource, args);
    if (isDev && resourceKey) {
      logWarnings(missingKeys, resourceKey, preferredLanguage);
      return `${preferredLanguage.toUpperCase()}_${resourceKey}`;
    }
    if (foundEnglishResource) return formatResource(resources.Eng[resourceKey], args);
    return resourceKey;
  };

  // If either context is null, returning a fallback function that always returns a placeholder text.
  // As of march 2024, this ensures that our Storybook app, which is incompatible with the contexts, can still render components that use this hook.
  const getResourceFallback: GetResourceFunction = () => "Resource text";
  /* eslint-disable */
  const getResourceFunction = reduxUseResource
    ? getResource
    : userLanguage
    ? useCallback(getResource, [userLanguage, resources, topicResources])
    : getResourceFallback;
  /* eslint-enable */

  return { getResource: getResourceFunction };
};
