import React from "react";
import styled from "styled-components";

import { useAppDispatch } from "store";
import { setSelectedColumns } from "store/analysisSettings/settingsSlice";
import { Text } from "components/Text";
import { Icon, IconType } from "components/_icons/Icon";

import { Color } from "ts/enums/color";

export const SuggestedColumnsBanner = () => {
  const dispatch = useAppDispatch();

  const handleDeselectColumns = () => {
    dispatch(setSelectedColumns([]));
  };

  return (
    <StyledSuggestedColumnsBanner>
      <div>
        <Icon type={IconType.info} size={12} />
        <Text resource="analysisSettings.prefiltering.suggestedColumnsMessage" />
        <StyledDeselectButton onClick={handleDeselectColumns}>
          <Text resource="analysisSettings.prefiltering.suggestedColumnsMessage.deselectAllColumns" />
        </StyledDeselectButton>
      </div>
    </StyledSuggestedColumnsBanner>
  );
};

const StyledSuggestedColumnsBanner = styled.div`
  height: 41px;
  border: 1px solid ${Color.green40};
  background-color: ${Color.green10};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 12px;
  margin-top: 12px;
  font-size: 14px;

  div {
    display: flex;
    gap: 6px;
    align-items: center;
  }
`;

const StyledDeselectButton = styled.button`
  all: unset;
  text-decoration: underline;
  color: ${Color.blue50};
  cursor: pointer;
`;
