import { DemographicFilterMethod } from "@explorance/mly-types";
import { cloneDeep, isEmpty } from "lodash";
import { DemographicFilter } from "ts/filters/demographicFilter";

export const mapInitialDemographicFiltersForFrontend = (filters: any): DemographicFilter[] =>
  filters.map((f) => {
    return {
      id: f.id,
      name: f.name,
      totalCount: f.totalCount,
      method: DemographicFilterMethod.List,
      listValues: [],
      searchValue: [],
    };
  });

export const mapDemographicFiltersForFrontend = (filters: any): DemographicFilter[] =>
  filters.map((f) => {
    return {
      id: f.id,
      name: f.name,
      totalCount: f.totalCount,
      method: f.method,
      listValues: f.method === DemographicFilterMethod.List ? f.values : [],
      searchValue: f.method === DemographicFilterMethod.Search ? f.values : [],
    };
  });

export const mapDemographicFiltersForBackend = (filters: DemographicFilter[]): any =>
  filters
    .map((f) => {
      return {
        id: f.id,
        name: f.name,
        totalCount: f.totalCount,
        method: f.method,
        values: f.method === DemographicFilterMethod.List ? f.listValues : f.searchValue,
      };
    })
    .filter((f) => !isEmpty(f.values));

export const getTitleFilterCount = (filterArr) => {
  const count = filterArr.length;
  return count === 0 ? "" : ` (${count})`;
};

export const removeEmptyDemographicFilters = (
  filters: DemographicFilter[]
): DemographicFilter[] => {
  const copy = cloneDeep(filters);
  return copy.filter((c) => !(c.listValues.length === 0 && c.searchValue.length === 0));
};

export const formatDemographicsForApi = (stateDemographicFilters: DemographicFilter[]) =>
  stateDemographicFilters.map((filter) => ({
    id: filter.id,
    name: filter.name,
    method: filter.method,
    values: filter.method === DemographicFilterMethod.List ? filter.listValues : filter.searchValue,
  }));
