import React, { ReactNode } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import { Color } from "ts/enums/color";
import { ConditionalWrapper } from "components/ConditionalWrapper";

type Tab = {
  id: string;
  label: string | ReactNode;
  onClick?: () => void;
  href?: string;
};

type Props = {
  tabs: Tab[];
  activeTab: string;
  onSelectTab?: (tab: string) => void;
};

export const Tabs = ({ tabs, activeTab, onSelectTab }: Props) => {
  if (!activeTab) return <div style={{ width: "514px" }} />;

  return (
    <StyledAnalysisTabs>
      {tabs.map(({ id, label, href, onClick }) => {
        return (
          <ConditionalWrapper
            key={id}
            condition={!!href}
            wrapper={(children) => (
              <Link to={href} onClick={onClick}>
                {children}
              </Link>
            )}
          >
            <StyledTab
              isActive={id === activeTab}
              onClick={onSelectTab ? () => onSelectTab(id) : undefined}
            >
              <div>{label}</div>
            </StyledTab>
          </ConditionalWrapper>
        );
      })}
    </StyledAnalysisTabs>
  );
};

const StyledAnalysisTabs = styled.div`
  display: flex;
  flex-grow: 1;
`;

const StyledTab = styled.div<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? Color.blue50 : Color.gray50)};
  border-bottom: 3px solid ${({ isActive }) => (isActive ? Color.blue50 : "transparent")};
  margin-right: 16px;
  font-size: 16px;
  font-weight: bold;
  transition: 0.4s border-color;

  div {
    margin-bottom: -3px;
    padding: 9px 6px 6px 6px;
    border-radius: 2px;
    border-bottom: 3px solid ${({ isActive }) => (isActive ? Color.blue50 : "transparent")};
    transition: 0.2s color, 0.4s border-color, 0.2s background-color;

    &:hover {
      color: ${Color.blue50};
      background-color: ${Color.sky30};
    }
  }
`;
