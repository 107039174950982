import { StatisticsType } from "@explorance/mly-types";
import { CommentLabelResource } from "ts/comments";

export const escapeRegExp = (string: string): string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

// This removes all occurences of " OR", " OR ", " ||", " || " if at the end of the string
export const removeHangingSeparatorsFromSearch = (search: string): string => {
  const separatorRegEx = new RegExp(/ OR $| \|{2} $| OR$| \|{2}$/g);
  const formattedSearch = search.match(separatorRegEx)
    ? search.replace(separatorRegEx, "")
    : search;

  return formattedSearch;
};

export const formatSearchTerms = (searchFields: string): string => {
  const specialCharacters = new RegExp(/[.*+?^${}()|[\]\\]/g);
  const searchFieldsArray = removeHangingSeparatorsFromSearch(searchFields)
    .split(/ OR | \|{2} | OR$| \|{2}$/)
    .filter(Boolean); // removes possible empty strings

  const formattedSearchItems = searchFieldsArray.map((search) => {
    return search.match(specialCharacters) ? escapeRegExp(search) : search;
  });
  return formattedSearchItems.join("|");
};

// NOTE: keep this in case we make changes to the comments count caption for the next release
export const getCommentsLabelKeys = (
  currentStatistic: StatisticsType,
  isSearchActive: boolean,
  currentCommentsCount: number,
  totalStatCount: number
): CommentLabelResource[] => {
  switch (currentStatistic) {
    case StatisticsType.total:
      return isSearchActive
        ? [
            { key: "comments.outOf", count: currentCommentsCount },
            {
              key: `comments.countSentence.comments`,
              count: totalStatCount,
            },
          ]
        : [
            currentCommentsCount > 1 || currentCommentsCount === 0
              ? { key: `comments.countSentence.comments`, count: currentCommentsCount }
              : {
                  key: `comments.countSentence.commentsSingular`,
                  count: currentCommentsCount,
                },
          ];
    default:
      return isSearchActive
        ? [
            { key: "comments.outOf", count: currentCommentsCount },
            { key: `comments.countSentence.${currentStatistic}`, count: totalStatCount },
          ]
        : [
            currentCommentsCount > 1 || currentCommentsCount === 0
              ? { count: currentCommentsCount, key: `comments.countSentence.${currentStatistic}` }
              : {
                  count: currentCommentsCount,
                  key: `comments.countSentence.${currentStatistic}Singular`,
                },
          ];
  }
};

export const statisticsURLStringToArray = (statistics: string): StatisticsType[] => {
  if (!statistics) return [StatisticsType.total];
  return statistics.split(",") as StatisticsType[];
};
