import React from "react";
import styled from "styled-components";
import { CategorizationType } from "@explorance/mly-types";

import { Color } from "ts/enums/color";
import { BaseModelPill } from "components/_pills/BaseModelPill";
import { Text } from "components/Text";

type Props = {
  categorizationType: CategorizationType;
};

export const CategorizationTypeSymbol = ({ categorizationType }: Props) => {
  const type = categorizationType?.toString().split("_")[0];

  if (!type) {
    return <BaseModelPill />;
  }

  return (
    <StyledSymbol className={type}>
      <Text resource={`categorizationType.${type}.acronym`} />
    </StyledSymbol>
  );
};

const StyledSymbol = styled.span`
  text-align: center;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: bold;
  color: ${Color.white};
  border-radius: 3px;
  cursor: default;
  flex-shrink: 0;

  &.EEC {
    background: ${Color.indigo60};
  }

  &.ELC {
    background: ${Color.orange40};
  }

  &.SCE {
    background: #00bcff;
  }

  &.POL {
    background: #ff555e;
  }

  &.REC {
    background: #729869;
  }

  &.ALR {
    background: ${Color.red50};
  }
`;
