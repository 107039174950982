import React, { useMemo } from "react";
import parseHTML from "html-react-parser";
import styled, { AnyStyledComponent } from "styled-components";
import { useResource } from "hooks/useResource";

export type Resource =
  | {
      key?: string;
      args?: string[];
    }
  | string;

type Props = {
  resource: Resource;
};

const Text = React.memo(({ resource }: Props) => {
  const { getResource } = useResource();

  const { key, args, isResourceKeyUndefined } = {
    key: typeof resource === "string" ? resource : resource?.key,
    args: typeof resource === "string" ? [] : resource?.args || [],
    isResourceKeyUndefined: typeof resource === "undefined",
  };

  const memoizedHTML = useMemo(() => {
    if (isResourceKeyUndefined) return null;
    const resourceString = getResource(key, ...args);
    if (!resourceString) return null;
    return parseHTML(resourceString);
  }, [isResourceKeyUndefined, key, args]); // eslint-disable-line

  // Fallback if resource key is undefined (may happen during initial renders)
  if (isResourceKeyUndefined) return <StyledMlyText></StyledMlyText>;

  // Main display
  return (
    <StyledMlyText
      data-resource={key}
      {...(args.length > 0 && { ["data-resource-args"]: JSON.stringify(args) })}
    >
      {memoizedHTML}
    </StyledMlyText>
  );
});

const StyledMlyText = styled("mly-text" as AnyStyledComponent)``;

export { Text, StyledMlyText };
