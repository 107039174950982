import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { AxiosError } from "axios";
import { useAppDispatch, useAppSelector } from "store";

import { handleAxiosError, setWidgetsApiData } from "context/AnalysisContext/actions";
import { AnalysisContext } from "context/AnalysisContext";
import { showToastSuccess } from "store/toast/toastSlice";

import { isAnalyzed } from "utils/isAnalyzed";
import { isAnyAdmin } from "utils/isAnyAdmin";
import { setGroupExpanded } from "services/widgets";
import { getWidgetsByAnalysisId } from "services/analysis";
import { useQueryParams } from "hooks/useQueryParams";

import { ErrorScreen } from "components/ErrorScreen";
import { EmptyState } from "components/EmptyState";
import { Skeleton } from "components/Skeleton";
import { ExpandableWidgetGroup } from "./_layouts/ExpandableWidgetGroup";
import { WidgetGroupModal } from "./_layouts/WidgetGroupModal";
import { CreateNewGroupButton } from "./_layouts/CreateNewGroupButton";
import { ManageWidgetsModal } from "./_layouts/ManageWidgetsModal";

import { PermissionLevel, RoleType, SharingDashboardAccess } from "@explorance/mly-types";
import { WidgetGroup } from "ts/widget";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { PageErrorType } from "ts/enums/pageErrorType";

export const DashboardPage = () => {
  // redux/context
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [state, analysisContextDispatch, handlers] = useContext(AnalysisContext);

  // hooks
  const [updatedWidgetId, setUpdatedWidgetId] = useState<number>();
  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);
  const { sharingId, sharingPreview } = useQueryParams();

  // Variables
  const isAdmin = isAnyAdmin(currentUser.roleType);
  const isSharing = !!state.analysisDetails.sharing;
  const isViewer =
    currentUser.roleType === RoleType.Viewer ||
    (state.analysisDetails?.sharing?.permissionLevel === PermissionLevel.View &&
      currentUser.roleType === RoleType.Analyst);
  const hasContributionPermission =
    state.analysisDetails?.sharing?.permissionLevel === PermissionLevel.Contribution && !isViewer;
  const showCreateNewWidget =
    !sharingPreview && (isAdmin || (!isSharing && !isViewer) || hasContributionPermission);
  const hasPageAccess =
    !isSharing || state.analysisDetails.sharing.dashboardAccess === SharingDashboardAccess.Shared;

  // functions
  const handleUpdateLastWidgetsPushedDate = async () => {
    if (state.widgetsApiData.lastPushed && !state.widgetsApiData.pushAvailable) {
      await handlers.updateLastWidgetsPushedDate();
    }
  };

  const onWidgetAdd = async (widgetId: number) => {
    dispatch(showToastSuccess("widget.toast.success.add"));
    setUpdatedWidgetId(widgetId);
    await handleUpdateLastWidgetsPushedDate();
  };

  const onWidgetSave = async (widgetId: number) => {
    dispatch(showToastSuccess("widget.toast.success.update"));
    setUpdatedWidgetId(widgetId);
    await handleUpdateLastWidgetsPushedDate();
  };

  const onWidgetDelete = async () => {
    dispatch(showToastSuccess("widget.toast.success.delete"));
    setUpdatedWidgetId(null);
    await handleUpdateLastWidgetsPushedDate();
  };

  const fetchWidgetGroups = async () => {
    if (!isAnalyzed(state.analysisDetails.status)) return;
    try {
      const { data } = await getWidgetsByAnalysisId({
        analysisId,
        sharingId,
        user_id: state.previewUser.id,
      });
      analysisContextDispatch(setWidgetsApiData(data));
    } catch (err) {
      analysisContextDispatch(handleAxiosError(err as AxiosError<any>));
    }
  };

  const handleExpandGroup = async (groupId: number, isExpanded: boolean) => {
    try {
      setGroupExpanded(groupId, isExpanded);
      analysisContextDispatch(
        setWidgetsApiData({
          ...state.widgetsApiData,
          widgetGroups: state.widgetsApiData?.widgetGroups.map((group) =>
            group.id === groupId ? { ...group, isExpanded } : group
          ),
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchWidgetGroups();
  }, [analysisId, analysisContextDispatch, state.analysisDetails.status, state.previewUser]); // eslint-disable-line

  // Clear widgets data on unmount, to ensure /widgets always runs before /widgets/id/statistics when navigating back to the page
  // Avoids a 404 network error when the sharer pushes widget changes, c.f. BLUEML-3163
  useEffect(() => {
    return () => {
      analysisContextDispatch(setWidgetsApiData(null));
    };
  }, [analysisContextDispatch]);

  if (!hasPageAccess) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <div className="fade-in">
      {state.widgetsApiData ? (
        <>
          <StyledDashboardPage className="fade-in">
            {state.widgetsApiData?.widgetGroups.length > 0 ? (
              state.widgetsApiData?.widgetGroups.map((widgetGroup: WidgetGroup, i) => (
                <ExpandableWidgetGroup
                  key={i}
                  widgetGroup={widgetGroup}
                  title={widgetGroup.title}
                  setIsExpanded={(isExpanded) => handleExpandGroup(widgetGroup.id, isExpanded)}
                  refetch={fetchWidgetGroups}
                  updatedWidgetId={updatedWidgetId}
                  onWidgetSave={onWidgetSave}
                  onWidgetAdd={onWidgetAdd}
                  onWidgetDelete={onWidgetDelete}
                  showCreateNewWidget={showCreateNewWidget}
                />
              ))
            ) : (
              <EmptyState
                type={EmptyStateType.noWidgetGroups}
                customStyles={{ marginTop: "0px" }}
              />
            )}
            {!sharingPreview && !isViewer && <CreateNewGroupButton />}
          </StyledDashboardPage>
          <ManageWidgetsModal refetch={fetchWidgetGroups} />
          <WidgetGroupModal refetch={fetchWidgetGroups} />
        </>
      ) : (
        <>
          <Skeleton width={1175} height={400} />
          <Skeleton width={1175} height={100} customStyles={{ marginTop: "20px" }} />
          <Skeleton width={1175} height={100} customStyles={{ marginTop: "20px" }} />
        </>
      )}
    </div>
  );
};

const StyledDashboardPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 1220px;
  margin-left: calc((1175px - 1220px) / 2);
  @media (max-width: 1366px) {
    width: 972px;
    margin-left: calc((918px - 972px) / 2);
  }
`;
