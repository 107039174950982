import React, { useContext } from "react";

import { AnalysisContext } from "context/AnalysisContext";

import { ExcludedValuesRow } from "./ExcludedValuesRow";
import { CollapsibleSection } from "components/CollapsibleSection";

export const ExcludedValues = () => {
  const [state] = useContext(AnalysisContext);

  const findExcludedValues = (column: string) => {
    return (
      (state.emptyCommentsDetails?.excludedComments.find(
        (excludedComment) => excludedComment.selectedColumn === column
      )?.count ?? 0) +
      (state.emptyCommentsDetails?.emptyCommentCells.find(
        (excludedValue) => excludedValue.selectedColumn === column
      )?.count ?? 0)
    );
  };

  const getCountLabels = (count: number, isTotalColumns?: boolean): string => {
    const label = isTotalColumns
      ? count === 1
        ? "overview.analysisSettings.selectedColumnsSingular"
        : "overview.analysisSettings.selectedColumns"
      : count === 1
      ? "overview.analysisSettings.excludedValuesSingular"
      : "overview.analysisSettings.excludedValues";
    return label;
  };

  return (
    <CollapsibleSection
      titleLeftResource={{
        key:
          state.emptyCommentsDetails &&
          getCountLabels(state.emptyCommentsDetails.totalColumns, true),
        args: [state.emptyCommentsDetails?.totalColumns?.toString()],
      }}
      titleRightResource={{
        key:
          state.emptyCommentsDetails && getCountLabels(state.emptyCommentsDetails.totalEmptyCells),
        args: [state.emptyCommentsDetails?.totalEmptyCells?.toString()],
      }}
      isLoading={!state.emptyCommentsDetails}
      tooltipCaptionKey="tooltip.emptyCells"
    >
      {state.analysisDetails &&
        state.emptyCommentsDetails &&
        state.analysisDetails.selectedColumns?.map((column, index) => (
          <ExcludedValuesRow
            key={index}
            columnName={column}
            excludedValueCount={findExcludedValues(column)}
          />
        ))}
    </CollapsibleSection>
  );
};
