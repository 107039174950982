import React from "react";
import styled from "styled-components";
import { useAppDispatch } from "store";

import { openModal, setUserInput } from "store/widgets/widgetGroupsSlice";

import { Text } from "components/Text";
import { Icon, IconType } from "components/_icons/Icon";

import { Color } from "ts/enums/color";
import { WidgetGroupModalType } from "ts/enums/widgetGroupModalType";
import { useResource } from "hooks/useResource";

export const CreateNewGroupButton = () => {
  const dispatch = useAppDispatch();
  const { getResource } = useResource();

  return (
    <StyledCreateNewGroupButton
      onClick={() => {
        dispatch(setUserInput(getResource("modal.widgetGroup.inputInitialValue")));
        dispatch(openModal(WidgetGroupModalType.newGroup));
      }}
    >
      <Icon
        type={IconType.plusCircle}
        size={14}
        style={{ marginRight: "5px" }}
        color={Color.blue50}
      />
      <Text resource="button.widgets.createGroup" />
    </StyledCreateNewGroupButton>
  );
};

const StyledCreateNewGroupButton = styled.button`
  height: 50px;
  width: 100%;
  color: ${Color.blue50};
  background-color: ${Color.sky15};
  border-radius: 5px;
  border: 1px solid ${Color.sky60};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;
