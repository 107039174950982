import React from "react";
import styled from "styled-components";

import { Text } from "components/Text";

import { Color } from "ts/enums/color";

type Props = {
  columnName: string;
  excludedValueCount: number;
};

export const ExcludedValuesRow = ({ columnName, excludedValueCount }: Props) => (
  <StyledExcludedValueRow>
    <StyledColumnName>{columnName}</StyledColumnName>
    <span>
      <b>{excludedValueCount}</b>
      <Text
        resource={
          excludedValueCount === 1
            ? "overview.analysisSettings.commentsExcludedSingular"
            : "overview.analysisSettings.commentsExcluded"
        }
      />
    </span>
  </StyledExcludedValueRow>
);

const StyledExcludedValueRow = styled.div`
  color: ${Color.gray40};
  font-size: 15px;
  padding: 10px 0px 0px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  b {
    margin-right: 3px;
  }
`;

const StyledColumnName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
`;
