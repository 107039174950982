import React, { useContext, useState } from "react";
import styled from "styled-components";
import { intersection } from "lodash";

import { useQueryParams } from "hooks/useQueryParams";
import useClickOutside from "hooks/useClickOutside";
import { isModelVirtual } from "utils/isModelVirtual";
import { AnalysisContext } from "context/AnalysisContext";
import { useAppSelector } from "store";

import { Color } from "ts/enums/color";
import { CommentsView, RecommendationType, RoleType, StatisticsType } from "@explorance/mly-types";
import { Comment as CommentType } from "ts/comments";
import { CategorizedTopic } from "ts/enums/categorizedTopic";
import { PopupPosition } from "ts/enums/popupPosition";
import { Pin } from "ts/pin";
import { ButtonSize, ButtonVariant } from "ts/enums/button";

import { getCommentInterpretationCounts } from "common-layouts/CommentResultsIcons/helpers";
import { CommentResultsIcons } from "common-layouts/CommentResultsIcons";
import { NonCategorizedRecommendationPillList } from "common-layouts/NonCategorizedRecommendationPillList";
import { AllRecommendationsPillList } from "common-layouts/AllRecommendationsPillList";
import { CategorizedTopicPillList } from "common-layouts/CategorizedTopicPillList";
import { CommentText } from "./CommentText";
import { Text } from "components/Text";
import { Popup } from "components/Popup";
import { DiscussionActionButton } from "./DiscussionActionButton";
import { DetailsActionButton } from "./DetailsActionButton";
import { FeedbackActionButton } from "./FeedbackActionButton";
import { PinActionButton } from "./PinActionButton";
import { Checkbox } from "components/_inputs/Checkbox";
import { Pill, PillSize } from "components/_pills/Pill";
import { Button } from "components/_buttons/Button";
import { TextTruncator } from "components/TextTruncator";
import { RedactionActionButton } from "./RedactionActionButton";

type Props = {
  comment: CommentType;
  searchField: string;
  selectionModeActive: boolean;
  checkCommentById: (id: number) => void;
  updateCommentPin: (ids: number[], pinData?: Pin) => void;
  updateCommentNotesCount: (id: number, notesCount: number) => void;
};
const LOAD_MORE_LIMIT = 10;

export const Comment = ({
  comment,
  searchField,
  selectionModeActive,
  updateCommentPin,
  checkCommentById,
  updateCommentNotesCount,
}: Props) => {
  const { currentUser } = useAppSelector((state) => state.auth);

  const [state] = useContext(AnalysisContext);
  const { view, statistics } = useQueryParams();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [incrementedValue, setIncrementedValue] = useState<number>(LOAD_MORE_LIMIT);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setShowTranslationPopup((previousOpen) => !previousOpen);
  };

  const [showTranslationPopup, setShowTranslationPopup] = useState<boolean>(false);

  const popupButtonRef = useClickOutside(() => setShowTranslationPopup(false));

  const showSentimentName =
    !view || view === CommentsView.allComments || view === CommentsView.notCategorizedComments;

  const isCustomModelActive = !!state.selectedModelId;

  const handleLoadMore = () => {
    setIncrementedValue((prev) => prev + LOAD_MORE_LIMIT);
  };

  // Ensures the comment only shows the pills of the applied topics filters and selected statistics
  const getFilteredTopics = (
    commentTopics: Record<CategorizedTopic, string[]> | Record<RecommendationType, string[]>
  ) => {
    const filteredTopicsByStatistics: any =
      statistics && statistics !== StatisticsType.total
        ? statistics.split(",").reduce((acc, stat) => {
            if (commentTopics[stat]) acc[stat] = commentTopics[stat];
            return acc;
          }, {})
        : commentTopics;

    if (state.selectedTopicNodes.length === 0) return filteredTopicsByStatistics;

    const selectedTopicPaths = state.selectedTopicNodes.map((topic) => topic.fullPath);

    return Object.keys(filteredTopicsByStatistics).reduce((acc, key) => {
      acc[key] = intersection(filteredTopicsByStatistics[key], selectedTopicPaths);
      return acc;
    }, {});
  };

  // Ensures the comment only shows the uncatrgorized pills of the selected recommendation tabs
  const getFilteredRecommendations = (recommendations: RecommendationType[]) => {
    if (!statistics) return recommendations;
    return recommendations.filter((rec) => statistics.includes(rec));
  };

  const getPillList = () => {
    switch (view) {
      case CommentsView.allComments:
        return null;
      case CommentsView.categorizedComments:
        return (
          <CategorizedTopicPillList
            topics={getFilteredTopics(comment.categorizedTopics)}
            pillStyling={{ ...(isCustomModelActive && { overflowWrap: "break-work" }) }}
            isCustomModel={isModelVirtual(state.selectedModelId, state.analysisModels)}
          />
        );
      case CommentsView.notCategorizedComments:
        return null;
      case CommentsView.allRecommendations:
        return (
          <AllRecommendationsPillList
            recommendations={getFilteredRecommendations(comment.recommendations)}
            categorizedRecommendations={getFilteredTopics(comment.categorizedRecommendations)}
            commentId={comment.id}
          />
        );
      case CommentsView.categorizedRecommendations:
        return (
          <CategorizedTopicPillList
            topics={getFilteredTopics(comment.categorizedRecommendations)}
            pillStyling={{ ...(isCustomModelActive && { overflowWrap: "break-word" }) }}
            isCustomModel={isModelVirtual(state.selectedModelId, state.analysisModels)}
          />
        );
      case CommentsView.notCategorizedRecommendations:
        return (
          <NonCategorizedRecommendationPillList
            style={{ marginBottom: -5, marginTop: 8 }}
            recommendations={getFilteredRecommendations(comment.recommendations)}
          />
        );
      case CommentsView.alerts:
        return null;
    }
  };

  return (
    <StyledComment commentChecked={comment.checked}>
      <StyledCommentBodySection>
        {currentUser.roleType !== RoleType.Viewer && (
          <StyledCommentCheckbox>
            <Checkbox checked={comment.checked} onChange={() => checkCommentById(comment.id)} />
          </StyledCommentCheckbox>
        )}
        <StyledCommentBody>
          <CommentText comment={comment} searchField={searchField} />
          {Object.keys(comment.translations).length > 0 && state.selectedTranslationLanguage && (
            <StyledTranslationButton ref={popupButtonRef} onClick={(e) => handleClick(e)}>
              <Text resource="comments.multilingual.button.seeOriginal" />
            </StyledTranslationButton>
          )}

          {showTranslationPopup && (
            <Popup
              isOpen={showTranslationPopup}
              placement={PopupPosition.bottomStart}
              popupStyles={{ padding: 10, maxWidth: 1000, maxHeight: 600 }}
              anchorEl={anchorEl}
              fixedPopup
            >
              <StyledTranslatedCommentContainer>
                <StyledPopupHeader>
                  <Text resource="comments.multilingual.popup.translation" />
                </StyledPopupHeader>
                <StyledTranslatedComment>{comment.value}</StyledTranslatedComment>
              </StyledTranslatedCommentContainer>
            </Popup>
          )}

          {getPillList()}

          <CommentResultsIcons
            sentiment={comment.commentPolarity}
            interpretations={getCommentInterpretationCounts(comment, state.alertThreshold)}
            bnw={!showSentimentName}
            showSentimentName={showSentimentName}
            removeSentimentTooltip={showSentimentName}
            interpretationTooltipData={comment}
            isCustomModel={isModelVirtual(state.selectedModelId, state.analysisModels)}
          />
          {comment?.pin?.category && (
            <StyledPinPillsContainer>
              <Pill
                style={{ fontWeight: "bold", padding: "5px 12px", height: "24px" }}
                backgroundColor={Color.indigo60}
                color={Color.white}
                size={PillSize.sm}
                round
              >
                <TextTruncator value={comment.pin?.category} customWidth="200px" />
              </Pill>
              {comment.pin?.tags[0] &&
                comment.pin.tags.map(
                  (tag, index) =>
                    index < incrementedValue && (
                      <Pill
                        key={index}
                        style={{ fontWeight: "bold", padding: "5px 12px", height: "24px" }}
                        border={`1px solid ${Color.indigo20} `}
                        size={PillSize.sm}
                        round
                      >
                        <TextTruncator value={tag} customWidth="200px" />
                      </Pill>
                    )
                )}
              {comment.pin?.tags.length > incrementedValue ? (
                <Button
                  variant={ButtonVariant.secondary}
                  size={ButtonSize.sm}
                  style={{ backgroundColor: Color.white }}
                  onClick={handleLoadMore}
                >
                  <Text resource="button.loadMore" />
                </Button>
              ) : incrementedValue >= comment.pin?.tags.length &&
                comment.pin?.tags.length > LOAD_MORE_LIMIT ? (
                <Button
                  variant={ButtonVariant.secondary}
                  size={ButtonSize.sm}
                  style={{ backgroundColor: Color.white }}
                  onClick={() => setIncrementedValue(LOAD_MORE_LIMIT)}
                >
                  <Text resource="button.showLess" />
                </Button>
              ) : null}
            </StyledPinPillsContainer>
          )}
        </StyledCommentBody>
      </StyledCommentBodySection>
      <StyledCommentActionSection>
        {currentUser.roleType !== RoleType.Viewer && (
          <PinActionButton
            comment={comment}
            updateCommentPin={updateCommentPin}
            disabled={selectionModeActive}
          />
        )}
        <RedactionActionButton commentId={comment.id} />
        <DiscussionActionButton
          comment={comment}
          disabled={selectionModeActive}
          updateCommentNotesCount={updateCommentNotesCount}
        />
        <FeedbackActionButton comment={comment} disabled={selectionModeActive} />
        <DetailsActionButton comment={comment} disabled={selectionModeActive} />
      </StyledCommentActionSection>
    </StyledComment>
  );
};

const StyledComment = styled.div<{ commentChecked: boolean }>`
  border-bottom: 1px solid ${Color.indigo10};
  background-color: ${({ commentChecked }) => commentChecked && Color.indigo10};
  padding: 18px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-center;
`;

const StyledCommentCheckbox = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px 10px 0px 6px;
`;

const StyledCommentBodySection = styled.div`
  display: flex;
`;

const StyledCommentBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledCommentActionSection = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-shrink: 0;
  gap: 6px;
`;

const StyledTranslationButton = styled.button`
  all: unset;
  color: ${Color.indigo60};
  text-decoration: underline;
  width: fit-content;
  height: 17px;
  margin: 3px 0;
  cursor: pointer;
  font-size: 12px;
`;

const StyledTranslatedCommentContainer = styled.div`
  background-color: ${Color.neutral10};
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 8px;
`;

const StyledPopupHeader = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: ${Color.gray30};
`;

const StyledTranslatedComment = styled.div`
  color: ${Color.gray50};
  font-size: 14px;
`;

const StyledPinPillsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
`;
