import React from "react";
import styled from "styled-components";
import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { Color } from "ts/enums/color";
import { Icon, IconType } from "components/_icons/Icon";

type Props = {
  checked?: boolean;
  disabled?: boolean;
  includeParentsForInsert?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: number;
  checkedAppearance?: CheckboxCheckedAppearance;
  horizontalOffset?: number;
  onClickInput?: (event: React.MouseEvent) => void;
};

export const Checkbox = ({
  checked,
  disabled,
  includeParentsForInsert,
  onChange,
  size,
  checkedAppearance = CheckboxCheckedAppearance.Default,
  horizontalOffset,
  onClickInput,
}: Props) => {
  const getInnerIcon = () => {
    switch (checkedAppearance) {
      case CheckboxCheckedAppearance.Default:
        return <Icon type={IconType.check} color={Color.white} className="icon" size={12} />;
      case CheckboxCheckedAppearance.Partial:
        return <span className="square icon" />;
    }
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    !disabled && onChange(event);
  };

  return (
    <StyledCheckbox
      isPartial={checkedAppearance === CheckboxCheckedAppearance.Partial}
      disabled={disabled}
      size={size}
      includeParentsForInsert={includeParentsForInsert}
      horizontalOffset={horizontalOffset}
    >
      <label className="checkbox">
        <input
          onClick={onClickInput || (() => undefined)}
          type="checkbox"
          onChange={onChangeInput}
          checked={checked}
        />
        <span className="overlay">{getInnerIcon()}</span>
      </label>
    </StyledCheckbox>
  );
};

type StyledProps = {
  isPartial: boolean;
  disabled: boolean;
  size: number;
  includeParentsForInsert: boolean;
  horizontalOffset?: number;
};

const StyledCheckbox = styled.span<StyledProps>`
  position: relative;

  .checkbox input {
    opacity: 0;
  }

  .checkbox .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${({ horizontalOffset }) => horizontalOffset || 0}px;
    margin: auto;
    height: ${({ size }) => size || 14}px;
    width: ${({ size }) => size || 14}px;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid ${Color.blue20};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkbox .overlay .icon {
    color: ${Color.white};
    display: none;
  }

  .checkbox input:checked ~ .overlay {
    background-color: ${({ isPartial, disabled, includeParentsForInsert }) =>
      includeParentsForInsert && disabled
        ? Color.gray30
        : !includeParentsForInsert && disabled
        ? Color.gray40
        : isPartial
        ? Color.white
        : Color.blue50};
    border-radius: 2px;
    border: 1px solid
      ${({ disabled, includeParentsForInsert }) =>
        includeParentsForInsert && disabled
          ? Color.gray30
          : !includeParentsForInsert && disabled
          ? Color.gray40
          : Color.blue50};
    opacity: 1;
  }

  .checkbox input:checked ~ .overlay .icon {
    display: block;
  }

  .square {
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: ${({ disabled }) => (disabled ? Color.white : Color.blue50)};
    border-radius: 2px;
  }

  .checkbox:hover {
    cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};
  }
`;
