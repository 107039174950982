export const getMonthDay = (date: Date): number[] => {
  const dateString = date.toLocaleDateString(undefined, {
    month: "numeric",
    day: "numeric",
  });
  const separator = dateString.includes("/") ? "/" : dateString.includes("-") ? "-" : "";
  const [month, day] = dateString.split(separator);

  return [parseInt(month, 10), parseInt(day, 10)];
};
