import React, { useState } from "react";
import styled from "styled-components";

import { setComment } from "store/notes/discussionThreadSlice";
import { useAppDispatch } from "store";

import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";
import { SideDrawer } from "components/SideDrawer";
import { DiscussionThreadDrawer } from "./DiscussionThreadDrawer";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { Comment } from "ts/comments";

type Props = {
  comment: Comment;
  disabled?: boolean;
  updateCommentNotesCount: (id: number, notesCount: number) => void;
};

export const DiscussionActionButton = ({ comment, disabled, updateCommentNotesCount }: Props) => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen(true);
    dispatch(setComment(comment));
  };

  const hasNotes = comment.notesCount > 0;

  return (
    <div>
      <Button
        variant={ButtonVariant.light}
        square
        disabled={disabled}
        tooltipContentKey={"comments.discussionThread.tooltip"}
        size={ButtonSize.sm}
        onClick={handleClick}
        style={{ color: hasNotes ? Color.indigo50 : Color.gray20, position: "relative" }}
      >
        <Icon type={IconType.commentBubble} size={12} />
        {hasNotes && (
          <StyledNoteCount>{comment.notesCount > 99 ? 99 : comment.notesCount}</StyledNoteCount>
        )}
      </Button>
      <SideDrawer
        render={DiscussionThreadDrawer}
        isOpen={isOpen}
        closeHandler={() => setIsOpen(false)}
        renderComponentProps={{
          closeHandler: () => setIsOpen(false),
          updateCommentNotesCount,
        }}
        width="462px"
      />
    </div>
  );
};

const StyledNoteCount = styled.span`
  position: absolute;
  top: -7px;
  right: -4.5px;
  height: 16px;
  width: 16px;
  background-color: ${Color.sky20};
  border: 1px solid ${Color.white};
  color: ${Color.indigo50};
  border-radius: 999px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
