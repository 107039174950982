import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Skeleton } from "components/Skeleton";

import { Color } from "ts/enums/color";
import { Topic } from "ts/topic";
import { TopicView } from "ts/enums/topicView";

type Props = {
  topicView: TopicView;
  topics?: Topic[];
};

export const BarChartSkeleton = ({ topicView, topics }: Props) => {
  const [skeletonArrayCount, setSkeletonArrayCount] = useState<number>();

  useEffect(() => {
    if (!topics) {
      const findSkeletonArrayLength = () => setSkeletonArrayCount(window.innerWidth > 1366 ? 6 : 4);
      findSkeletonArrayLength();

      window.addEventListener("resize", findSkeletonArrayLength);
      return () => window.removeEventListener("resize", findSkeletonArrayLength);
    }
  }, [topics]);

  return (
    <StyledSkeletonContainer>
      {[...Array(skeletonArrayCount)].map((_, index) => (
        <StyledChartSkeletonContainer
          key={index}
          className="pulse"
          width={topicView === TopicView.insights ? 160 : 158}
        >
          <StyledChartSkeleton>
            <Skeleton
              width={12}
              height={63}
              backgroundColor={Color.blue20}
              borderRadius="5px 5px 0px 0px"
            />
            <Skeleton
              width={12}
              height={40}
              backgroundColor={Color.blue20}
              borderRadius="5px 5px 0px 0px"
            />
          </StyledChartSkeleton>
          <StyledLegendSkeletonContainer>
            <Skeleton
              width={150}
              height={46}
              backgroundColor={Color.neutral10}
              borderRadius="5px"
            />
          </StyledLegendSkeletonContainer>
        </StyledChartSkeletonContainer>
      ))}
    </StyledSkeletonContainer>
  );
};

const StyledSkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const StyledChartSkeletonContainer = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  height: 117px;
`;

const StyledChartSkeleton = styled.div`
  border-bottom: 1px solid ${Color.indigo40};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  column-gap: 6px;
  height: 50%;
`;

const StyledLegendSkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
`;
