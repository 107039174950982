import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { AnalysisContext } from "context/AnalysisContext";
import { isModelVirtual } from "utils/isModelVirtual";

import { CategorizedTopicPillList } from "common-layouts/CategorizedTopicPillList";
import { NonCategorizedRecommendationPillList } from "common-layouts/NonCategorizedRecommendationPillList";
import { CollapsibleName } from "components/CollapsibleName";
import { SectionTitle } from "components/SectionTitle";
import { SentimentPill } from "components/_pills/SentimentPill";
import { InterpretationPill } from "components/_pills/InterpretationPill";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";
import { CommentAnalysisResults } from "ts/comments";
import { InterpretationIconType } from "ts/enums/interpretationIconType";

type Props = {
  results: CommentAnalysisResults;
};

export const AnalysisResultsSection = ({ results }: Props) => {
  const [isSectionOpen, setIsSectionOpen] = useState<boolean>(true);
  const [state] = useContext(AnalysisContext);

  const location = useLocation();

  const isCustomModelActive = location.search.includes("custom_model_id");

  const pillStyling = {
    maxWidth: "calc(100% - 16px)",
    ...(isCustomModelActive && { overflowWrap: "break-word" }),
  };

  return (
    <>
      <CollapsibleName
        name={
          <SectionTitle
            mt={10}
            color={Color.gray30}
            titleKey="comments.detailsPopup.analysisResults.header"
          />
        }
        isExpanded={isSectionOpen}
        onClick={() => setIsSectionOpen((openSection) => !openSection)}
        customStyles={{
          arrowColor: Color.gray30,
          labelMargin: 6,
          marginBottom: 0,
        }}
      />
      {isSectionOpen &&
        (Object.keys(results).length > 0 ? (
          <StyledAnaysisResultsContent>
            {results.commentPolarity && (
              <StyledAnalysisResultSection>
                <StyledResultTitle>
                  <Text resource="comments.detailsPopup.sentiment.label" />
                </StyledResultTitle>
                <SentimentPill sentiment={results.commentPolarity} showSentimentName />
              </StyledAnalysisResultSection>
            )}
            {Object.keys(results.categorizedTopics)?.length > 0 && (
              <StyledAnalysisResultSection mb={16}>
                <StyledResultTitle>
                  <Text resource="comments.detailsPopup.topicInsights.label" />
                </StyledResultTitle>
                <CategorizedTopicPillList
                  topics={results.categorizedTopics}
                  pillStyling={pillStyling}
                  isCustomModel={isModelVirtual(state.selectedModelId, state.analysisModels)}
                />
              </StyledAnalysisResultSection>
            )}
            {Object.keys(results.categorizedRecommendations).some(
              (k) => results.categorizedRecommendations[k].length > 0
            ) && (
              <StyledAnalysisResultSection>
                <StyledResultTitle>
                  <Text resource="comments.detailsPopup.recommendations.label" />
                </StyledResultTitle>
                <CategorizedTopicPillList
                  topics={results.categorizedRecommendations}
                  pillStyling={pillStyling}
                  isCustomModel={isModelVirtual(state.selectedModelId, state.analysisModels)}
                />
              </StyledAnalysisResultSection>
            )}
            {results.recommendations?.length > 0 && (
              <StyledAnalysisResultSection>
                <StyledResultTitle>
                  <Text resource="comments.detailsPopup.generalRecommendations.label" />
                </StyledResultTitle>
                <NonCategorizedRecommendationPillList recommendations={results.recommendations} />
              </StyledAnalysisResultSection>
            )}
            {state.analysisDetails.availableResults.alerts === true && (
              <StyledAnalysisResultSection>
                <StyledResultTitle>
                  <Text resource="comments.detailsPopup.alertScore.label" />
                </StyledResultTitle>
                <InterpretationPill
                  count={results.alertScore}
                  iconType={
                    results.hasAlert
                      ? InterpretationIconType.alertRedFlagAlert
                      : InterpretationIconType.alertNothingToReport
                  }
                  bnw
                />
              </StyledAnalysisResultSection>
            )}
          </StyledAnaysisResultsContent>
        ) : (
          <StyledEmptyState>
            <Text resource="comments.detailsPopup.noResults" />
          </StyledEmptyState>
        ))}
    </>
  );
};

const StyledAnalysisResultSection = styled.div<{ mb?: number }>`
  margin-bottom: ${({ mb }) => mb || 12}px;
`;

const StyledResultTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${Color.gray50};
  margin-bottom: 5px;
`;

const StyledAnaysisResultsContent = styled.div`
  margin-left: 14px;
`;

const StyledEmptyState = styled.div`
  font-size: 13px;
  margin: 0px 14px 8px 14px;
  color: ${Color.neutral50};
`;
