import React from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";

type Props = {
  value: number;
  stroke: number;
  size: number;
  loading?: boolean;
};

export const RingChart = ({ value, stroke, size, loading }: Props) => {
  if (loading || isNaN(value)) {
    return (
      <StyledSkeleton className="pulse" style={{ height: size, width: size }}></StyledSkeleton>
    );
  }

  if (value > 100) value = 100;

  const normalizedradius = 0.406 * size;
  const circumference = normalizedradius * 2 * Math.PI;
  const strokeDashoffset = circumference - (value / 100) * circumference;
  const strokeDashArray = `${circumference} ${circumference}`;

  return (
    <StyledRingChartContainer style={{ height: size, width: size }}>
      <StyledRingContainer>
        <svg height={size} width={size} style={{ textAlign: "center" }}>
          <circle
            stroke={Color.blue20}
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={strokeDashArray}
            r={normalizedradius}
            cx={size / 2}
            cy={size / 2}
          />
          <circle
            stroke={Color.red40}
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={strokeDashArray}
            style={{ strokeDashoffset }}
            r={normalizedradius}
            cx={size / 2}
            cy={size / 2}
          />
        </svg>
      </StyledRingContainer>
      <b>{value > 0 && value < 1 ? "<1" : Math.round(value)}%</b>
    </StyledRingChartContainer>
  );
};

const StyledSkeleton = styled.div`
  background-color: ${Color.sky20};
  border-radius: 50%;
`;

const StyledRingChartContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  b {
    position: absolute;
    font-size: 28px;
    font-family: "Helvetica Neue", Helvetica;
    height: 100%;
    width: 100%;
    font-weight: bold;
    color: ${Color.red40};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledRingContainer = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
`;
