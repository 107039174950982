import React from "react";
import styled from "styled-components";

import { getRandomNumber } from "utils/getRandomNumber";
import { useResource } from "hooks/useResource";

import { UserDetailRow } from "./UserDetailRow";
import { Text } from "components/Text";
import { Color } from "ts/enums/color";

type Props = {
  userDetails: Record<string, string>;
};

export const UserDetailsPopup = ({ userDetails }: Props) => {
  const { getResource } = useResource();

  if (userDetails === null) return;

  return (
    <StyledUserDetailsPopup>
      <StyledUserDetailsTitle>
        <Text resource="userList.detailsPopup.title" />
      </StyledUserDetailsTitle>
      {Object.keys(userDetails)
        .sort()
        .map((d, index) => (
          <UserDetailRow
            key={index}
            id={getRandomNumber()}
            demographicTitle={d}
            demographicValue={
              userDetails[d] ?? getResource("totalBlockCount.commentersCount.undefined")
            }
          />
        ))}
    </StyledUserDetailsPopup>
  );
};

const StyledUserDetailsPopup = styled.div`
  padding: 10px;
  width: 220px;
  max-height: 380px;
  overflow-y: auto;
`;

const StyledUserDetailsTitle = styled.div`
  color: ${Color.gray30};
  font-weight: bold;
  font-size: 12px;
`;
