import React from "react";
import styled from "styled-components";
import appConfig from "appSettings.json";

import { Text } from "components/Text";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";

import rocket from "assets/images/rocket-drawing.png";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";

export const TrainingResources = () => (
  <StyledTrainingResources className="fade-in-up">
    <StyledRocket src={rocket} alt="rocket" />
    <StyledTrainingRightSection>
      <StyledTrainingCaptionsContainer>
        <h2>
          <Text resource="home.training.header" />
        </h2>
        <p>
          <Text resource="home.training.description" />
        </p>
      </StyledTrainingCaptionsContainer>
      <StyledTrainingButtonsContainer>
        <Button
          variant={ButtonVariant.primaryPurple}
          size={ButtonSize.sm}
          href={appConfig.app.helpCenter}
          openNewTab={true}
        >
          <Text resource="button.helpCenter" />
          <Icon type={IconType.externalLink} style={{ marginLeft: 5 }} />
        </Button>
        <Button
          variant={ButtonVariant.secondary}
          size={ButtonSize.sm}
          href="https://rise.articulate.com/share/_3XG-x5FhAH29CGYUp3_g8mrPcS4IeTl#/"
          openNewTab={true}
        >
          <Text resource="button.trainingSite" />
          <Icon type={IconType.externalLink} style={{ marginLeft: 5 }} />
        </Button>
      </StyledTrainingButtonsContainer>
    </StyledTrainingRightSection>
  </StyledTrainingResources>
);

const StyledTrainingResources = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  background-color: ${Color.indigo45};
  border-radius: 5px;
  margin-bottom: 20px;
  justify-content: space-between;
  height: 90px;
  @media (min-width: 1366px) {
    width: 765px;
  }
  @media (max-width: 1365px) {
    height: 146px;
  }
`;

const StyledTrainingRightSection = styled.div`
  display: flex;
  align-items: center;
  width: 556px;
  @media (max-width: 1365px) {
    display: block;
    width: 300px;
  }
`;

const StyledRocket = styled.img`
  width: 140px;
  position: relative;
  top: -12px;
  left: -10px;
  @media (max-width: 1365px) {
    top: 0px;
    left: 0px;
    width: 120px;
  }
`;

const StyledTrainingCaptionsContainer = styled.div`
  position: relative;
  left: -9px;
  width: 300px;
  * {
    color: ${Color.white};
  }
  h2 {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
  }
  p {
    margin: 0;
    font-size: 14px;
  }

  @media (max-width: 1366px) {
    left: -10px;
  }
`;

const StyledTrainingButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 1365px) {
    position: relative;
    left: -10px;
    margin-top: 12px;
  }
`;
