import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector } from "store";

import { routes } from "routes";
import { AnalysisContext } from "context/AnalysisContext";
import { setAlertThreshold } from "context/AnalysisContext/actions";

import { useResource } from "hooks/useResource";
import { useQueryParams } from "hooks/useQueryParams";
import { getCommentStatisticsByAnalysisId, updateAnalysis } from "services/analysis";
import { getAbbreviatedNumber } from "utils/formatters";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { isAnyAdmin } from "utils/isAnyAdmin";

import { TotalBlockCount } from "../TotalBlockCount";
import { BlockEmptyState } from "./BlockEmptyState";
import { Pill } from "components/_pills/Pill";
import { Icon, IconType } from "components/_icons/Icon";
import { RingChart } from "components/RingChart/index";
import { NumberInput } from "components/_inputs/NumberInput";
import { Text } from "components/Text";

import { BlockEmptyStateType } from "ts/enums/blockEmptyStateType";
import { PermissionLevel, RoleType, SharingCommentExplorerAccess } from "@explorance/mly-types";
import { PreviewUser } from "ts/analysis";
import { Color } from "ts/enums/color";

type Props = {
  isEmptyState: boolean;
  sharedUser: PreviewUser;
};

export const AlertSection = ({ isEmptyState, sharedUser }: Props) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  // hooks
  const [state, dispatch] = useContext(AnalysisContext);

  const [alertData, setAlertData] = useState({
    total: undefined,
    requiresAttention: undefined,
    nothingToReport: undefined,
  });
  const [localAlertThreshold, setLocalAlertThreshold] = useState<number>(0);
  const [lastLocalAlertThreshold, setLastLocalAlertThreshold] = useState<number>(0);

  const { getResource } = useResource();
  const history = useHistory();
  const { sharingPreview, sharingId, sharedWithPage, expandedRowId, step } = useQueryParams();

  // variables
  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);

  const countInfo = getAbbreviatedNumber(alertData?.requiresAttention, isEmptyState);

  const isAnalysisOwner = !state.analysisDetails?.sharing;
  const isAdmin = isAnyAdmin(currentUser.roleType);
  const isViewer = currentUser.roleType === RoleType.Viewer;
  const hasContributionPermission =
    state.analysisDetails?.sharing?.permissionLevel === PermissionLevel.Contribution;

  const canAccessComments =
    isAdmin ||
    isAnalysisOwner ||
    state.analysisDetails?.sharing?.commentExplorerAccess === SharingCommentExplorerAccess.Shared;

  const canAccessAlertThreshold =
    !sharingPreview && !isViewer && (isAdmin || isAnalysisOwner || hasContributionPermission);

  // functions
  const updateData = async (newThreshold: number) => {
    setLastLocalAlertThreshold(localAlertThreshold);
    dispatch(setAlertThreshold(newThreshold));

    const reqBody = {
      alertThreshold: newThreshold,
      commenterColumn: state.analysisDetails.selectedCommentersColumn,
    };

    try {
      await updateAnalysis(analysisId, reqBody);
      const { data } = await getCommentStatisticsByAnalysisId(
        analysisId,
        undefined,
        undefined,
        state.previewUser.id,
        sharingId
      );
      setAlertData(data.statistics.alerts);
    } catch (error) {
      throw error;
    }
  };

  const decrement = () => {
    if (localAlertThreshold > 1) {
      setLocalAlertThreshold(localAlertThreshold - 1);
    }
  };

  const increment = () => {
    if (localAlertThreshold < 100) {
      setLocalAlertThreshold(localAlertThreshold + 1);
    }
  };

  const handleTotalCountClick = () => {
    history.push(
      routes.commentsPage(
        analysisId,
        getUrlQueryString({
          view: "alerts",
          sharingPreview,
          sharingId,
          sharedWithPage,
          expandedRowId,
          step,
        })
      )
    );
  };

  useEffect(() => {
    if (state.commentStatistics?.alerts && !isEmptyState) {
      setAlertData(state.commentStatistics?.alerts);
      setLocalAlertThreshold(state.alertThreshold);
      setLastLocalAlertThreshold(state.alertThreshold);
    }
  }, [state.commentStatistics, isEmptyState]); //eslint-disable-line

  return (
    <StyledAlertSectionContainer isEmptyState={isEmptyState}>
      <TotalBlockCount
        title={<Text resource="totalBlockCount.title.alerts" />}
        displayedCount={countInfo?.isResource ? getResource(countInfo?.count) : countInfo?.count}
        addBorder={false}
        isEmptyState={isEmptyState}
        handleClick={
          !state?.analysisDetails?.availableResults?.alerts ||
          !canAccessComments ||
          sharedUser?.commentExplorerAccess === SharingCommentExplorerAccess.Hidden
            ? undefined
            : () => handleTotalCountClick()
        }
      >
        <Icon type={IconType.alertNoBgColor} size={16} />
      </TotalBlockCount>
      {isEmptyState ? (
        <BlockEmptyState type={BlockEmptyStateType.alerts} />
      ) : (
        <>
          <StyledChartContainer>
            <RingChart
              value={(alertData?.requiresAttention / alertData?.total) * 100}
              stroke={23}
              size={128}
              loading={!state.commentStatistics}
            />
            <StyledAlertReportsContainer>
              <StyledReportContainer>
                <Pill backgroundColor={Color.neutral10}>
                  <StyledReport color={Color.gray50}>
                    <Icon type={IconType.alertNoBgColor} size={16} color={Color.red50} />
                    <b>{alertData?.requiresAttention}</b>
                    <span>
                      <Text resource="overview.alerts.countLabel.hasAlert" />
                    </span>
                  </StyledReport>
                </Pill>
              </StyledReportContainer>
              <StyledReportContainer>
                <Pill backgroundColor={Color.white}>
                  <StyledReport color={Color.gray20}>
                    <Icon type={IconType.alertNoBgColor} size={16} color={Color.gray50} />
                    <b>{alertData?.nothingToReport}</b>
                    <span>
                      <Text resource="overview.alerts.countLabel.noAlert" />
                    </span>
                  </StyledReport>
                </Pill>
              </StyledReportContainer>
            </StyledAlertReportsContainer>
          </StyledChartContainer>
          <StyledThresholdContainer canAccessComments={canAccessComments}>
            <b>
              <Text resource="overview.alerts.thresholdInput.label" />
            </b>
            {canAccessAlertThreshold ? (
              <StyledThresholdPercentContainer color={Color.gray50}>
                <StyledThresholdInputContainer
                  color={
                    lastLocalAlertThreshold === localAlertThreshold ? Color.sky50 : Color.blue50
                  }
                >
                  <button color={Color.sky40} onClick={() => decrement()}>
                    <Icon type={IconType.minus} size={10} color={Color.gray30} />
                  </button>
                  <StyledInputContainer>
                    <NumberInput
                      value={localAlertThreshold}
                      minimum={1}
                      maximum={100}
                      updateOnType={true}
                      styling={{
                        border: "none",
                        width: "32px",
                        textAlign: "right",
                        focusBorder: "none",
                      }}
                      handleChange={setLocalAlertThreshold}
                    />
                    <span>%</span>
                  </StyledInputContainer>
                  <button color={Color.sky40} onClick={increment}>
                    <Icon type={IconType.plus} size={10} color={Color.gray30} />
                  </button>
                </StyledThresholdInputContainer>
                <button
                  onClick={() => updateData(localAlertThreshold)}
                  disabled={localAlertThreshold === lastLocalAlertThreshold}
                >
                  <Text resource="button.set" />
                </button>
              </StyledThresholdPercentContainer>
            ) : (
              <span>{localAlertThreshold}%</span>
            )}
          </StyledThresholdContainer>
        </>
      )}
    </StyledAlertSectionContainer>
  );
};

const StyledAlertSectionContainer = styled.div<{ isEmptyState: boolean }>`
  display: flex;
  align-items: center;
  column-gap: ${({ isEmptyState }) => !isEmptyState && "20px"};
`;

const StyledThresholdInputContainer = styled.div<{ color: Color }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  width: 110px;
  height: 34px;
  border: 2px solid;
  border-radius: 2px;
  border-color: ${({ color }) => color};

  button {
    display: flex;
    border: none;
    border-radius: 2.5px;
    width: 16px;
    height: 16px;
    padding: 0px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: ${Color.sky40};
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;

  span {
    color: ${Color.gray50};
  }
`;

const StyledThresholdPercentContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: auto;
  height: 34px;

  > button {
    min-width: 48px;
    border: none;
    cursor: pointer;
    height: 29px;
    background-color: ${Color.sky15};
    border-radius: 2px;
    color: ${Color.blue50};
    font-size: 14px;
    user-select: none;
    font-weight: bold;
    margin: 0 10px;
    :disabled {
      background-color: ${Color.neutral10};
      pointer-events: none;
      color: ${Color.neutral40};
      font-size: 14px;
      user-select: none;
      font-weight: bold;
    }
  }
`;

const StyledThresholdContainer = styled.div<{ canAccessComments: boolean }>`
  flex-direction: column;
  row-gap: 6px;
  display: flex;
  position: relative;
  height: 57px;
  margin-left: auto;
  margin-bottom: auto;
  color: ${Color.gray50};

  b {
    font-size: 14px;
    font-weight: bold;
    color: ${Color.gray50};
  }

  span {
    margin-left: ${({ canAccessComments }) => !canAccessComments && "auto"};
  }
`;

const StyledChartContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  @media (max-width: 1365px) {
    width: 65%;
  }

  @media (min-width: 1366px) {
    width: 70%;
  }
`;

const StyledAlertReportsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 290px;
  height: 69px;
`;

const StyledReportContainer = styled.div`
  height: 29px;
  width: auto;
`;

const StyledReport = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 5px;

  b {
    margin: 0 3px;
    margin-left: 5px;
    color: ${({ color }) => color};
  }
  span {
    color: ${({ color }) => color};
  }
`;
