import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import styled from "styled-components";
import { CSS } from "@dnd-kit/utilities";
import { useAppSelector, useAppDispatch } from "store";

import {
  changeWidgetName,
  moveCardSameSection,
  moveCardToDifferentSection,
} from "store/widgetOrdering/widgetOrderingModal";
import useClickOutside from "hooks/useClickOutside";

import { TextInput } from "components/_inputs/TextInput";
import { WidgetTypeIcon } from "components/Widget/WidgetTypeIcon";
import { Icon, IconType } from "components/_icons/Icon";
import { DropdownMenu } from "components/DropdownMenu";
import { MoveUpDownButtons } from "components/_buttons/MoveUpDownButtons";
import { VerticalSeparator } from "components/Separators/VerticalSeparator";

import { Color } from "ts/enums/color";
import { Widget } from "ts/widget";

type Props = {
  widget: Widget;
  sectionId: number;
  dragging?: boolean;
  placeholder?: boolean;
};

export const DraggableWidgetCard = ({ widget, dragging, sectionId, placeholder }: Props) => {
  const state = useAppSelector((state) => state.widgetOrdering);
  const dispatch = useAppDispatch();

  const [dropdownMenuOpen, setDropdownMenuOpen] = useState<boolean>(false);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: widget.id,
  });
  const dropdownMenuRef = useClickOutside(() => {
    setDropdownMenuOpen(false);
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
  };

  const getDropDownMenuItems = () => {
    //map sections to dropdown items and remove current section
    return state.groups
      .filter((group) => group.id !== sectionId)
      .map((group) => {
        return {
          label: group.title,
          onClick: () => dispatch(moveCardToDifferentSection({ groupId: group.id, id: widget.id })),
        };
      });
  };

  return (
    <StyledDraggableWidgetCard
      ref={placeholder ? null : setNodeRef}
      style={style}
      data-no-dnd="true"
    >
      <StyledDragIndicator
        ref={placeholder ? null : setNodeRef}
        isDragging={dragging}
        data-no-dnd="false"
        {...listeners}
        {...attributes}
      >
        <div />
        <div />
      </StyledDragIndicator>
      <WidgetTypeIcon
        widgetType={widget.type}
        displayBackground={false}
        customStyles={{ margin: "0px 8px 0px 8px" }}
        useSolidIcons
      />
      <TextInput
        value={widget.title}
        onChange={(e) => dispatch(changeWidgetName({ title: e.target.value, id: widget.id }))}
      />
      {state.groups && state.groups.length > 1 && (
        <>
          <StyledRearrangeSelector
            ref={dropdownMenuRef}
            onClick={() => setDropdownMenuOpen((isOpen) => !isOpen)}
          >
            <Icon type={IconType.rearrangeArrows} size={16} />
          </StyledRearrangeSelector>
          <DropdownMenu
            items={getDropDownMenuItems()}
            show={dropdownMenuOpen}
            position={{ top: 27, right: 40 }}
            width="160px"
            titleKey="modal.manageWidgets.dropdown.title"
            handleCloseMenu={() => setDropdownMenuOpen(false)}
          />
        </>
      )}
      <VerticalSeparator height="32px" style={{ marginLeft: "10px" }} />
      <MoveUpDownButtons
        id={widget.id}
        moveItem={(direction) => dispatch(moveCardSameSection({ direction, id: widget.id }))}
        customStyles={{ marginRight: "5px", marginLeft: "7px" }}
      />
    </StyledDraggableWidgetCard>
  );
};

const StyledDraggableWidgetCard = styled.div<{ opacity?: number }>`
  opacity: ${(props) => props.opacity};
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  border: 1px solid ${Color.blue20};
  border-radius: 3px;
  width: 100%;
  background-color: ${Color.white};
`;

const StyledDragIndicator = styled.div<{ isDragging: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ isDragging }) => (isDragging ? Color.sky30 : Color.sky20)};
  width: 42px;
  height: 42px;
  gap: 3px;
  cursor: ${({ isDragging }) => (isDragging ? "grabbing" : "grab")};
  div {
    width: 16px;
    height: 2px;
    background-color: ${({ isDragging }) => (isDragging ? Color.blue50 : Color.gray20)};
  }
`;

const StyledRearrangeSelector = styled.div`
  width: 12px;
  height: 12px;
  margin-left: 19px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
