import React, { useState } from "react";
import { lowerFirst } from "lodash";

import { useQueryParams } from "hooks/useQueryParams";

import { TopicsTableRowsPlaceholder } from "./TopicsTableRowsPlaceholder";
import { TopicsTableRowContainer } from "./TopicsTableRowContainer";
import { StyledSentimentsViewTable, StyledRecommendationsViewTable } from "./StyledTopicsTable";
import { ClickableTableHeader, TableInformation } from "components/Table/ClickableTableHeader";
import { SentimentIcon } from "components/_icons/SentimentIcon";
import { RecommendationIcon } from "components/_icons/RecommendationIcon";
import { ConditionalWrapper } from "components/ConditionalWrapper";

import {
  TopicsSortingParameter,
  SentimentsViewTopicsSorting,
  RecommendationsViewTopicsSorting,
} from "ts/enums/sorting";
import { Topic } from "ts/topic";
import {
  RecommendationType,
  SentimentType,
  SortingOrder,
  TopicsListView,
} from "@explorance/mly-types";

const PREVIEW_TOP_OFFSET = 210;

type Props = {
  topics?: Topic[];
  sortingOrder: SortingOrder;
  sortingParameter: TopicsSortingParameter;
  showPlaceholder: boolean;
  view: TopicsListView;
  analysisHeaderHeight: number;
  onSort(parameter: TopicsSortingParameter): void;
};

export const TopicsTable = ({
  topics,
  sortingParameter,
  sortingOrder,
  showPlaceholder,
  view,
  analysisHeaderHeight,
  onSort,
}: Props) => {
  const [selectedTopicPath, setSelectedTopicPath] = useState<Array<string>>();

  const { sharingPreview } = useQueryParams();

  const sentimentsViewTableInformation: TableInformation<TopicsSortingParameter>[] = Object.values(
    SentimentsViewTopicsSorting
  )
    .map((svt) => ({
      headerName: `analysisList.table.${lowerFirst(svt.toString())}`,
      sortingParameter: TopicsSortingParameter[svt.toString()],
      ...(Object.keys(SentimentType).some((st) => st === lowerFirst(svt.toString())) && {
        icon: <SentimentIcon type={lowerFirst(svt.toString()) as SentimentType} size={16} />,
        className: "sentiment-label",
      }),
    }))
    .filter((ti) => ti.headerName);

  const recommendationsViewTableInformation: TableInformation<TopicsSortingParameter>[] =
    Object.values(RecommendationsViewTopicsSorting).map((rvt) => ({
      headerName: `analysisList.table.${lowerFirst(rvt.toString())}`,
      sortingParameter: TopicsSortingParameter[rvt.toString()],
      ...(Object.keys(RecommendationType).some((rt) => rt === lowerFirst(rvt.toString())) && {
        icon: <RecommendationIcon type={lowerFirst(rvt.toString()) as RecommendationType} />,
        className: "recommendation-label",
      }),
    }));

  // functions
  const handleTopicPathSelection = (topicPath: Array<string>) => setSelectedTopicPath(topicPath);

  const getWrapper = (children) => {
    switch (view) {
      case TopicsListView.Sentiments:
        return (
          <StyledSentimentsViewTable
            top={sharingPreview ? PREVIEW_TOP_OFFSET : analysisHeaderHeight}
            isSharingPreview={!!sharingPreview}
          >
            {children}
          </StyledSentimentsViewTable>
        );
      case TopicsListView.Recommendations:
        return (
          <StyledRecommendationsViewTable
            top={sharingPreview ? PREVIEW_TOP_OFFSET : analysisHeaderHeight}
            isSharingPreview={!!sharingPreview}
          >
            {children}
          </StyledRecommendationsViewTable>
        );
    }
  };

  return (
    <>
      <ConditionalWrapper condition={true} wrapper={(children) => getWrapper(children)}>
        <ClickableTableHeader
          tableInformation={
            view === TopicsListView.Sentiments
              ? sentimentsViewTableInformation
              : recommendationsViewTableInformation
          }
          sortingParameter={sortingParameter}
          sortingOrder={sortingOrder}
          handleSort={(type: TopicsSortingParameter) => onSort(type)}
        />
        <tbody>
          {showPlaceholder ? (
            <TopicsTableRowsPlaceholder />
          ) : (
            <TopicsTableRowContainer
              aggregationDepth={1}
              topics={topics}
              selectedTopicPath={selectedTopicPath}
              isImmediateParentExpanded={true}
              isAncestorExpanded={true}
              view={view}
              handleTopicPathSelection={handleTopicPathSelection}
            />
          )}
        </tbody>
      </ConditionalWrapper>
    </>
  );
};
