import React from "react";
import styled, { CSSProperties } from "styled-components";

import { getAppConfig } from "config/configProviders";

import { Modal } from "../Modal";
import mlyLogo from "assets/images/mly-logo.svg";

import { Color } from "ts/enums/color";
import { Text } from "components/Text";

type Props = {
  isOpen: boolean;
  onClose(): void;
};

const modalStyles = {
  content: {
    position: "relative" as CSSProperties["position"],
    boxShadow: "0px 4px 16px #B6BACB29",
    margin: "100px auto",
    padding: "25px",
    width: "450px",
    height: "auto",
    border: `1px solid ${Color.neutral30}`,
    borderRadius: "5px",
    backgroundColor: Color.white,
    inset: "0px",
  },
};

const dateCopyright = new Date().getFullYear();

const appConfig = getAppConfig();

export const AboutMlyModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} handleRequestCloseFunc={onClose} styles={modalStyles}>
      <StyledModal>
        <img src={mlyLogo} alt="mly-logo" width={104} />

        <p className="aboutMly">
          <Text resource="modal.aboutBlueML.description" />
        </p>
        <p className="versionNumber">
          <Text resource="modal.aboutBlueML.versionNumber" /> &nbsp;&nbsp;
          {`${appConfig.version}.${appConfig.build}`}
        </p>

        <p className="copyright">
          <Text resource={{ key: "modal.aboutBlueML.copyright", args: [`${dateCopyright}`] }} />
        </p>
      </StyledModal>
    </Modal>
  );
};

const StyledModal = styled.div`
  font-family: Lato, sans-serif;
  cursor: default;

  .aboutMly {
    font-size: 0.875em;
    line-height: 1.4em;
    color: ${Color.black};
    margin: 20px 0px 0px 0px;
  }

  .versionNumber {
    font-size: 0.875em;
    color: ${Color.black};
    margin: 20px 0px 0px 0px;
  }

  .copyright {
    font-size: 0.75em;
    color: ${Color.gray40};
    margin: 5px 0px 0px 0px;
  }
`;
