import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import { deepFindOneTopic } from "utils/topics";
import { createChild } from "./CustomTopicsTree/helpers";
import { useCustomModelBuilder } from ".";

import { SideDrawer } from "components/SideDrawer";
import { MapTopicsSideDrawer } from "common-layouts/MapTopicsSideDrawer";
import { CustomTopicsTree } from "./CustomTopicsTree";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { EmptyState } from "components/EmptyState";
import { Text } from "components/Text";

import emptyState from "assets/images/empty-virtual.svg";
import { ApiTopicNode } from "ts/topic";
import { CustomModelBuilderMode } from "ts/enums/customModelBuilderMode";
import { AnalysisModel } from "ts/filters/analysisModel";
import { CustomModelTopicUpdateMethod } from "ts/enums/customModelTopicUpdateMethod";
import { TreeData, TreeItem } from "@atlaskit/tree";
import { ButtonSize } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { EmptyStateType } from "ts/enums/emptyStateType";

type Props = {
  updateMappedTopicsCount: (tree: TreeData) => void;
  onTreeChange: () => void;
};

export const CustomModelBuilderBox = ({ updateMappedTopicsCount, onTreeChange }: Props) => {
  const [showMapTopicsSideDrawer, setShowMapTopicsSideDrawer] = useState<boolean>(false);
  const [selectedRowName, setSelectedRowName] = useState<string>();
  const [selectedTreeItem, setSelectedTreeItem] = useState<TreeItem>();

  const ctx = useCustomModelBuilder();

  const noTopics = Object.keys(ctx.tree.items).length === 1;

  const handleMapTopicsSideDrawer = (item: TreeItem, topicName: string) => {
    setShowMapTopicsSideDrawer(true);
    setSelectedRowName(topicName);
    setSelectedTreeItem(item);
  };

  const updateBaseModel = (
    baseModel: AnalysisModel,
    selectedTopics: ApiTopicNode[],
    type: CustomModelTopicUpdateMethod
  ) => {
    const bmTopics = cloneDeep(baseModel.topics);
    let copy = [...ctx.allSelectedTopics];
    switch (type) {
      case "add":
        selectedTopics.forEach((t) => {
          deepFindOneTopic(bmTopics, t)["isHidden"] = true;
        });
        break;
      case "delete":
        selectedTopics.forEach((t) => {
          const topic = deepFindOneTopic(bmTopics, t);
          copy = copy.filter((c) => c.fullPath !== topic.fullPath);
          topic["isHidden"] = false;
        });
        ctx.setAllSelectedTopics(copy);
        break;
    }

    ctx.setUpdatedBaseModel({ ...ctx.updatedBaseModel, topics: bmTopics });
  };

  useEffect(() => {
    if (noTopics && ctx.mode === CustomModelBuilderMode.edit) {
      const newItemId = createChild({ id: "root" }, ctx.tree, ctx.setTree);
      ctx.setFocusedId(newItemId);
    }
  }, [ctx, noTopics]);

  useEffect(() => {
    if (!showMapTopicsSideDrawer) {
      updateBaseModel(
        ctx.updatedBaseModel,
        ctx.allSelectedTopics,
        CustomModelTopicUpdateMethod.add
      );
      updateMappedTopicsCount(ctx.tree);
    }
  }, [showMapTopicsSideDrawer]); // eslint-disable-line

  if (!ctx.baseModel) return null;

  return (
    <StyledCustomModelBuilderBox className="fade-in">
      <StyledTop>
        <div>
          <StyledTopHeader>
            <Text resource="customModels.modelBuilder.header" />
          </StyledTopHeader>
          <StyledParagraph>
            <Text resource="customModels.modelBuilder.description" />
          </StyledParagraph>
        </div>
        {ctx.mode === CustomModelBuilderMode.edit && (
          <Button
            size={ButtonSize.sm}
            onClick={() => {
              const newTopicId = createChild({ id: "root" }, ctx.tree, ctx.setTree);
              ctx.setFocusedId(newTopicId);
            }}
            style={{ minWidth: 136 }}
          >
            <Icon type={IconType.plus} size={16} style={{ marginRight: 4 }} />
            <Text resource="button.addRootTopic" />
          </Button>
        )}
      </StyledTop>
      {noTopics && ctx.mode === CustomModelBuilderMode.view ? (
        <EmptyState
          type={EmptyStateType.noListResults}
          image={emptyState}
          titleKey={"customModels.emptyTopicState.header"}
          captionKey={"customModels.emptyTopicState.description"}
          linkKey={"customModels.emptyTopicState.link"}
          customStyles={{ display: "flex", marginTop: "100px" }}
          handleClickCaptionLink={() => ctx.switchMode(CustomModelBuilderMode.edit)}
        />
      ) : (
        <CustomTopicsTree
          topicsSideDrawerOpen={showMapTopicsSideDrawer}
          onClickMapTopics={handleMapTopicsSideDrawer}
          updateBaseModelTopics={updateBaseModel}
          onTreeChange={onTreeChange}
        />
      )}
      <SideDrawer
        render={MapTopicsSideDrawer}
        isOpen={showMapTopicsSideDrawer}
        renderComponentProps={{
          selectedTreeItem,
          selectedTopicName: selectedRowName,
          updateBaseModel,
          onTreeChange,
        }}
        width={"600px"}
        closeHandler={() => setShowMapTopicsSideDrawer(false)}
      />
    </StyledCustomModelBuilderBox>
  );
};

const StyledCustomModelBuilderBox = styled.div`
  border: 1px solid ${Color.blue20};
  height: calc(100vh - 220px);
  padding: 16px;
  overflow-y: auto;
`;

const StyledTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const StyledTopHeader = styled.div`
  color: ${Color.black};
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 6px;
`;

const StyledParagraph = styled.p`
  font-size: 14px;
  color: ${Color.gray40};
  margin: 0;
  max-width: 90%;
`;
