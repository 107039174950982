import React from "react";
import styled from "styled-components";

import { Skeleton } from "components/Skeleton";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";

type Props = {
  titleKey: string;
  isLoading: boolean;
};

export const SectionTitle = ({ titleKey, isLoading }: Props) => (
  <>
    {isLoading ? (
      <div className="pulse">
        <Skeleton width={170} height={16} backgroundColor={Color.neutral10} borderRadius="5px" />
      </div>
    ) : (
      <StyledHeaderTitle>
        <Text resource={titleKey} />
      </StyledHeaderTitle>
    )}
  </>
);
export const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const StyledHeaderTitle = styled.span`
  font-size: 15px;
  font-weight: bold;
`;
