import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";

import useClickOutside from "hooks/useClickOutside";
import { useQueryParams } from "hooks/useQueryParams";
import { useAppDispatch, useAppSelector } from "store";
import {
  openModal,
  setCurrentlyEditedGroupId,
  setUserInput,
} from "store/widgets/widgetGroupsSlice";
import { AnalysisContext } from "context/AnalysisContext";

import { DropdownMenu } from "components/DropdownMenu";
import { Text } from "components/Text";
import { TextTruncator } from "components/TextTruncator";
import { ExpandCollapseButton } from "components/_buttons/ExpandCollapseButton";

import { DropdownMenuItem, DropdownSize } from "ts/dropdown";
import { Color } from "ts/enums/color";
import { WidgetGroupModalType } from "ts/enums/widgetGroupModalType";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { WidgetGroup } from "ts/widget";
import { PermissionLevel, RoleType } from "@explorance/mly-types";

type Props = {
  title: string;
  handleExpand: (isExpanded: boolean) => void;
  isEmptyGroupInSharing: boolean;
  widgetGroup: WidgetGroup;
  handleShowDeleteGroupModal: () => void;
};

export const Header = ({
  title,
  handleExpand,
  isEmptyGroupInSharing,
  widgetGroup,
  handleShowDeleteGroupModal,
}: Props) => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [state] = useContext(AnalysisContext);

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [dropdownMenuPosition, setDropdownMenuPosition] = useState<{
    top: number;
    right: number | string;
    zIndex: ZIndexStackingContext;
  }>({
    top: 0,
    right: 0,
    zIndex: ZIndexStackingContext.low,
  });

  const editGroupRef = useClickOutside(() => setDropdownOpen(false));
  const { sharingPreview } = useQueryParams();

  const isViewer =
    currentUser.roleType === RoleType.Viewer ||
    (state.analysisDetails?.sharing?.permissionLevel === PermissionLevel.View &&
      currentUser.roleType === RoleType.Analyst);

  const handleOpenDropdown = () => {
    if (!dropdownOpen) {
      const { top } = editGroupRef.current.getBoundingClientRect();
      const scrollY = document.getElementsByClassName("main-body")[0].scrollTop;
      const topPosition = scrollY + top + 20;
      setDropdownMenuPosition({ ...dropdownMenuPosition, top: topPosition, right: 0 });
    }
    setDropdownOpen(true);
  };

  const handleOpenEditModal = () => {
    dispatch(setUserInput(widgetGroup.title));
    dispatch(openModal(WidgetGroupModalType.renameGroup));
    dispatch(setCurrentlyEditedGroupId(widgetGroup.id));
    setDropdownOpen(false);
  };

  const dropdownItems = [
    {
      label: <Text resource="widgetGroups.dropdown.rename" />,
      onClick: handleOpenEditModal,
    },
    {
      label: <Text resource="widgetGroups.dropdown.delete" />,
      onClick: handleShowDeleteGroupModal,
    },
  ] as DropdownMenuItem[];

  return (
    <StyledExpandableWidgetGroupHeader
      isExpanded={isEmptyGroupInSharing ? false : widgetGroup.isExpanded}
    >
      <StyledClickableTitleSection
        onClick={() => !isEmptyGroupInSharing && handleExpand(!widgetGroup.isExpanded)}
        isEmptyGroupInSharing={isEmptyGroupInSharing}
      >
        <ExpandCollapseButton
          isCollapsed={!widgetGroup.isExpanded}
          customWidth={16}
          customHeight={16}
          onClick={() => undefined}
          disabled={isEmptyGroupInSharing}
        />
        <TextTruncator value={title} id={widgetGroup.id} customWidth="85%" />
      </StyledClickableTitleSection>

      {!isViewer && !sharingPreview && (
        <>
          <StyledEditGroupButton onClick={handleOpenDropdown} ref={editGroupRef}>
            <Text resource="button.widgets.editGroup" />
          </StyledEditGroupButton>
          <DropdownMenu
            width="136px"
            show={dropdownOpen}
            items={dropdownItems}
            position={dropdownMenuPosition}
            size={DropdownSize.lg}
          />
        </>
      )}
    </StyledExpandableWidgetGroupHeader>
  );
};

const StyledExpandableWidgetGroupHeader = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      padding-bottom: 16px;
      transition: 300ms padding-bottom ease-out;
    `}

  span {
    font-weight: bold;
  }
`;

const StyledClickableTitleSection = styled.div<{ isEmptyGroupInSharing: boolean }>`
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: ${({ isEmptyGroupInSharing }) => (isEmptyGroupInSharing ? "default" : "pointer")};
  user-select: none;
`;

const StyledEditGroupButton = styled.button`
  all: unset;
  text-decoration: underline;
  color: ${Color.blue50};
  font-weight: bold;
  cursor: pointer;
`;
