import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { routes } from "routes";

import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

import error from "assets/images/error.svg";
import { Color } from "ts/enums/color";

export const ErrorPage = () => {
  const history = useHistory();

  return (
    <StyledErrorPage className="fade-in">
      <StyledBackButton onClick={() => history.push(routes.homePage)}>
        <Icon
          type={IconType.arrowLeft}
          color={Color.blue50}
          size={18}
          style={{ marginRight: 20 }}
        />
        <Text resource="button.backToHome" />
      </StyledBackButton>
      <StyledContentContainer>
        <StyledImage src={error} alt="" />
        <StyledTextContainer>
          <StyledTitle>
            <Text resource="errorPage.title" />
          </StyledTitle>
          <StyledCaption>
            <Text resource="errorPage.description" />
          </StyledCaption>
        </StyledTextContainer>
      </StyledContentContainer>
    </StyledErrorPage>
  );
};

const StyledErrorPage = styled.div`
  margin-top: 35px;
`;

const StyledBackButton = styled.div`
  background: none;
  border: none;
  color: ${Color.blue50};
  font-family: Lato, sans-serif;
  font-size: 21px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:focus {
    outline-style: none;
  }
`;

const StyledContentContainer = styled.div`
  margin: 100px auto;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTextContainer = styled.div`
  text-align: center;
`;

const StyledImage = styled.img`
  margin: 0 auto;
`;

const StyledTitle = styled.div`
  margin: 40px 0 20px 0;
  font-size: 21px;
  font-weight: bold;
`;

const StyledCaption = styled.div`
  color: ${Color.gray40};
  font-size: 14px;
  p {
    margin: 5px 0;
    text-align: center;
  }

  a {
    color: ${Color.blue50};
    text-decoration: underline;
  }
`;
