import React from "react";
import styled from "styled-components";
import { ItemId, TreeItem } from "@atlaskit/tree";
import { Color } from "ts/enums/color";

type Props = {
  item: TreeItem;
  onExpand: (itemId: ItemId) => void;
  onCollapse: (itemId: ItemId) => void;
};

export const PretextIcon = ({ item, onExpand, onCollapse }: Props) => {
  const showIcon = item.children && item.children.length > 0;

  return (
    <StyledContainer>
      {showIcon ? (
        item?.isExpanded ? (
          <StyledPretextIcon onClick={() => onCollapse(item.id)}>–</StyledPretextIcon>
        ) : (
          <StyledPretextIcon onClick={() => onExpand(item.id)}>+</StyledPretextIcon>
        )
      ) : (
        <StyledBlankSpace />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 8px;
`;

const StyledPretextIcon = styled.span`
  background: ${Color.sky40} 0% 0% no-repeat padding-box;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${Color.gray30};
  padding-bottom: 3px;
`;

const StyledBlankSpace = styled.div`
  display: inline-block;
  width: 100%;
`;
