import React from "react";
import styled from "styled-components";

import { percentChance } from "utils/percentChance";

import { Skeleton } from "components/Skeleton";

import { Color } from "ts/enums/color";

export const PreFilteringPlaceholder = () => (
  <StyledPlaceholder>
    <Skeleton
      width={340}
      height={42}
      borderRadius="21px"
      backgroundColor={Color.blue20}
      customStyles={{ marginRight: "10px" }}
    />
    {[...Array(6)].map((_, i) => (
      <div key={i}>
        <Skeleton
          width={percentChance(50) ? 170 : 100}
          height={42}
          borderRadius="21px"
          backgroundColor={Color.neutral10}
        />
      </div>
    ))}
  </StyledPlaceholder>
);

const StyledPlaceholder = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
