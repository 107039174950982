import React, { useEffect } from "react";
import { upperFirst } from "lodash";
import { useHistory } from "react-router";

import { routes } from "routes";

import { useAppDispatch, useAppSelector } from "store";
import { setSearchTerm } from "store/search/searchbarSlice";
import { fetchCustomModels } from "store/customModels/thunks";
import {
  setShowDeleteConfirmation,
  setCurrentEditedRow,
  clearState,
  updateCustomModelsListSorting,
  setCurrentPage,
} from "store/customModels/customModelListSlice";

import { deleteCustomModel } from "services/customModels";
import { getDayMonthYearFromDate } from "utils/formatters";
import { getInternationalDateFormat } from "utils/getInternationalDateFormat";

import { ListPageHeaderPlaceholder } from "common-layouts/ListPagePlaceholder/ListPageHeaderPlaceholder";
import { CustomModelsPageHeader } from "./_layouts/Header";

import { EmptyState } from "components/EmptyState";
import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { TextTruncator } from "components/TextTruncator";
import { CategorizationTypeInfo } from "components/CategorizationTypeInfo";
import { ErrorScreen } from "components/ErrorScreen";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";
import { Text } from "components/Text";
import { TableInformation } from "components/Table/ClickableTableHeader";

import emptyCustomModels from "assets/images/empty-custom-models.svg";
import { FlexTableType } from "ts/enums/flexTable";
import { RoleType } from "@explorance/mly-types";
import { PageErrorType } from "ts/enums/pageErrorType";
import { Color } from "ts/enums/color";
import { CustomModelsListSortingParameter } from "ts/enums/sorting";

const CUSTOMIZED_ANALYSES_HEADERS: TableInformation<CustomModelsListSortingParameter>[] =
  Object.values(CustomModelsListSortingParameter).map((cm) => ({
    headerName: cm,
    sortingParameter: CustomModelsListSortingParameter[upperFirst(cm)],
  }));

export const CustomModelsPage = () => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const state = useAppSelector((state) => state.customModelList);
  const dispatch = useAppDispatch();

  const history = useHistory();

  const getMenuContents = () => {
    return [
      {
        label: <Text resource="customModels.table.dropdown.goToGroup" />,
        onClick: () => {
          history.push(routes.editCustomModelPage(state.currentEditedRow.id));
        },
      },
      {
        label: <Text resource="button.delete" />,
        onClick: () => dispatch(setShowDeleteConfirmation(true)),
      },
    ];
  };

  const deleteModel = async () => {
    await deleteCustomModel(state.currentEditedRow.id);
    dispatch(setShowDeleteConfirmation(false));
    dispatch(fetchCustomModels());
  };

  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const sortColumn = CUSTOMIZED_ANALYSES_HEADERS[updatedSorting.columnIndex].sortingParameter;
    dispatch(updateCustomModelsListSorting({ sortColumn, sortOrder: updatedSorting.order }));
    dispatch(setCurrentPage(1));
    dispatch(fetchCustomModels());
  };

  useEffect(() => {
    dispatch(fetchCustomModels());

    // Reset state when component unmounts
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  if (currentUser.roleType === RoleType.Viewer) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <div className="fade-in">
      {state.isLoading ? (
        <ListPageHeaderPlaceholder showCreateButton showFilterButton={false} applyMorePadding />
      ) : (
        <>
          <ActionConfirmationModal
            title={
              <Text
                resource={{
                  key: "modal.deleteWithName.title",
                  args: [state.currentEditedRow?.name],
                }}
              />
            }
            caption={<Text resource="modal.deleteCustomModel.caption" />}
            actionButtonLabel={<Text resource="button.delete" />}
            isOpen={state.showDeleteConfirmation}
            onCancel={() => dispatch(setShowDeleteConfirmation(false))}
            onClickActionButton={deleteModel}
            onClose={() => dispatch(setShowDeleteConfirmation(false))}
          />
          <CustomModelsPageHeader />
        </>
      )}
      {state.totalModelCount > 0 || state.isLoading ? (
        <FlexTable
          type={FlexTableType.Table}
          data={{
            headers: CUSTOMIZED_ANALYSES_HEADERS.map((h) => (
              //Storybook needs Text to be declared in the component
              <Text key={h.headerName.toString()} resource={`customModels.table.${h.headerName}`} />
            )).concat(<></>),
            rows: state.customModelList?.map((cm) => ({
              contentId: cm.id,
              data: [
                <TextTruncator
                  value={cm.name}
                  id={cm.id}
                  key={`name-${cm.id}`}
                  customWidth="85%"
                />,
                <TextTruncator value={cm.env} id={cm.id} key={`env-${cm.id}`} customWidth="85%" />,
                <CategorizationTypeInfo
                  isPowerset={cm.isPowerset}
                  family={cm.family}
                  languages={cm.languages}
                  isOutdated={cm.isOutdated}
                  key={`categorization-${cm.id}`}
                />,
                <span key={`created-${cm.id}`}>
                  {getInternationalDateFormat(getDayMonthYearFromDate(cm.createdDate.toString()))}
                </span>,
                <span key={`lastUpdated-${cm.id}`}>
                  {getInternationalDateFormat(getDayMonthYearFromDate(cm.lastUpdated.toString()))}
                </span>,
              ],
              dotsMenuParams: {
                rowId: cm.id,
                currentEditedRowId: state.currentEditedRow?.id,
                menuContents: getMenuContents(),
                onDotMenuClick: () => dispatch(setCurrentEditedRow(cm)),
              },
            })),
            columnWidths: ["25%", "20%", "20%", "15%", "15%", "5%"],
          }}
          handleDoubleClickRow={(id) => {
            history.push(routes.editCustomModelPage(id));
          }}
          customStyles={{
            rows: {
              padding: "10px",
              backgroundColor: Color.white,
            },
          }}
          initialSorting={{
            columnIndex: CUSTOMIZED_ANALYSES_HEADERS.findIndex(
              (h) => h.sortingParameter === state.sortColumn
            ),
            order: state.sortOrder,
          }}
          onSortingChange={updateSorting}
          isLoading={state.isLoading}
        />
      ) : (
        <EmptyState
          type={state.emptyStateType}
          image={emptyCustomModels}
          titleKey={"emptyState.noCustomModels.title"}
          captionKey={"emptyState.noCustomModels.caption"}
          handleClickCaptionLink={() => dispatch(setSearchTerm(""))}
        />
      )}
    </div>
  );
};
