import React from "react";
import { useAppSelector } from "store";

import styled from "styled-components";
import { isNumber } from "lodash";

import { Color } from "ts/enums/color";
import { SentimentType } from "@explorance/mly-types";
import { QuickAnalysisState } from "ts/enums/quickAnalysis";
import { DEFAULT_ALERT_THRESHOLD } from "assets/constants/defaultAlertThreshold";

import { useResource } from "hooks/useResource";
import { getCommentInterpretationCounts } from "common-layouts/CommentResultsIcons/helpers";

import { DominantSentiment } from "./DominantSentiment";
import { TopicInsights } from "./TopicInsights";
import { Recommendations } from "./Recommendations";
import { AlertScore } from "./AlertScore";
import { CollapsibleName } from "components/CollapsibleName";
import { CommentResultsIcons } from "common-layouts/CommentResultsIcons";
import { Text } from "components/Text";

export const AnalysisInterpretation = () => {
  const { getResource } = useResource();
  const state = useAppSelector((state) => state.quickAnalysis);

  if (!state.quickAnalysisResult) return null;

  let nbTopicInsights = 0;
  if (state.quickAnalysisResult.categorizedTopics) {
    Object.keys(state.quickAnalysisResult.categorizedTopics).forEach((key) => {
      nbTopicInsights += state.quickAnalysisResult.categorizedTopics[key].length;
    });
  }

  let nbRecommendations = 0;
  if (state.quickAnalysisResult.categorizedRecommendations) {
    Object.keys(state.quickAnalysisResult.categorizedRecommendations)?.forEach((key) => {
      nbRecommendations += state.quickAnalysisResult.categorizedRecommendations[key].length;
    });
  }
  if (state.quickAnalysisResult.hasRecommendation) {
    nbRecommendations += state.quickAnalysisResult.recommendations.length;
  }

  const descriptionParagraph = (
    <p>
      <Text resource="quickAnalysis.interpretation.summary.alsoFound" />
      {(state.quickAnalysisResult.hasRecommendation ||
        state.quickAnalysisResult.categorizedTopics) && (
        <Text resource="quickAnalysis.interpretation.summary.totalOf" />
      )}
      {state.quickAnalysisResult.categorizedTopics && (
        <span>
          <b>
            {nbTopicInsights}{" "}
            <Text
              resource={{
                key:
                  nbTopicInsights === 1
                    ? "quickAnalysis.interpretation.summary.topicInsight"
                    : "quickAnalysis.interpretation.summary.topicInsights",
              }}
            />
          </b>
          {isNumber(state.quickAnalysisResult.alertScore) &&
            (state.quickAnalysisResult.hasRecommendation ? (
              ", "
            ) : (
              <>
                {" "}
                <Text resource="term.and" />{" "}
              </>
            ))}
        </span>
      )}
      {state.quickAnalysisResult.hasRecommendation && (
        <span>
          <b>
            {nbRecommendations}{" "}
            <Text
              resource={{
                key:
                  nbRecommendations === 1
                    ? "quickAnalysis.interpretation.summary.recommendation"
                    : "quickAnalysis.interpretation.summary.recommendations",
              }}
            />
          </b>
          {isNumber(state.quickAnalysisResult.alertScore) &&
            (state.quickAnalysisResult.categorizedTopics ? `, ` : "")}
          {isNumber(state.quickAnalysisResult.alertScore) && (
            <>
              {" "}
              <Text resource="term.and" />
            </>
          )}{" "}
        </span>
      )}
      {isNumber(state.quickAnalysisResult.alertScore) && (
        <Text
          resource={{
            key: "quickAnalysis.interpretation.summary.alertScore",
            args: [`${Math.round(state.quickAnalysisResult.alertScore)}`],
          }}
        />
      )}
      .
      <br />
      {(state.quickAnalysisResult.categorizedTopics ||
        state.quickAnalysisResult.hasRecommendation) && (
        <Text resource="quickAnalysis.interpretation.viewResults" />
      )}
    </p>
  );

  const hasCategorizedTopics =
    state.quickAnalysisResult.categorizedTopics &&
    Object.keys(state.quickAnalysisResult.categorizedTopics).length > 0;

  return (
    <div
      className={
        state.quickAnalysisState === QuickAnalysisState.results
          ? "fade-in"
          : state.quickAnalysisState === QuickAnalysisState.edit
          ? "fade-out"
          : undefined
      }
    >
      <CommentResultsIcons
        sentiment={SentimentType[state.quickAnalysisResult["commentSentiment"]]}
        interpretations={getCommentInterpretationCounts(
          state.quickAnalysisResult,
          DEFAULT_ALERT_THRESHOLD
        )}
        interpretationTooltipData={state.quickAnalysisResult as any}
        isCustomModel={false}
      />
      <StyledInterpretationTitle>
        <Text resource="quickAnalysis.interpretation.title" />:
      </StyledInterpretationTitle>
      <StyledInterpretationContainer>
        <DominantSentiment sentiment={state.quickAnalysisResult.commentSentiment} />
        {(hasCategorizedTopics || state.quickAnalysisResult.hasRecommendation) &&
          descriptionParagraph}
        {hasCategorizedTopics && (
          <div>
            <CollapsibleName
              useLocalExpandedState
              name={`${getResource(
                "quickAnalysis.interpretation.topicInsights.title",
                nbTopicInsights
              )}`}
              defaultExpanded={true}
              customStyles={{
                arrowColor: Color.gray20,
                textColor: Color.gray50,
                fontSize: 15,
                fontWeight: "bold",
              }}
              padChildren
            >
              <TopicInsights data={state.quickAnalysisResult.categorizedTopics as any} />
            </CollapsibleName>
          </div>
        )}
        {state.quickAnalysisResult.hasRecommendation && (
          <div>
            <CollapsibleName
              useLocalExpandedState
              name={`${getResource(
                "quickAnalysis.interpretation.recommendations.title",
                nbRecommendations
              )}`}
              defaultExpanded={true}
              customStyles={{
                arrowColor: Color.gray20,
                textColor: Color.gray50,
                fontSize: 15,
                fontWeight: "bold",
              }}
              padChildren
            >
              <Recommendations
                categorizedRecommendations={
                  state.quickAnalysisResult.categorizedRecommendations as any
                }
                nonCategorizedRecommendations={state.quickAnalysisResult.recommendations as any}
              />
            </CollapsibleName>
          </div>
        )}
        {isNumber(state.quickAnalysisResult.alertScore) && (
          <AlertScore value={state.quickAnalysisResult.alertScore} />
        )}
      </StyledInterpretationContainer>
    </div>
  );
};

const StyledInterpretationTitle = styled.div`
  font-size: 0.875em;
  font-weight: bold;
  color: ${Color.gray50};
  margin-bottom: 5px;
`;

const StyledInterpretationContainer = styled.div`
  background-color: ${Color.sky15};
  border: 1px solid ${Color.sky50};
  border-radius: 2px;
  max-width: 716px;
  font-size: 0.875em;
  padding: 12px;
  color: ${Color.gray50};
  p {
    ${Color.gray50}
  }
`;
