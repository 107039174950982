import React, { HTMLAttributes } from "react";
import styled from "styled-components";

import { LoadingDots } from "../LoadingDots";

type Props = {
  text?: string;
} & HTMLAttributes<HTMLDivElement>;

export const ProcessingPlaceholder = (props: Props) => {
  return (
    <StyledPlaceholder {...props}>
      <div className="loading-dots">
        <LoadingDots />
      </div>
      {props.text && <div className="text">{props.text}</div>}
    </StyledPlaceholder>
  );
};

const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .loading-dots {
    height: 13px;
    position: relative;

    .loading-anim {
      top: -10px;
      position: absolute;
    }
  }

  .text {
    margin-left: 5px;
  }
`;
