import React, { useContext } from "react";

import { AnalysisContext } from "context/AnalysisContext";

import { Topic } from "ts/topic";
import { TopicsTableRow } from "./TopicsTableRow";
import { TopicsListView } from "@explorance/mly-types";

type Props = {
  aggregationDepth: number;
  topics?: Topic[];
  selectedTopicPath: Array<string>;
  isImmediateParentExpanded: boolean;
  isAncestorExpanded: boolean;
  view: TopicsListView;
  handleTopicPathSelection(topicPath: Array<string>): void;
};

export const TopicsTableRowContainer = ({
  aggregationDepth,
  topics,
  selectedTopicPath,
  isImmediateParentExpanded,
  isAncestorExpanded,
  view,
  handleTopicPathSelection,
}: Props) => {
  const [state] = useContext(AnalysisContext);

  return (
    <>
      {topics
        .filter((topic) => (state.threshold !== 0 ? topic.comments !== 0 : true))
        .map((topic) => (
          <TopicsTableRow
            key={topic.fullPath}
            depth={aggregationDepth}
            topic={topic}
            selectedTopicPath={selectedTopicPath}
            isImmediateParentExpanded={isImmediateParentExpanded}
            isAncestorExpanded={isAncestorExpanded}
            allTopics={topics}
            view={view}
            handleTopicPathSelection={handleTopicPathSelection}
          />
        ))}
    </>
  );
};
