import React from "react";
import styled from "styled-components";

import { CategorizedTopic } from "ts/enums/categorizedTopic";
import { Color } from "ts/enums/color";
import { ResultTagSection } from "./ResultTagSection";

type Props = {
  categorizedRecommendations: Record<CategorizedTopic, string[]> | Record<string, never>;
  nonCategorizedRecommendations: CategorizedTopic[];
};

export const Recommendations = ({
  categorizedRecommendations = {},
  nonCategorizedRecommendations = [],
}: Props) => {
  const ORDERED_FILTERED_CATEGORIZED_RECOMMENDATIONS = [
    CategorizedTopic.doMore,
    CategorizedTopic.doLess,
    CategorizedTopic.start,
    CategorizedTopic.stop,
    CategorizedTopic.continue,
    CategorizedTopic.change,
    CategorizedTopic.generalRecommendation,
  ].filter((ct) => categorizedRecommendations[ct]);

  return (
    <StyledRecommendations>
      {Object.keys(categorizedRecommendations).length >= 0 &&
        ORDERED_FILTERED_CATEGORIZED_RECOMMENDATIONS.map((sentiment, i) => (
          <ResultTagSection
            key={i}
            sentiment={sentiment}
            topics={categorizedRecommendations[sentiment]}
            headerColor={Color.gray50}
          />
        ))}

      {nonCategorizedRecommendations.length > 0 && (
        <ResultTagSection
          sentiment="notCategorized"
          topics={nonCategorizedRecommendations}
          headerColor={Color.gray50}
          isNonCatRec
        />
      )}
    </StyledRecommendations>
  );
};

const StyledRecommendations = styled.div`
  font-size: 12px;
`;
