import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RecipientGroupsApiData, SharingUsersApiData } from "ts/sharing";
import { fetchSharedGroupsData, fetchSharedUsersData } from "./thunks";
import {
  GroupSharingSortingParameter,
  RecipientUsersSortingParameter,
  SortingOrder,
} from "@explorance/mly-types";

type sharedWithState = {
  isGroupsApiDataLoading: boolean;
  isSharedUsersApiDataLoading: boolean;
  sharedGroupsApiData: RecipientGroupsApiData;
  sharedUsersApiData: SharingUsersApiData;
  sortingColumnUsers: RecipientUsersSortingParameter;
  sortingColumnGroups: GroupSharingSortingParameter;
  sortOrder: SortingOrder;
  currentPage: number;
  expandedRowId: number;
  returningFromPreview: boolean;
};

const initialState: sharedWithState = {
  isGroupsApiDataLoading: true,
  isSharedUsersApiDataLoading: true,
  sharedGroupsApiData: {
    groups: [],
    itemCount: null,
    totalCount: null,
  },
  sharedUsersApiData: {
    users: [],
    itemCount: null,
    totalCount: null,
  },
  sortingColumnUsers: RecipientUsersSortingParameter.FirstName,
  sortingColumnGroups: GroupSharingSortingParameter.GroupName,
  sortOrder: SortingOrder.DESC,
  currentPage: 1,
  expandedRowId: null,
  returningFromPreview: false,
};

const sharedWithSlice = createSlice({
  name: "sharedWithSlice",
  initialState,
  reducers: {
    setSharedGroupsApiData: (state, action: PayloadAction<RecipientGroupsApiData>) => {
      state.sharedGroupsApiData = action.payload;
    },
    setSortingColumnUsers: (state, action: PayloadAction<RecipientUsersSortingParameter>) => {
      state.sortingColumnUsers = action.payload;
    },
    setSortingColumnGroups: (state, action: PayloadAction<GroupSharingSortingParameter>) => {
      state.sortingColumnGroups = action.payload;
    },
    setSortOrder: (state, action: PayloadAction<SortingOrder>) => {
      state.sortOrder = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setExpandedRowId: (state, action: PayloadAction<number>) => {
      state.expandedRowId = action.payload;
    },
    setReturningFromPreview: (state, action: PayloadAction<boolean>) => {
      state.returningFromPreview = action.payload;
    },
    clearSharedWithState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSharedUsersData.fulfilled, (state, action) => {
      state.isSharedUsersApiDataLoading = false;
      state.sharedUsersApiData = action.payload;
    });
    builder.addCase(fetchSharedUsersData.rejected, (state) => {
      state.isSharedUsersApiDataLoading = false;
    });
    builder.addCase(fetchSharedGroupsData.fulfilled, (state, action) => {
      state.isGroupsApiDataLoading = false;
      state.sharedGroupsApiData = action.payload;
    });
    builder.addCase(fetchSharedGroupsData.rejected, (state) => {
      state.isGroupsApiDataLoading = false;
    });
  },
});

export const {
  setSharedGroupsApiData,
  clearSharedWithState,
  setSortingColumnUsers,
  setSortingColumnGroups,
  setSortOrder,
  setCurrentPage,
  setExpandedRowId,
  setReturningFromPreview,
} = sharedWithSlice.actions;

export default sharedWithSlice.reducer;
