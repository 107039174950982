import React from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import { UserDemographic } from "context/UserProfileContext";

import { Select } from "components/_inputs/Select";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

import { DemographicCustomCondition } from "ts/sharing";
import { Color } from "ts/enums/color";
import { DemographicFilterHeader } from "ts/filters/demographicFilter";
import { ButtonVariant } from "ts/enums/button";
import { isOneCustomConditionEmpty } from "utils/sharing";

type Props = {
  index: number;
  customConditionDemographics: DemographicCustomCondition;
  savedCustomConditions: DemographicCustomCondition[];
  analysisDemographicHeaders: DemographicFilterHeader[];
  userDemographics: UserDemographic[];
  showCustomConditionError: boolean;
  updateDemographicCustomConditions: (
    updatedCustomConditions: DemographicCustomCondition[]
  ) => void;
  handleHideCustomConditionError: () => void;
};

export const CustomCondition = ({
  index,
  customConditionDemographics,
  savedCustomConditions,
  analysisDemographicHeaders,
  userDemographics,
  showCustomConditionError,
  updateDemographicCustomConditions,
  handleHideCustomConditionError,
}: Props) => {
  const { analysisDemographic, userDemographic } = customConditionDemographics;

  const commentsDemographicHasError =
    showCustomConditionError && !analysisDemographic && userDemographic;

  const userDemographicHasError =
    showCustomConditionError && !userDemographic && analysisDemographic;

  // functions
  const selectCommentersDemographicOption = (name: string) => {
    const selectedHeader = analysisDemographicHeaders.find((adh) => adh.name === name);
    const copy = cloneDeep(savedCustomConditions);
    copy[index].analysisDemographic = selectedHeader.name;
    updateDemographicCustomConditions(copy);
    validateConditions(copy);
  };

  const selectUserDemographicOption = (name: string) => {
    const selectedUserDemographic = userDemographics.find((aud) => aud.name === name);
    const copy = cloneDeep(savedCustomConditions);
    copy[index].userDemographic = selectedUserDemographic.name;
    updateDemographicCustomConditions(copy);
    validateConditions(copy);
  };

  const deleteCondition = () => {
    const copy = cloneDeep(savedCustomConditions);
    copy.splice(index, 1);
    updateDemographicCustomConditions(copy);
    validateConditions(copy);
  };

  const validateConditions = (updatedCustomConditions: DemographicCustomCondition[]) => {
    if (showCustomConditionError) {
      const hasError = isOneCustomConditionEmpty(updatedCustomConditions);
      !hasError && handleHideCustomConditionError();
    }
  };

  return (
    <StyledCustomCondition>
      <span>
        <Text
          resource={{
            key:
              index === 0
                ? "sharing.permissionForm.customCondition.firstCondition.phrase"
                : "sharing.permissionForm.customCondition.subsequentConditions.phrase",
          }}
        />
      </span>
      <Select
        selectedOption={{
          label: analysisDemographic,
          value: analysisDemographic,
        }}
        options={analysisDemographicHeaders.map((analysisDemHeader) => ({
          label: analysisDemHeader.name,
          value: analysisDemHeader.name,
        }))}
        handleChange={selectCommentersDemographicOption}
        dropdownWidth="100%"
        buttonStyle={{ borderRadius: "2px", width: "100%" }}
        buttonVariant={
          commentsDemographicHasError ? ButtonVariant.error : ButtonVariant.outlineGray
        }
        dropdownMaxHeight="260px"
        placeholderKey="sharing.permissionForm.customCondition.defaultCommentersDemographic.label"
      />
      <span>
        <Text resource="sharing.permissionForm.customCondition.equalsTo.phrase" />
      </span>
      <Select
        selectedOption={{
          label: userDemographic,
          value: userDemographic,
        }}
        options={userDemographics.map((userDem) => ({
          label: userDem.name,
          value: userDem.name,
        }))}
        handleChange={selectUserDemographicOption}
        dropdownWidth="100%"
        buttonStyle={{ borderRadius: "2px", width: "100%" }}
        buttonVariant={userDemographicHasError ? ButtonVariant.error : ButtonVariant.outlineGray}
        dropdownMaxHeight="260px"
        placeholderKey="sharing.permissionForm.customCondition.defaultUserDemographic.label"
      />
      <StyledDeleteContainer onClick={deleteCondition}>
        <Icon type={IconType.xCircle} color={Color.gray30} size={14} />
      </StyledDeleteContainer>
    </StyledCustomCondition>
  );
};

const StyledCustomCondition = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    font-size: 15px;
    color: ${Color.gray50};
  }

  /* setting the widths of Select dropdowns */
  @media (max-width: 1365px) {
    :first-child {
      > :nth-child(2),
      > :nth-child(4) {
        width: 28%;
      }
    }

    :not(:first-child) {
      > :nth-child(2),
      > :nth-child(4) {
        width: 34.1%;
      }
    }
  }

  @media (min-width: 1366px) {
    :first-child {
      > :nth-child(2),
      > :nth-child(4) {
        width: 33.4%;
      }
    }

    :not(:first-child) {
      > :nth-child(2),
      > :nth-child(4) {
        width: 38%;
      }
    }
  }
`;

const StyledDeleteContainer = styled.span`
  cursor: pointer;
`;
